<template>
  <svg width="34" height="25" viewBox="0 0 34 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="1.1001" width="33" height="23" rx="3.5" fill="white" stroke="#D9D9D9"/>
  </svg>
</template>

<script>
export default {
  name: 'OtherCardIcon'
}
</script>