<template>
  <div @click="action"
       class="flex bg-white items-center justify-between px-10 py-6 rounded-lg w-full cursor-pointer active:opacity-75">
    <div class="flex items-center">
      <slot name="leftIcon"></slot>
      <span class="ml-4 label-m-500">{{ text }}</span>
    </div>
    <ChevronLeftIcon class="transform rotate-180 w-6 h-6 text-secondary-main"/>
  </div>
</template>

<script>

import ChevronLeftIcon from "@/components/shared/svg/ChevronLeftIcon";

export default {
  name: 'MenuBox',
  components: {
    ChevronLeftIcon

  },
  props: {
    text: {type: String, default: "I'm a Text"},
    classes: {
      type: String,
      default: 'rounded-md bg-secondary-main px-5 py-3 text-white font-semibold'
    }
  },
  methods: {
    action() {
      this.$emit('onClick');
    }
  }
}
</script>
