<template>
  <div class="h-screen grid grid-cols-1 lg:grid-cols-2">
    <!--    Left col-->
    <div
      class="flex flex-col items-center justify-center gradient text-white relative overflow-hidden"
    >
      <Blur class="absolute left-0 top-0 z-0" />
      <div class="relative z-10 w-full md:w-6/12 px-6 md:px-0">
        <h2 class="text-white -mt-32 mb-24 heading-xl-600">Login</h2>
        <ValidationObserver v-slot="{ invalid, handleSubmit }" rules="required">
          <form
            @submit.prevent="handleSubmit(login)"
            class="flex flex-col items-center justify-center space-y-8"
          >
            <div class="w-full">
              <ValidationProvider
                name="Email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <TextInput
                  label="Email"
                  background="bg-primary"
                  placeholder="Email"
                  x-padding="px-5"
                  label-color="text-white"
                  type="email"
                  :errors="errors"
                  v-model="form.email"
                  @enterPressed="handleSubmit(login)"
                />
              </ValidationProvider>
            </div>
            <div class="w-full">
              <ValidationProvider
                name="Password"
                rules="required"
                v-slot="{ errors }"
              >
                <TextInput
                  label="Password"
                  background="bg-primary"
                  placeholder="Password"
                  x-padding="px-5"
                  label-color="text-white"
                  type="password"
                  :errors="errors"
                  v-model="form.password"
                  @enterPressed="handleSubmit(login)"
                />
              </ValidationProvider>
            <router-link :to="{name:'ForgotPassword'}" class="mt-2 button-label-m-500 flex justify-end">Forgot your password?</router-link>
            </div>
            <p v-show="error" class="text-sm text-left w-full">{{ error }}</p>
            <!-- Divider-->
            <div class="flex items-center w-full">
              <span class="w-full border border-auxiliary-blue"></span>
              <span class="flex-shrink-0 mx-10">or</span>
              <span class="w-full border border-auxiliary-blue"></span>
            </div>
            <div class="grid grid-cols-2 gap-x-4 w-full">
              <button
                class="bg-white flex justify-center items-center rounded-xl py-3"
                @click.prevent="oauthLoginRedirect(providers.Google)"
              >
                <GoogleIcon />
              </button>
              <button
                class="bg-white flex justify-center items-center rounded-xl py-3"
                @click.prevent="oauthLoginRedirect(providers.Facebook)"
              >
                <FacebookIcon />
              </button>
            </div>

            <button type="submit" :disabled="invalid" class="w-full">
              <ButtonIcon
                text="Log in"
                :disabled="invalid"
                :loading="loading"
                class="w-full"
              />
            </button>
            <router-link :to="{ name:'UserType' }" class="body-m-400">
              Don't have an account? Create one here.
            </router-link>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <!--    Right col-->
    <div class="bg-cream relative overflow-hidden hidden lg:block">
      <img class="absolute right-0 top-0 mt-32" src="@/assets/images/talentpond_preview.png">
    </div>
  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import { mapActions } from "vuex";
import Blur from "@/components/shared/svg/Blur";
import GoogleIcon from "@/components/shared/svg/GoogleIcon";
import FacebookIcon from "@/components/shared/svg/FacebookIcon";
import TextInput from "@/components/shared/inputs/TextInput";
import { Providers } from "@/views/auth/OauthProviders";
import API from "@/api/index";

export default {
  name: "Login",
  components: {
    TextInput,
    FacebookIcon,
    GoogleIcon,
    Blur,
    ButtonIcon,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        device_name: "desktop",
      },
      error: null,
      loading: false,
      providers: Providers,
    };
  },
  mounted() {
    const callbackData = this.$route.query;
    const { provider } = this.$route.params;

    if (!callbackData || !provider) {
      return;
    }

    this.loading = true;
    this.oauthLogin({ callbackData, provider, device_name: this.form.device_name })
      .then((response) => {
        if (!response) {
          this.error = `Couldn't get a response from the server, try again later`;
          return;
        }
        
        const { continue_user_creation } = response;
        if(!continue_user_creation) {
          this.$router.push({ name: "Home" });
          return;
        }

        this.$router.push({ name: "UserType" });
      })
      .catch((error) => {
        this.error = error;
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    ...mapActions({
      authLogin: "auth/login",
      oauthLogin: "auth/oauthLogin",
    }),
    login() {
      this.loading = true;
      this.authLogin(this.form)
        .then((response) => {
          if (response) {
            this.$router.push({ name: "Home" });
          }
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => (this.loading = false));
    },
    async oauthLoginRedirect(provider) {
      try {
        const response = await API.get(`${provider}/auth`);

        window.location = response.data.url;
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>

