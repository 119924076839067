<template>
  <div class="flex items-center justify-between border-b pb-3">
    <span>{{label}}</span>
    <Toggle @update="active = !active" :active="active" color="bg-secondary-main"/>
  </div>
</template>

<script>
import Toggle from "@/components/shared/Toggle";

export default {
  name: "NotificationRow",
  components: {Toggle},
  props:{
    label:String
  },
  data() {
    return {
      active: false
    }
  }
}
</script>

<style scoped>

</style>
