<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.88467 12.438H4.23067C3.86133 12.438 3.562 12.1387 3.562 11.7693V10.1147C3.562 9.93733 3.49133 9.76733 3.366 9.642L2.196 8.472C1.93467 8.21067 1.93467 7.78733 2.196 7.52667L3.378 6.34467C3.496 6.22667 3.562 6.06733 3.562 5.90067V4.23067C3.562 3.86133 3.86133 3.562 4.23067 3.562H5.88533C6.06267 3.562 6.23267 3.49133 6.358 3.366L7.528 2.196C7.78933 1.93467 8.21267 1.93467 8.47333 2.196L9.64333 3.366C9.76867 3.49133 9.93867 3.562 10.116 3.562H11.7707C12.14 3.562 12.4393 3.86133 12.4393 4.23067V5.88533C12.4393 6.06267 12.51 6.23267 12.6353 6.358L13.8053 7.528C14.0667 7.78933 14.0667 8.21267 13.8053 8.47333L12.6353 9.64333C12.51 9.76867 12.4393 9.93867 12.4393 10.116V11.7707C12.4393 12.14 12.14 12.4393 11.7707 12.4393H10.116C9.93867 12.4393 9.76867 12.51 9.64333 12.6353L8.47333 13.8053C8.212 14.0667 7.78867 14.0667 7.528 13.8053L6.358 12.6353C6.232 12.508 6.062 12.438 5.88467 12.438Z"
      fill="#ABF1C1"
      stroke="#26355C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 7L7.5 9.5L6 8"
      stroke="#26355C"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "CheckStarIcon",
};
</script>

<style scoped></style>
