import * as api from '@/api/auth'

const auth = {
  namespaced: true,

  state: {
    user: {},
    type: null,
    registerStep: 1,
    form: {
      user: {},
      isOauthRegistration: false,
    },
    checkEmail: null
  },

  getters: {
    authUser: state => state.user,
    loggedIn: state => !!Object.keys(state.user).length,
    guest: () => {
      const storageItem = window.localStorage.getItem('guest');
      if (!storageItem) return true;
      if (storageItem === 'isGuest') return true;
      if (storageItem === 'isNotGuest') return false;
    },
  },

  actions: {
    login({ commit, dispatch }, payload) {
      return api.setCSRFCookie().then(() => {
        return api.login(payload).then(({ data }) =>
          handleLoginSuccess(data, commit, dispatch)
        ).catch(error =>
          Promise.reject(error)
        )
      }).catch(error => Promise.reject(error));
    },

    oauthLogin({ commit, dispatch }, payload) {
      const { provider, callbackData, device_name } = payload;

      return api.setCSRFCookie().then(() => {
        return api.oauthLogin(provider, callbackData, device_name).then(({ data }) =>
          handleOauthLoginSuccess(data, commit, dispatch)
        ).catch(error => Promise.reject(error))
      }).catch(error => Promise.reject(error));
    },

    register({ commit, dispatch }, payload) {
      return api.setCSRFCookie().then(() => {
        return api.register(payload).then(({ data }) => {
          if (data.success) {
            commit('setUser', data.data.user)
            dispatch('setGuest', { value: 'isNotGuest' });
            return Promise.resolve(true)
          }
          return Promise.resolve(false)
        }).catch(error => {
          return Promise.reject(error)
        })
      })
    },

    confirmPassword(_, payload) {
      return api.setCSRFCookie().then(() => {
        return api.confirmPassword(payload)
      }).catch(error => Promise.reject(error));
    },

    logout({ commit, dispatch }) {
      return api.logout().then(() => {
        dispatch('setGuest', { value: 'isGuest' });
        commit('setUser', {})
        return Promise.resolve(true)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    forgotPassword(_, payload) {
      return api.setCSRFCookie().then(() => {
        return api.requestResetPassword(payload).then(() => {
          return Promise.resolve(true)
        }).catch(error => {
          return Promise.reject(error)
        })
      })
    },

    resetPassword(_, payload) {
      return api.setCSRFCookie().then(() => {
        return api.resetPassword(payload).then(() => {
          return Promise.resolve(true)
        }).catch(error => {
          return Promise.reject(error)
        })
      })
    },

    getUser({ commit, dispatch }) {
      return api.getUser().then(({ data }) => {
        commit('setUser', data.data)

        return Promise.resolve(data.data)
      }).catch(error => {
        dispatch('setGuest', { value: 'isGuest' });
        commit('setUser', {})
        return Promise.reject(error)
      })
    },

    checkEmail({ commit }, payload) {
      return api.setCSRFCookie().then(() => {
        return api.checkEmail(payload).then(({ data }) => {
          commit('checkEmail', data.data)

          return Promise.resolve(data.data)
        }).catch(error => {
          return Promise.reject(error)
        })
      })
    },

    impersonate({ commit, dispatch }, payload) {
      return api.setCSRFCookie().then(() => {
        return api.impersonate(payload).then(({ data }) => {
          if (data.success) {
            commit('setUser', data.user);
            dispatch('setGuest', { value: 'isNotGuest' });
            return Promise.resolve(true)
          }
          return Promise.resolve(false)
        }).catch(error => {
          return Promise.reject(error)
        })
      })
    },
    
    loginFromInvitation({ commit, dispatch }, payload) {
      return api.setCSRFCookie().then(() => {
        return api.loginFromInvitation(payload).then(({ data }) => {
          if (data.success) {
            commit('setUser', data.user);
            dispatch('setGuest', { value: 'isNotGuest' });
            return { job: data.job };
          }
          return Promise.resolve(false)
        }).catch(error => {
          return Promise.reject(error)
        })
      })
    },
    
    loginFromBid({ commit, dispatch }, payload) {
      return api.setCSRFCookie().then(() => {
        return api.loginFromBid(payload).then(({ data }) => {
          if (data.success) {
            commit('setUser', data.user);
            dispatch('setGuest', { value: 'isNotGuest' });
            return { bid: data.bid };
          }
          return Promise.resolve(false)
        }).catch(error => {
          return Promise.reject(error)
        })
      })
    },

    setGuest(context, { value }) {
      window.localStorage.setItem('guest', value);
    },
  },

  mutations: {
    initProForm(state) {
      state.form.pro = {}
      delete state.form.church;
    },

    setUser(state, data) {
      state.user = data
    },

    setOauthUserRegistrationForm(state, data) {
      state.form.user = data;
      state.form.isOauthRegistration = true;
    },

    increaseStep(state) {
      state.registerStep++;
    },

    decreaseStep(state) {
      state.registerStep--;
    },

    setType(state, type) {
      state.type = type;
    },

    setUserCountry(state, country) {
      if (Object.keys(country).length) {
        state.form.user.country = country.code;
      } else {
        delete state.form.user.country;
      }
    },

    setProCategories(state, categories) {
      state.form.pro.categories = categories.map(category => category.id);
    },

    setProSkills(state, skills) {
      state.form.pro.tags = skills.map(skill => skill.name);
    },

    setProProfileImage(state, url) {
      state.form.pro.profile_image_url = url
    },

    setProCoverImage(state, url) {
      state.form.pro.cover_image_url = url
    },

    setProPortfolio(state, portfolio) {
      state.form.pro.portfolio = portfolio
    },

    checkEmail(state, data) {
      state.checkEmail = data
    },

    resetForm(state) {
      state.form = {
        user: {},
        isOauthRegistration: false
      }
    },

    updateChurchCapabilityToCreateJobs(state) {
      const { user } = state;
      if (user && user.type && user.type.church) {
        state.user.type.church.can_create_jobs = true;
      }
    },
  },
}

function handleLoginSuccess(data, commit, dispatch) {
  if (data.success) {
    commit('setUser', data.data.user);
    dispatch('setGuest', { value: 'isNotGuest' });

    return Promise.resolve(true);
  }

  return Promise.reject(data.message);
}

function handleOauthLoginSuccess(response, commit, dispatch) {
  if (!response.success) {
    return Promise.reject(data.message);
  }

  const { data } = response;
  const { continue_user_creation } = data;
  if (continue_user_creation) {
    commit('setOauthUserRegistrationForm', data.user);

    return Promise.resolve(data);
  }

  commit('setUser', data.user);
  dispatch('setGuest', { value: 'isNotGuest' });

  return Promise.resolve(true);
}

export default auth
