<template>
  <svg width="36" height="35" viewBox="0 0 36 35" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M18 30.625V30.625C10.7506 30.625 4.875 24.7494 4.875 17.5V17.5C4.875 10.2506 10.7506 4.375 18 4.375V4.375C25.2494 4.375 31.125 10.2506 31.125 17.5V17.5C31.125 24.7494 25.2494 30.625 18 30.625Z"
          :stroke="stroke" :stroke-width="strokeWidth" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M23.8337 14.5834L16.542 21.875L12.167 17.5" :stroke="stroke" :stroke-width="strokeWidth" stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "CheckCircleIcon",
  props: {
    strokeWidth: { type: String, default: '2.33333'},
    stroke: { type: String, default: 'white'},
  }
}
</script>

<style scoped>

</style>
