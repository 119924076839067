import ForgotPassword from "@/views/auth/ForgotPassword";
import ResetPassword from "@/views/auth/ResetPassword";
import Login from "@/views/auth/Login";
import store from '../store/index';

const guest = (from, to, next) => {
  const isGuest = store.getters['auth/guest'];
  if (!isGuest) {
    return next({ name: 'Home' });
  }

  return next();
};

const routes = [
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/auth/register/index'),
    beforeEnter: guest,
    children: [
      {
        path: '/',
        name: 'UserType',
        component: () => import(/* webpackChunkName: "userType" */ '../views/auth/register/UserType'),
        meta: {
          rootClasses: true
        },
      },
      {
        path: '/account-information',
        name: 'UserForm',
        component: () => import(/* webpackChunkName: "accountInfo" */ '../views/auth/register/UserForm'),
        meta: {
          rootClasses: true
        },
      },
      {
        path: '/pro-information',
        name: 'ProForm',
        component: () => import(/* webpackChunkName: "accountInfo" */ '../views/auth/register/ProForm'),
        meta: {
          rootClasses: true
        },
      },
      {
        path: '/church-information',
        name: 'ChurchForm',
        component: () => import('../views/auth/register/ChurchForm'),
        meta: {
          rootClasses: true
        },
      },
    ],
    meta: {
      rootClasses: true
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: guest,
    meta: {
      rootClasses: true
    },
    children: [
      {
        path: ':provider/callback',
        name: 'LoginOauthCallbackHandler',
        component: Login,
        meta: {
          rootClasses: true
        },
      },
    ],
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    beforeEnter: guest,
    meta: {
      rootClasses: true
    },
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    beforeEnter: guest,
    meta: {
      rootClasses: true
    },
  },
]

export default routes;