<template>
  <div id="app">
    <!--Header-->
    <Header v-if="showHeader" />
    <MobileMenu />
    <!--Content-->
    <main class="bg-cream" :style="`min-height: calc(100vh - ${mainHeight})`">
      <div
        :class="rootClasses ? null : 'container mx-auto px-6 py-10 lg:px-20 xl:px-32'"
      >
        <router-view :key="$route.fullPath" />
      </div>
    </main>
    <!--Footer-->
    <Footer v-if="showFooter" />
    <portal-target name="modal" multiple></portal-target>
    <Modal2 v-show="modalVisible" @close="hideModal" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import anime from "animejs";
import Header from "@/components/shared/layout/Header";
import Footer from "@/components/shared/layout/Footer";
import MobileMenu from "@/components/shared/layout/MobileMenu";
import Modal2 from '@/components/shared/Modal2';

export default {
  name: "App",
  components: { Footer, Header, MobileMenu, Modal2 },

  mounted() {
    if (this.loggedIn) {
      this.$store.dispatch("auth/getUser");
      return;
    }
  },
  methods: {
    ...mapMutations({
      hideModal: 'general/hideModal',
    }),

    enter(el) {
      anime({
        targets: el,
        translateX: [300, 0],
        duration: 100,
        easing: "easeInOutCubic",
      });
    },
  },
  computed: {
    ...mapGetters({
      loggedIn: 'auth/loggedIn',
    }),

    ...mapState({
      modalVisible: state => state.general.modalVisible,
    }),

    rootClasses() {
      return this.$route.meta.rootClasses;
    },
    currentRoute() {
      return this.$route.name;
    },

    showHeader() {
      return (
        this.currentRoute == "Home" ||
        this.loggedIn &&
        this.currentRoute !== "Login" &&
        this.currentRoute !== "Register" &&
        this.currentRoute !== "UserType" &&
        this.currentRoute !== "UserForm" &&
        this.currentRoute !== "ProForm" &&
        this.currentRoute !== "NotFound" &&
        this.currentRoute !== "Welcome" &&
        this.currentRoute !== "ForgotPassword" &&
        this.currentRoute !== "ResetPassword"
      );
    },
    showFooter() {
      return this.currentRoute === "Home";
    },
    mainHeight() {
      return this.showFooter ? "314px" : "110px";
    },
  },
};
</script>
