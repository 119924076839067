<template>
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.96274 13.4554L6.97858 13.4569"
      stroke="#2C5DE5"
      stroke-width="2.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.8439 6.5123L22.4409 7.91538"
      stroke="#2C5DE5"
      stroke-width="2.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.8994 3.53606V5.52022"
      stroke="#2C5DE5"
      stroke-width="2.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.95482 6.5123L11.3565 7.91538"
      stroke="#2C5DE5"
      stroke-width="2.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.8202 13.4569L24.836 13.4554"
      stroke="#2C5DE5"
      stroke-width="2.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.7553 18.4173V26.2122C29.7553 28.5604 27.8517 30.4639 25.5035 30.4639H8.49646C6.14827 30.4639 4.24469 28.5604 4.24469 26.2122V18.4173"
      stroke="#2C5DE5"
      stroke-width="2.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.24469 21.9604H10.4551C10.9915 21.9604 11.4819 22.2632 11.7221 22.7427L12.3557 24.0112C12.5958 24.4917 13.0869 24.7952 13.6241 24.7949H20.3745C20.9117 24.7952 21.4028 24.4917 21.6429 24.0112L22.2764 22.7441C22.5166 22.2636 23.0077 21.9601 23.5449 21.9604H29.7553"
      stroke="#2C5DE5"
      stroke-width="2.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.3474 13.2216L17 11.5662V18.182"
      stroke="#2C5DE5"
      stroke-width="2.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.3517 18.1862H18.6483"
      stroke="#2C5DE5"
      stroke-width="2.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "OneTimeJobIcon",
};
</script>

<style scoped></style>
