<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class=" stroke-current">
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.3077 18.981H5.30768C4.20268 18.981 3.30768 18.086 3.30768 16.981V7.01904C3.30768 5.91404 4.20268 5.01904 5.30768 5.01904H19.3077C20.4127 5.01904 21.3077 5.91404 21.3077 7.01904V16.982C21.3077 18.086 20.4127 18.981 19.3077 18.981V18.981Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.3077 9L12.3077 12L7.30768 9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
name:'InboxIcon'
}
</script>
