<template>
  <div class="bg-cream">
    <SubHeader/>
    <main class="py-3 lg:py-10 container mx-auto px-6 lg:px-16">
      <component :is="`Step${jobPostingStep}`"/>
    </main>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import SubHeader from "@/components/jobs/postJob/SubHeader";
import Step1 from "@/components/jobs/postJob/Step1";
import Step2 from "@/components/jobs/postJob/Step2";
import Step3 from "@/components/jobs/postJob/Step3";

export default {
  name: "PostJob",
  components: {SubHeader, Step1, Step2, Step3},
  props: {
    startFrom2: { type: Boolean, default: false },
  },

  mounted() {
    this.resetJobPostingSteps()
    if (this.startFrom2) {
      this.setStep(2);
    }
  },

  methods: {
    ...mapMutations({
      resetJobPostingSteps: 'jobs/resetJobPostingSteps',
      setStep: 'jobs/setJobPostingStep',
    }),
  },

  computed:{
    ...mapState({
      jobPostingStep: state => state.jobs.jobPostingStep,
    }),
  },
}
</script>

