<template>
  <ButtonIcon
    @onClick="invitePro"
    :text="isAlreadyInvited ? 'Invited' : 'Invite pro to job'"
    :background="isAlreadyInvited ? 'bg-secondary-main' : 'bg-white'"
    border="border border-secondary-dark"
    :hover-background="isAlreadyInvited ? 'hover:bg-white' : 'hover:bg-secondary-dark'"
    :hover-color="isAlreadyInvited ? 'hover:text-secondary-dark' :'hover:text-white'"
    x-padding="px-8"
    :color="isAlreadyInvited ? 'text-white' : 'text-secondary-dark'"
    font-size="text-xs lg:text-sm"
    font-weight="font-normal"
    :loading="loading"
    :disabled="isAlreadyInvited"
  />
</template>

<script>
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import {mapActions, mapState} from "vuex";
export default {
  name: 'InviteProToJob',
  components: {ButtonIcon},

  props: {
    pro: Object,
  },

  data() {
    return {
      isAlreadyInvited: false,
      loading: false,
    }
  },

  mounted() {
    this.isAlreadyInvited = this.job.invitations.map(invitation => invitation.pro_id).includes(this.pro.id)
  },
  
  methods: {
    ...mapActions({
      inviteProToJob: 'jobs/inviteProToJob',
      fetchJob: 'jobs/fetchJob'
    }),

    async invitePro() {
      this.loading = true;
      const data = {
        pro_id: this.pro.id,
        email: this.pro.email,
      }
      await this.inviteProToJob(data)
      await this.fetchJob({jobId: this.job.id, includes: 'invitations'});
      this.isAlreadyInvited = this.job.invitations.filter(invitation => invitation.pro_id == this.pro.id).length > 0
      this.loading = false
    }
  },

  computed: {
    ...mapState({
      job: state => state.jobs.current,
    }),

  },

  watch: {
    job(job) {
      this.isAlreadyInvited = job.invitations.map(invitation => invitation.pro_id).includes(this.pro.id)
    }
  },
}
</script>