<template>
  <label
    :class="{'cursor-not-allowed': uploading}"
    class="border-dashed border-2 border-gray-300 h-48 flex flex-col items-center justify-center text-fonts-tertiary cursor-pointer hover:border-secondary-dark hover:text-secondary-dark transition-all duration-300 group"
  >
    <AddNewImageIcon class="group-hover:text-secondary-dark transition-all duration-300 text-fonts-tertiary"/>
    <div v-show="!uploading" class="flex flex-col items-center mt-4">
      <label class="cursor-pointer text-secondary-dark font-semibold">
        Upload a file
        <input
          ref="upload"
          type="file"
          :disabled="uploading"
          class="hidden"
          @change="change($event)"
        />
      </label>
      <span class="mt-1 text-fonts-tertiary body-xs-400">PNG, JPG, GIF</span>
      <span class="text-fonts-tertiary body-xs-400">up to 20MB</span>
    </div>
    <div v-if="uploading" class="text-sm text-gray-700 mt-2">Uploading...</div>
    <div v-if="!uploading && uploadingError" class="text-sm text-red-500 mt-2">
      The image could not be uploaded
    </div>
  </label>
</template>

<script>
import AddNewImageIcon from '@/components/shared/svg/AddNewImageIcon';

export default {
  name: 'EmptyImageUpload',

  components: { AddNewImageIcon },

  props: {
    uploading: Boolean,
    uploadingError: Boolean,
  },

  methods: {
    change(event) {
      this.$emit('change', event)
    }
  },
}
</script>
