<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 5C2 3.89543 2.89543 3 4 3H11C12.1046 3 13 3.89543 13 5V9C13 10.1046 12.1046 11 11 11H9L6 14V11H4C2.89543 11 2 10.1046 2 9V5Z"
      fill="currentColor"/>
    <path
      d="M15 7V9C15 11.2091 13.2091 13 11 13H9.82844L8.06174 14.7667C8.34155 14.9156 8.66092 15 9.00001 15H11L14 18V15H16C17.1046 15 18 14.1046 18 13V9C18 7.89543 17.1046 7 16 7H15Z"
      fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "ConversationIcon"
}
</script>

<style scoped>

</style>
