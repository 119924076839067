<template>
  <h3 class="block label-xs-500" :class="[{'text-functional-error': errors.length}, color, margin]">{{ label }}</h3>
</template>

<script>
export default {
  name: 'InputLabel',
  props: {
    label: String,
    errors : { type: Array , required: false, default: () => []},
    color: {type: String, default: 'text-fonts-primary'},
    margin: {type: String, default: 'mb-1'},
  }
}
</script>