import api from '@/api';

const pros = {
  namespaced: true,

  state: {
    items: [],
    current: null,
    pagination: {
      total: 0,
      count: 0,
      per_page: 15,
      current_page: 1,
      total_pages: 1,
      links: {}
    },
  },

  actions: {
    fetch({ commit }, payload = {}) {
      const params = new URLSearchParams();
      if (payload.includes) {
        params.append('include', payload.includes);
      }

      if (payload.page) {
        params.append('page', payload.page);
      }

      if (payload.filters) {
        for (const filter in payload.filters) {
          params.append(`filter[${filter}]`, payload.filters[filter]);
        }
      }

      if (payload.sort) {
        params.append('sort', payload.sort);
      }

      let data = { params };
      return api.get('/pros', data)
        .then(({ data }) => {
          commit('setPros', data.data)
          commit('setPagination', data.meta)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchPro({ commit }, payload) {
      let url = `/pros/${payload.id}`;
      if (payload.includes) {
        url += `?include=${payload.includes}`
      }
      return api.get(url)
        .then(({ data }) => {
          commit('setPro', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    deleteMedia(context, payload) {
      return api.delete(`/pros/${payload.model_id}/media/${payload.uuid}`)
        .then(() => {
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    toggleFavorite(context, proId) {
      return api.put(`/pros/${proId}/favorite`)
        .then(() => {
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    addPortfolioImage(context, pro) {
      return api.post(`/pros/${pro.id}/add-portfolio-image`, { image_url: pro.url })
        .then(({ data }) => {
          return Promise.resolve(data.data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    async getBankAccountConfigurationPageUrl() {
      const { data } = await api.get(`/payments/bank-account-configuration`);

      return data.url;
    },
    async getStripeOnboardingUrl() {
      const { data } = await api.get(`/payments/stripe-onboarding-url`);
      return data.url;
    },
  },

  mutations: {
    setPros(state, data) {
      state.items = data;
    },

    setPro(state, data) {
      state.current = data;
    },

    setPagination(state, data) {
      if (data.pagination) {
        state.pagination = data.pagination;
      }
    },
  },

}

export default pros
