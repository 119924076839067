<template>
  <div>
    <ButtonIcon
      v-if="user"
      @onClick="redirectToStripeOnboarding"
      :loading="loading"
      :text="buttonText"
      fontWeight="font-normal"
    >
      <template slot="leftIcon">
        <PlusIcon class="mr-2" />
      </template>
    </ButtonIcon>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import PlusIcon from "@/components/shared/svg/PlusIcon";

export default {
  name: "AddBankAccountButton",
  components: { ButtonIcon, PlusIcon },

  data() {
    return {
      loading: false,
    };
  },

  async mounted() {
    try {
      const queryParams = this.$route.query;

      let currentRoute = Object.assign({}, this.$router.currentRoute);
      if (
        !currentRoute.query.reloaded &&
        currentRoute.name == "BankAccountConfiguration"
      ) {
        const newRoute = {
          name: currentRoute.name,
          query: { return: currentRoute.query.return, reloaded: true },
        };
        this.$router.replace(newRoute);
        this.$router.go(newRoute);
      }

      if (queryParams.return) {
        if (queryParams.return == "false") {
          await this.redirectToStripeOnboarding();
        } else {
          this.$toast.success("Account information saved successfully");
          let query = Object.assign({}, this.$route.query);
          delete query.return;
          this.$router.replace({ query });
        }
      } else if (queryParams.refresh) {
        this.$toast.warning(
          "Your session timed out, please re-enter this page"
        );
      }
    } catch (error) {
      this.$toast.error(error.message);
    }
  },

  methods: {
    ...mapActions({
      getStripeOnboardingUrl: "pros/getStripeOnboardingUrl",
    }),

    async redirectToStripeOnboarding() {
      this.loading = true;
      const url = await this.getStripeOnboardingUrl();
      this.loading = false;
      window.location = url;
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    buttonText() {
      const stripeAccountStatus = this.user.type.stripe_account_status;
      let prefix = stripeAccountStatus == "not_set_up" ? "Add" : "Change";
      if (stripeAccountStatus === "actions_needed") {
        prefix = "Verify";
      }

      return `${prefix} bank account`;
    },
  },
};
</script>