<template>
  <div :class="[background, color, xPadding, yPadding, border]"
       class="rounded-full inline-block flex-shrink-0">
    <div class="flex items-center justify-center">
      <span :class="[{'mr-2':withIcon}, styles]">{{ text }}</span>
      <button @click="action" class="flex items-center">
        <slot name="icon"></slot>
      </button>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Badge',
  props: {
    text: String,
    withIcon: {
      type: Boolean,
      default: false
    },
    background: {
      type: String,
      default: 'bg-auxiliary-blue'
    },
    color: {
      type: String,
      default: 'text-white'
    },
    xPadding: {
      type: String,
      default: 'px-4'
    },
    yPadding: {
      type: String,
      default: 'py-1'
    },
    border: {
      type: String,
      default: ''
    },
    styles: {
      type: String,
      default: 'body-s-400'
    }
  },
  methods: {
    action() {
      this.$emit('onClick');
    }
  }
}
</script>
