<template>
  <div @click="goToPro" class="bg-white rounded-cards px-6 pb-10 pt-8 relative cursor-pointer">
    <div class="flex mb-6">
      <AvatarImage :isOnline="pro.is_online" :image="pro.image_url" class="mr-5" classes="w-16 w-16"/>
      <div class="w-full">
        <h4 class="heading-s-700">{{ pro.full_name }}</h4>
        <span class="body-s-400 text-auxiliary-blueLighter block">{{ pro.title }}</span>
      </div>
      <div v-if="withInvite" class="relative w-9/12">
        <InviteProToJob :user="pro" class="absolute right-0 top-0 w-full" ref="invitePro"/>
      </div>
      <button
        v-else
        @click.stop="favorite"
        class="rounded-full shadow-xl p-2.5 bg-white absolute right-0 top-0 mr-8 mt-8 flex items-center justify-center transition-all duration-300 hover:bg-auxiliary-blue group focus:outline-none">
        <HeartIcon class="w-4 h-4 text-auxiliary-blue group-hover:text-white" :fill="editedPro.is_favorited"/>
      </button>
    </div>
    <div class="w-full pl-6 pr-20 body-m-400">
      <p>{{ pro.bio | truncate(100, "...") }}
        <router-link class="text-secondary-dark" to="#">view more</router-link>
      </p>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import AvatarImage from "@/components/shared/AvatarImage"
import HeartIcon from "@/components/shared/svg/HeartIcon"
import InviteProToJob from "@/components/pros/InviteProToJob";

export default {
  name: 'ProCard',
  components: {AvatarImage, HeartIcon, InviteProToJob},

  props: {
    pro: Object,
    withInvite: { type: Boolean, default: false },
    openInNewTab: { type: Boolean, default: false },
    invite: {type: Boolean, default: false},
  },

  data() {
    return {
      editedPro: {}
    }
  },

  mounted() {
    this.editedPro = this.pro
    if (this.invite) {
      this.$refs.invitePro.invitePro()
    }
  },

  methods: {
    ...mapActions({
      toggleFavorite: 'pros/toggleFavorite'
    }),

    goToPro() {
      if (!this.openInNewTab) {
        return this.$router.push({name:'Pro', params: {proId: this.pro.id}})
      }

      let routeData = this.$router.resolve({name: 'Pro', params: {proId: this.pro.id}});
      window.open(routeData.href, '_blank');
    },

    favorite() {
      this.editedPro.is_favorited = !this.editedPro.is_favorited 
      this.toggleFavorite(this.pro.id)
    },
  },
}
</script>
