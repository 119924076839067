<template>
  <footer class="w-full px-6 lg:px-20 py-6 md:py-10 bg-auxiliary-blue md:h-52 text-white flex items-center">
    <div class="flex flex-col items-start space-y-4">
      <h4 class="text-white font-bold text-xl">Know someone who would like Talent Pond? <br> Send them a free invite.</h4>
      <div class="flex flex-wrap md:flex-nowrap items-center md:space-x-4 space-y-4 md:space-y-0">
        <ValidationProvider
          name="Email"
          rules="required|email"
          v-slot="{ errors }"
          class="flex"
        >
          <TextInput 
            v-model="email" 
            x-padding="px-4" 
            placeholder="Email" 
            type="email"
            :errors="errors" 
            color="text-gray-500" 
            class="w-full input-width mr-2"
          />
          <div class="pb-5">
            <ButtonIcon @onClick="sendUserInvite" text="Send" x-padding="px-20" :disabled="errors.length > 0"/>
          </div>
        </ValidationProvider>
      </div>
    </div>
  </footer>
</template>

<script>
import TextInput from "@/components/shared/inputs/TextInput";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import { mapActions } from "vuex";

export default {
  name: "Footer",
  components: {ButtonIcon, TextInput},
  
  data() {
    return {
      email: ''
    }
  },

  methods: {
    ...mapActions({
      inviteUser: 'general/inviteUser'
    }),

    async sendUserInvite() {
      const payload = {
        email: this.email,
      }
      await this.inviteUser(payload);
      this.email = "";
      this.$toast.success('Invitation sent successfully');
    },
  },
}
</script>

<style scoped>
.input-width{
  min-width: 320px;
}
</style>
