<template>
  <div v-if="user && user.type">
    <BackButton
      @onClick="$router.push({ name: 'Settings' })"
      text="Back to settings"
      font-weight="font-normal"
      class="mb-6"
    />
    <PageTitle title="Bank account information" class="mb-5 md:mb-10" />

    <div class="bg-white rounded-md p-8 flex flex-col mb-8">
      <h2 class="font-medium font-poppins text-lg leading-7 mb-4">
        {{ text }}
      </h2>
      <span class="body-m-400 mb-6"
        >We’ll use your bank account to wire the payment for the job.</span
      >
      <div>
        <AddBankAccountButton />
      </div>
    </div>

    <h1 class="heading-m-600 mb-6">How payments works</h1>

    <p class="body-s-400 text-fonts-secondary">Our mission is to create the best platform for businesses and pros. Your fees go into marketing, platform development, software maintenance, and administration.</p>

    <div class="flex items-center justify-center mt-6">
      <HowPaymentsWork />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import BackButton from "@/components/shared/buttons/BackButton";
import PageTitle from "@/components/shared/labels/PageTitle";
import AddBankAccountButton from "@/components/shared/buttons/AddBankAccountButton";
import HowPaymentsWork from "@/components/shared/svg/HowPaymentsWork";

export default {
  name: "BankAccountConfiguration",
  components: { BackButton, PageTitle, HowPaymentsWork, AddBankAccountButton },

  async mounted() {
    await this.getUser();
  },

  methods: {
    ...mapActions({
      getUser: "auth/getUser",
    }),
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    stripeAccountStatus() {
      return this.user.type.stripe_account_status;
    },

    text() {
      switch (this.stripeAccountStatus) {
        case "approved":
          return "Your bank account information has been verified. You can now start applying for jobs!";
        case "pending":
          return "Your Stripe account setup is pending. Once it's been verified, you'll be able to start bidding on jobs.";
        case "actions_needed":
          return "Stripe requires additional information to verify your account. Please send this to start applying for jobs";
        default:
          return "Complete your bank account information to apply for jobs";
      }
    },
  },
};
</script>
