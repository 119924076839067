<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7426 4.25736C15.0858 6.60051 15.0858 10.3995 12.7426 12.7426C10.3995 15.0858 6.60049 15.0858 4.25736 12.7426C1.91421 10.3995 1.91421 6.60049 4.25736 4.25736C6.60051 1.91421 10.3995 1.91421 12.7426 4.25736"
      stroke="#26355C"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.83325 11.1668L11.1666 5.8335"
      stroke="#26355C"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "JobEndedIcon",
};
</script>
