<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1665 4.5498L6.82487 12.8915L3.0332 9.0998"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "CheckIcon",
  props: {
    color: { type: String, default: '#38A169'}
  }
};
</script>

<style scoped></style>
