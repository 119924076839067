<template>
  <ValidationProvider :rules="validationRules" ref="multiselect" :name="typeStartCase" v-slot="{ errors }">
    <InputLabel v-if="label" :label="label" />
    <div class="w-full rounded-xl bg-white flex items-center" :class="errors.length ? 'border-functional-error border-1.5' : 'mb-4'">
      <multiselect
        id="ajax"
        v-model="selected"
        :options="options"
        :show-labels="false"
        :placeholder="placeholder"
        :track-by="trackBy"
        label="name"
        :allow-empty="allowEmpty"
        :multiple="multiple"
        :searchable="searchable"
        :loading="isLoading"
        :options-limit="optionsLimit"
        :limit="10"
        :limit-text="limitText"
        :hide-selected="true"
        @input="$emit('selected', selected)"
        @search-change="asyncFind"
        @open="isOpen = true"
        @close="isOpen = false"
      >
        <span class="arrow" slot="caret">
          <SearchIcon class="flex-shrink-0 text-auxiliary-blue mr-3"/>
        </span>
        <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} options selected</span></template>
      </multiselect>
    </div>
    <div>
      <SelectedOption @removeOption="removeOption" v-for="(tag, index) in selectedOptions" :key="index" :tag="tag"/>
    </div>
    <p class="text-functional-error text-xs mt-1" v-if="errors.length">{{ errors[0] }}</p>
  </ValidationProvider>
</template>

<script>
import { isArray, startCase } from 'lodash';
import pluralize from 'pluralize';
import Multiselect from 'vue-multiselect';
import SearchIcon from "@/components/shared/svg/SearchIcon";
import InputLabel from "@/components/shared/labels/InputLabel";
import SelectedOption from "@/components/shared/inputs/base-multiselect/partials/SelectedOption";

export default {
  name: 'BaseMultiselect',
  components: { SearchIcon, InputLabel, Multiselect, SelectedOption },

  props: {
    type: String,
    label: { type: String, default: '' },
    multiple: { type: Boolean, default: true },
    allowEmpty: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
    options: { type: Array, default: () => [] },
    optionsLimit: {type: Number, default: 99999},
    initialOptions: { type: Array, default: () => [] },
    initialValues: { type: Array, required: false, default: () => [] },
    isLoading: { type: Boolean, default: false },
    trackBy: { type: String, default: 'id' },
    placeholder: { type: String, default: 'Type to search...' },
    errors: { type: Array, required: false, default: () => [] },
    validationRules: { type: String, default: 'required' },
  },

  data() {
    return {
      selected: [],
      isOpen: false,
    };
  },

  watch: {
    initialValues(initialValues) {
      this.selected = initialValues;
    },
  },

  computed: {
    selectedOptions() {
      return isArray(this.selected) ? this.selected : [this.selected]
    },

    typeStartCase() {
      return startCase(pluralize(this.type, 2))
    }
  },

  mounted() {
    let vm = this;
    this.$nextTick().then(() => {
      if (vm.initialValues.length) {
        vm.selected = vm.initialValues;
      }
    });
  },

  methods: {
    limitText(count) {
      return `and ${count} other ${pluralize(this.type, 2)}`;
    },

    async asyncFind(query) {
      this.$emit('search-change', query);
    },

    clearAll() {
      this.selected = [];
    },

    close(selected) {
      this.isOpen = false;
      this.$emit('selected', selected);
    },

    removeOption(id) {
      if (isArray(this.selected)) {
        const tagIndex = this.selected.findIndex(tag => tag.id == id)
        this.selected.splice(tagIndex, 1)
      } else {
        this.selected = [];
      }
      this.$emit('selected', this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.arrow {
  @apply absolute left-0 pl-4 h-full flex items-center;
}
</style>