import { render, staticRenderFns } from "./ChevronLeftIcon.vue?vue&type=template&id=2aee8d3a&scoped=true&"
import script from "./ChevronLeftIcon.vue?vue&type=script&lang=js&"
export * from "./ChevronLeftIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aee8d3a",
  null
  
)

export default component.exports