import JobBid from "@/views/JobBid";

const routes = [
  {
    path: '/bids/:bidId',
    name: 'JobBid',
    component: JobBid,
    props:true,
    meta: {
      rootClasses: true,
    },
  },
]

export default routes;
