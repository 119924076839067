<template>
  <div class="flex items-center space-x-4">
    <InputLabel v-if="label" :label="label" :errors="errors" margin=""/>
    <button @click="toggle" type="button" aria-pressed="false" :class=" active ? color: 'bg-gray-200'"
            class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none">
    <span aria-hidden="true" :class="active ? 'translate-x-5': 'translate-x-0'"
          class="inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
    </button>
  </div>
</template>

<script>
import InputLabel from "@/components/shared/labels/InputLabel"

export default {
  name: "Toggle",
  components: {InputLabel},
  props: {
    color: {type: String, default: 'bg-gray-200'},
    label: {type: String, default: ''},
    labelClasses: {type: String, default: ''},
    errors: {type: Array, required: false, default: () => []},
    active: {type: Boolean, default: true},
  },

  methods: {
    toggle() {
      this.$emit('update')
    }
  }
}
</script>

<style scoped>

</style>
