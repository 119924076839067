<template>
  <div v-if="jobPostingStep < 4" class="w-full bg-primary text-white border-t border-auxiliary-blue overflow-hidden">
    <div class="container mx-auto px-6 lg:px-20 xl:px-32 py-10">
      <div class="flex items-center">
        <Badge class="uppercase mr-4" background="bg-auxiliary-blueLight" :text="`Step ${jobPostingStep}`"/>
        <h4 class="text-white body-l-700">{{ stepText }}</h4>
      </div>
    </div>
  </div>
</template>
<script>
import Badge from "@/components/shared/Badge";
import {mapState} from "vuex";

export default {
  name: 'SubHeader',
  components: {Badge},
  computed: {
    ...mapState({
      jobPostingStep: state => state.jobs.jobPostingStep
    }),
    currentRoute() {
      return this.$route.name
    },
    stepText() {
      let step = this.jobPostingStep;
      switch (step) {
        case  1:
          return "Hire a pro with a job template or your own..."
        case  2:
          return "Add tasks to your job."
        case  3:
          return "Invite Pros to your job posting..."
        default  :
          return ""
      }
    }
  }
}
</script>
