<template>
  <div>
    <ValidationProvider name="Name on Card" rules="required" v-slot="{ errors }">
      <TextInput
        label="Name on card"
        v-model="nameOnCard"
        placeholder="Jane Doe"
        x-padding="px-5"
        :errors="errors"
      />
    </ValidationProvider>

    <div class="grid md:grid-cols-6 gap-x-4 my-4">
      <div class="md:col-span-3">
        <InputLabel label="Credit card" />
        <div ref="stripeCardNumber" />
        <h4 v-if="numberError" class="text-red-600 text-sm mt-2">{{ numberError }}</h4>
      </div>
      <div>
        <InputLabel label="Expiration date" />
        <div ref="stripeCardExpiry" />
        <h4 v-if="expiryError" class="text-red-600 text-sm mt-2">{{ expiryError }}</h4>
      </div>
      <div>
        <InputLabel label="CVC" />
        <div ref="stripeCardCVC" />
        <h4 v-if="cvcError" class="text-red-600 text-sm mt-2">{{ cvcError }}</h4>
      </div>

      <ValidationProvider name="Zip code" rules="required" v-slot="{ errors }" >
        <TextInput
          type="number"
          label="ZIP"
          v-model="zipCode"
          placeholder="00000"
          x-padding="px-5"
          :errors="errors"
        />
      </ValidationProvider>
      <p v-if="error" class="text-red-600 text-sm mt-2">{{ error }}</p>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

import TextInput from "@/components/shared/inputs/TextInput";
import InputLabel from "@/components/shared/labels/InputLabel";

export default {
  name: "CardDetails",
  components: {
    TextInput,
    InputLabel,
  },
  
  props: {
    stripe: { type: Object, required: true },
    error: { type: String, required: false, default: '' },
  },

  data() {
    return {
      cardNumber: null,
      zipCode: '',
      isNumberFilled: false,
      isExpiryFilled: false,
      isCvcFilled: false,
      expiryError: '',
      numberError: '',
      cvcError: '',
      loading: false,
      nameOnCard: '',
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    cardStyles() {
      return {
        base: {
          fontFamily: "Roboto",
          fontSize: "16px",
          "::placeholder": {
            color: "#C0B9B3",
          },
          lineHeight: "24px",
        },
      };
    },

    cardClasses() {
      return { base: "py-3 px-5 body-m-400 bg-white rounded-xl w-full" };
    },

    canSubmit() {
      return (
        this.nameOnCard != "" &&
        this.zipCode != "" &&
        this.isNumberFilled &&
        this.isExpiryFilled &&
        this.isCvcFilled
      );
    },
  },

  watch: {
    canSubmit(canSubmit) {
      this.$emit('canCharge', canSubmit);
    },

    zipCode(zipCode) {
      this.$emit('zipCode', zipCode);
    },
    
    nameOnCard(nameOnCard) {
      this.$emit('nameOnCard', nameOnCard);
    },
  },

  async mounted() {
      await this.createStripeCard();
  },

  methods: {
    createStripeCard() {
      const elements = this.stripe.elements({
        fonts: [{ cssSrc: "https://fonts.googleapis.com/css2?family=Roboto" }],
        locale: "auto",
      });

      const refs = this.$refs;

      this.cardNumber = elements.create("cardNumber", { classes: this.cardClasses, style: this.cardStyles, showIcon: true });
      this.cardNumber.mount(refs.stripeCardNumber);
      var cardExpiry = elements.create("cardExpiry", { classes: this.cardClasses, style: this.cardStyles });
      cardExpiry.mount(refs.stripeCardExpiry);
      var cardCvc = elements.create("cardCvc", { classes: this.cardClasses, style: this.cardStyles });
      cardCvc.mount(refs.stripeCardCVC);

      this.cardNumber.on("change", this.handleNumberChange);
      cardExpiry.on("change", this.handleExpiryChange);
      cardCvc.on("change", this.handleCvcChange);
    },

    handleNumberChange(event) {
      const { complete, error } = event;
      this.isNumberFilled = complete;
      if (error) {
        this.numberError = error.message;
      } else {
        this.numberError = '';
      }
      this.$emit('card', this.cardNumber);
    },

    handleExpiryChange(event) {
      const { complete, error } = event;
      this.isExpiryFilled = complete;
      if (error) {
        this.expiryError = error.message;
      } else {
        this.expiryError = '';
      }
    },

    handleCvcChange(event) {
      const { complete, error } = event;
      this.isCvcFilled = complete;
      if (error) {
        this.cvcError = error.message;
      } else {
        this.cvcError = '';
      }
    },
  },
};
</script>
