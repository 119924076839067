<template>
  <div @click="goToJob()" class="bg-white rounded-cards py-8 px-10 relative" :class="{'cursor-pointer': clickable}">
    <div class="flex mb-6">
      <AvatarImage :image="job.church.logo_url" :isOnline="job.church.is_online" class="mr-5" classes="w-16 w-16"/>
      <div class="w-full">
        <h4 class="heading-s-700">{{job.title}}</h4>
        <span class="text-sm block font-bold">{{job.church.name}}</span>
        <span class="text-sm block">{{job.church.location}}</span>
      </div>
      <div class="relative w-5/12">
        <ApplyToJob :job="job" class="absolute right-0 top-0 w-full"/>
      </div>
    </div>
    <div class="w-full">
      <Categories :categories="job.categories" class="mb-4"/>
      <template v-if="job.tasks.length">
       <ul>
          <li v-for="(task, index) in job.tasks.slice(0,3)" :key="index" class="flex items-center">
            <CircleIcon class="text-auxiliary-blue mr-3"/>
            <span class="body-m-400">{{ task.name }}</span>
          </li>
        </ul>
        <router-link v-if="job.tasks.length > 3" class="text-secondary-dark" to="#">show all tasks</router-link>
      </template>
      <p v-else-if="job.description" class="body-m-400">
        {{ job.description | truncate(100, "...")}}  <router-link class="text-secondary-dark" to="#">view more</router-link>
      </p>
    </div>
  </div>
</template>
<script>
import AvatarImage from "@/components/shared/AvatarImage"
import ApplyToJob from "@/components/jobs/ApplyToJob";
import Categories from "@/components/shared/Categories";
import CircleIcon from "@/components/shared/svg/CircleIcon";

export default {
  name: 'JobCard',
  components: { AvatarImage, ApplyToJob, Categories, CircleIcon },

  props: {
    job: Object,
    clickable: { type: Boolean, default: true },
  },

  methods: {
    goToJob() {
      if (this.clickable) {
        this.$router.push({name: 'Job', params: {jobId: this.job.id}})
      }
    }
  },
}
</script>
