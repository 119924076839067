<template>
  <svg width="113" height="113" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="113" height="113" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M73.6106 90.4666C65.7751 93.8535 58.9965 100.615 50.4931 99.9359C41.917 99.2514 35.7817 92.1537 28.978 86.9043C21.5176 81.1483 10.3843 77.2316 8.98815 67.913C7.58687 58.5602 18.399 52.2508 22.508 43.7202C26.434 35.5694 24.7595 23.9523 32.4576 19.1875C40.1539 14.424 50.1127 18.9 58.8494 21.2268C66.2943 23.2097 72.4358 27.3423 78.8825 31.5519C86.7194 36.6694 96.551 39.9109 100.471 48.4057C104.754 57.6859 105.981 69.7044 100.47 78.3332C95.0523 86.8171 82.8547 86.4708 73.6106 90.4666Z" fill="#EAECFB"/>
  <path d="M32 81V83H22V56H32V58V81Z" fill="#656D78"/>
  <path d="M60 65L79.32 62.061C79.54 62.021 79.77 62 80 62C82.21 62 84 63.79 84 66C84 67.48 83.2 68.771 82 69.461L55 84L32 81V58H41L56.69 61.061C58.57 61.381 60 63.021 60 65Z" fill="#EAC6BB"/>
  <path d="M81.964 62.5352C81.797 63.7872 81.053 64.8552 80 65.4612L53.708 79.1072L32 76.1702V81.0002L55 84.0002L82 69.4612C83.2 68.7712 84 67.4812 84 66.0002C84 64.5082 83.175 63.2232 81.964 62.5352Z" fill="#D3B1A9"/>
  <path d="M63 33C63 37.42 59.42 41 55 41C50.58 41 47 37.42 47 33V30C47 34.42 50.58 38 55 38C59.42 38 63 34.42 63 30V33Z" fill="#F6BB42"/>
  <path d="M55 38C59.4183 38 63 34.4183 63 30C63 25.5817 59.4183 22 55 22C50.5817 22 47 25.5817 47 30C47 34.4183 50.5817 38 55 38Z" fill="#FFCE54"/>
  <path d="M66.1204 41.8802C67.2904 43.0502 64.2904 47.9502 63.1204 49.1202C61.9504 50.2902 60.0504 50.2902 58.8804 49.1202C57.7104 47.9502 57.7104 46.0502 58.8804 44.8802C60.0504 43.7102 64.9504 40.7102 66.1204 41.8802Z" fill="#A0D468"/>
  <path d="M69.0004 63.63L60.0004 65C60.0004 63.03 58.5704 61.38 56.6904 61.06L46.9004 59.15L55.0004 53L69.0004 63.63Z" fill="#965353"/>
  <path d="M55 39C50.037 39 46 34.963 46 30C46 25.037 50.037 21 55 21C59.963 21 64 25.037 64 30C64 34.963 59.963 39 55 39ZM55 23C51.141 23 48 26.141 48 30C48 33.859 51.141 37 55 37C58.859 37 62 33.859 62 30C62 26.141 58.859 23 55 23Z" fill="#26355C"/>
  <path d="M55 42C50.037 42 46 37.962 46 32.999C46 32.446 46.447 31.999 47 31.999C47.553 31.999 48 32.446 48 32.999C48 36.859 51.141 40 55 40C58.859 40 62 36.859 62 32.999C62 32.446 62.447 31.999 63 31.999C63.553 31.999 64 32.446 64 32.999C64 37.962 59.963 42 55 42Z" fill="#26355C"/>
  <path d="M63 33.999C62.447 33.999 62 33.552 62 32.999V30C62 29.447 62.447 29 63 29C63.553 29 64 29.447 64 30V32.999C64 33.552 63.553 33.999 63 33.999Z" fill="#26355C"/>
  <path d="M47 33.999C46.447 33.999 46 33.552 46 32.999V30C46 29.447 46.447 29 47 29C47.553 29 48 29.447 48 30V32.999C48 33.552 47.553 33.999 47 33.999Z" fill="#26355C"/>
  <path d="M27.0304 81C26.4774 81 26.0254 80.553 26.0254 80C26.0254 79.447 26.4674 79 27.0194 79H27.0304C27.5824 79 28.0304 79.447 28.0304 80C28.0304 80.553 27.5824 81 27.0304 81Z" fill="#26355C"/>
  <path d="M82.002 70.465C81.656 70.465 81.32 70.286 81.136 69.966C80.859 69.487 81.023 68.876 81.5 68.6C82.426 68.065 83 67.069 83 66C83 64.346 81.654 63 80 63C79.447 63 79 62.553 79 62C79 61.447 79.447 61 80 61C82.757 61 85 63.243 85 66C85 67.779 84.043 69.439 82.502 70.33C82.344 70.422 82.172 70.465 82.002 70.465Z" fill="#26355C"/>
  <path d="M55.0006 85C54.6446 85 54.2996 84.81 54.1186 84.474C53.8566 83.988 54.0396 83.381 54.5256 83.12L81.5256 68.584C82.0096 68.323 82.6176 68.505 82.8796 68.991C83.1416 69.477 82.9586 70.084 82.4726 70.345L55.4726 84.881C55.3226 84.962 55.1606 85 55.0006 85Z" fill="#26355C"/>
  <path d="M55.0008 85C54.9578 85 54.9148 84.997 54.8708 84.991L31.8708 81.991C31.3228 81.92 30.9358 81.418 31.0088 80.871C31.0798 80.323 31.5778 79.933 32.1288 80.009L55.1288 83.009C55.6768 83.08 56.0638 83.582 55.9908 84.129C55.9258 84.633 55.4958 85 55.0008 85Z" fill="#26355C"/>
  <path d="M32 84H22C21.447 84 21 83.553 21 83V56C21 55.447 21.447 55 22 55H32C32.553 55 33 55.447 33 56V83C33 83.553 32.553 84 32 84ZM23 82H31V57H23V82Z" fill="#26355C"/>
  <path d="M59.9995 66C59.5135 66 59.0875 65.646 59.0125 65.15C58.9295 64.604 59.3045 64.094 59.8505 64.011L79.1675 61.071C79.7125 60.984 80.2245 61.363 80.3065 61.909C80.3895 62.455 80.0145 62.965 79.4685 63.048L60.1515 65.988C60.1005 65.996 60.0495 66 59.9995 66Z" fill="#26355C"/>
  <path d="M56 70C55.447 70 55 69.553 55 69C55 68.447 55.447 68 56 68C57.654 68 59 66.654 59 65C59 63.536 57.956 62.293 56.517 62.045C55.973 61.951 55.608 61.434 55.702 60.89C55.796 60.346 56.316 59.984 56.857 60.075C59.258 60.487 61 62.56 61 65C61 67.757 58.757 70 56 70Z" fill="#26355C"/>
  <path d="M56.6878 62.0599C56.6248 62.0599 56.5598 62.0539 56.4958 62.0409L40.8078 58.9809C40.2658 58.8759 39.9118 58.3499 40.0178 57.8079C40.1228 57.2649 40.6478 56.9099 41.1908 57.0179L56.8788 60.0779C57.4208 60.1829 57.7748 60.7089 57.6688 61.2509C57.5758 61.7289 57.1568 62.0599 56.6878 62.0599Z" fill="#26355C"/>
  <path d="M41 59H32C31.447 59 31 58.553 31 58C31 57.447 31.447 57 32 57H41C41.553 57 42 57.447 42 58C42 58.553 41.553 59 41 59Z" fill="#26355C"/>
  <path d="M55.5801 69.9779C55.5541 69.9779 55.5281 69.9769 55.5021 69.9749L42.9231 68.9969C42.3721 68.9539 41.9601 68.4739 42.0031 67.9229C42.0461 67.3709 42.5261 66.9579 43.0771 67.0029L55.6561 67.9809C56.2071 68.0239 56.6191 68.5039 56.5761 69.0549C56.5351 69.5789 56.0981 69.9779 55.5801 69.9779Z" fill="#26355C"/>
  <path d="M79.3152 63.058C78.8362 63.058 78.4142 62.714 78.3312 62.227C78.2372 61.683 78.6022 61.166 79.1472 61.073C79.4262 61.024 79.7122 61 80.0002 61C80.5532 61 81.0002 61.447 81.0002 62C81.0002 62.553 80.5532 63 80.0002 63C79.8242 63 79.6522 63.015 79.4852 63.043C79.4292 63.053 79.3712 63.058 79.3152 63.058Z" fill="#26355C"/>
  <path d="M55.9997 70C55.8217 70 55.6467 69.99 55.4757 69.972C54.9257 69.913 54.5287 69.421 54.5867 68.872C54.6457 68.322 55.1347 67.923 55.6867 67.983C55.7897 67.994 55.8947 68 55.9997 68C56.5527 68 56.9997 68.447 56.9997 69C56.9997 69.553 56.5527 70 55.9997 70Z" fill="#26355C"/>
  <path d="M68.9986 64.6299C68.7876 64.6299 68.5756 64.5639 68.3946 64.4269L54.3946 53.7969C53.9546 53.4629 53.8686 52.8349 54.2026 52.3959C54.5376 51.9549 55.1646 51.8709 55.6036 52.2039L69.6036 62.8339C70.0436 63.1679 70.1296 63.7959 69.7956 64.2349C69.5996 64.4939 69.3006 64.6299 68.9986 64.6299Z" fill="#26355C"/>
  <path d="M46.9 60.1501C46.597 60.1501 46.299 60.0141 46.102 59.7541C45.768 59.3151 45.854 58.6871 46.294 58.3531L54.395 52.2031C54.835 51.8701 55.461 51.9551 55.796 52.3951C56.13 52.8341 56.044 53.4621 55.604 53.7961L47.503 59.9461C47.323 60.0841 47.111 60.1501 46.9 60.1501Z" fill="#26355C"/>
  <path d="M55 54C54.447 54 54 53.553 54 53V41C54 40.447 54.447 40 55 40C55.553 40 56 40.447 56 41V53C56 53.553 55.553 54 55 54Z" fill="#26355C"/>
  <path d="M61 50.9998C59.932 50.9998 58.928 50.5838 58.172 49.8278C57.416 49.0718 57 48.0678 57 46.9998C57 45.9318 57.416 44.9278 58.172 44.1718C59.229 43.1148 63.121 40.6118 65.313 40.6118C65.948 40.6118 66.458 40.7998 66.829 41.1718C67.696 42.0388 67.556 43.5998 66.397 45.9428C65.664 47.4268 64.559 49.0968 63.829 49.8278C63.072 50.5838 62.068 50.9998 61 50.9998ZM65.313 42.6118C63.891 42.6118 60.515 44.6568 59.586 45.5858C59.208 45.9638 59 46.4658 59 46.9998C59 47.5338 59.208 48.0358 59.586 48.4138C60.342 49.1698 61.658 49.1698 62.414 48.4138C63.605 47.2228 65.548 43.5858 65.393 42.6148C65.372 42.6128 65.345 42.6118 65.313 42.6118Z" fill="#26355C"/>
  <path d="M55 33C54.447 33 54 32.553 54 32V28C54 27.447 54.447 27 55 27C55.553 27 56 27.447 56 28V32C56 32.553 55.553 33 55 33Z" fill="#26355C"/>
  </svg>
</template>

<script>
export default {
  name: "BookkeeperIcon"
}
</script>

<style scoped>

</style>
