<template>
  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="22.1496" cy="24.9431" r="15.3649" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45ZM26.1939 10.8719C22.9339 9.98496 19.6515 10.3254 16.929 11.6504L16.929 11.6504C14.2132 12.9669 12.0136 15.3335 11.1643 18.4494C10.1554 22.078 10.4412 29.8456 19.9189 38.0046C20.0245 38.0973 20.1453 38.1604 20.2725 38.1951C20.5175 38.2619 20.787 38.2227 21.0294 38.072C21.3967 37.8428 21.6317 37.4027 21.6312 36.9404L21.6299 32.9037C24.4318 33.3766 27.1909 32.9675 29.5352 31.8268C32.2492 30.5116 34.4506 28.1437 35.2999 25.0278C36.1491 21.9119 35.4533 18.7562 33.7825 16.2439C32.1075 13.7205 29.4531 11.7618 26.1939 10.8719Z"
          fill="url(#paint0_radial)"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45ZM26.1939 10.8719C22.9339 9.98496 19.6515 10.3254 16.929 11.6504L16.929 11.6504C14.2132 12.9669 12.0136 15.3335 11.1643 18.4494C10.1554 22.078 10.4412 29.8456 19.9189 38.0046C20.0245 38.0973 20.1453 38.1604 20.2725 38.1951C20.5175 38.2619 20.787 38.2227 21.0294 38.072C21.3967 37.8428 21.6317 37.4027 21.6312 36.9404L21.6299 32.9037C24.4318 33.3766 27.1909 32.9675 29.5352 31.8268C32.2492 30.5116 34.4506 28.1437 35.2999 25.0278C36.1491 21.9119 35.4533 18.7562 33.7825 16.2439C32.1075 13.7205 29.4531 11.7618 26.1939 10.8719Z"
          fill="url(#paint1_radial)"/>
    <defs>
      <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(39.8064 37.5144) rotate(-174.864) scale(35.6621)">
        <stop stop-color="#FF7F51"/>
        <stop offset="1" stop-color="#3144D3"/>
      </radialGradient>
      <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(27.4347 -10.1768) rotate(129.289) scale(31.1967)">
        <stop stop-color="#007EFF"/>
        <stop offset="1" stop-color="#007EFF" stop-opacity="0"/>
      </radialGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "TalentPondLogo"
}
</script>

<style scoped>

</style>
