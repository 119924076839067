import api from '@/api'

const messages = {
  namespaced: true,

  state: {
    conversations: null,
    currentConversation: null,
    currentThread: null,
    nonSentMessage: [],
    isLoadingFiles: false,
  },

  getters: {
    nonSentMessages: state => state.nonSentMessage,
    isLoadingFiles: state => state.isLoadingFiles,
  },

  actions: {
    fetchConversations({commit}) {
      return api.get('/messages')
        .then(({data}) => {
          commit('setConversations', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchConversationMessages({commit}, threadId) {
      return api.get(`/messages/${threadId}`)
        .then(({data}) => {
          commit('setCurrentThread', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    sendMessage({ state }, message) {
      return api.put(`/messages/${state.currentConversation.id}`, message)
        .then(({data}) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    deleteUploadedMessages(_, fileUrls) {
      api.post('/uploads/delete-uploaded-files', { fileUrls })
    },

  },

  mutations: {
    setConversations(state, data) {
      state.conversations = data;
    },

    setCurrentConversation(state, data) {
      state.currentConversation = data;
    },
    setCurrentThread(state, data) {
      state.currentThread = data;
    },
    
    setNonSentMessages(state, data){
      state.nonSentMessage = data;
    },

    setIsLoadingFiles(state, data){
      state.isLoadingFiles = data;
    },
  },

}

export default messages
