<template>
  <ValidationObserver v-slot="{ invalid }" rules="required" class="flex justify-center w-full">
    <form @submit.prevent="createJob" class="grid grid-cols-1 gap-y-10 w-full lg:w-6/12">
      <!--Job title-->
      <ValidationProvider name="Job title" rules="required" v-slot="{ errors }">
        <TextInput
          v-model="form.title"
          label="Give your job a title"
          x-padding="px-6"
          :errors="errors"
        />
      </ValidationProvider>

      <div>
        <InputLabel label="Select your job type" />
        <div class="grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-y-0 md:gap-x-4">
          <div
            v-for="(type, index) in jobTypes"
            :key="index"
            :class="[type.id == form.type ? 'active-option' : 'inactive-option']"
            @click="setType(type.id)"
            class="card"
          >
          <component :is="type.icon" class="mr-5" />
          <div class="flex flex-col">
            <span class="block text-auxiliary-blue body-m-700">{{ type.title }}</span>
            <p class="body-xs-400">{{ type.subtitle }}</p>
          </div>
          </div>
        </div>
      </div>

      <ValidationProvider name="Budget" rules="required" v-slot="{ errors }">
        <TextInput
          v-model="form.budget"
          label="What is your budget for the job?"
          x-padding="px-6"
          placeholder="100"
          :currencyInput="true"
          :errors="errors"
        >
          <template #leftIcon>
            <DollarIcon class="text-primary"/>
          </template>
        </TextInput>
      </ValidationProvider>

      <ValidationProvider v-if="form.type == 'one_time'" name="Date" rules="required" v-slot="{ errors }">
        <TextInput
          v-model="form.due_date"
          type="date"
          label="When does this one-time job need to be completed?"
          x-padding="px-6"
          placeholder="05/30/2022"
          :errors="errors"
        >
          <template #leftIcon>
            <CalendarIcon class="text-primary mr-1.5"/>
          </template>
        </TextInput>
      </ValidationProvider>

      <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
        <TextAreaInput
          v-model="form.description"
          label="What do you need help with? Include as much detail as possible"
          placeholder="Add your details here..."
          rows="5"
          :errors="errors"
          :deleteOnEnter="false"
        />
      </ValidationProvider>

      <ValidationProvider name="Task" rules="required" ref="listInput" v-slot="{ errors }">
        <input type="hidden" v-model="form.tasks">
        <ListInput
          label="Add tasks one at a time and press enter"
          ref="listInput"
          placeholder="Type and press enter to add tasks"
          @itemsUpdated="setTasks"
          :errors="errors"
          :initialValues="initialTasks"
        />
      </ValidationProvider>

      <ButtonIcon @onClick="createJob" text="Confirm and Continue" :disabled="invalid" :loading="loading"/>
    </form>
  </ValidationObserver>
</template>

<script>
import {mapMutations, mapState, mapActions} from "vuex";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import TextInput from "@/components/shared/inputs/TextInput";
import ListInput from "@/components/shared/inputs/ListInput";
import InputLabel from "@/components/shared/labels/InputLabel";
import OneTimeJobIcon from "@/components/shared/svg/OneTimeJobIcon";
import RecurringJobIcon from "@/components/shared/svg/RecurringJobIcon";
import DollarIcon from '@/components/shared/svg/DollarIcon';
import CalendarIcon from '@/components/shared/svg/CalendarIcon';
import TextAreaInput from "@/components/shared/inputs/TextAreaInput";


export default {
  name: "Step2",
  components: {
    TextInput,
    ButtonIcon,
    ListInput,
    InputLabel,
    OneTimeJobIcon,
    RecurringJobIcon,
    DollarIcon,
    CalendarIcon,
    TextAreaInput
  },

  data() {
    return {
      initialTasks: [],
      loading: false,
      jobTypes: [
        {
          id: 'one_time',
          title: 'One-Time Job',
          subtitle: 'I only need this job done once.',
          icon: 'OneTimeJobIcon'
        },
        {
          id: 'recurring',
          title: 'Monthly Recurring Job',
          subtitle: 'I need this job done every month',
          icon: 'RecurringJobIcon'
        },
      ]
    }
  },

  async beforeMount() {
    await this.fetchCategories();
  },

  mounted() {
    this.initializeJob();
  },

  destroyed() {
    this.setCurrentTemplate(null);
  },

  methods: {
    ...mapActions({
      create: 'jobs/create',
      fetchCategories: 'categories/fetch',
      inviteProToJob: 'jobs/inviteProToJob',
    }),

    ...mapMutations({
      increaseStep: 'jobs/increaseJobPostingStep',
      setJobForm: 'jobs/setJobForm',
      setJobCategories: 'jobs/setJobCategories',
      setJobTasks: 'jobs/setJobTasks',
      setType: 'jobs/setType',
      setCurrentTemplate: 'jobs/setCurrentTemplate',
    }),

    async setTasks(tasks) {
      await this.$refs.listInput.validate();
      this.setJobTasks(tasks);
    },

    async createJob(){
      try {
        this.loading = true;
        const { success } = await this.create(this.form)
        if (success) {
          if (this.$route.params.pro) {
            const data = {
              pro_id: this.$route.params.pro.id,
              email: this.$route.params.pro.email,
            }
            await this.inviteProToJob(data)
          }
          this.increaseStep();
        }
      } catch (errors) {
        this.$refs.userForm.setErrors(errors.errors)
      } finally {
        this.loading = false
      }
    },

    initializeJob() {
      if (this.currentTemplate) {
        this.initializeJobWithTemplate();
      } else {
        this.setJobForm({visible: true, categories: [9], type: 'one_time'});
      }
    },

    initializeJobWithTemplate() {
      const job = { categories: [9], type: 'one_time' }
      if (this.currentTemplate.id != 9) {
        job.title = this.currentTemplate.title;
        job.tasks = this.currentTemplate.tasks;
        job.categories = this.currentTemplate.categories;
        if (this.currentTemplate.tasks) {
          this.initialTasks = this.currentTemplate.tasks.map(task => {
            return {id: Math.floor((Math.random() * 10000000)), text: task}
          });
        }
      }
      this.setJobForm(job);
    }
  },

  computed: {
    ...mapState({
      form: state => state.jobs.form,
      currentTemplate: state => state.jobs.currentTemplate,
      categories: state => state.categories.items,
    }),
  },
}
</script>

<style scoped>
.card {
  @apply px-6 py-5 flex items-center cursor-pointer transition-all duration-300;
}

.active-option {
  @apply bg-primary-10 border-auxiliary-blue rounded-xl border-2 shadow-2xl;
}

.inactive-option {
  @apply border-2 bg-white rounded-xl border-transparent;
}
</style>
