<template>
    <div class="relative inline-block text-left">
      <div>
        <slot name="button" v-bind:close="close" v-bind:toggle="toggle">
          <button
            v-on-clickaway="close"
            @click.prevent="toggle"
            class="p-1.5 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
            :class="show? 'bg-primary-70' : 'bg-primary-10'"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <span class="sr-only">Open options</span>
            <DotsVerticalIcon :class="show ? 'text-primary-10' : 'text-primary-70'"/>
          </button>
        </slot>
      </div>

      <transition @enter="enter">
        <div
          v-show="show"
          class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-xl bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 z-50"
        >
          <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <slot name="content"/>
          </div>
          <slot name="separator"/>
        </div>
      </transition>
    </div>
</template>

<script>
import {mixin as clickaway} from 'vue-clickaway';
import anime from "animejs";
import DotsVerticalIcon from "@/components/shared/svg/DotsVerticalIcon";

export default {
  name: 'Dropdown',
  mixins: [clickaway],
  components: {DotsVerticalIcon},

  data() {
    return {
      show: false
    }
  },

  methods: {
    enter(el) {
      anime({
        targets: el,
        translateY: [60, 0],
        duration: 900
      })
    },
    
    close() {
      this.show = false;
    },

    toggle() {
      this.show = !this.show;
    }
  },
}
</script>