<template>
  <div class="inset-0 h-screen  w-full">
    <div class=" items-center flex flex-col space-y-2 ">
      <ConfirmationIcon/>
      <h2 class="font-bold text-xl w-3/12 text-center">Congrats! We’re on the search for your perfect Pros...</h2>
      <div class="flex flex-col justify-center space-y-4">
        <ButtonIcon @onClick="goToPostJob" text="Create another job post" fontSize="text-xs"
                    xPadding="px-16" class="mt-4"/>
        <ButtonIcon @onClick="goToJob" fontSize="text-xs" xPadding="px-16" text="View job posting" background="bg-primary"/>
      </div>
    </div>
  </div>
</template>


<script>
import {mapMutations, mapState} from "vuex";
import ConfirmationIcon from "@/components/shared/svg/ConfirmationIcon";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";

export default {
  name: "Step3",
  components: {
    ConfirmationIcon,
    ButtonIcon,
  },
  methods: {
    ...mapMutations({
      resetSteps: 'jobs/resetJobPostingSteps'
    }),
    goToPostJob() {
      this.resetSteps();
      this.$router.push({name: 'PostJob'})
    },

    goToJob() {
      this.$router.push({name:'Job', params: { jobId: this.job.id}});
    }
  },

  computed: {
    ...mapState({
      job: state => state.jobs.current,
    }),
  },


}

</script>
