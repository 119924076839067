<template>
  <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.0735 22.7668H11.5269" stroke="currentColor" stroke-width="1.4" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path
      d="M13.2996 6.14185V6.14185C10.2395 6.14185 7.75793 8.6234 7.75793 11.6835V11.6835V11.719V14.4588C7.75793 14.8179 7.5551 15.1448 7.23479 15.3055L6.6773 15.5837C5.98127 15.9329 5.54126 16.6444 5.54126 17.4225V17.4225C5.54126 18.5574 6.46118 19.4773 7.59611 19.4773H19.0031C20.138 19.4773 21.0579 18.5574 21.0579 17.4225V17.4225C21.0579 16.6444 20.6179 15.9329 19.9219 15.5848L19.3644 15.3067C19.0441 15.1448 18.8413 14.8179 18.8413 14.4588V11.719V11.6835V11.6835"
      stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.5624 6.29912C14.1557 6.2038 13.7367 6.14173 13.3 6.14173" stroke="currentColor" stroke-width="1.4"
          stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.6837 10.4211C18.779 10.8279 18.8411 11.2469 18.8411 11.6835" stroke="currentColor" stroke-width="1.4"
          stroke-linecap="round" stroke-linejoin="round"/>
    <path
      d="M20.0848 4.89892C21.3833 6.19741 21.3833 8.30268 20.0848 9.60117C18.7863 10.8997 16.681 10.8997 15.3826 9.60117C14.0841 8.30268 14.0841 6.19741 15.3826 4.89892C16.681 3.60043 18.7863 3.60043 20.0848 4.89892"
      stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "NotificationsIcon"
}
</script>

<style scoped>

</style>
