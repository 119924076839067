<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class=" stroke-current">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.3008 16.0562C14.5962 16.0562 16.457 14.1953 16.457 11.8999C16.457 9.60447 14.5962 7.74365 12.3008 7.74365C10.0053 7.74365 8.14453 9.60447 8.14453 11.8999C8.14453 14.1953 10.0053 16.0562 12.3008 16.0562Z"  stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.974 21.1002L12.8867 16.0129"  stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.4131 12.4873L21.5003 17.5734"  stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.10059 17.5736L8.18784 12.4863"  stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.7152 16.0142L6.62793 21.1003"  stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.62793 2.70093L11.7152 7.78707"  stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.18784 11.3136L3.10059 6.22632"  stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.5003 6.22632L16.4131 11.3136"  stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.8867 7.78707L17.974 2.70093"  stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.3004 22.7063C18.2685 22.7063 23.1066 17.8681 23.1066 11.9C23.1066 5.93187 18.2685 1.09375 12.3004 1.09375C6.33226 1.09375 1.49414 5.93187 1.49414 11.9C1.49414 17.8681 6.33226 22.7063 12.3004 22.7063Z"  stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
name:'HelpIcon'
}
</script>
