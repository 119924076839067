<template>
  <div class="bg-white rounded-xl py-8 px-6">
    <div v-if="loading || !job" class="flex justify-center items-center">
      <loading-icon class="h-2" />
    </div>
    <template v-else>
      <p class="body-m-400">{{ job.description }}</p>
      <ul class="mt-7">
        <TaskItem
          v-for="(task, index) in job.tasks"
          :key="index"
          :task="task"
          :canEdit="canUpdateTasks"
        />
      </ul>
    </template>
  </div>
</template>

<script>
import TaskItem from "@/components/tasks/TaskItem";

export default {
  name: "JobRequirements",
  components: { TaskItem },
  props: {
    job: { type: Object, default: null },
    canUpdateTasks: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
};
</script>
