<template>
  <div>
    <div class="flex items-center justify-center mt-8 mb-16">
      <div class="relative mr-4">
        <AvatarImage width="w-28" height="h-28" :image="user.image_url"/>
      </div>

      <div>
        <h2 class="mb-1 heading-l-600">{{ user.full_name }}</h2>
        <div class="flex space-x-1 mb-4 text-fonts-secondary">
          <MarkerPinIcon/>
          <span class="body-s-400 items-center flex">{{ userIsPro ? user.location : user.type.church.location }}</span>
        </div>
        <div class="text-secondary-dark flex items-center">
          <BriefcaseIcon class="mr-2"/>
          <router-link :to="goToProfile" class="body-s-400">See profile page</router-link>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center">
      <div class="flex-col flex items-center justify-center mb-10 space-y-3 w-full lg:w-7/12">
        <MenuBox @onClick="$router.push({name:'AccountDetails'})" text="Account Details">
          <template slot="leftIcon">
            <AccountIcon class="text-fonts-tertiary"/>
          </template>
        </MenuBox>

        <MenuBox v-if="!envIsProduction" @onClick="$router.push({name:'EditNotifications'})" text="Notifications">
          <template slot="leftIcon">
            <NotificationsIcon class="text-fonts-tertiary"/>
          </template>
        </MenuBox>
      </div>
      <div class="flex-col flex items-center justify-center mb-10 space-y-3 w-full lg:w-7/12">
        <MenuBox v-if="!envIsProduction" text="Help" class="bg-pink-500">
          <template slot="leftIcon">
            <HelpIcon class="text-fonts-tertiary"/>
          </template>
        </MenuBox>

        <MenuBox @onClick="$router.push({name:'PrivacyPolicy'})" v-if="!envIsProduction" text="Privacy Policy">
          <template slot="leftIcon">
            <FileIcon class="text-fonts-tertiary"/>
          </template>
        </MenuBox>

        <MenuBox v-if="!envIsProduction" text="Terms of Use" class="bg-pink-500">
          <template slot="leftIcon">
            <FileIcon class="text-fonts-tertiary"/>
          </template>
        </MenuBox>

        <MenuBox v-if="!userIsPro" @onClick="$router.push({name: 'ChurchPaymentDetails'})" text="Payment Details">
          <template slot="leftIcon">
            <div class="border-1.5 border-fonts-tertiary rounded-md w-6 h-6 flex items-center justify-center">
              <DollarIcon class="text-fonts-tertiary"/>
            </div>
          </template>
        </MenuBox>

        <MenuBox v-else @onClick="$router.push({name:'BankAccountConfiguration'})" text="Bank details">
          <template slot="leftIcon">
            <div class="border-1.5 border-fonts-tertiary rounded-md w-6 h-6 flex items-center justify-center">
              <DollarIcon class="text-fonts-tertiary"/>
            </div>
          </template>
        </MenuBox>

        <MenuBox @onClick="$router.push({name:'ReportProblem'})" text="Report Technical Problem">
          <template slot="leftIcon">
            <ProblemReportIcon class="text-fonts-tertiary"/>
          </template>
        </MenuBox>

        <MenuBox @onClick="logout" text="Log out">
          <template slot="leftIcon">
            <LogoutIcon class="text-fonts-tertiary"/>
          </template>
        </MenuBox>
      </div>
    </div>
    <span v-if="!envIsProduction" class="justify-center items-center flex text-sm text-pink-500">VERSION 115.33</span>
  </div>
</template>

<script>
import MarkerPinIcon from '@/components/shared/svg/MarkerPinIcon'
import AccountIcon from '@/components/shared/svg/AccountIcon'
import HelpIcon from '@/components/shared/svg/HelpIcon'
import FileIcon from '@/components/shared/svg/FileIcon'
import DollarIcon from '@/components/shared/svg/DollarIcon'
import ProblemReportIcon from '@/components/shared/svg/ProblemReportIcon'
import LogoutIcon from '@/components/shared/svg/LogoutIcon'
import MenuBox from '@/components/settings/MenuBox'
import {mapActions, mapState} from 'vuex'
import NotificationsIcon from "@/components/shared/svg/NotificationsIcon";
import BriefcaseIcon from '@/components/shared/svg/BriefcaseIcon'
import AvatarImage from '@/components/shared/AvatarImage'

export default {
  name: "Settings",
  components: {
    NotificationsIcon,
    MarkerPinIcon,
    MenuBox,
    AccountIcon,
    HelpIcon,
    FileIcon,
    ProblemReportIcon,
    LogoutIcon,
    BriefcaseIcon,
    AvatarImage,
    DollarIcon,
  },

  mounted() {
    this.getUser()
  },

  methods: {
    ...mapActions({
      authLogout: 'auth/logout',
      getUser: 'auth/getUser',
    }),
    logout() {
      this.authLogout()
        .then(() => this.$router.push({name: 'Login'}))
        .catch(errors => {
          console.log(errors);
          this.error = errors.response;
        })
    }
  },
  
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),

    userIsPro() {
      return this.user.userable_type == 'pro'
    },

    goToProfile() {
      let redirectTo = {name: this.userIsPro ? 'Pro': 'Church', params: {}};
      redirectTo.params[`${this.userIsPro ? 'proId' : 'churchId'}`] = this.userIsPro ? this.user.type.id : this.user.type.church.id
      return redirectTo
    },

    envIsProduction() {
      return process.env.NODE_ENV == 'production';
    }
  }
}
</script>
