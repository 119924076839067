<template>
  <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg" class=" stroke-current">
<path d="M6.65039 13.9001H8.70081L11.1037 19.4417L15.4971 8.3584L17.9 13.9001H19.9504" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.3965 24.4292H7.20482C4.75651 24.4292 2.77148 22.4442 2.77148 19.9958V7.80418C2.77148 5.35587 4.75651 3.37085 7.20482 3.37085H19.3965C21.8448 3.37085 23.8298 5.35587 23.8298 7.80418V19.9958C23.8298 22.4442 21.8448 24.4292 19.3965 24.4292Z"  stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
name:'ProblemReportIcon'
}
</script>
