<template>
  <svg width="113" height="113" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="113" height="113" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M95.4106 57.3657C94.4328 65.8457 96.9061 75.0947 92.0723 82.1233C87.197 89.2122 77.984 90.9839 70.0387 94.2577C61.3264 97.8476 52.374 105.538 43.603 102.095C34.7998 98.639 34.7319 86.1208 29.3924 78.3013C24.2906 70.8299 13.3892 66.4802 13.1047 57.4312C12.8202 48.3845 21.6709 41.9909 28.0492 35.5831C33.4845 30.1226 40.1316 26.8649 46.9979 23.3813C55.3448 19.1464 63.0623 12.2466 72.3798 13.0917C82.5586 14.015 93.5845 18.9527 98.3092 28.0356C102.955 36.9658 96.5643 47.3613 95.4106 57.3657Z" fill="#EAECFB"/>
    <path d="M80 80V85H32V80C33.11 80 34 79.11 34 78H78C78 79.11 78.89 80 80 80Z" fill="#965353"/>
    <path d="M85 73V85H80V80C78.89 80 78 79.11 78 78H34C34 79.11 33.11 80 32 80V85H27V73H85Z" fill="#F7C292"/>
    <path d="M87 69V73H85H27H25V69H55H85H87Z" fill="#FFD2A6"/>
    <path d="M51 64C51 65.11 50.1 66 49 66H28C26.89 66 26 65.1 26 64V47C26 45.89 26.89 45 28 45H49C50.11 45 51 45.89 51 47V62V64Z" fill="#E6E9ED"/>
    <path d="M38.5 58C39.8807 58 41 56.8807 41 55.5C41 54.1193 39.8807 53 38.5 53C37.1193 53 36 54.1193 36 55.5C36 56.8807 37.1193 58 38.5 58Z" fill="white"/>
    <path d="M62 62V64C62 65.11 61.11 66 60 66H55H49C50.1 66 51 65.11 51 64V62H55H62Z" fill="#CCD1D9"/>
    <path d="M70 56.9998L67 57.9998L65 55.9998V52.7998C66.5 53.5708 68.2 53.9998 70 53.9998C71.8 53.9998 73.5 53.5698 75 52.7998V55.9998L73 57.9998L70 56.9998Z" fill="#F5F7FA"/>
    <path d="M67 58L70 57L73 58L75 56H79C82.32 56 85 58.68 85 62V69H55V66H60C61.11 66 62 65.11 62 64V62H55C55 58.68 57.68 56 61 56H65L67 58Z" fill="#5D9CEC"/>
    <path d="M79 56H75C78.32 56 81 58.68 81 62V69H85V62C85 58.68 82.32 56 79 56Z" fill="#4A89DC"/>
    <path d="M62 36L59 39V34C59 29.03 63.03 25 68 25H76V29C78.76 29 81 31.24 81 34V39L78 36C78 36 74.29 38 70 38C65.71 38 62 36 62 36Z" fill="#ECC94B"/>
    <path d="M76 29V25H72V31C74.76 31 77 32.24 77 35V36.468C77.634 36.197 78 36 78 36L81 39V34C81 31.24 78.76 29 76 29Z" fill="#D69E2E"/>
    <path d="M81 39V43C81 47.27 78.56 50.98 75 52.8C73.5 53.571 71.8 54 70 54C68.2 54 66.5 53.57 65 52.8C61.44 50.981 59 47.27 59 43V39L62 36C62 36 65.71 38 70 38C74.29 38 78 36 78 36L81 39Z" fill="#EAC6BB"/>
    <path d="M78 36C78 36 77.634 36.197 77 36.468V43C77 47.27 74.56 50.98 71 52.8C70.071 53.278 69.059 53.606 68 53.8C68.65 53.919 69.314 54 70 54C71.8 54 73.5 53.57 75 52.8C78.56 50.981 81 47.27 81 43V39L78 36Z" fill="#D3B1A9"/>
    <path d="M38.5 59C36.57 59 35 57.43 35 55.5C35 53.57 36.57 52 38.5 52C40.43 52 42 53.57 42 55.5C42 57.43 40.43 59 38.5 59ZM38.5 54C37.673 54 37 54.673 37 55.5C37 56.327 37.673 57 38.5 57C39.327 57 40 56.327 40 55.5C40 54.673 39.327 54 38.5 54Z" fill="#26355C"/>
    <path d="M80 86C79.447 86 79 85.553 79 85V80C79 79.447 79.447 79 80 79C80.553 79 81 79.447 81 80V85C81 85.553 80.553 86 80 86Z" fill="#26355C"/>
    <path d="M32 86C31.447 86 31 85.553 31 85V80C31 79.447 31.447 79 32 79C32.553 79 33 79.447 33 80V85C33 85.553 32.553 86 32 86Z" fill="#26355C"/>
    <path d="M78 79H34C33.447 79 33 78.553 33 78C33 77.447 33.447 77 34 77H78C78.553 77 79 77.447 79 78C79 78.553 78.553 79 78 79Z" fill="#26355C"/>
    <path d="M80 81C78.346 81 77 79.654 77 78C77 77.447 77.447 77 78 77C78.553 77 79 77.447 79 78C79 78.552 79.448 79 80 79C80.553 79 81 79.447 81 80C81 80.553 80.553 81 80 81Z" fill="#26355C"/>
    <path d="M32 81C31.447 81 31 80.553 31 80C31 79.447 31.447 79 32 79C32.552 79 33 78.552 33 78C33 77.447 33.447 77 34 77C34.553 77 35 77.447 35 78C35 79.654 33.654 81 32 81Z" fill="#26355C"/>
    <path d="M51 64.999C50.447 64.999 50 64.552 50 63.999V47C50 46.447 50.447 46 51 46C51.553 46 52 46.447 52 47V63.999C52 64.552 51.553 64.999 51 64.999Z" fill="#26355C"/>
    <path d="M49 46H28C27.447 46 27 45.553 27 45C27 44.447 27.447 44 28 44H49C49.553 44 50 44.447 50 45C50 45.553 49.553 46 49 46Z" fill="#26355C"/>
    <path d="M48.999 66.999H28C27.447 66.999 27 66.552 27 65.999C27 65.446 27.447 64.999 28 64.999H48.999C49.552 64.999 49.999 65.446 49.999 65.999C49.999 66.552 49.552 66.999 48.999 66.999Z" fill="#26355C"/>
    <path d="M26 64.999C25.447 64.999 25 64.552 25 63.999V47C25 46.447 25.447 46 26 46C26.553 46 27 46.447 27 47V63.999C27 64.552 26.553 64.999 26 64.999Z" fill="#26355C"/>
    <path d="M28 66.999C26.346 66.999 25 65.653 25 63.999C25 63.446 25.447 62.999 26 62.999C26.553 62.999 27 63.446 27 63.999C27 64.551 27.448 64.999 28 64.999C28.553 64.999 29 65.446 29 65.999C29 66.552 28.553 66.999 28 66.999Z" fill="#26355C"/>
    <path d="M62 63H50.999C50.446 63 49.999 62.553 49.999 62C49.999 61.447 50.446 61 50.999 61H62C62.553 61 63 61.447 63 62C63 62.553 62.553 63 62 63Z" fill="#26355C"/>
    <path d="M62 65.001C61.447 65.001 61 64.554 61 64.001V62C61 61.447 61.447 61 62 61C62.553 61 63 61.447 63 62V64.001C63 64.554 62.553 65.001 62 65.001Z" fill="#26355C"/>
    <path d="M60 67H48.999C48.446 67 47.999 66.553 47.999 66C47.999 65.447 48.446 65 48.999 65H60C60.553 65 61 65.447 61 66C61 66.553 60.553 67 60 67Z" fill="#26355C"/>
    <path d="M60 67.001C59.447 67.001 59 66.554 59 66.001C59 65.448 59.447 65.001 60 65.001C60.552 65.001 61 64.553 61 64.001C61 63.448 61.447 63.001 62 63.001C62.553 63.001 63 63.448 63 64.001C63 65.655 61.654 67.001 60 67.001Z" fill="#26355C"/>
    <path d="M48.999 67.001C48.446 67.001 47.999 66.554 47.999 66.001C47.999 65.448 48.446 65.001 48.999 65.001C49.551 65.001 49.999 64.553 49.999 64.001C49.999 63.448 50.446 63.001 50.999 63.001C51.552 63.001 51.999 63.448 51.999 64.001C51.999 65.655 50.653 67.001 48.999 67.001Z" fill="#26355C"/>
    <path d="M51 48C50.447 48 50 47.553 50 47C50 46.448 49.552 46 49 46C48.447 46 48 45.553 48 45C48 44.447 48.447 44 49 44C50.654 44 52 45.346 52 47C52 47.553 51.553 48 51 48Z" fill="#26355C"/>
    <path d="M26 48C25.447 48 25 47.553 25 47C25 45.346 26.346 44 28 44C28.553 44 29 44.447 29 45C29 45.553 28.553 46 28 46C27.448 46 27 46.448 27 47C27 47.553 26.553 48 26 48Z" fill="#26355C"/>
    <path d="M85 86C84.447 86 84 85.553 84 85V73C84 72.447 84.447 72 85 72C85.553 72 86 72.447 86 73V85C86 85.553 85.553 86 85 86Z" fill="#26355C"/>
    <path d="M27 86C26.447 86 26 85.553 26 85V73C26 72.447 26.447 72 27 72C27.553 72 28 72.447 28 73V85C28 85.553 27.553 86 27 86Z" fill="#26355C"/>
    <path d="M87 74H25C24.447 74 24 73.553 24 73V69C24 68.447 24.447 68 25 68H87C87.553 68 88 68.447 88 69V73C88 73.553 87.553 74 87 74ZM26 72H86V70H26V72Z" fill="#26355C"/>
    <path d="M55 63C54.447 63 54 62.553 54 62C54 58.141 57.141 55 61 55C61.553 55 62 55.447 62 56C62 56.553 61.553 57 61 57C58.243 57 56 59.243 56 62C56 62.553 55.553 63 55 63Z" fill="#26355C"/>
    <path d="M65 57.002L60.999 57C60.447 57 60 56.552 60 56C60 55.447 60.448 55 61 55C61 55 61 55 61.001 55L65.001 55.002C65.553 55.002 66 55.45 66 56.002C66 56.555 65.552 57.002 65 57.002Z" fill="#26355C"/>
    <path d="M85 63C84.447 63 84 62.553 84 62C84 59.243 81.757 57 79 57C78.447 57 78 56.553 78 56C78 55.447 78.447 55 79 55C82.859 55 86 58.141 86 62C86 62.553 85.553 63 85 63Z" fill="#26355C"/>
    <path d="M75 57.002C74.448 57.002 74 56.555 74 56.002C74 55.45 74.447 55.002 74.999 55.002L78.999 55C79 55 79 55 79 55C79.552 55 80 55.447 80 56C80 56.552 79.553 57 79.001 57L75 57.002Z" fill="#26355C"/>
    <path d="M85 70.004C84.447 70.004 84 69.557 84 69.004V62C84 61.447 84.447 61 85 61C85.553 61 86 61.447 86 62V69.004C86 69.557 85.553 70.004 85 70.004Z" fill="#26355C"/>
    <path d="M65 57.0018C64.447 57.0018 64 56.5548 64 56.0018V52.8008C64 52.2478 64.447 51.8008 65 51.8008C65.553 51.8008 66 52.2478 66 52.8008V56.0018C66 56.5548 65.553 57.0018 65 57.0018Z" fill="#26355C"/>
    <path d="M75 56.9998C74.447 56.9998 74 56.5528 74 55.9998V52.8008C74 52.2478 74.447 51.8008 75 51.8008C75.553 51.8008 76 52.2478 76 52.8008V55.9998C76 56.5528 75.553 56.9998 75 56.9998Z" fill="#26355C"/>
    <path d="M70 55C63.383 55 58 49.617 58 43.001C58 42.448 58.447 42.001 59 42.001C59.553 42.001 60 42.448 60 43.001C60 48.515 64.486 53 70 53C75.514 53 80 48.515 80 43.001C80 42.448 80.447 42.001 81 42.001C81.553 42.001 82 42.448 82 43.001C82 49.617 76.617 55 70 55Z" fill="#26355C"/>
    <path d="M59 44.002C58.447 44.002 58 43.555 58 43.002V34C58 33.447 58.447 33 59 33C59.553 33 60 33.447 60 34V43.002C60 43.555 59.553 44.002 59 44.002Z" fill="#26355C"/>
    <path d="M81 44.003C80.447 44.003 80 43.556 80 43.003V34C80 33.447 80.447 33 81 33C81.553 33 82 33.447 82 34V43.003C82 43.556 81.553 44.003 81 44.003Z" fill="#26355C"/>
    <path d="M81 35C80.447 35 80 34.553 80 34C80 31.794 78.206 30 76 30C75.447 30 75 29.553 75 29C75 28.447 75.447 28 76 28C79.309 28 82 30.691 82 34C82 34.553 81.553 35 81 35Z" fill="#26355C"/>
    <path d="M76 30C75.447 30 75 29.553 75 29V25C75 24.447 75.447 24 76 24C76.553 24 77 24.447 77 25V29C77 29.553 76.553 30 76 30Z" fill="#26355C"/>
    <path d="M76 26H68C67.447 26 67 25.553 67 25C67 24.447 67.447 24 68 24H76C76.553 24 77 24.447 77 25C77 25.553 76.553 26 76 26Z" fill="#26355C"/>
    <path d="M59 35C58.447 35 58 34.553 58 34C58 28.487 62.486 24.002 68 24.002C68.553 24.002 69 24.449 69 25.002C69 25.555 68.553 26.002 68 26.002C63.589 26.002 60 29.59 60 34C60 34.553 59.553 35 59 35Z" fill="#26355C"/>
    <path d="M81.0003 40.0002C80.7443 40.0002 80.4883 39.9023 80.2933 39.7073L77.2932 36.7072C76.9022 36.3162 76.9022 35.6842 77.2932 35.2932C77.6842 34.9022 78.3163 34.9022 78.7073 35.2932L81.7073 38.2933C82.0983 38.6843 82.0983 39.3163 81.7073 39.7073C81.5123 39.9023 81.2563 40.0002 81.0003 40.0002Z" fill="#26355C"/>
    <path d="M70.0002 39.0001C65.5152 39.0001 61.6872 36.9671 61.5262 36.8811C61.0402 36.6191 60.8582 36.0141 61.1192 35.5271C61.3802 35.0421 61.9852 34.8551 62.4732 35.1191C62.5082 35.1381 66.0322 37.0001 70.0002 37.0001C73.9872 37.0001 77.4912 35.1391 77.5262 35.1191C78.0102 34.8571 78.6182 35.0401 78.8802 35.5261C79.1422 36.0121 78.9592 36.6191 78.4732 36.8801C78.3132 36.9671 74.4852 39.0001 70.0002 39.0001Z" fill="#26355C"/>
    <path d="M59.0003 40.0002C58.7443 40.0002 58.4882 39.9023 58.2932 39.7073C57.9022 39.3163 57.9022 38.6843 58.2932 38.2933L61.2933 35.2932C61.6843 34.9022 62.3163 34.9022 62.7073 35.2932C63.0983 35.6842 63.0983 36.3162 62.7073 36.7072L59.7073 39.7073C59.5123 39.9023 59.2563 40.0002 59.0003 40.0002Z" fill="#26355C"/>
    <path d="M67.0003 59.0002C66.7443 59.0002 66.4883 58.9022 66.2933 58.7072L64.2932 56.7092C63.9022 56.3192 63.9022 55.6862 64.2932 55.2952C64.6842 54.9042 65.3163 54.9042 65.7073 55.2952L67.7073 57.2932C68.0983 57.6832 68.0983 58.3162 67.7073 58.7072C67.5123 58.9022 67.2563 59.0002 67.0003 59.0002Z" fill="#26355C"/>
    <path d="M66.9998 59.0001C66.5808 59.0001 66.1908 58.7351 66.0518 58.3161C65.8768 57.7921 66.1598 57.2251 66.6838 57.0511L69.6838 56.0511C70.2118 55.8741 70.7738 56.1591 70.9488 56.6831C71.1238 57.2071 70.8408 57.7741 70.3168 57.9481L67.3168 58.9481C67.2108 58.9831 67.1038 59.0001 66.9998 59.0001Z" fill="#26355C"/>
    <path d="M73.0003 59.0002C72.7443 59.0002 72.4882 58.9022 72.2932 58.7072C71.9022 58.3162 71.9022 57.6832 72.2932 57.2932L74.2933 55.2952C74.6843 54.9042 75.3163 54.9042 75.7073 55.2952C76.0983 55.6862 76.0983 56.3192 75.7073 56.7092L73.7073 58.7072C73.5123 58.9022 73.2563 59.0002 73.0003 59.0002Z" fill="#26355C"/>
    <path d="M72.9997 59C72.8957 59 72.7887 58.983 72.6837 58.948L69.6837 57.948C69.1607 57.774 68.8767 57.208 69.0517 56.683C69.2267 56.16 69.7887 55.874 70.3167 56.051L73.3167 57.051C73.8397 57.225 74.1237 57.791 73.9487 58.316C73.8087 58.735 73.4187 59 72.9997 59Z" fill="#26355C"/>
    <path d="M70.0304 67C69.4774 67 69.0254 66.553 69.0254 66C69.0254 65.447 69.4674 65 70.0194 65H70.0304C70.5824 65 71.0304 65.447 71.0304 66C71.0304 66.553 70.5824 67 70.0304 67Z" fill="#26355C"/>
    <path d="M70.0304 63C69.4774 63 69.0254 62.553 69.0254 62C69.0254 61.447 69.4674 61 70.0194 61H70.0304C70.5824 61 71.0304 61.447 71.0304 62C71.0304 62.553 70.5824 63 70.0304 63Z" fill="#26355C"/>
    </svg>
</template>

<script>
export default {
  name: "GraphicDesignerIcon"
}
</script>

<style scoped>

</style>
