<template>
  <div v-if="user && job" class="bg-cream">
    <main class="py-10 container mx-auto px-6 lg:px-20 xl:px-32">
      <p class="heading-s-700">Hey, {{ user.first_name }}  we have a new job from {{ job.church.name }} we think could be great for you. </p>
      <div class="my-8 p-4 bg-white rounded-lg">
        <div v-for="(task, index) in job.tasks" :key="index" class="flex items-center body-m-400">
          <div class="mr-3 w-2.5 h-2.5"><CircleIcon/></div>
          <span>{{task.name}}</span>
        </div>
      </div>

      <ButtonIcon @onClick="goToBid" text="Yes! I want to bid on this project" class="w-full"/>
    </main>
  </div>
  <div v-else class="flex flex-col items-center justify-center h-screen">
    <loading-icon class="h-5 text-auxiliary-blueLight" />
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";
import CircleIcon from "@/components/shared/svg/CircleIcon";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";

export default {
  name: "InvitationToBid",
  components: { CircleIcon, ButtonIcon },

  async created() {
    const query = this.$route.query;
    const { job } = await this.loginFromInvitation({ email: query.email, token: query.token, job_id: this.$route.params.jobId})
    this.setJob(job);
  },

  methods: {
    ...mapActions({
      fetchJob: 'jobs/fetchJob',
      loginFromInvitation: 'auth/loginFromInvitation',
    }),

    ...mapMutations({
      setJob: 'jobs/setJob',
    }),

    goToBid() {
      this.$router.push({ name: "Bid", params: { job: this.job } });
    },
  },
  computed:{
    ...mapState({
      job: state => state.jobs.current,
      user: state => state.auth.user,
    }),
  }
}
</script>

