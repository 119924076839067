<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class=" stroke-current">
<path d="M14.207 7.65721C15.0864 8.53657 15.0864 9.96116 14.207 10.8405C13.3277 11.7199 11.9031 11.7199 11.0237 10.8405C10.1444 9.96116 10.1444 8.53657 11.0237 7.65721C11.9031 6.77784 13.3277 6.77784 14.207 7.65721" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.9382 16.5429C16.7171 15.1533 15.5226 14.0889 14.072 14.0889H11.1588C9.70718 14.0889 8.51368 15.1533 8.29259 16.5429" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.6175 21.0037H7.6133C5.40338 21.0037 3.61163 19.212 3.61163 17.002V6.99788C3.61163 4.78796 5.40338 2.99622 7.6133 2.99622H17.6175C19.8274 2.99622 21.6191 4.78796 21.6191 6.99788V17.002C21.6191 19.212 19.8274 21.0037 17.6175 21.0037Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
name:'ProsIcon'
}
</script>
