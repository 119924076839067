<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class=" stroke-current"
  >
    <path
      d="M18.3536 3.84666C22.249 7.74214 22.249 14.058 18.3536 17.9534C14.4581 21.8489 8.14226 21.8489 4.24681 17.9534C0.351325 14.0579 0.351325 7.74212 4.24681 3.84666C8.14229 -0.0488214 14.4581 -0.0488214 18.3536 3.84666"
      :stroke="stroke"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
      :stroke-dasharray="strokeDasharray"
    />
    <path
      d="M17.1828 18.9432C16.7572 16.9039 14.2989 15.3334 11.3009 15.3334C8.30283 15.3334 5.84455 16.9039 5.41895 18.9432"
      :stroke="stroke"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
      :stroke-dasharray="strokeDasharray"
    />
    <path
      d="M13.6517 6.33214C14.9502 7.63063 14.9502 9.73591 13.6517 11.0344C12.3532 12.3329 10.2479 12.3329 8.94945 11.0344C7.65096 9.73591 7.65096 7.63063 8.94945 6.33214C10.2479 5.03365 12.3532 5.03365 13.6517 6.33214"
      :stroke="stroke"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
      :stroke-dasharray="strokeDasharray"
    />
  </svg>
</template>

<script>
export default {
  name: "AccountIcon",
  props: {
    strokeWidth: { type: String, default: '1.4'},
    strokeDasharray: { type: String, default: ''},
    stroke: { type: String, default: ''},
  },
};
</script>
