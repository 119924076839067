<template>
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5248 2.38141C13.2056 2.06204 12.8266 1.80869 12.4094 1.63584C11.9923 1.46299 11.5451 1.37402 11.0936 1.37402C10.642 1.37402 10.1949 1.46299 9.77773 1.63584C9.36057 1.80869 8.98155 2.06204 8.66233 2.38141L7.99983 3.04391L7.33733 2.38141C6.69252 1.73661 5.81798 1.37436 4.90608 1.37436C3.99418 1.37436 3.11964 1.73661 2.47483 2.38141C1.83002 3.02622 1.46777 3.90077 1.46777 4.81266C1.46777 5.72456 1.83002 6.59911 2.47483 7.24391L3.13733 7.90641L7.99983 12.7689L12.8623 7.90641L13.5248 7.24391C13.8442 6.92469 14.0975 6.54567 14.2704 6.12851C14.4433 5.71135 14.5322 5.26422 14.5322 4.81266C14.5322 4.36111 14.4433 3.91398 14.2704 3.49682C14.0975 3.07966 13.8442 2.70064 13.5248 2.38141V2.38141Z"
      stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" :fill="fill ? 'currentColor': 'none'"/>
  </svg>
</template>

<script>
export default {
  name: "HeartIcon",
  props: {
    fill: { type: Boolean, required: false, default: false},
  }
}
</script>

<style scoped>

</style>
