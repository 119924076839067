import Vue from 'vue';
import VueRouter from 'vue-router';
import authRoutes from './auth';
import proRoutes from './pros';
import jobRoutes from './jobs';
import churchRoutes from './churches';
import bidRoutes from './bids';
import settingsRoutes from './settings';
import store from '../store/index';

Vue.use(VueRouter);

const guest = (from, to, next) => {
  const isGuest = store.getters['auth/guest'];
  if (!isGuest) {
    return next({ name: 'Home' });
  }

  return next();
};

const impersonate = (from) => {
  store.dispatch('auth/impersonate', from.query)
    .finally(() => {
      router.push({ name: 'Home' });
    });
};

const routes = [
  {
    path: '/impersonate',
    beforeEnter: impersonate,
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/auth/Welcome'),
    beforeEnter: guest,
    meta: {
      rootClasses: true
    },
  },
  {
    path: '/inbox',
    name: 'Inbox',
    component: () => import(/* webpackChunkName: "inbox" */ '../views/inbox/Inbox.vue'),
    meta: {
      rootClasses: true,
      requiresAuth: true,
    },
  },
  {
    path: '/inbox/:threadId',
    redirect: (route) => ({ name: 'Inbox', params: { threadId: route.params.threadId } }),
  },
  {
    path: '/single-thread',
    name: 'SingleThread',
    props: true,
    component: () => import(/* webpackChunkName: "inbox" */ '../views/inbox/SingleThread.vue'),
    meta: {
      rootClasses: true,
      requiresAuth: true,
    },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  ...authRoutes,
  ...settingsRoutes,
  ...proRoutes,
  ...jobRoutes,
  ...churchRoutes,
  ...bidRoutes,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const authUser = store.getters['auth/authUser'];
  const reqAuth = to.matched.some(record => record.meta.requiresAuth);

  if (reqAuth && !authUser) {
    store.dispatch('auth/getUser').then(() => {
      // store.dispatch('user/getUnreadNotifications');
      if (!store.getters['auth/authUser']) {
        next({ name: 'Welcome' });
      } else {
        next();
      }
    });
  } else {
    // if (reqAuth) store.dispatch('user/getUnreadNotifications');
    next();
  }
});

export default router;
