<template>
  <svg width="807" height="609" viewBox="0 0 807 609" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f)">
      <ellipse cx="249.697" cy="74.6402" rx="258.904" ry="130.645" transform="rotate(171.854 249.697 74.6402)"
               fill="#FF7F51"/>
    </g>
    <g filter="url(#filter1_f)">
      <ellipse cx="299.197" cy="173.914" rx="291.977" ry="130.935" transform="rotate(171.854 299.197 173.914)"
               fill="#3144D3"/>
    </g>
    <g filter="url(#filter2_f)">
      <ellipse cx="25.2949" cy="203.013" rx="231.761" ry="174.346" transform="rotate(171.854 25.2949 203.013)"
               fill="#1986EC"/>
    </g>
    <defs>
      <filter id="filter0_f" x="-225.106" y="-291.371" width="949.607" height="732.023" filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur"/>
      </filter>
      <filter id="filter1_f" x="-208.387" y="-197.069" width="1015.17" height="741.968" filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur"/>
      </filter>
      <filter id="filter2_f" x="-428.831" y="-202.413" width="908.252" height="810.851" filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur"/>
      </filter>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "Blur"
}
</script>

<style scoped>

</style>
