import api from '@/api'

const general = {
  namespaced: true,

  state: {
    showNotificationsPopUp: false,
    showMobileMenu: false,
    modalVisible: false,
    modalComponent: null,
    modalProps: {},
    modalMinWH: 'min-width: 760px; min-height: 384px;',
    modalMaxWH: 'max-w-3xl lg:max-w-4.5xl max-h-96 lg:max-h-180 2xl:max-h-192',
  },

  actions: {
    reportTechnicalProblem(_, payload) {
      return api.post(`/feedbacks`, payload)
        .then(({ data }) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    inviteUser(_, payload) {
      return api.post('/invite-user', payload)
        .then(({ data }) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
  },

  mutations: {
    setShowNotificationsPopUp(state, payload) {
      state.showNotificationsPopUp = payload;
    },
    setShowMobileMenu(state, payload) {
      state.showMobileMenu = payload;
    },

    showModal(state, modal) {
      state.modalVisible = true;
      state.modalComponent = modal.componentName;
      state.modalProps = modal.props;
      if (modal.minWH) {
        state.modalMinWH = modal.minWH;
      }
      if (modal.maxWH) {
        state.modalMaxWH = modal.maxWH;
      }
    },
    
    hideModal(state) {
      state.modalVisible = false;
      state.modalComponent = null;
      state.modalProps = {};
      state.modalMinWH = 'min-width: 760px; min-height: 384px;';
      state.modalMaxWH = 'max-w-3xl lg:max-w-4.5xl max-h-96 lg:max-h-180 2xl:max-h-192';
    },
  },

}

export default general
