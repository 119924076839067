<template>
  <h2 class="heading-l-500">{{ title }}</h2>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: String
  }
}
</script>
