<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class=" stroke-current">
<path d="M8.9231 13V17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.9231 7V17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.9231 10V17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.9231 3H19.9231C21.0281 3 21.9231 3.895 21.9231 5V19C21.9231 20.105 21.0281 21 19.9231 21H5.9231C4.8181 21 3.9231 20.105 3.9231 19V5C3.9231 3.895 4.8181 3 5.9231 3Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
name:'ReportsIcon'
}
</script>
