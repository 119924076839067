<template>
  <div v-if="imageUrl" class="h-48 relative overflow-hidden rounded-xl border-2 border-dashed border-secondary-dark">
    <img :src="imageUrl" alt="Image" class="h-full m-auto object-cover">
    <div v-if="!working && !uploading" class="absolute z-10 inset-x-0 bg-opacity-75 h-10 flex items-center justify-center bottom-0 transition duration-150 ease-in-out hover:bg-opacity-100" :class="uploadingError ? 'bg-functional-error text-white' : 'bg-gray-800'">
      <div class="flex p-2">
        <span v-if="uploadingError" class="body-xs-400 mr-3">Image not saved</span>
        <label
          class="body-xs-700 text-white focus:outline-none focus:shadow-ouline-gray cursor-pointer hover:text-primary-20"
        >
          Change image
          <input type="file" class="hidden" @change="uploadImage($event)">
        </label>
      </div>
    </div>

    <div class="absolute z-10 top-0 right-0 flex items-center justify-center transition duration-150 ease-in-out">
      <Modal :closeButtonOutside="true" ref="cropModal">
        <div class="p-1 bg-blue-400 bg-opacity-75 rounded-lg mt-2 mr-2 text-white hover:bg-opacity-100">
          <CropIcon/>
        </div>
        <template v-slot:content="{ close }">
          <CropImage :imageUrl="imageUrl" @cropped="saveImage($event, close)" @cancel="close" :loading="uploading" :aspectRatio="cropAspectRatio" :stencilComponent="cropStencilComponent"/>
        </template>
      </Modal>

      <AlertDialog v-if="canDelete" confirm="Delete" @confirm="deleteMedia($event)" type="button" class="p-1 bg-red-400 bg-opacity-75 rounded-lg mt-2 mr-2 text-white hover:bg-opacity-100">
        <TrashIcon/>

        <template #title>
          Delete image
        </template>

        <template #content>
          Are you sure you want to delete this image?
        </template>
      </AlertDialog>
    </div>
    <div v-if="working || uploading" class="absolute z-10 inset-x-0 bg-gray-800 bg-opacity-75 text-white h-10 flex items-center justify-center bottom-0 transition duration-150 ease-in-out text-sm">
      Saving...
    </div>
  </div>
  <div v-else class="w-full rounded-xxl p-5 bg-white" :class="{'border-functional-error border-1.5': error}">
    <EmptyImageUpload class="w-full rounded-xl" @change="uploadImage($event)" :uploading="uploading" :uploadingError="uploadingError"/>
  </div>
</template>

<script>
  import api from '@/api'
  import AlertDialog from '@/components/shared/AlertDialog'
  import EmptyImageUpload from '@/components/shared/EmptyImageUpload'
  import Modal from '@/components/shared/Modal'
  import TrashIcon from '@/components/shared/svg/TrashIcon'
  import CropIcon from '@/components/shared/svg/CropIcon'
  import CropImage from '@/components/shared/CropImage'

  export default {
    name: 'ImageUploadSingle',

    props: {
      entity: String,
      working: { type: Boolean, defualt: false},
      modelId: Number,
      imageUrl: String,
      imageUuid: String,
      error: { type: Boolean, defualt: false},
      canDelete: { type: Boolean, defualt: false},
      cropAspectRatio: { type: Number, required: false, default: 1 },
      cropStencilComponent: { type: String, required: false, default: 'circle-stencil'}
    },

    components: {
      AlertDialog,
      TrashIcon,
      CropIcon,
      Modal,
      CropImage,
      EmptyImageUpload
    },

    data() {
      return {
        uploading: false,
        uploadingError: false,
      }
    },

    methods: {
      saveImage(canvas, callback){
        this.uploading = true
        this.uploadingError = false
        if (canvas) {
          let uploadForm = new FormData()
          canvas.toBlob(blob => {
            uploadForm.append('image', blob);
            api.post('/uploads', uploadForm)
            .then(response => {
              this.$emit('uploaded', response.data.uploads[0])
              callback.call();
            })
            .catch(() => {
              this.uploadingError = true
            })
            .finally(() => {
              this.uploading = false
            })
          }, 'image/jpeg');
        }
      },

      uploadImage(e) {
        if (e.target.files.length == 0 ) {
          return
        }

        this.uploading = true
        this.uploadingError = false

        let uploadForm = new FormData()

        uploadForm.append('image', e.target.files[0])

        api.post('/uploads', uploadForm)
          .then(response => {
            this.$emit('uploaded', response.data.uploads[0])
          })
          .catch(() => {
              this.uploadingError = true
          })
          .finally(() => {
              this.uploading = false
              e.target.value = null
          })
      },

      // TODO: this is not working fine
      async deleteMedia(callback) {
        if (!this.entity.includes('new')) {
          await this.$store.dispatch('users/deleteMedia', {
            model_id: this.modelId,
            uuid: this.imageUuid,
          })

          callback.call()

          // await this.$store.dispatch(this.fetchAction, {id: this.modelId})
        }
        this.$emit('deleted')
      },
    },

    computed: {
      fetchAction() {
        switch (this.entity) {
          case 'pro':
            return 'pros/fetchPro';
          case 'church':
            return 'churches/fetchChurch';
          default:
            return 'pros/fetchPro';
        }
      },
    },
  }
</script>
