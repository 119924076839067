<template>
  <div class="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 gap-x-20">
    <!--Left col-->
    <div class="md:col-span-4">
      <h2 class="heading-l-500 mb-10">Jobs</h2>
      <Filters @fetch="fetchJobs(true)" />
    </div>
    <!--Right col-->
    <div class="md:col-span-8">
      <div class="flex items-center justify-between mb-10 flex-col md:flex-row">
        <Search
          @fetch="fetchJobs(true)"
          property="title"
          class="md:w-1/2 mb-2 md:mb-0"
          placeholder="Search jobs..."
        />
        <Sort
          @fetch="fetchJobs(true)"
          class="w-full md:w-auto"
          :options="jobSortingOptions"
        />
      </div>
      <ExploreSkeleton v-if="loading"/>
      <div class="grid grid-cols-1 gap-y-8" v-else-if="jobs.length">
        <JobCard v-for="(job, index) in jobs" :key="index" :job="job" />
      </div>
      <div
        v-else
        class="flex flex-col items-center justify-center pt-20 p-40 text-center"
      >
        <span class="body-l-500 mb-5">No results found</span>
        <span class="body-s-400 text-fonts-secondary"
          >Try adjusting the filters or the search criteria to find what you are
          looking for</span
        >
      </div>
      <Pagination
        class="flex justify-start mt-8 sm:justify-end"
        :pagination="pagination"
        v-on:redirectTo="redirectTo"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Filters from "@/components/pros/filters/Filters";
import Sort from "@/components/pros/Sort";
import JobCard from "@/components/jobs/JobCard";
import Search from "@/components/pros/filters/Search";
import Pagination from "@/components/shared/Pagination";
import ExploreSkeleton from "@/components/skeleton/ExploreSkeleton";

export default {
  name: "Jobs",
  components: { JobCard, Filters, Search, Sort, Pagination, ExploreSkeleton },

  data() {
    return {
      loading: false,
      headerHeight: 0,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.clearFilters();
      if (vm.$route.query.categories) {
        vm.setCategories(vm.$route.query.categories);
      }

      if (vm.$route.query.title) {
        vm.setTitle(vm.$route.query.title);
      }

      if (vm.$route.query.skills) {
        vm.setSkills(vm.$route.query.skills);
      }

      if (vm.$route.query.sort) {
        vm.setSort(vm.$route.query.sort);
      }

      if (vm.$route.query.page) {
        vm.setPage(vm.$route.query.page);
      }
    });
  },

  methods: {
    ...mapActions({
      getJobs: "jobs/fetch",
    }),

    ...mapMutations({
      clearFilters: "filters/clearFilters",
      setCategories: "filters/setCategories",
      setTitle: "filters/setTitle",
      setSkills: "filters/setSkills",
      setSort: "filters/setSort",
      setPage: "filters/setPage",
    }),

    async fetchJobs(resetPagination = false) {
      if (resetPagination) {
        this.setPage(1)
      }

      this.addParams()
      this.loading = true;

      await this.getJobs({
        includes: "church,bids",
        filters: this.filters,
        sort: this.sort,
        page: this.page,
      });
      this.loading = false;
      window.scrollTo(0, 0);
    },

    redirectTo(page) {
      this.setPage(page);
      this.fetchJobs();
    },

    addParams() {
      history.pushState(
        {},
        null,
        this.$route.path + '?' +
        Object.keys(this.query)
          .map(key => {
            return (
              encodeURIComponent(key) + '=' + encodeURIComponent(this.query[key])
            )
          })
          .join('&')
        )
    }
  },

  mounted() {
    this.fetchJobs({ includes: "church" });
  },

  computed: {
    ...mapState({
      jobs: (state) => state.jobs.items,
      pagination: (state) => state.jobs.pagination,
      query: (state) => state.filters.query,
      filters: (state) => state.filters.filters,
      sort: (state) => state.filters.sort,
      page: (state) => state.filters.page,
      jobSortingOptions: (state) => state.filters.jobSortingOptions,
    }),
  },
};
</script>

