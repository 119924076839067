<template>
  <div class="min-h-screen bg-cream">
    <SubHeader/>
    <div class="flex flex-col items-center justify-center px-100 pt-32 pb-10">
      <span class="heading-l-500 mb-5">Reset your password</span>
    </div>
    <div class="px-96">
      <ValidationObserver ref="resetPasswordForm" v-slot="{ invalid }">
        <form @submit.prevent="submit" method="post" autocomplete="off">
          <ValidationProvider name="New password" rules="required|min:8" vid="password" v-slot="{ errors }">
            <TextInput
              v-model="form.password"
              label="New Password"
              x-padding="px-5"
              :type="showNewPassword ? 'text' : 'password'"
              :errors="errors"
              class="mb-5"
            >
              <template slot="rightIcon">
                <div @click.prevent="showNewPassword = !showNewPassword" class="eye-icon" :class="showNewPassword ? 'active':'inactive'">
                <EyeIcon class="eye-icon"/>
                </div>
              </template>
            </TextInput>
          </ValidationProvider>

          <ValidationProvider name="Confirm new password" rules="required|min:8|confirmed:password" vid="password_confirmation" v-slot="{ errors }">
            <TextInput
              v-model="form.password_confirmation"
              label="Confirm new Password"
              x-padding="px-5"
              :type="showConfirmPassword ? 'text' : 'password'"
              :errors="errors"
            >
              <template slot="rightIcon">
                <div @click.prevent="showConfirmPassword = !showConfirmPassword" class="eye-icon" :class="showConfirmPassword ? 'active':'inactive'">
                  <EyeIcon class="eye-icon-color"/>
                </div>
              </template>
            </TextInput>
          </ValidationProvider>

          <div class="flex flex-col justify-center items-center space-y-3 mt-10">
            <button type="submit" :disabled="invalid">
              <ButtonIcon text="Save new password" :disabled="invalid" :loading="loading" fontWeight="font-medium"/>
            </button>

            <ButtonIcon
              @onClick="$router.push({name: 'Welcome'})"
              text="Cancel"
              font-weight="font-normal"
              background="bg-transparent"
              color="text-secondary-main"
              hover-background="hover:bg-secondary-main"
              hover-color="hover:text-white"
              fontSize="text-sm"
              x-padding="px-12"
            />
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import SubHeader from "@/components/register/SubHeader";
import TextInput from "@/components/shared/inputs/TextInput";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import EyeIcon from "@/components/shared/svg/EyeIcon";
import { mapActions } from 'vuex';

export default {
  name: 'ResetPassword',
  components: {SubHeader, TextInput, ButtonIcon, EyeIcon},

  data() {
    return {
      loading: false,
      form: {
        token: null,
        email: null,
        password: '',
        password_confirmation: '',
      },
      showNewPassword: false,
      showConfirmPassword: false,
    }
  },

  mounted() {
    if (this.$route.query.email) {
      this.form.email = this.$route.query.email
    }
  },

  methods: {
    ...mapActions({
      resetPassword: 'auth/resetPassword',
    }),

    submit() {
      this.loading = true;
      this.form.token = this.$route.params.token;
      this.resetPassword(this.form)
        .then(response => {
          if (response) {
            this.$router.push({name: 'Login'})
          }
        })
        .catch(errors => {
          if (errors.response.data.errors) {
            this.$toast.error(errors.response.data.errors);
          }
          if (errors.response.data.message) {
            this.$toast.error(errors.response.data.message);
          }
          this.form.password = null;
          this.form.password_confirmation = null;
          this.form.email = null;
        })
        .finally(() => this.loading = false)

    }
  },
}
</script>