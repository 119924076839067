<template>
  <div class="w-full md:w-8/12 mx-auto">
    <BackButton @onClick="$router.back()" text="Back to settings"  font-weight="font-normal"
                class="mb-6"/>
    <PageTitle title="Report Technical Problem" class="mb-8"/>
    <template v-if="!sent">
      <TextAreaInput
        v-model="form.description"
        label="Tell us about the problem"
        placeholder="Add a brief explanation of the problem you are experiencing"
        rows="4"
        resizable="resize-y"
        class="mb-5"
      />
      <div class="flex space-x-4">
        <ButtonIcon @onClick="sendFeedback" font-weight="font-normal" text="Report Problem" x-padding="px-8" font-size="text-sm" :disabled="form.description == ''"/>
        <ButtonIcon @onClick="$router.back()" font-weight="font-normal" text="Cancel" background="bg-transparent" hover-background="" hover-color=""
                    color="text-secondary-main"/>
      </div>
    </template>
    <template v-else>
      <div class="flex flex-col items-center py-4">
        <CheckCircleIcon class="text-transparent h-12 w-12 mb-6" strokeWidth="1.5" stroke="#EC600C"/>
        <p class="font-bold">Message sent!</p>
        <span class="mb-14">Thank you for helping us improve Talent Pond</span>
        <ButtonIcon @onClick="$router.back()" font-weight="font-normal" text="Back to settings" x-padding="px-8" font-size="text-sm"/>
      </div>
    </template>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import BackButton from "@/components/shared/buttons/BackButton";
import PageTitle from "@/components/shared/labels/PageTitle";
import TextAreaInput from "@/components/shared/inputs/TextAreaInput";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import CheckCircleIcon from "@/components/shared/svg/CheckCircleIcon";

export default {
  name: "ReportProblem",
  components: {ButtonIcon, TextAreaInput, PageTitle, BackButton, CheckCircleIcon},

  data() {
    return {
      form: {
        description: ''
      },
      sent: false
    }
  },

  methods: {
    ...mapActions({
      reportTechnicalProblem: 'general/reportTechnicalProblem',
    }),

    sendFeedback() {
      this.reportTechnicalProblem(this.form).then(() => this.sent = true)
    }
  },
}
</script>

<style scoped>

</style>
