<template>
  <svg width="113" height="113" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="113" height="113" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M94.5374 56.0095C93.5528 64.4888 96.0188 73.7397 91.1793 80.7645C86.2984 87.8494 77.084 89.6139 69.1361 92.8813C60.421 96.4642 51.4624 104.148 42.6942 100.697C33.8937 97.2345 33.8358 84.7163 28.5025 76.8925C23.4067 69.4171 12.5088 65.0587 12.2314 56.0095C11.9542 46.9626 20.8099 40.576 27.1934 34.1733C32.633 28.7171 39.2827 25.4647 46.1518 21.9866C54.502 17.7584 62.2251 10.8647 71.5418 11.7172C81.7199 12.6486 92.7418 17.5951 97.4594 26.6817C102.098 35.6157 95.699 46.006 94.5374 56.0095Z" fill="#EAECFB"/>
  <path d="M66.9692 66.9692C67.4492 66.4902 67.9002 65.9992 68.3202 65.4902L72.8302 70.0102L70.0102 72.8402L65.5002 68.3302L65.4902 68.3192C65.9992 67.8982 66.4892 67.4492 66.9692 66.9692Z" fill="#CCD1D9"/>
  <path d="M70.0098 72.8394L72.8298 70.0094L74.2498 68.5894L86.8298 81.1694C88.3898 82.7294 88.3898 85.2694 86.8298 86.8294C85.2688 88.3894 82.7298 88.3894 81.1698 86.8294L68.5898 74.2494L70.0098 72.8394Z" fill="#656D78"/>
  <path d="M64.849 35.1483C73.049 43.3483 73.049 56.6483 64.849 64.8483C56.65 73.0483 43.349 73.0483 35.15 64.8483C26.95 56.6483 26.95 43.3483 35.15 35.1483C43.349 26.9493 56.649 26.9493 64.849 35.1483ZM61.999 52.0093V51.9993V47.9993C61.999 41.3693 56.629 35.9993 49.999 35.9993C43.369 35.9993 37.999 41.3693 37.999 47.9993V51.9993V52.0093C37.999 56.8493 40.869 61.0293 44.999 62.9193C46.519 63.6193 48.209 64.0093 49.999 64.0093C51.789 64.0093 53.479 63.6193 54.999 62.9193C59.129 61.0283 61.999 56.8493 61.999 52.0093Z" fill="#5D9CEC"/>
  <path d="M64.849 35.148C60.24 30.54 54.02 28.522 47.999 29.095C52.689 29.54 57.258 31.558 60.849 35.149C69.049 43.349 69.049 56.649 60.849 64.849C57.258 68.44 52.69 70.457 47.999 70.904C54.02 71.476 60.24 69.459 64.849 64.849C73.049 56.648 73.049 43.349 64.849 35.148Z" fill="#4A89DC"/>
  <path d="M35.1495 64.8492C43.3485 73.0492 56.6495 73.0492 64.8485 64.8492C73.0485 56.6492 73.0485 43.3492 64.8485 35.1492C56.6495 26.9502 43.3485 26.9502 35.1495 35.1492C26.9495 43.3492 26.9495 56.6482 35.1495 64.8492ZM33.0295 33.0282C42.3995 23.6592 57.5985 23.6592 66.9685 33.0282C75.8595 41.9192 76.3195 56.0582 68.3195 65.4892C67.8995 65.9992 67.4495 66.4892 66.9685 66.9682C66.4895 67.4482 65.9985 67.8982 65.4895 68.3182C56.0695 76.3182 41.9195 75.8582 33.0295 66.9682C23.6595 57.5992 23.6595 42.3982 33.0295 33.0282Z" fill="#E6E9ED"/>
  <path d="M50 71.998C44.123 71.998 38.599 69.71 34.443 65.555C30.288 61.401 28 55.877 28 49.999C28 44.122 30.288 38.598 34.443 34.443C38.599 30.288 44.123 28 50 28C55.877 28 61.401 30.288 65.557 34.443C74.134 43.02 74.134 56.977 65.557 65.554C61.401 69.71 55.877 71.998 50 71.998ZM50 30C44.657 30 39.635 32.08 35.857 35.857C32.08 39.634 30 44.656 30 49.999C30 55.342 32.08 60.364 35.857 64.141C39.634 67.918 44.657 69.998 50 69.998C55.343 69.998 60.365 67.918 64.143 64.141C71.941 56.343 71.941 43.656 64.143 35.858C60.365 32.08 55.343 30 50 30Z" fill="#26355C"/>
  <path d="M50 74.998C43.322 74.998 37.044 72.398 32.322 67.677C27.6 62.956 25.001 56.677 25 49.999C25 43.321 27.601 37.043 32.322 32.322C37.045 27.601 43.323 25 50 25C56.678 25 62.956 27.601 67.678 32.322C77.425 42.069 77.425 57.928 67.678 67.676C62.955 72.398 56.677 74.998 50 74.998ZM50 27C43.857 27 38.081 29.393 33.736 33.736C29.393 38.08 27 43.855 27 49.999C27.001 56.143 29.393 61.919 33.736 66.263C38.079 70.607 43.856 72.998 50 72.998C56.143 72.998 61.919 70.606 66.264 66.263C75.231 57.295 75.231 42.703 66.264 33.737C61.92 29.393 56.144 27 50 27Z" fill="#26355C"/>
  <path d="M70.0063 73.834C69.7503 73.834 69.4943 73.736 69.2993 73.541L64.7913 69.032C64.4003 68.641 64.4003 68.009 64.7913 67.618C65.1823 67.227 65.8143 67.227 66.2053 67.618L70.7133 72.127C71.1043 72.518 71.1043 73.15 70.7133 73.541C70.5183 73.736 70.2623 73.834 70.0063 73.834Z" fill="#26355C"/>
  <path d="M72.8336 71.006C72.5776 71.006 72.3216 70.908 72.1266 70.713L67.6126 66.198C67.2216 65.807 67.2216 65.175 67.6126 64.784C68.0036 64.393 68.6356 64.393 69.0266 64.784L73.5406 69.299C73.9316 69.69 73.9316 70.322 73.5406 70.713C73.3456 70.908 73.0896 71.006 72.8336 71.006Z" fill="#26355C"/>
  <path d="M68.592 75.2481C68.336 75.2481 68.08 75.1501 67.885 74.9551C67.494 74.5641 67.494 73.9321 67.885 73.5411L73.541 67.8831C73.932 67.4921 74.5641 67.4921 74.9551 67.8831C75.3461 68.2741 75.3461 68.9061 74.9551 69.2971L69.299 74.9551C69.104 75.1501 68.848 75.2481 68.592 75.2481Z" fill="#26355C"/>
  <path d="M81.172 87.8278C80.916 87.8278 80.66 87.7298 80.465 87.5348L67.885 74.9548C67.494 74.5638 67.494 73.9318 67.885 73.5408C68.276 73.1498 68.908 73.1498 69.299 73.5408L81.8791 86.1208C82.2701 86.5118 82.2701 87.1438 81.8791 87.5348C81.6841 87.7298 81.428 87.8278 81.172 87.8278Z" fill="#26355C"/>
  <path d="M86.8283 82.1731C86.5723 82.1731 86.3163 82.0751 86.1213 81.8801L73.5413 69.2971C73.1503 68.9061 73.1503 68.2741 73.5413 67.8831C73.9323 67.4921 74.5643 67.4921 74.9553 67.8831L87.5353 80.4661C87.9263 80.8571 87.9263 81.4891 87.5353 81.8801C87.3403 82.0751 87.0843 82.1731 86.8283 82.1731Z" fill="#26355C"/>
  <path d="M84.0011 89.0001C82.6651 89.0001 81.4091 88.4791 80.4651 87.5351C80.0741 87.1441 80.0741 86.5121 80.4651 86.1211C80.8561 85.7301 81.4881 85.7301 81.8791 86.1211C83.0111 87.2531 84.9881 87.2571 86.1201 86.1211C86.6871 85.5551 87.0001 84.8011 87.0001 83.9991C87.0001 83.1981 86.6881 82.4461 86.1221 81.8811C85.7301 81.4901 85.7301 80.8581 86.1201 80.4671C86.5111 80.0741 87.1451 80.0761 87.5341 80.4651C88.4791 81.4081 89.0001 82.6631 89.0001 83.9991C89.0001 85.3351 88.4791 86.5911 87.5351 87.5351C86.5921 88.4791 85.3371 89.0001 84.0011 89.0001Z" fill="#26355C"/>
  <ellipse cx="50" cy="49" rx="15" ry="16" fill="#5D9CEC"/>
  </svg>
</template>

<script>
export default {
  name: "OtherIcon"
}
</script>

<style scoped>

</style>
