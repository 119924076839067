<template>
  <div class="min-h-screen bg-cream">
    <SubHeader/>
    <div class="flex flex-col items-center justify-center px-100 pt-32 pb-10">
      <span class="heading-l-500 mb-5">Forgot your password?</span>
      <span class="body-m-400">Enter your email address and we’ll send you a reset link.</span>
    </div>
    <div class="px-96">
      <ValidationObserver v-slot="{ invalid }" rules="required" ref="forgotPasswordForm">
        <form @submit.prevent="submit">
          <ValidationProvider name="Email" vid="email" rules="required|email" v-slot="{ errors }" class="mb-5">
            <TextInput
              type="email"
              label="Email"
              v-model="form.email"
              placeholder="example@email.com"
              x-padding="px-5"
              :errors="errors"
            />
          </ValidationProvider>
          <div class="flex flex-col justify-center items-center space-y-3 mt-10">
            <button type="submit" :disabled="invalid">
              <ButtonIcon text="Send reset link" :disabled="invalid" :loading="loading" fontWeight="font-medium"/>
            </button>
            <ButtonIcon
              @onClick="$router.back()"
              text="Cancel"
              font-weight="font-normal"
              background="bg-transparent"
              color="text-secondary-main"
              hover-background="hover:bg-secondary-main"
              hover-color="hover:text-white"
              fontSize="text-sm"
              x-padding="px-12"
            />
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import SubHeader from "@/components/register/SubHeader";
import TextInput from "@/components/shared/inputs/TextInput";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import { mapActions } from 'vuex';

export default {
  name: 'ForgotPassword',
  components: {SubHeader, TextInput, ButtonIcon},

  data() {
    return {
      loading: false,
      form: {
        email: ''
      }
    }
  },

  methods: {
    ...mapActions({
      forgotPassword: 'auth/forgotPassword',
    }),

    submit() {
      this.loading = true;
      this.forgotPassword(this.form)
        .then(() => {
          this.$toast.success(`We've sent an email to ${this.form.email}. Click the link in the email to reset your password`);
        })
        .finally(() => {
          this.loading = false;
          this.form.email = ''
        })
    }
  },
}
</script>