<template>
  <div>
    <InputLabel :label="label" :errors="errors"/>
    <div v-for="(item, index) in itemsEditionCopy" :key="index" class="item">
      <TextInput 
        :value="item.text"
        x-padding="px-6"
        :clearAfterSubmit="false"
        @enterPressed="editItem($event, index)"
        :background="itemIsFocused(index) ? 'bg-white' : 'bg-primary-10'"
        @onFocus="changeFocus(index)"
        @onChange="temptativelyEdit($event, index)"
      >
        <template slot="leftIcon">
          <TasksCheckBoxIcon class="checkbox-icon" />
        </template>
        <template slot="rightIcon">
          <div v-if="itemIsFocused(index)" class="text-auxiliary-blue cursor-pointer" @click="editItem(itemsEditionCopy[index].text, index)"> Save </div>
          <div v-else @click.prevent="removeItem(item.id)">
            <TasksTrashIcon 
              class="flex text-fonts-tertiary h-3.5 cursor-pointer"
              strokeWidth="3"
            />
          </div>
        </template>
      </TextInput>
    </div>
    <TextInput
      @enterPressed="addItem"
      :placeholder="placeholder"
      x-padding="px-6"
      :clearAfterSubmit="true"
      :errors="errors"
      @onFocus="changeFocus(-1)"
      v-model="currentTask"
    >
      <template slot="leftIcon">
        <TasksCheckBoxIcon class="checkbox-icon" />
      </template>
      <template slot="rightIcon">
        <div class="save-text cursor-pointer" @click="addItem(currentTask)"> Save </div>
      </template>
    </TextInput>
  </div>
</template>
<script>
import TextInput from "@/components/shared/inputs/TextInput";
import TasksTrashIcon from "@/components/shared/svg/TasksTrashIcon";
import TasksCheckBoxIcon from "@/components/shared/svg/TasksCheckBoxIcon";
import InputLabel from "@/components/shared/labels/InputLabel";


export default {
  name: 'ListInput',
  components: {
    TextInput, 
    InputLabel,
    TasksTrashIcon,
    TasksCheckBoxIcon,
  },
  props: {
    label: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    errors : { type: Array , required: false, default: () => []},
    initialValues: {type: Array, required: false, default: () => []},
  },
  data() {
    return {
      items: [],
      itemsEditionCopy: [],
      currentFocus: -1,
      currentTask: '',
    }
  },
  
  mounted() {
    let vm = this;
    this.$nextTick().then(() => {
      if (vm.initialValues.length) {
        vm.items = vm.initialValues;
        vm.itemsEditionCopy = [...vm.initialValues];
      }
    });
  },

  methods: {
    changeFocus(index) {
      this.currentFocus = index;
      // Ignore any temptative changes
      this.itemsEditionCopy = [...this.items];
    },

    addItem(text) {
      if (text) {
        const id = Math.floor((Math.random() * 10000000));
        this.items.push({ id, text });
        this.itemsEditionCopy.push({ id, text });
        this.currentTask = '';
      }
    },

    editItem(text, index) {
      if (text) {
        this.items[index].text = text;
        this.items = [...this.items];
        this.itemsEditionCopy = [...this.items];
        this.changeFocus(-1);
      }
    },

    temptativelyEdit(text, index) {
      if (text) {
        this.itemsEditionCopy[index]  = {
          id: this.itemsEditionCopy[index].id,
          text,
        };
        this.itemsEditionCopy = [...this.itemsEditionCopy];
      }
    },

    removeItem(id) {
      this.items = this.items.filter(item => item.id !== id);
      this.itemsEditionCopy = [...this.items];
    },

    itemIsFocused(index) {
      return index === this.currentFocus;
    },
  },

  watch: {
    items(items){
      this.$emit('itemsUpdated', items)
    }
  },
}
</script>
<style lang="scss" scoped>
.checkbox-icon {
  margin-right: 10px;
}

.item {
  padding-bottom: 10px;
}
</style>