<template>
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3447 20.5502C12.0969 21.7501 13.1665 22.7871 15.1215 23.3254C17.0949 23.868 19.2766 23.6187 20.9312 22.4131C22.4995 21.2712 23.358 19.6435 23.5988 17.9463"
      stroke="#2C5DE5"
      stroke-width="2.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.4012 16.0537C10.6434 14.3508 11.5076 12.7217 13.0773 11.5813C14.7291 10.3813 16.9051 10.1391 18.8742 10.6803C20.8377 11.2186 21.9087 12.2528 22.6553 13.4498"
      stroke="#2C5DE5"
      stroke-width="2.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.084 23.3835V20.5502H13.9173"
      stroke="#2C5DE5"
      stroke-width="2.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.916 10.6165V13.4498H20.0827"
      stroke="#2C5DE5"
      stroke-width="2.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 29.75V29.75C9.95775 29.75 4.25 24.0422 4.25 17V17C4.25 9.95775 9.95775 4.25 17 4.25V4.25C24.0422 4.25 29.75 9.95775 29.75 17V17C29.75 24.0422 24.0422 29.75 17 29.75Z"
      stroke="#2C5DE5"
      stroke-width="2.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "RecurringJobIcon",
};
</script>

<style scoped></style>
