<template>
  <AlertDialog
    confirm="Yes, delete the job posting"
    @confirm="removeJob($event)"
    :confirmDelay="4"
    :modalClasses="modalClasses"
  >
    <slot name="trigger" />

    <template #title>
      Are you sure you want to delete this job posting?
    </template>
  </AlertDialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import AlertDialog from "@/components/shared/AlertDialog";

export default {
  name: "RemoveJobAlertDialog",
  components: { AlertDialog },
  props: {
    job: { type: Object, required: true },
    modalClasses: { type: String, default: 'bg-auxiliary-blue hover:bg-skyBlue hover:text-white py-2 px-6 flex justify-center items-center rounded-lg outline-none focus:outline-none' },
  },

  methods: {
    ...mapActions({
      remove: 'jobs/remove',
    }),

    ...mapMutations({
      setJob: "jobs/setJob",
      removeItem: "home/removeItem",
    }),

    removeJob(callback) {
      const jobId = this.job.id;
      this.setJob(this.job);
      this.remove()
        .then(response => {
          if (response) {
            callback.call()
            this.removeItem(jobId);
            this.$router.push('/');
          }
        })
        .catch(errors => {
          console.log(errors)
        })
    },
  },
};
</script>
