<template>
  <Dropdown>
    <template slot="content">
      <AlertDialog confirm="Yes, delete the job posting" @confirm="removeJob($event)" :confirmDelay="4" role="menuitem" modalClasses="block w-full flex px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
        Delete job post

        <template #title>
          Are you sure you want to delete this job posting?
        </template>
      </AlertDialog>
    </template>
  </Dropdown>
</template>

<script>
import {mapState, mapActions} from "vuex";
import Dropdown from "@/components/shared/Dropdown";
import AlertDialog from '@/components/shared/AlertDialog'

export default {
  name: 'DeleteJobDropdown',
  components: {Dropdown, AlertDialog},

  methods: {
    ...mapActions({
      remove: 'jobs/remove',
    }),

    removeJob(callback) {
      this.remove()
        .then(response => {
          if (response) {
            callback.call()
            this.$router.push({name:'Church', params: {churchId: this.user.type.church.id}});
          }
        })
        .catch(errors => {
          this.$refs.userForm.setErrors(errors.errors)
        })
    }
  },

  computed: {
    ...mapState({
      job: state => state.jobs.current,
      user: state => state.auth.user,
    }),
  },
}
</script>