import api from '@/api'

const reports = {
  namespaced: true,

  state: {
    jobsReport: [],
  },

  actions: {
    fetchJobsReport({ commit }, date = null) {
      const params = new URLSearchParams();
      if (date) {
        params.append('date', date);
      }
      let data = {params};
      return api.get('/jobs-report', data)
        .then(({ data }) => {
          commit('setJobsReport', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
  },

  mutations: {
    setJobsReport(state, data) {
      state.jobsReport = data;
    },
  },

}

export default reports
