<template>
  <div>
    <div class="grid grid-cols-3 gap-x-5 mb-9">
      <div class="col-span-2">
        <h4 class="mb-4 heading-s-600">Requirements</h4>
        <JobRequirements :job="job" />
      </div>
      <div>
        <h4 class="mb-4 heading-s-600">Details</h4>
        <JobDetails :job="job" :display-items-vertically="true" :showStatus="false" />
      </div>
    </div>
    <ValidationObserver v-if="job" v-slot="{ invalid }" rules="required">
      <form @submit.prevent="next" class="grid grid-cols-1 gap-y-10">
        <ValidationProvider name="Bid amount" rules="required" v-slot="{ errors }">
          <TextInput
            v-model="form.amount"
            label="What do you want to bid on the job?"
            x-padding="px-6"
            placeholder="100"
            :currencyInput="true"
            :errors="errors"
          >
            <template #leftIcon>
              <DollarIcon class="text-primary"/>
            </template>
          </TextInput>
        </ValidationProvider>

        <TextAreaInput
          v-model="form.personal_message"
          label="Add a comment about your bid"
          placeholder="Explain the value of the services you are offering."
        />
      
        <ButtonIcon v-if="!bid" @onClick="bidForJob" text="Save and Continue" class="w-full" :disabled="invalid || loading" :loading="loading"/>
        <ButtonIcon v-else @onClick="updateBid" text="Update your bid" class="w-full" :disabled="invalid" />
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {mapMutations, mapState, mapActions } from "vuex";
import TextInput from "@/components/shared/inputs/TextInput";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import JobRequirements from '@/components/jobs/JobRequirements';
import JobDetails from '@/components/jobs/JobDetails';
import DollarIcon from '@/components/shared/svg/DollarIcon';
import TextAreaInput from "@/components/shared/inputs/TextAreaInput";

export default {
  name: 'Step1',
  components: { TextInput, ButtonIcon, JobRequirements, JobDetails, DollarIcon, TextAreaInput },
  
  props:{
    bid: Object
  },

  data() {
    return {
      loading: false
    }
  },

  methods: {
    ...mapActions({
      create: 'bids/create',
      update: 'bids/update',
    }),
    ...mapMutations({
      increaseBidStep: 'bids/increaseBidStep',
      updateBid: 'bids/update',
      setForm: 'bids/setForm',
    }),
    
    bidForJob() {
      this.loading = true;
      this.create(this.form)
        .then(response => {
          if (response.success) {
            this.increaseBidStep();
          } else { 
            this.$toast.error(response.message);
          }
        })
        .catch(errors => {
          this.$toast.error(errors.data.errors);
        })
        .finally(()=> this.loading = false)
    },

    updateBid(){
      this.update(this.form)
        .then(response => {
          if (response.success) {
            this.$router.push({ name: 'JobBids', params: { jobId: this.job.id } }); 
          } else { 
            this.$toast.error(response.message);
          }
        })
        .catch(errors => {
          this.$toast.error(errors.data.errors);
        })
        .finally(()=> this.loading = false)
    },
    setBid(){
      this.setForm({ personal_message: this.bid.personal_message });
    }
  },
  watch: {
    bid(){
      this.setBid();
    }
  },
  computed: {
    ...mapState({
      form: state => state.bids.form,
      job: state => state.jobs.current
    }),
  },
  mounted() {
    if(this.bid) {
      this.setBid();
    }
  },
}
</script>