<template>
  <div v-if="userCanBid">
    <ButtonIcon
      v-if="userCanApply"
      @onClick="goToBid"
      text="Apply now"
      background="bg-secondary-main"
      border="border border-secondary-dark"
      :hover-background="
        isInvited ? 'hover:bg-secondary-dark' : 'hover:bg-white'
      "
      :hover-color="
        isInvited ? 'hover:text-white' : 'hover:text-secondary-dark'
      "
      x-padding="px-8"
      :color="isInvited ? 'text-secondary-dark' : 'text-white'"
      font-size="text-xs lg:text-sm"
      font-weight="font-normal"
      :loading="loading"
    />
    <span v-else class="font-bold text-primary">PENDING</span>
  </div>
  <BankAccountIncompleteModal v-else />
</template>

<script>
import { mapMutations, mapState } from "vuex";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import BankAccountIncompleteModal from "@/components/shared/modals/BankAccountIncompleteModal";

export default {
  name: "ApplyToJob",
  components: { ButtonIcon, BankAccountIncompleteModal },

  props: {
    job: Object,
  },

  data() {
    return {
      isInvited: false,
      loading: false,
    };
  },

  methods: {
    ...mapMutations({
      setJob: "jobs/setJob",
    }),

    goToBid() {
      this.setJob(this.job);
      this.$router.push({ name: "Bid", params: { job: this.job } });
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    userCanApply() {
      return (
        this.job.bids.filter(
          (bid) =>
            bid.pro.id == this.user.id &&
            (bid.status == "pending" || bid.status == "accepted")
        ).length == 0
      );
    },

    userCanBid() {
      return this.user.type.stripe_account_status == "approved";
    },
  },
};
</script>
