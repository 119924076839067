<template>
  <div class="text-sm bg-auxiliary-blue text-white rounded-full px-4 py-1 inline-block mr-2 mb-2">
    <div class="flex items-center">
      <span class="mr-3">{{ tag.name }}</span>
      <button @click.stop="clearTag" type="button" class="inline-block focus:outline-none">
        <CircleCloseIcon class="w-4 h-4 text-skyBlue hover:text-skyBlueLight"/>
      </button>
    </div>
  </div>
</template>
<script>
import CircleCloseIcon from "@/components/shared/svg/CircleCloseIcon";

export default {
  name: 'SelectedOption',
  components: {CircleCloseIcon},
  props: {
    tag: Object,
  },
  methods: {
    clearTag(e) {
      this.$emit('removeOption', this.tag.id, e)
    }
  }
}
</script>
