import Jobs from '@/views/Jobs';
import Job from '@/views/Job';
import Bid from '@/views/Bid';
import PostJob from '@/views/PostJob';
import JobBids from '@/views/JobBids';
import EditBid from '@/views/pro/EditBid';
import InvitationToBid from '@/views/InvitationToBid';

const routes = [
    {
        path: '/jobs',
        name: 'Jobs',
        component: Jobs,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/jobs/:jobId',
        name: 'Job',
        props: true,
        component: Job,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/post-job',
        name: 'PostJob',
        component: PostJob,
        props: true,
        meta: {
            rootClasses: true,
            requiresAuth: true,
        },
    },
    {
        path: '/bid',
        name: 'Bid',
        component: Bid,
        meta: {
            rootClasses: true,
            requiresAuth: true,
        },
    },    
    {
        path: '/jobs/:jobId/bids/:bidId',
        name: 'EditBid',
        props: true,
        component: EditBid,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/jobs/:jobId/bid',
        redirect: (route) => ({
            name: 'Bid',
            params: { jobId: route.params.jobId },
        }),
    },
    {
        path: '/jobs/:jobId/bids',
        name: 'JobBids',
        component: JobBids,
        meta: {
            rootClasses: true,
            requiresAuth: true,
        },
    },
    {
        path: '/jobs/:jobId/bid-invitation',
        name: 'BidInvitation',
        component: InvitationToBid,
        meta: {
            rootClasses: true,
        },
    },
];

export default routes;