<template>
  <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg" class=" stroke-current">
<path d="M3.32129 13.9001H15.518"  stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.1914 10.5737L15.5178 13.9001L12.1914 17.2265" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.64746 10.7256V8.7996C6.6481 7.74293 7.39422 6.83347 8.4304 6.62637L20.6272 3.9586C21.3079 3.87997 21.9894 4.09861 22.4974 4.55856C23.0053 5.0185 23.2903 5.6751 23.2794 6.36025V21.6616C23.2797 22.3157 22.9913 22.9365 22.4912 23.3581C21.9912 23.7797 21.3305 23.959 20.6859 23.8482L8.48917 21.7503C7.4247 21.5673 6.64695 20.6439 6.64746 19.5638V17.1943"  stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
name:'LogoutIcon'
}
</script>
