<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.22979 5.16924C4.36215 5.26888 4.55202 5.25274 4.66423 5.13231C5.49961 4.23188 6.68902 3.71053 7.94263 3.69532C9.19623 3.68011 10.3989 4.17243 11.2576 5.05231C11.3175 5.11056 11.3992 5.14333 11.4844 5.14333C11.5695 5.14333 11.6512 5.11056 11.7112 5.05231L13.5767 3.36616C13.6372 3.30839 13.6712 3.22974 13.6712 3.1477C13.6712 3.06566 13.6372 2.98701 13.5767 2.92924C12.087 1.40594 9.99624 0.562987 7.82415 0.609923C5.65206 0.656859 3.60264 1.58928 2.18534 3.17539C2.12602 3.24203 2.09907 3.32989 2.11128 3.41688C2.12348 3.50387 2.17368 3.5817 2.24923 3.63078L4.22979 5.16924Z"
      fill="#EB4335"/>
    <path
      d="M3.68069 9.71693C3.77524 9.63505 3.81037 9.50696 3.77014 9.39078C3.45422 8.50121 3.45422 7.53573 3.77014 6.64616C3.81318 6.52386 3.77235 6.38868 3.66791 6.3077L1.57875 4.70155C1.50314 4.64353 1.40432 4.62162 1.30998 4.64194C1.21564 4.66227 1.13594 4.72265 1.09319 4.80616C0.0547165 6.88403 0.0902068 9.31114 1.18903 11.36C1.23467 11.4448 1.31859 11.5044 1.41618 11.5212C1.51377 11.5381 1.61396 11.5104 1.68736 11.4462L3.68069 9.71693Z"
      fill="#FBBC05"/>
    <path
      d="M15.6664 7.07693C15.6664 6.907 15.5234 6.76924 15.347 6.76924H8.95812C8.78169 6.76924 8.63867 6.907 8.63867 7.07693V9.53847C8.63867 9.70841 8.78169 9.84616 8.95812 9.84616H12.0503C11.8622 10.2218 11.6167 10.5682 11.322 10.8739C11.2123 10.991 11.2123 11.1691 11.322 11.2862L13.0278 13.1323C13.0882 13.1945 13.1727 13.2297 13.261 13.2297C13.3494 13.2297 13.4338 13.1945 13.4942 13.1323C14.7942 11.851 15.5662 10.1582 15.6664 8.36924V7.07693Z"
      fill="#4285F4"/>
    <path
      d="M10.2481 11.9754C10.1546 11.8754 10.0045 11.8455 9.87752 11.9015C8.11156 12.7025 6.00936 12.2951 4.70252 10.8985C4.57831 10.7812 4.37951 10.7812 4.2553 10.8985L2.33863 12.5477C2.27816 12.6055 2.24414 12.6841 2.24414 12.7662C2.24414 12.8482 2.27816 12.9269 2.33863 12.9846C4.78687 15.5788 8.79128 16.1573 11.922 14.3692C12.0036 14.3225 12.0595 14.2431 12.0743 14.1527C12.0891 14.0623 12.0614 13.9703 11.9986 13.9015L10.2481 11.9754Z"
      fill="#34A853"/>
  </svg>
</template>

<script>
export default {
  name: "GoogleIcon"
}
</script>

<style scoped>

</style>
