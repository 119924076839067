<template>
  <div class="flex justify-between">
    <span class="flex items-center mr-4 label-xs-500">Sort by</span>
    <Dropdown>
      <template v-slot:button="{ toggle, close }">
        <div style="min-width: 200px" @click="toggle" v-on-clickaway="close" class="rounded-lg bg-white px-4 py-3 flex cursor-pointer">
          <span class="flex mr-2 body-s-500 text-fonts-secondary w-full">{{currentSort}}</span>
          <button class="flex items-center focus:outline-none">
            <ChevronLeftIcon class="transform -rotate-90 text-auxiliary-blue"/>
          </button>
        </div>
      </template>
      <template slot="content">
        <div v-for="(sort, index) in options" :key="index" @click.stop="sortBy(sort.id)" role="menuitem" class="dropdown-item">
          <span class="text-blueishGray text-sm">{{sort.name}}</span>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import {mixin as clickaway} from 'vue-clickaway';
import Dropdown from "@/components/shared/Dropdown";
import ChevronLeftIcon from "@/components/shared/svg/ChevronLeftIcon"
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'Sort',
  mixins: [clickaway],
  components: {Dropdown, ChevronLeftIcon},

  props: {
    options: Array
  },

  methods: {
    ...mapMutations({
      setSort: 'filters/setSort',
    }),

    sortBy(sort) {
      this.setSort(sort)
      this.$emit('fetch')
    }
  },

  computed: {
    ...mapState({
      currentSort: state => state.filters.currentSort,
    }),
  },
}
</script>