<template>
  <transition @enter="enter" mode="out-in">
    <div v-if="showMobileMenu"
         class="w-full h-screen flex justify-start px-10 pt-40 bg-primary absolute inset-0 text-white z-15 md:hidden">
      <div class="flex flex-col space-y-20">
        <button @click="closeAndRedirect('Home')" class="menu-item">
          <TeamIcon v-if="churchUser"/>
          <HomeIcon v-else/>
          <span :class="activePage === 'Home' ? 'active' : null" v-text="churchUser ? 'Team' : 'Home'"></span>
        </button>
        <button @click="closeAndRedirect('Inbox')" class="menu-item">
          <InboxIcon/>
          <span :class="activePage === 'Inbox' ? 'active' : null">Inbox</span>
        </button>
        <button @click="closeAndRedirect(churchUser ? 'Pros' : 'Jobs')" class="menu-item">
          <ProsIcon/>
          <span :class="activePage === 'Pros' || activePage === 'Jobs' ? 'active' : null"
                v-text="churchUser ? 'Pros' : 'Jobs'"></span>
        </button>
        <button @click="closeAndRedirect('Reports')" class="menu-item">
          <ReportsIcon/>
          <span :class="activePage === 'Reports' ? 'active' : null">Reports</span>
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import HomeIcon from "@/components/shared/svg/HomeIcon";
import InboxIcon from "@/components/shared/svg/InboxIcon";
import ProsIcon from "@/components/shared/svg/ProsIcon";
import ReportsIcon from "@/components/shared/svg/ReportsIcon";
import anime from "animejs";
import TeamIcon from "@/components/shared/svg/TeamIcon";

export default {
  name: "MobileMenu",
  components: {TeamIcon, ReportsIcon, ProsIcon, InboxIcon, HomeIcon},
  methods: {
    ...mapMutations({
      setShowMobileMenu: 'general/setShowMobileMenu'
    }),
    enter(el) {
      anime({
        targets: el,
        duration: 300,
        opacity: [0, 1],
        translateX: [-200, 0],
        easing: 'easeOutQuart'
      })
    },
    closeAndRedirect(page) {
      console.log(page)
      this.setShowMobileMenu(false);
      this.activePage = page;
      this.$router.push({name: page})
    }
  },
  data() {
    return {
      activePage: 'Home'
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      showMobileMenu: state => state.general.showMobileMenu
    }),
    churchUser() {
      return this.user.userable_type == 'hire_manager';
    }
  }
}
</script>

<style scoped>
.menu-item {
  @apply flex items-center space-x-5 relative;
}

.z-15 {
  z-index: 15;
}
.active:after {
  position: absolute;
  left: 48px;
  bottom: -10px;
  content: "";
  width: 20px;
  height: 1px;
  border: 2px solid #FF7F51;
  border-radius: 99px;
}
</style>
