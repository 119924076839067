<template>
  <div class="w-full md:w-6/12 mx-auto bg-pink-500">
    <BackButton @onClick="$router.back()" text="Back to settings" font-weight="font-normal"
                class="mb-6"/>

    <PageTitle title="Notifications" class="mb-8"/>

    <div class="grid grid-cols-1 gap-y-20 mb-10">
      <div class="grid grid-cols-1 gap-y-10">
        <span class="divider">In App Notifications</span>
        <NotificationRow label="Bid Accepted"/>
        <NotificationRow label="New Messages"/>
        <NotificationRow label="New Job Post in Your Category"/>
        <NotificationRow label="New Messages"/>
      </div>

      <div class="grid grid-cols-1 gap-y-10">
        <span class="divider">Email Notifications</span>
        <NotificationRow label="Bid Accepted"/>
        <NotificationRow label="New Messages"/>
        <NotificationRow label="New Job Post in Your Category"/>
        <NotificationRow label="New Messages"/>
      </div>
    </div>

    <div class="flex space-x-3">
      <ButtonIcon text="Update settings" font-weight="font-normal"/>
      <ButtonIcon text="Cancel" font-weight="font-normal" hover-background="" hover-color=""
                  color="text-secondary-main" background="bg-transparent"/>
    </div>

  </div>
</template>

<script>
import BackButton from "@/components/shared/buttons/BackButton";
import NotificationRow from "@/components/settings/notifications/NotificationRow";
import PageTitle from "@/components/shared/labels/PageTitle";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";

export default {
  name: "EditNotifications",
  components: {ButtonIcon, PageTitle, NotificationRow, BackButton},

}
</script>

<style scoped>
.divider {
  @apply border-b block pb-2 mb-1;
}
</style>
