<template>
  <AlertDialog
    confirm="Yes, remove the bid"
    @confirm="removeJob($event)"
    :confirmDelay="4"
    :modalClasses="modalClasses"
  >
    <slot name="trigger" />

    <template #title>
      Are you sure you want to remove this bid?
    </template>
  </AlertDialog>
</template>

<script>
import api from '@/api';
import { mapMutations } from "vuex";
import AlertDialog from "@/components/shared/AlertDialog";

export default {
  name: "RemoveBidAlertDialog",
  components: { AlertDialog },
  props: {
    job: { type: Object, required: true },
    bidId: { type: Number, required: false },
    modalClasses: { type: String, default: 'bg-secondary-main hover:bg-secondary-dark text-white px-3 py-1.5 rounded-lg' },
  },

  methods: {
    ...mapMutations({
      removeItem: "home/removeItem",
    }),

    async removeJob(callback) {
      try {
        const jobId = this.job.id;
        if (this.bidId) {
          const { data } = await api.delete(`/bids/${this.bidId}`);
          if (data.success) {
              callback.call()
              this.removeItem(jobId);
              this.$router.push('/');
            }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
