<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.8609 6.07843V6.07843C19.8059 8.96144 19.8563 13.686 16.9733 16.6311L12.9832 20.7071C12.3039 21.4011 11.1905 21.4129 10.4965 20.7335L6.42056 16.7435C3.47548 13.8605 3.42516 9.13591 6.30817 6.19082V6.19082C9.19118 3.24574 13.9158 3.19542 16.8609 6.07843Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.8609 6.07843V6.07843C19.8059 8.96144 19.8563 13.686 16.9733 16.6311L12.9832 20.7071C12.3039 21.4011 11.1905 21.4129 10.4965 20.7335L6.42056 16.7435C3.47548 13.8605 3.42516 9.13591 6.30817 6.19082V6.19082C9.19118 3.24574 13.9158 3.19542 16.8609 6.07843Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6685 13.9713C13.0825 13.9563 14.2166 12.7978 14.2015 11.3838C14.1865 9.96979 13.028 8.83572 11.614 8.85078C10.2 8.86584 9.06592 10.0243 9.08098 11.4383C9.09604 12.8523 10.2545 13.9864 11.6685 13.9713Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "MarkerPinIcon",
};
</script>
