<template>
  <main class="lg:py-10 container mx-auto px-6 lg:px-20 xl:px-32">
    <Step1 v-if="bid" :bid="bid" />
  </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import Step1 from "@/components/bid/Step1";

export default {
  name: "EditBid",
  components: { Step1 },
  props: {
    bidId: [String, Number],
    jobId: [String, Number],
  },
  async mounted() {
    await this.fetchBid({
      bidId: this.bidId,
      includes: "job",
    });
    this.setJob(this.jobId);
  },
  computed: {
    ...mapState({
      bid: (state) => state.bids.current,
    }),
  },
  methods: {
    ...mapActions({
      fetchBid: "bids/fetchBid",
    }),
    ...mapMutations({
      setJob: "jobs/setJob",
    }),
  },
};
</script>

<style scoped></style>
