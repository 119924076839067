<template>
  <Modal class="button focus:outline-none bg-secondary-main hover:bg-secondary-dark border text-xs lg:text-sm">
    <span>Apply now</span>
    <template v-slot:content="{ classes }">
      <div class="bg-white p-7 rounded-2xl w-100" :class="classes">
        <div class="border-1.5 border-fonts-tertiary rounded-md w-7 flex items-center justify-center mb-2">
          <DollarIcon class="text-fonts-tertiary"/>
        </div>
        <h2 class="heading-m-500 mb-3">Complete your bank account information to apply for this job</h2>
        <div class="mb-5">
          <p class="body-m-400">We’ll use your bank account to wire the payment for the job.</p>
          <router-link :to="{name: 'BankAccountConfiguration'}" class="body-m-400 text-secondary-dark">Learn more about payments</router-link>
        </div>
        
        <AddBankAccountButton/>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Modal from '@/components/shared/Modal'
import DollarIcon from "@/components/shared/svg/DollarIcon";
import AddBankAccountButton from "@/components/shared/buttons/AddBankAccountButton";

export default {
  name: 'BankAccountIncompleteModal',
  components: { Modal, DollarIcon, AddBankAccountButton },

  methods: {
    ...mapActions({
      fetchJob: 'jobs/fetchJob'
    }),
  },

  computed: {
    ...mapState({
      job: state => state.jobs.current,
    }),

  },
}
</script>

<style lang="scss" scoped>
.button {
  @apply w-full py-3 font-normal rounded-lg text-white transition-all duration-300 border border-transparent flex justify-center items-center outline-none;
}
  
</style>