<template>
<svg width="113" height="113" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="113" height="113" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M95.4106 57.3657C94.4328 65.8457 96.9061 75.0947 92.0723 82.1233C87.197 89.2122 77.984 90.9839 70.0387 94.2577C61.3264 97.8476 52.374 105.538 43.603 102.095C34.7998 98.639 34.7319 86.1208 29.3924 78.3013C24.2906 70.8299 13.3892 66.4802 13.1047 57.4312C12.8202 48.3845 21.6709 41.9909 28.0492 35.5831C33.4845 30.1226 40.1316 26.8649 46.9979 23.3813C55.3448 19.1464 63.0623 12.2466 72.3798 13.0917C82.5586 14.015 93.5845 18.9527 98.3092 28.0356C102.955 36.9658 96.5643 47.3613 95.4106 57.3657Z" fill="#EAECFB"/>
<path d="M74 32V33H68H46H40V32C40 29.24 42.24 27 45 27H69C71.76 27 74 29.24 74 32Z" fill="#656D78"/>
<path d="M80.1701 55.5502C80.9001 54.8212 82.0801 54.8212 82.8101 55.5502C83.5401 56.2802 83.5401 57.4602 82.8101 58.1912L82.6501 58.3502L80.0001 61.0002L77.3601 58.3502L77.1901 58.1912C76.4601 57.4612 76.4601 56.2812 77.1901 55.5502C77.9191 54.8212 79.0991 54.8212 79.8301 55.5502L80.0001 55.7102L80.1701 55.5502Z" fill="#E6E9ED"/>
<path d="M82.81 55.55C82.081 54.821 80.901 54.821 80.17 55.55L80 55.71L79.83 55.55C79.1 54.821 77.92 54.821 77.19 55.55C76.46 56.28 76.46 57.46 77.19 58.191L77.36 58.35L80 61L82.65 58.35L82.809 58.191C83.54 57.46 83.54 56.28 82.81 55.55ZM88 50V66H74H72V60L71 58L72 56V50H74H88Z" fill="#ED5565"/>
<path d="M34.1701 49.5502C34.9001 48.8212 36.0801 48.8212 36.8101 49.5502C37.5401 50.2802 37.5401 51.4602 36.8101 52.1912L36.6401 52.3502L34.0001 55.0002L31.3501 52.3502L31.1901 52.1902C30.4601 51.4602 30.4601 50.2802 31.1901 49.5492C31.9191 48.8202 33.0991 48.8202 33.8301 49.5492L34.0001 49.7102L34.1701 49.5502Z" fill="#E6E9ED"/>
<path d="M36.81 49.55C36.081 48.821 34.901 48.821 34.17 49.55L34 49.71L33.83 49.55C33.1 48.821 31.92 48.821 31.19 49.55C30.46 50.28 30.46 51.46 31.19 52.191L31.35 52.35L34 55L36.64 52.35L36.81 52.191C37.54 51.46 37.54 50.28 36.81 49.55ZM42 50L43 52L42 54V60H40H26V44H40H42V50Z" fill="#ED5565"/>
<path d="M55.9297 64.71L54.9697 66H48.9697L52.9697 61L55.9297 64.71Z" fill="#434A54"/>
<path d="M60.9697 58L66.9697 66H54.9697L55.9297 64.71L60.9697 58Z" fill="#656D78"/>
<path d="M53.5 54C54.3284 54 55 53.3284 55 52.5C55 51.6716 54.3284 51 53.5 51C52.6716 51 52 51.6716 52 52.5C52 53.3284 52.6716 54 53.5 54Z" fill="#FFCE54"/>
<path d="M68 58V66H66.97L60.97 58L55.93 64.71L52.97 61L48.97 66H46V52V44H68V58ZM55 52.5C55 51.67 54.33 51 53.5 51C52.67 51 52 51.67 52 52.5C52 53.33 52.67 54 53.5 54C54.33 54 55 53.33 55 52.5Z" fill="#5D9CEC"/>
<path d="M68 33H46V38H68V33Z" fill="#5D9CEC"/>
<path d="M72 50H74V33H68V38H46V33H40V44H42V50L43 52L42 54V60H40V77H46V72H68V77H74V66H72V60L71 58L72 56V50ZM68 66H66.97H54.97H48.97H46V52V44H68V58V66Z" fill="#E6E9ED"/>
<path d="M68 72V77H57H46V72H68Z" fill="#5D9CEC"/>
<path d="M68 85C71.3 85 74 82.3 74 79V83C74 86.3 71.3 89 68 89H46C42.7 89 40 86.3 40 83V79C40 82.3 42.7 85 46 85H57H68Z" fill="#434A54"/>
<path d="M57 79C58.1 79 59 79.9 59 81C59 82.11 58.1 83 57 83C55.9 83 55 82.11 55 81C55 79.9 55.9 79 57 79Z" fill="#AAB2BD"/>
<path d="M68 77H57H46H40V79C40 82.3 42.7 85 46 85H57H68C71.3 85 74 82.3 74 79V77H68ZM57 83C55.9 83 55 82.11 55 81C55 79.9 55.9 79 57 79C58.1 79 59 79.9 59 81C59 82.11 58.1 83 57 83Z" fill="#656D78"/>
<path d="M68 86H46C42.141 86 39 82.859 39 79C39 78.447 39.447 78 40 78C40.553 78 41 78.447 41 79C41 81.757 43.243 84 46 84H68C70.757 84 73 81.757 73 79C73 78.447 73.447 78 74 78C74.553 78 75 78.447 75 79C75 82.859 71.859 86 68 86Z" fill="#26355C"/>
<path d="M54.0304 31C53.4774 31 53.0254 30.553 53.0254 30C53.0254 29.447 53.4674 29 54.0194 29H54.0304C54.5824 29 55.0304 29.447 55.0304 30C55.0304 30.553 54.5824 31 54.0304 31Z" fill="#26355C"/>
<path d="M60 31H57C56.447 31 56 30.553 56 30C56 29.447 56.447 29 57 29H60C60.553 29 61 29.447 61 30C61 30.553 60.553 31 60 31Z" fill="#26355C"/>
<path d="M74 33.002C73.447 33.002 73 32.555 73 32.002C73 29.796 71.206 28.002 69 28.002C68.447 28.002 68 27.555 68 27.002C68 26.449 68.447 26.002 69 26.002C72.309 26.002 75 28.693 75 32.002C75 32.555 74.553 33.002 74 33.002Z" fill="#26355C"/>
<path d="M40 33C39.447 33 39 32.553 39 32C39 28.691 41.691 26 45 26C45.553 26 46 26.447 46 27C46 27.553 45.553 28 45 28C42.794 28 41 29.794 41 32C41 32.553 40.553 33 40 33Z" fill="#26355C"/>
<path d="M69 28H45C44.447 28 44 27.553 44 27C44 26.447 44.447 26 45 26H69C69.553 26 70 26.447 70 27C70 27.553 69.553 28 69 28Z" fill="#26355C"/>
<path d="M74 34.002H40C39.447 34.002 39 33.555 39 33.002C39 32.449 39.447 32.002 40 32.002H74C74.553 32.002 75 32.449 75 33.002C75 33.555 74.553 34.002 74 34.002Z" fill="#26355C"/>
<path d="M68 90H46C42.141 90 39 86.859 39 83C39 82.447 39.447 82 40 82C40.553 82 41 82.447 41 83C41 85.757 43.243 88 46 88H68C70.757 88 73 85.757 73 83C73 82.447 73.447 82 74 82C74.553 82 75 82.447 75 83C75 86.859 71.859 90 68 90Z" fill="#26355C"/>
<path d="M74 78H40C39.447 78 39 77.553 39 77C39 76.447 39.447 76 40 76H74C74.553 76 75 76.447 75 77C75 77.553 74.553 78 74 78Z" fill="#26355C"/>
<path d="M57 84C55.346 84 54 82.654 54 81C54 79.346 55.346 78 57 78C58.654 78 60 79.346 60 81C60 82.654 58.654 84 57 84ZM57 80C56.448 80 56 80.448 56 81C56 81.552 56.448 82 57 82C57.552 82 58 81.552 58 81C58 80.448 57.552 80 57 80Z" fill="#26355C"/>
<path d="M68 67H46C45.447 67 45 66.553 45 66V44C45 43.447 45.447 43 46 43H68C68.553 43 69 43.447 69 44V66C69 66.553 68.553 67 68 67ZM47 65H67V45H47V65Z" fill="#26355C"/>
<path d="M68 39H46C45.447 39 45 38.553 45 38V33C45 32.447 45.447 32 46 32C46.553 32 47 32.447 47 33V37H67V33C67 32.447 67.447 32 68 32C68.553 32 69 32.447 69 33V38C69 38.553 68.553 39 68 39Z" fill="#26355C"/>
<path d="M68 78C67.447 78 67 77.553 67 77V73H47V77C47 77.553 46.553 78 46 78C45.447 78 45 77.553 45 77V72C45 71.447 45.447 71 46 71H68C68.553 71 69 71.447 69 72V77C69 77.553 68.553 78 68 78Z" fill="#26355C"/>
<path d="M54.9662 67C54.7572 67 54.5472 66.935 54.3672 66.8C53.9252 66.469 53.8352 65.842 54.1672 65.401L60.1672 57.401C60.4982 56.959 61.1262 56.871 61.5662 57.201C62.0082 57.532 62.0982 58.159 61.7662 58.6L55.7662 66.6C55.5702 66.862 55.2712 67 54.9662 67Z" fill="#26355C"/>
<path d="M66.9682 67.0002C66.6632 67.0002 66.3642 66.8622 66.1672 66.6002L60.1672 58.6002C59.8352 58.1592 59.9252 57.5322 60.3672 57.2012C60.8072 56.8702 61.4352 56.9582 61.7662 57.4012L67.7662 65.4012C68.0982 65.8422 68.0082 66.4692 67.5662 66.8002C67.3872 66.9352 67.1772 67.0002 66.9682 67.0002Z" fill="#26355C"/>
<path d="M48.9659 67.0004C48.7469 67.0004 48.5269 66.9294 48.3419 66.7814C47.9109 66.4354 47.8409 65.8064 48.1859 65.3754L52.1859 60.3754C52.5329 59.9464 53.1599 59.8734 53.5919 60.2194C54.0229 60.5654 54.0929 61.1944 53.7479 61.6254L49.7479 66.6254C49.5499 66.8714 49.2599 67.0004 48.9659 67.0004Z" fill="#26355C"/>
<path d="M55.9346 65.7094C55.6406 65.7094 55.3506 65.5804 55.1526 65.3344L52.1856 61.6254C51.8406 61.1934 51.9116 60.5644 52.3416 60.2194C52.7746 59.8734 53.4026 59.9464 53.7476 60.3754L56.7146 64.0844C57.0596 64.5164 56.9886 65.1454 56.5586 65.4904C56.3736 65.6384 56.1526 65.7094 55.9346 65.7094Z" fill="#26355C"/>
<path d="M53.5 55C52.121 55 51 53.879 51 52.5C51 51.121 52.121 50 53.5 50C54.879 50 56 51.121 56 52.5C56 53.879 54.879 55 53.5 55ZM53.5 52C53.225 52 53 52.225 53 52.5C53 52.775 53.225 53 53.5 53C53.775 53 54 52.775 54 52.5C54 52.225 53.775 52 53.5 52Z" fill="#26355C"/>
<path d="M33.9996 56.0002C33.7346 56.0002 33.4796 55.8952 33.2926 55.7072L30.4816 52.8962C29.9406 52.3562 29.6416 51.6362 29.6416 50.8702C29.6406 50.1032 29.9396 49.3832 30.4816 48.8412C31.3936 47.9252 32.9166 47.7552 33.9986 48.4152C35.0806 47.7592 36.6026 47.9222 37.5196 48.8442C38.0586 49.3832 38.3576 50.1042 38.3566 50.8702C38.3566 51.6372 38.0576 52.3562 37.5156 52.8972L34.7056 55.7072C34.5196 55.8952 34.2656 56.0002 33.9996 56.0002ZM32.5106 50.0002C32.2786 50.0002 32.0616 50.0902 31.8986 50.2542C31.7326 50.4202 31.6426 50.6382 31.6426 50.8702C31.6426 51.1012 31.7326 51.3182 31.8956 51.4812L33.9996 53.5852L36.1086 51.4772C36.2666 51.3192 36.3566 51.1022 36.3566 50.8712C36.3566 50.6392 36.2666 50.4212 36.1026 50.2582C35.7726 49.9242 35.2016 49.9282 34.8736 50.2562L34.7026 50.4252C34.3116 50.8102 33.6866 50.8102 33.2966 50.4252L33.1306 50.2602C32.9616 50.0912 32.7426 50.0002 32.5106 50.0002Z" fill="#26355C"/>
<path d="M42 61H26C25.447 61 25 60.553 25 60V44C25 43.447 25.447 43 26 43H42C42.553 43 43 43.447 43 44V49.763L43.895 51.552C44.036 51.833 44.036 52.165 43.895 52.447L43 54.235V60C43 60.553 42.553 61 42 61ZM27 59H41V53.999C41 53.844 41.036 53.69 41.105 53.552L41.881 51.999L41.105 50.446C41.036 50.308 41 50.154 41 49.999V45H27V59Z" fill="#26355C"/>
<path d="M40 45C39.447 45 39 44.553 39 44V32.002C39 31.449 39.447 31.002 40 31.002C40.553 31.002 41 31.449 41 32.002V44C41 44.553 40.553 45 40 45Z" fill="#26355C"/>
<path d="M40 84C39.447 84 39 83.553 39 83V60C39 59.447 39.447 59 40 59C40.553 59 41 59.447 41 60V83C41 83.553 40.553 84 40 84Z" fill="#26355C"/>
<path d="M79.9996 61.9999C79.7336 61.9999 79.4796 61.8949 79.2926 61.7069L76.4876 58.9019C75.9406 58.3559 75.6416 57.6359 75.6416 56.8699C75.6406 56.1029 75.9396 55.3829 76.4816 54.8409C77.3926 53.9259 78.9166 53.7549 79.9986 54.4149C81.0806 53.7589 82.6036 53.9219 83.5196 54.8439C84.0586 55.3829 84.3576 56.1039 84.3566 56.8699C84.3566 57.6369 84.0576 58.3559 83.5156 58.8969L80.7056 61.7069C80.5196 61.8949 80.2656 61.9999 79.9996 61.9999ZM78.5106 55.9999C78.2786 55.9999 78.0616 56.0899 77.8986 56.2539C77.7326 56.4199 77.6426 56.6379 77.6426 56.8699C77.6426 57.1009 77.7326 57.3179 77.8956 57.4809L79.9996 59.5849L82.1036 57.4819C82.2676 57.3179 82.3576 57.1009 82.3576 56.8699C82.3576 56.6379 82.2676 56.4199 82.1036 56.2569C81.7736 55.9229 81.2026 55.9269 80.8746 56.2549L80.7036 56.4239C80.3126 56.8089 79.6876 56.8089 79.2976 56.4239L79.1316 56.2599C78.9616 56.0909 78.7426 55.9999 78.5106 55.9999Z" fill="#26355C"/>
<path d="M87.9998 67H71.9998C71.4468 67 70.9998 66.553 70.9998 66V60.235L70.1048 58.446C69.9638 58.165 69.9638 57.833 70.1048 57.551L70.9998 55.763V50C70.9998 49.447 71.4468 49 71.9998 49H87.9998C88.5528 49 88.9998 49.447 88.9998 50V66C88.9998 66.553 88.5528 67 87.9998 67ZM72.9998 65H86.9998V51H72.9998V55.999C72.9998 56.154 72.9638 56.308 72.8948 56.446L72.1188 57.999L72.8948 59.552C72.9638 59.69 72.9998 59.844 72.9998 59.999V65Z" fill="#26355C"/>
<path d="M74 51C73.447 51 73 50.553 73 50V32.002C73 31.449 73.447 31.002 74 31.002C74.553 31.002 75 31.449 75 32.002V50C75 50.553 74.553 51 74 51Z" fill="#26355C"/>
<path d="M74 84C73.447 84 73 83.553 73 83V66C73 65.447 73.447 65 74 65C74.553 65 75 65.447 75 66V83C75 83.553 74.553 84 74 84Z" fill="#26355C"/>
</svg>
</template>

<script>
export default {
  name: "SocialMediaManagerIcon"
}
</script>

<style scoped>

</style>
