<template>
  <p>
    <span v-for="(category, index) in categories" :key="index" class="text-primary uppercase text-sm font-bold leading-6 font-poppins">
      {{category.name}}<span v-if="categories.length > index + 1" class="mr-1">,</span>
    </span>
  </p>
</template>
<script>

export default {
  name: 'Categories',
  props: {
    categories: Array,
  }
}
</script>
