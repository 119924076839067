<template>
  <svg width="113" height="113" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="113" height="113" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M57.1453 19.131C48.666 20.1156 39.4151 17.6497 32.3903 22.4891C25.3053 27.37 23.5409 36.5845 20.2734 44.5324C16.6905 53.2475 9.00717 62.206 12.4574 70.9743C15.9202 79.7747 28.4385 79.8327 36.2623 85.166C43.7377 90.2618 48.0961 101.16 57.1453 101.437C66.1922 101.714 72.5788 92.8585 78.9815 86.4751C84.4376 81.0354 87.69 74.3857 91.1682 67.5167C95.3964 59.1664 102.29 51.4434 101.438 42.1266C100.506 31.9485 95.5597 20.9266 86.4731 16.2091C77.5391 11.5708 67.1488 17.9694 57.1453 19.131Z" fill="#EAECFB"/>
  <g clip-path="url(#clip0)">
  <path d="M30 72V74H24V66H30V68V72Z" fill="#ED5565"/>
  <path d="M46 40.97L43 43V40C43 35.58 46.58 32 51 32H57V36C59.21 36 61 37.79 61 40V43L58 40.97C58 40.97 55.99 41.97 52.5 41.97C49.01 41.97 46 40.97 46 40.97Z" fill="#965353"/>
  <path d="M57 36V32H53V38C55.21 38 57 38.79 57 41V41.34C57.643 41.141 58 40.97 58 40.97L61 43V40C61 37.79 59.21 36 57 36Z" fill="#844747"/>
  <path d="M61 43.03V47C61 50.53 58.96 53.59 56 55.06C54.8 55.66 53.43 56 52 56C50.57 56 49.2 55.66 48 55.06C45.04 53.59 43 50.531 43 47V43.03L46 41C46 41 49.01 42 52.5 42C55.99 42 58 41 58 41L61 43.03Z" fill="#EAC6BB"/>
  <path d="M58 41C58 41 57.643 41.171 57 41.369V47C57 50.53 54.96 53.59 52 55.06C51.375 55.373 50.702 55.611 50 55.771C50.646 55.918 51.315 56 52 56C53.43 56 54.8 55.66 56 55.06C58.96 53.59 61 50.531 61 47V43.03L58 41Z" fill="#D3B1A9"/>
  <path d="M30 50.5V51C30 54.87 33.13 58 37 58H47C43.47 58 40.41 60.04 38.94 63H38.93H37C30.37 63 25 57.63 25 51V50.5C25 49.12 26.12 48 27.5 48C28.88 48 30 49.12 30 50.5Z" fill="#CCD1D9"/>
  <path d="M25 34L23 31H31L29 34H25Z" fill="#FFCE54"/>
  <path d="M33 31V45H21V31H23L25 34H29L31 31H33Z" fill="#656D78"/>
  <path d="M83 34V43L77.75 38.5L83 34Z" fill="#EDAB37"/>
  <path d="M74.25 38.5L69 43V34L74.25 38.5Z" fill="#EDAB37"/>
  <path d="M83 34L77.75 38.5L76 40L74.25 38.5L69 34H83Z" fill="#FFCE54"/>
  <path d="M76 40L77.75 38.5L83 43H69L74.25 38.5L76 40Z" fill="#F6BB42"/>
  <path d="M79 65H73V74H79V65Z" fill="#4A89DC"/>
  <path d="M79 50.5V51C79 57.63 73.63 63 67 63H65.07H65.06C63.59 60.04 60.531 58 57 58H67C70.87 58 74 54.87 74 51V50.5C74 49.12 75.12 48 76.5 48C77.88 48 79 49.12 79 50.5Z" fill="#CCD1D9"/>
  <path d="M52 56.0001C53.43 56.0001 54.8 55.6601 56 55.0601V58.0001L54 60.0001L52 59.0001L50 60.0001L48 58.0001V55.0601C49.2 55.6601 50.57 56.0001 52 56.0001Z" fill="#F5F7FA"/>
  <path d="M48 58L50 60L52 59L54 60L56 58H57C60.53 58 63.59 60.04 65.06 63C65.66 64.2 66 65.56 66 67V69C66 73.42 69.58 77 74 77H74.5C75.88 77 77 78.12 77 79.5C77 80.88 75.88 82 74.5 82H74C66.81 82 61 76.19 61 69V83H43V69C43 76.19 37.19 82 30 82H29.5C28.12 82 27 80.88 27 79.5C27 78.12 28.12 77 29.5 77H30C34.42 77 38 73.42 38 69V67C38 65.56 38.34 64.2 38.94 63C40.41 60.04 43.469 58 47 58H48Z" fill="#E6E9ED"/>
  <path d="M52.0304 66C51.4774 66 51.0254 65.553 51.0254 65C51.0254 64.447 51.4674 64 52.0194 64H52.0304C52.5824 64 53.0304 64.447 53.0304 65C53.0304 65.553 52.5824 66 52.0304 66Z" fill="#26355C"/>
  <path d="M52.0304 71C51.4774 71 51.0254 70.553 51.0254 70C51.0254 69.447 51.4674 69 52.0194 69H52.0304C52.5824 69 53.0304 69.447 53.0304 70C53.0304 70.553 52.5824 71 52.0304 71Z" fill="#26355C"/>
  <path d="M52.0304 76C51.4774 76 51.0254 75.553 51.0254 75C51.0254 74.447 51.4674 74 52.0194 74H52.0304C52.5824 74 53.0304 74.447 53.0304 75C53.0304 75.553 52.5824 76 52.0304 76Z" fill="#26355C"/>
  <path d="M52.0304 81C51.4774 81 51.0254 80.553 51.0254 80C51.0254 79.447 51.4674 79 52.0194 79H52.0304C52.5824 79 53.0304 79.447 53.0304 80C53.0304 80.553 52.5824 81 52.0304 81Z" fill="#26355C"/>
  <path d="M76.0304 73C75.4774 73 75.0254 72.553 75.0254 72C75.0254 71.447 75.4674 71 76.0194 71H76.0304C76.5824 71 77.0304 71.447 77.0304 72C77.0304 72.553 76.5824 73 76.0304 73Z" fill="#26355C"/>
  <path d="M52 57C46.486 57 42 52.514 42 47C42 46.447 42.447 46 43 46C43.553 46 44 46.447 44 47C44 51.411 47.589 55 52 55C56.411 55 60 51.411 60 47C60 46.447 60.447 46 61 46C61.553 46 62 46.447 62 47C62 52.514 57.514 57 52 57Z" fill="#26355C"/>
  <path d="M43 48C42.447 48 42 47.553 42 47V40C42 39.447 42.447 39 43 39C43.553 39 44 39.447 44 40V47C44 47.553 43.553 48 43 48Z" fill="#26355C"/>
  <path d="M61 48C60.447 48 60 47.553 60 47V39.999C60 39.446 60.447 38.999 61 38.999C61.553 38.999 62 39.446 62 39.999V47C62 47.553 61.553 48 61 48Z" fill="#26355C"/>
  <path d="M30 78C29.447 78 29 77.553 29 77C29 76.447 29.447 76 30 76C33.859 76 37 72.859 37 69C37 68.447 37.447 68 38 68C38.553 68 39 68.447 39 69C39 73.963 34.963 78 30 78Z" fill="#26355C"/>
  <path d="M38 68C37.447 68 37 67.553 37 67C37 61.486 41.486 57.001 47 57.001C47.553 57.001 48 57.448 48 58.001C48 58.554 47.553 59.001 47 59.001C42.589 59.001 39 62.589 39 67C39 67.553 38.553 68 38 68Z" fill="#26355C"/>
  <path d="M30 83C29.447 83 29 82.553 29 82C29 81.447 29.447 81 30 81C36.617 81 42 75.617 42 69C42 68.447 42.447 68 43 68C43.553 68 44 68.447 44 69C44 76.72 37.72 83 30 83Z" fill="#26355C"/>
  <path d="M29.5 83C27.57 83 26 81.43 26 79.5C26 77.57 27.57 76 29.5 76C30.053 76 30.5 76.447 30.5 77C30.5 77.553 30.053 78 29.5 78C28.673 78 28 78.673 28 79.5C28 80.327 28.673 81 29.5 81C30.053 81 30.5 81.447 30.5 82C30.5 82.553 30.053 83 29.5 83Z" fill="#26355C"/>
  <path d="M38 70C37.447 70 37 69.553 37 69V67C37 66.447 37.447 66 38 66C38.553 66 39 66.447 39 67V69C39 69.553 38.553 70 38 70Z" fill="#26355C"/>
  <path d="M43 84C42.447 84 42 83.553 42 83V69C42 68.447 42.447 68 43 68C43.553 68 44 68.447 44 69V83C44 83.553 43.553 84 43 84Z" fill="#26355C"/>
  <path d="M30 83H29.5C28.947 83 28.5 82.553 28.5 82C28.5 81.447 28.947 81 29.5 81H30C30.553 81 31 81.447 31 82C31 82.553 30.553 83 30 83Z" fill="#26355C"/>
  <path d="M30 78H29.5C28.947 78 28.5 77.553 28.5 77C28.5 76.447 28.947 76 29.5 76H30C30.553 76 31 76.447 31 77C31 77.553 30.553 78 30 78Z" fill="#26355C"/>
  <path d="M74 78C69.037 78 65 73.963 65 69C65 68.447 65.447 68 66 68C66.553 68 67 68.447 67 69C67 72.859 70.141 76 74 76C74.553 76 75 76.447 75 77C75 77.553 74.553 78 74 78Z" fill="#26355C"/>
  <path d="M66 68C65.447 68 65 67.553 65 67C65 62.589 61.411 59.001 57 59.001C56.447 59.001 56 58.554 56 58.001C56 57.448 56.447 57.001 57 57.001C62.514 57.001 67 61.486 67 67C67 67.553 66.553 68 66 68Z" fill="#26355C"/>
  <path d="M74 83C66.28 83 60 76.72 60 69C60 68.447 60.447 68 61 68C61.553 68 62 68.447 62 69C62 75.617 67.383 81 74 81C74.553 81 75 81.447 75 82C75 82.553 74.553 83 74 83Z" fill="#26355C"/>
  <path d="M74.5 83C73.947 83 73.5 82.553 73.5 82C73.5 81.447 73.947 81 74.5 81C75.327 81 76 80.327 76 79.5C76 78.673 75.327 78 74.5 78C73.947 78 73.5 77.553 73.5 77C73.5 76.447 73.947 76 74.5 76C76.43 76 78 77.57 78 79.5C78 81.43 76.43 83 74.5 83Z" fill="#26355C"/>
  <path d="M66 70C65.447 70 65 69.553 65 69V67C65 66.447 65.447 66 66 66C66.553 66 67 66.447 67 67V69C67 69.553 66.553 70 66 70Z" fill="#26355C"/>
  <path d="M61 84C60.447 84 60 83.553 60 83V69C60 68.447 60.447 68 61 68C61.553 68 62 68.447 62 69V83C62 83.553 61.553 84 61 84Z" fill="#26355C"/>
  <path d="M74.5 83H74C73.447 83 73 82.553 73 82C73 81.447 73.447 81 74 81H74.5C75.053 81 75.5 81.447 75.5 82C75.5 82.553 75.053 83 74.5 83Z" fill="#26355C"/>
  <path d="M74.5 78H74C73.447 78 73 77.553 73 77C73 76.447 73.447 76 74 76H74.5C75.053 76 75.5 76.447 75.5 77C75.5 77.553 75.053 78 74.5 78Z" fill="#26355C"/>
  <path d="M61 84C60.447 84 60 83.553 60 83V69C60 68.447 60.447 68 61 68C61.553 68 62 68.447 62 69V83C62 83.553 61.553 84 61 84Z" fill="#26355C"/>
  <path d="M54.0003 60.9998C53.7443 60.9998 53.4882 60.9018 53.2932 60.7068C52.9022 60.3158 52.9022 59.6838 53.2932 59.2928L55.2933 57.2928C55.6843 56.9018 56.3163 56.9018 56.7073 57.2928C57.0983 57.6838 57.0983 58.3158 56.7073 58.7068L54.7073 60.7068C54.5123 60.9018 54.2563 60.9998 54.0003 60.9998Z" fill="#26355C"/>
  <path d="M53.9988 60.9999C53.8488 60.9999 53.6958 60.9659 53.5528 60.8949L51.5528 59.8949C51.0588 59.6479 50.8588 59.0469 51.1058 58.5529C51.3538 58.0589 51.9548 57.8609 52.4478 58.1059L54.4478 59.1059C54.9418 59.3529 55.1418 59.9539 54.8948 60.4479C54.7188 60.7979 54.3658 60.9999 53.9988 60.9999Z" fill="#26355C"/>
  <path d="M50.0009 61C49.6339 61 49.2809 60.798 49.1049 60.447C48.8579 59.953 49.0579 59.352 49.5519 59.105L51.5519 58.105C52.0449 57.86 52.6459 58.058 52.8939 58.552C53.1409 59.046 52.9409 59.647 52.4469 59.894L50.4469 60.894C50.3039 60.966 50.1509 61 50.0009 61Z" fill="#26355C"/>
  <path d="M50.0003 60.9998C49.7443 60.9998 49.4883 60.9018 49.2933 60.7068L47.2932 58.7068C46.9022 58.3158 46.9022 57.6838 47.2932 57.2928C47.6842 56.9018 48.3163 56.9018 48.7073 57.2928L50.7073 59.2928C51.0983 59.6838 51.0983 60.3158 50.7073 60.7068C50.5123 60.9018 50.2563 60.9998 50.0003 60.9998Z" fill="#26355C"/>
  <path d="M48 59C47.447 59 47 58.553 47 58V55.063C47 54.51 47.447 54.063 48 54.063C48.553 54.063 49 54.51 49 55.063V58C49 58.553 48.553 59 48 59Z" fill="#26355C"/>
  <path d="M56 59C55.447 59 55 58.553 55 58V55.063C55 54.51 55.447 54.063 56 54.063C56.553 54.063 57 54.51 57 55.063V58C57 58.553 56.553 59 56 59Z" fill="#26355C"/>
  <path d="M67 59C66.447 59 66 58.553 66 58C66 57.447 66.447 57 67 57C70.309 57 73 54.309 73 51C73 50.447 73.447 50 74 50C74.553 50 75 50.447 75 51C75 55.411 71.411 59 67 59Z" fill="#26355C"/>
  <path d="M67 64C66.447 64 66 63.553 66 63C66 62.447 66.447 62 67 62C73.065 62 78 57.065 78 51C78 50.447 78.447 50 79 50C79.553 50 80 50.447 80 51C80 58.168 74.168 64 67 64Z" fill="#26355C"/>
  <path d="M79 51.5C78.447 51.5 78 51.053 78 50.5C78 49.673 77.327 49 76.5 49C75.673 49 75 49.673 75 50.5C75 51.053 74.553 51.5 74 51.5C73.447 51.5 73 51.053 73 50.5C73 48.57 74.57 47 76.5 47C78.43 47 80 48.57 80 50.5C80 51.053 79.553 51.5 79 51.5Z" fill="#26355C"/>
  <path d="M79 52C78.447 52 78 51.553 78 51V50.5C78 49.947 78.447 49.5 79 49.5C79.553 49.5 80 49.947 80 50.5V51C80 51.553 79.553 52 79 52Z" fill="#26355C"/>
  <path d="M74 52C73.447 52 73 51.553 73 51V50.5C73 49.947 73.447 49.5 74 49.5C74.553 49.5 75 49.947 75 50.5V51C75 51.553 74.553 52 74 52Z" fill="#26355C"/>
  <path d="M67 59.001H56C55.447 59.001 55 58.554 55 58.001C55 57.448 55.447 57.001 56 57.001H67C67.553 57.001 68 57.448 68 58.001C68 58.554 67.553 59.001 67 59.001Z" fill="#26355C"/>
  <path d="M67.0004 64H65.0654C64.5124 64 64.0654 63.553 64.0654 63C64.0654 62.447 64.5124 62 65.0654 62H67.0004C67.5534 62 68.0004 62.447 68.0004 63C68.0004 63.553 67.5534 64 67.0004 64Z" fill="#26355C"/>
  <path d="M37 59C32.589 59 29 55.411 29 51C29 50.447 29.447 50 30 50C30.553 50 31 50.447 31 51C31 54.309 33.691 57 37 57C37.553 57 38 57.447 38 58C38 58.553 37.553 59 37 59Z" fill="#26355C"/>
  <path d="M37 64C29.832 64 24 58.168 24 51C24 50.447 24.447 50 25 50C25.553 50 26 50.447 26 51C26 57.065 30.935 62 37 62C37.553 62 38 62.447 38 63C38 63.553 37.553 64 37 64Z" fill="#26355C"/>
  <path d="M30 51.5C29.447 51.5 29 51.053 29 50.5C29 49.673 28.327 49 27.5 49C26.673 49 26 49.673 26 50.5C26 51.053 25.553 51.5 25 51.5C24.447 51.5 24 51.053 24 50.5C24 48.57 25.57 47 27.5 47C29.43 47 31 48.57 31 50.5C31 51.053 30.553 51.5 30 51.5Z" fill="#26355C"/>
  <path d="M25 52C24.447 52 24 51.553 24 51V50.5C24 49.947 24.447 49.5 25 49.5C25.553 49.5 26 49.947 26 50.5V51C26 51.553 25.553 52 25 52Z" fill="#26355C"/>
  <path d="M30 52C29.447 52 29 51.553 29 51V50.5C29 49.947 29.447 49.5 30 49.5C30.553 49.5 31 49.947 31 50.5V51C31 51.553 30.553 52 30 52Z" fill="#26355C"/>
  <path d="M48 59.001H37C36.447 59.001 36 58.554 36 58.001C36 57.448 36.447 57.001 37 57.001H48C48.553 57.001 49 57.448 49 58.001C49 58.554 48.553 59.001 48 59.001Z" fill="#26355C"/>
  <path d="M38.935 64H37C36.447 64 36 63.553 36 63C36 62.447 36.447 62 37 62H38.935C39.488 62 39.935 62.447 39.935 63C39.935 63.553 39.487 64 38.935 64Z" fill="#26355C"/>
  <path d="M30 75H24C23.447 75 23 74.553 23 74V66C23 65.447 23.447 65 24 65H30C30.553 65 31 65.447 31 66V74C31 74.553 30.553 75 30 75ZM25 73H29V67H25V73Z" fill="#26355C"/>
  <path d="M79 75H73C72.447 75 72 74.553 72 74V65C72 64.447 72.447 64 73 64H79C79.553 64 80 64.447 80 65V74C80 74.553 79.553 75 79 75ZM74 73H78V66H74V73Z" fill="#26355C"/>
  <path d="M33 73H30C29.447 73 29 72.553 29 72V68.001C29 67.448 29.447 67.001 30 67.001H33C33.553 67.001 34 67.448 34 68.001V72C34 72.553 33.553 73 33 73ZM31 71H32V69.001H31V71Z" fill="#26355C"/>
  <path d="M33 46H21C20.447 46 20 45.553 20 45V31C20 30.447 20.447 30 21 30H33C33.553 30 34 30.447 34 31V45C34 45.553 33.553 46 33 46ZM22 44H32V32H22V44Z" fill="#26355C"/>
  <path d="M25.0006 35.0001C24.6776 35.0001 24.3596 34.8441 24.1676 34.5551L22.1676 31.5561C21.8606 31.0971 21.9846 30.4761 22.4446 30.1691C22.9026 29.8621 23.5256 29.9861 23.8316 30.4461L25.8316 33.4451C26.1386 33.9041 26.0146 34.5251 25.5546 34.8321C25.3846 34.9451 25.1906 35.0001 25.0006 35.0001Z" fill="#26355C"/>
  <path d="M29 35H25C24.447 35 24 34.553 24 34C24 33.447 24.447 33 25 33H29C29.553 33 30 33.447 30 34C30 34.553 29.553 35 29 35Z" fill="#26355C"/>
  <path d="M28.9986 34.9998C28.8086 34.9998 28.6156 34.9448 28.4446 34.8318C27.9846 34.5248 27.8606 33.9048 28.1676 33.4448L30.1676 30.4448C30.4736 29.9858 31.0926 29.8608 31.5546 30.1678C32.0146 30.4748 32.1386 31.0948 31.8316 31.5548L29.8316 34.5548C29.6396 34.8438 29.3216 34.9998 28.9986 34.9998Z" fill="#26355C"/>
  <path d="M29 39H25C24.447 39 24 38.553 24 38C24 37.447 24.447 37 25 37H29C29.553 37 30 37.447 30 38C30 38.553 29.553 39 29 39Z" fill="#26355C"/>
  <path d="M29 42H25C24.447 42 24 41.553 24 41C24 40.447 24.447 40 25 40H29C29.553 40 30 40.447 30 41C30 41.553 29.553 42 29 42Z" fill="#26355C"/>
  <path d="M83 44H69C68.447 44 68 43.553 68 43V34C68 33.447 68.447 33 69 33H83C83.553 33 84 33.447 84 34V43C84 43.553 83.553 44 83 44ZM70 42H82V35H70V42Z" fill="#26355C"/>
  <path d="M75.9998 40.9994C75.7698 40.9994 75.5378 40.9204 75.3498 40.7584L68.3498 34.7584C67.9298 34.3994 67.8808 33.7684 68.2418 33.3494C68.6008 32.9294 69.2328 32.8814 69.6508 33.2414L76.6508 39.2414C77.0708 39.6004 77.1198 40.2314 76.7588 40.6504C76.5618 40.8814 76.2808 40.9994 75.9998 40.9994Z" fill="#26355C"/>
  <path d="M75.9994 40.9996C75.7184 40.9996 75.4374 40.8816 75.2404 40.6496C74.8804 40.2306 74.9284 39.5996 75.3484 39.2406L82.3484 33.2406C82.7694 32.8816 83.3994 32.9296 83.7584 33.3496C84.1184 33.7686 84.0704 34.3996 83.6504 34.7586L76.6504 40.7586C76.4614 40.9206 76.2294 40.9996 75.9994 40.9996Z" fill="#26355C"/>
  <path d="M83.0007 44.0002C82.7707 44.0002 82.5387 43.9212 82.3507 43.7592L77.1047 39.2622C76.6847 38.9032 76.6357 38.2722 76.9967 37.8532C77.3557 37.4322 77.9877 37.3852 78.4057 37.7452L83.6517 42.2422C84.0717 42.6012 84.1207 43.2322 83.7597 43.6512C83.5627 43.8822 83.2817 44.0002 83.0007 44.0002Z" fill="#26355C"/>
  <path d="M69.0004 43.9999C68.7194 43.9999 68.4384 43.8819 68.2414 43.6499C67.8814 43.2309 67.9294 42.5999 68.3494 42.2409L73.5994 37.7409C74.0184 37.3829 74.6504 37.4279 75.0084 37.8489C75.3684 38.2679 75.3204 38.8989 74.9004 39.2579L69.6504 43.7579C69.4624 43.9209 69.2304 43.9999 69.0004 43.9999Z" fill="#26355C"/>
  <path d="M61 40.999C60.447 40.999 60 40.552 60 39.999C60 38.346 58.654 37 57 37C56.447 37 56 36.553 56 36C56 35.447 56.447 35 57 35C59.757 35 62 37.242 62 39.999C62 40.552 61.553 40.999 61 40.999Z" fill="#26355C"/>
  <path d="M57 37C56.447 37 56 36.553 56 36V32C56 31.447 56.447 31 57 31C57.553 31 58 31.447 58 32V36C58 36.553 57.553 37 57 37Z" fill="#26355C"/>
  <path d="M43 41C42.447 41 42 40.553 42 40C42 35.037 46.037 31 51 31C51.553 31 52 31.447 52 32C52 32.553 51.553 33 51 33C47.141 33 44 36.141 44 40C44 40.553 43.553 41 43 41Z" fill="#26355C"/>
  <path d="M60.9987 44.025C60.8067 44.025 60.6117 43.969 60.4397 43.854L57.4397 41.829C56.9817 41.519 56.8617 40.898 57.1697 40.44C57.4797 39.983 58.0997 39.863 58.5587 40.17L61.5587 42.195C62.0167 42.505 62.1367 43.126 61.8287 43.584C61.6357 43.871 61.3197 44.025 60.9987 44.025Z" fill="#26355C"/>
  <path d="M52.4997 43C48.8877 43 45.8127 41.992 45.6847 41.949C45.1617 41.774 44.8777 41.209 45.0507 40.685C45.2247 40.162 45.7907 39.876 46.3147 40.05C46.3437 40.061 49.2227 41 52.4997 41C55.6777 41 57.5397 40.112 57.5587 40.103C58.0517 39.863 58.6527 40.066 58.8957 40.56C59.1387 41.054 58.9387 41.65 58.4467 41.896C58.3547 41.94 56.1857 43 52.4997 43Z" fill="#26355C"/>
  <path d="M43.0011 44.0252C42.6801 44.0252 42.3641 43.8712 42.1711 43.5852C41.8621 43.1272 41.9831 42.5062 42.4411 42.1962L45.4411 40.1712C45.9011 39.8632 46.5211 39.9832 46.8301 40.4412C47.1391 40.8992 47.0181 41.5202 46.5601 41.8302L43.5601 43.8552C43.3881 43.9702 43.1931 44.0252 43.0011 44.0252Z" fill="#26355C"/>
  <path d="M57 33H51C50.447 33 50 32.553 50 32C50 31.447 50.447 31 51 31H57C57.553 31 58 31.447 58 32C58 32.553 57.553 33 57 33Z" fill="#26355C"/>
  </g>
  <defs>
  <clipPath id="clip0">
  <rect width="64" height="64" fill="white" transform="translate(20 25)"/>
  </clipPath>
  </defs>
  </svg>
</template>

<script>
export default {
  name: "ExecutivePastorIcon"
}
</script>

<style scoped>

</style>
