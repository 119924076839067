import api from '@/api'

const home = {
  namespaced: true,

  state: {
    items: [],
    current: {},
    dashlyToken: '',
  },

  actions: {
    fetch({ commit }, status) {
      const params = new URLSearchParams();
      if (status) {
        params.append('status', status);
      }
      let data = { params };
      return api.get('/home', data)
        .then(({ data }) => {
          commit('setDashlyToken', data.data.dashly_token);
          commit('setItems', data.data.jobs)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
  },

  mutations: {
    setItems(state, items) {
      state.items = items;
    },

    setCurrent(state, data) {
      state.current = data
    },

    setDashlyToken(state, token) {
      state.dashlyToken = token
    },

    removeItem(state, id) {
      const index = state.items.findIndex(item => item.id == id);
      state.items.splice(index, 1);
    },

    updateItem(state, payload) {
      const item = state.items.find(item => item.id === payload.id);
      Object.assign(item, payload.data);
    },
  },

}

export default home
