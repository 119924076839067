<template>
<svg width="113" height="113" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="113" height="113" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M55.9524 19.131C64.4316 20.1156 73.6826 17.6497 80.7074 22.4891C87.7923 27.37 89.5567 36.5845 92.8242 44.5324C96.4071 53.2475 104.09 62.206 100.64 70.9743C97.1774 79.7747 84.6592 79.8327 76.8354 85.166C69.3599 90.2618 65.0015 101.16 55.9524 101.437C46.9055 101.714 40.5189 92.8585 34.1161 86.4751C28.66 81.0354 25.4076 74.3857 21.9294 67.5167C17.7013 59.1664 10.8075 51.4434 11.6601 42.1266C12.5915 31.9485 17.538 20.9266 26.6246 16.2091C35.5585 11.5708 45.9489 17.9694 55.9524 19.131Z" fill="#EAECFB"/>
<path d="M59.999 44L62.969 47.71L61.999 49H55.999L59.999 44Z" fill="#434A54"/>
<path d="M62.969 47.71L67.999 41L73.999 49H61.999L62.969 47.71Z" fill="#656D78"/>
<path d="M80.999 54H44.999V57H80.999V54Z" fill="#AAB2BD"/>
<path d="M32.999 42H27.019H26.999V40C26.999 38.89 27.898 38 28.999 38H29.019H32.999H33.009V42H32.999Z" fill="#AAB2BD"/>
<path d="M75.0186 83.08V88H27.0186V42H32.9986H33.0086V82H75.0186V83.08Z" fill="#CCD1D9"/>
<path d="M38.9893 36H33.0093H32.9893V34C32.9893 32.89 33.8893 32 34.9893 32H35.0103H38.9893H39.0293V36H38.9893Z" fill="#CCD1D9"/>
<path d="M81.0088 77.08V82H75.0188H33.0088V42V38V36H38.9888H39.0288V76H81.0088V77.08Z" fill="#E6E9ED"/>
<path d="M80.999 36V49H73.999L67.999 41L62.969 47.71L59.999 44L55.999 49H44.999V36H80.999Z" fill="#A0D468"/>
<path d="M87.0088 28V30H39.0288H39.0088V28C39.0088 26.89 39.9078 26 41.0088 26H85.0088C86.1178 26 87.0088 26.89 87.0088 28Z" fill="#E6E9ED"/>
<path d="M87.0293 30V76H81.0093H39.0293V36V32V30H87.0093H87.0293ZM80.9993 57V54H44.9993V57H80.9993ZM80.9993 49V36H44.9993V49H55.9993H61.9993H73.9993H80.9993Z" fill="#F5F7FA"/>
<path d="M42.0402 29C41.4872 29 41.0352 28.552 41.0352 28C41.0352 27.448 41.4772 27 42.0292 27H42.0402C42.5922 27 43.0402 27.448 43.0402 28C43.0402 28.552 42.5922 29 42.0402 29Z" fill="#26355C"/>
<path d="M45.0402 29C44.4872 29 44.0352 28.552 44.0352 28C44.0352 27.448 44.4772 27 45.0292 27H45.0402C45.5922 27 46.0402 27.448 46.0402 28C46.0402 28.552 45.5922 29 45.0402 29Z" fill="#26355C"/>
<path d="M48.0402 29C47.4872 29 47.0352 28.552 47.0352 28C47.0352 27.448 47.4772 27 48.0292 27H48.0402C48.5922 27 49.0402 27.448 49.0402 28C49.0402 28.552 48.5922 29 48.0402 29Z" fill="#26355C"/>
<path d="M87.0273 77H39.0273C38.4743 77 38.0273 76.553 38.0273 76V30C38.0273 29.448 38.4743 29 39.0273 29H87.0273C87.5803 29 88.0273 29.448 88.0273 30V76C88.0273 76.553 87.5793 77 87.0273 77ZM40.0273 75H86.0273V31H40.0273V75Z" fill="#26355C"/>
<path d="M39.0098 29C38.4568 29 38.0098 28.552 38.0098 28C38.0098 26.346 39.3558 25 41.0098 25C41.5628 25 42.0098 25.448 42.0098 26C42.0098 26.552 41.5628 27 41.0098 27C40.4578 27 40.0098 27.449 40.0098 28C40.0098 28.552 39.5628 29 39.0098 29Z" fill="#26355C"/>
<path d="M39.0098 31C38.4568 31 38.0098 30.552 38.0098 30V28C38.0098 27.448 38.4568 27 39.0098 27C39.5628 27 40.0098 27.448 40.0098 28V30C40.0098 30.552 39.5628 31 39.0098 31Z" fill="#26355C"/>
<path d="M87.0098 29C86.4568 29 86.0098 28.552 86.0098 28C86.0098 27.449 85.5618 27 85.0098 27C84.4568 27 84.0098 26.552 84.0098 26C84.0098 25.448 84.4568 25 85.0098 25C86.6638 25 88.0098 26.346 88.0098 28C88.0098 28.552 87.5628 29 87.0098 29Z" fill="#26355C"/>
<path d="M87.0098 31C86.4568 31 86.0098 30.552 86.0098 30V28C86.0098 27.448 86.4568 27 87.0098 27C87.5628 27 88.0098 27.448 88.0098 28V30C88.0098 30.552 87.5628 31 87.0098 31Z" fill="#26355C"/>
<path d="M85.0098 27H41.0098C40.4568 27 40.0098 26.552 40.0098 26C40.0098 25.448 40.4568 25 41.0098 25H85.0098C85.5628 25 86.0098 25.448 86.0098 26C86.0098 26.552 85.5628 27 85.0098 27Z" fill="#26355C"/>
<path d="M36.0236 35C35.4706 35 35.0186 34.552 35.0186 34C35.0186 33.448 35.4606 33 36.0126 33H36.0236C36.5756 33 37.0236 33.448 37.0236 34C37.0236 34.552 36.5756 35 36.0236 35Z" fill="#26355C"/>
<path d="M81.0098 83H33.0098C32.4568 83 32.0098 82.553 32.0098 82V36C32.0098 35.448 32.4568 35 33.0098 35C33.5628 35 34.0098 35.448 34.0098 36V81H80.0098V77.083C80.0098 76.53 80.4568 76.083 81.0098 76.083C81.5628 76.083 82.0098 76.53 82.0098 77.083V82C82.0098 82.553 81.5628 83 81.0098 83Z" fill="#26355C"/>
<path d="M32.9932 35C32.4402 35 31.9932 34.552 31.9932 34C31.9932 32.346 33.3392 31 34.9932 31C35.5462 31 35.9932 31.448 35.9932 32C35.9932 32.552 35.5462 33 34.9932 33C34.4412 33 33.9932 33.449 33.9932 34C33.9932 34.552 33.5452 35 32.9932 35Z" fill="#26355C"/>
<path d="M32.9932 37C32.4402 37 31.9932 36.552 31.9932 36V34C31.9932 33.448 32.4402 33 32.9932 33C33.5462 33 33.9932 33.448 33.9932 34V36C33.9932 36.552 33.5452 37 32.9932 37Z" fill="#26355C"/>
<path d="M38.9928 37H33.0098C32.4568 37 32.0098 36.552 32.0098 36C32.0098 35.448 32.4568 35 33.0098 35H38.9918C39.5448 35 39.9918 35.448 39.9918 36C39.9918 36.552 39.5448 37 38.9928 37Z" fill="#26355C"/>
<path d="M38.9928 33H35.0098C34.4568 33 34.0098 32.552 34.0098 32C34.0098 31.448 34.4568 31 35.0098 31H38.9918C39.5448 31 39.9918 31.448 39.9918 32C39.9918 32.552 39.5448 33 38.9928 33Z" fill="#26355C"/>
<path d="M30.0314 41C29.4784 41 29.0264 40.552 29.0264 40C29.0264 39.448 29.4684 39 30.0204 39H30.0314C30.5834 39 31.0314 39.448 31.0314 40C31.0314 40.552 30.5824 41 30.0314 41Z" fill="#26355C"/>
<path d="M75.0176 89H27.0176C26.4646 89 26.0176 88.553 26.0176 88V42C26.0176 41.448 26.4646 41 27.0176 41C27.5706 41 28.0176 41.448 28.0176 42V87H74.0176V83.083C74.0176 82.53 74.4646 82.083 75.0176 82.083C75.5706 82.083 76.0176 82.53 76.0176 83.083V88C76.0176 88.553 75.5706 89 75.0176 89Z" fill="#26355C"/>
<path d="M27 41C26.447 41 26 40.552 26 40C26 38.346 27.346 37 29 37C29.553 37 30 37.448 30 38C30 38.552 29.553 39 29 39C28.448 39 28 39.449 28 40C28 40.552 27.553 41 27 41Z" fill="#26355C"/>
<path d="M27 43C26.447 43 26 42.552 26 42V40C26 39.448 26.447 39 27 39C27.553 39 28 39.448 28 40V42C28 42.552 27.553 43 27 43Z" fill="#26355C"/>
<path d="M33.0016 43H27.0176C26.4646 43 26.0176 42.552 26.0176 42C26.0176 41.448 26.4646 41 27.0176 41H33.0016C33.5546 41 34.0016 41.448 34.0016 42C34.0016 42.552 33.5546 43 33.0016 43Z" fill="#26355C"/>
<path d="M33.0016 39H29.0176C28.4646 39 28.0176 38.552 28.0176 38C28.0176 37.448 28.4646 37 29.0176 37H33.0016C33.5546 37 34.0016 37.448 34.0016 38C34.0016 38.552 33.5546 39 33.0016 39Z" fill="#26355C"/>
<path d="M81 50H45C44.447 50 44 49.552 44 49V36C44 35.448 44.447 35 45 35H81C81.553 35 82 35.448 82 36V49C82 49.552 81.553 50 81 50ZM46 48H80V37H46V48Z" fill="#26355C"/>
<path d="M81 58H45C44.447 58 44 57.552 44 57V54C44 53.448 44.447 53 45 53H81C81.553 53 82 53.448 82 54V57C82 57.552 81.553 58 81 58ZM46 56H80V55H46V56Z" fill="#26355C"/>
<path d="M64 63H45C44.447 63 44 62.553 44 62C44 61.447 44.447 61 45 61H64C64.553 61 65 61.447 65 62C65 62.553 64.553 63 64 63Z" fill="#26355C"/>
<path d="M54 67H45C44.447 67 44 66.553 44 66C44 65.447 44.447 65 45 65H54C54.553 65 55 65.447 55 66C55 66.553 54.553 67 54 67Z" fill="#26355C"/>
<path d="M81 67H58C57.447 67 57 66.553 57 66C57 65.447 57.447 65 58 65H81C81.553 65 82 65.447 82 66C82 66.553 81.553 67 81 67Z" fill="#26355C"/>
<path d="M71 71H45C44.447 71 44 70.553 44 70C44 69.447 44.447 69 45 69H71C71.553 69 72 69.447 72 70C72 70.553 71.553 71 71 71Z" fill="#26355C"/>
<path d="M81 71H75C74.447 71 74 70.553 74 70C74 69.447 74.447 69 75 69H81C81.553 69 82 69.447 82 70C82 70.553 81.553 71 81 71Z" fill="#26355C"/>
<path d="M81 63H68C67.447 63 67 62.553 67 62C67 61.447 67.447 61 68 61H81C81.553 61 82 61.447 82 62C82 62.553 81.553 63 81 63Z" fill="#26355C"/>
<path d="M61.9994 49.9997C61.7904 49.9997 61.5804 49.9347 61.4004 49.7997C60.9584 49.4687 60.8684 48.8417 61.2004 48.3997L67.2004 40.3997C67.5324 39.9587 68.1584 39.8687 68.5994 40.1997C69.0414 40.5307 69.1314 41.1577 68.7994 41.5997L62.7994 49.5997C62.6044 49.8617 62.3044 49.9997 61.9994 49.9997Z" fill="#26355C"/>
<path d="M74.0014 49.9998C73.6964 49.9998 73.3974 49.8618 73.2004 49.5998L67.2004 41.5998C66.8684 41.1578 66.9584 40.5308 67.4004 40.1998C67.8414 39.8668 68.4674 39.9578 68.7994 40.3998L74.7994 48.3998C75.1314 48.8418 75.0414 49.4688 74.5994 49.7998C74.4204 49.9348 74.2104 49.9998 74.0014 49.9998Z" fill="#26355C"/>
<path d="M55.9992 49.9997C55.7802 49.9997 55.5602 49.9277 55.3751 49.7807C54.9442 49.4357 54.8741 48.8067 55.2191 48.3757L59.2192 43.3757C59.5662 42.9457 60.1932 42.8737 60.6252 43.2197C61.0562 43.5647 61.1261 44.1937 60.7811 44.6247L56.7812 49.6247C56.5832 49.8717 56.2932 49.9997 55.9992 49.9997Z" fill="#26355C"/>
<path d="M62.9678 48.7091C62.6738 48.7091 62.3838 48.5811 62.1858 48.3341L59.2188 44.6251C58.8738 44.1941 58.9448 43.5641 59.3748 43.2201C59.8078 42.8741 60.4358 42.9451 60.7808 43.3761L63.7478 47.0851C64.0928 47.5161 64.0218 48.1461 63.5918 48.4901C63.4078 48.6371 63.1868 48.7091 62.9678 48.7091Z" fill="#26355C"/>
<path d="M53.0314 41C52.4784 41 52.0264 40.552 52.0264 40C52.0264 39.448 52.4684 39 53.0204 39H53.0314C53.5834 39 54.0314 39.448 54.0314 40C54.0314 40.552 53.5824 41 53.0314 41Z" fill="#26355C"/>
</svg>

</template>

<script>
export default {
  name: "WebsiteDeveloperIcon"
}
</script>

<style scoped>

</style>
