<template>
  <div class="flex items-center justify-between cursor-pointer mb-3">
    <div class="flex items-center">
      <Checkbox
        fontWeight="font-normal"
        fontSize="text-sm"
        hover="hover:text-auxiliary-blue"
        borderWidth="border"
        :value="id"
        :title="name"
        name="category"
        :checked="isCategoryChecked"
        @on-toggle="toggle"
      />
    </div>
    <span v-if="!envIsProduction" class="text-sm bg-pink-500">{{ amount }}</span>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import Checkbox from '@/components/shared/Checkbox';

export default {
  name: 'Category',
  components: {Checkbox},
  props: {
    id: Number,
    name: String,
    amount: String
  },

  methods: {
    ...mapMutations({
      toggleCategory: 'filters/toggleCategory'
    }),

    toggle(){
      this.toggleCategory(this.id);
      this.$emit('onToggle');
    },
  },

  computed: {
    ...mapState({
      categories: state => state.filters.categories,
    }),

    isCategoryChecked() {
      return this.categories.split(',').findIndex(item => item == this.id) >= 0
    },

    envIsProduction() {
      return process.env.NODE_ENV == 'production';
    }
  },
}
</script>
