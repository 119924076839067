<template>
  <div class="w-full bg-primary text-white border-t border-auxiliary-blue overflow-hidden">
    <div class="container mx-auto px-4 lg:px-20 xl:px-72 py-8 lg:py-10">
      <div class="flex items-center">
        <Badge class="uppercase mr-4" background="bg-auxiliary-blueLight" :text="`Step 1`" styles="body-xs-400"/>
        <h4 class="text-white body-l-700">{{ stepText }}</h4>
      </div>
    </div>
  </div>
</template>
<script>
import Badge from "@/components/shared/Badge";
import { mapState } from "vuex";

export default {
  name: 'SubHeader',
  components: { Badge },
  computed: {
    ...mapState({
      job: state => state.jobs.current,
    }),

    stepText() {
      return `Bid on job for ${this.job.church.name}`;
    }
  }
}
</script>
