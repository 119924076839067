<template>
  <div class="relative inline-block flex-shrink-0">
    <span v-if="isOnline" :class="[statusClasses, right, top]"
          class="inline-block rounded-full absolute  border-white bg-green-400"></span>
    <img class="flex-shrink-0 object-cover" :class="[width, height, borderRadius, borderColor, borderWidth]" :src="image" alt="">
  </div>
</template>
<script>
export default {
  name: 'AvatarImage',
  props: {
    image: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: 'w-20'
    },
    right: {
      type: String,
      default: 'right-0'
    },
    top: {
      type: String,
      default: 'top-0'
    },
    height: {
      type: String,
      default: 'h-20'
    },
    borderRadius: {
      type: String,
      default: 'rounded-full'
    },
    borderColor: {
      type: String,
      default: ''
    },
    borderWidth: {
      type: String,
      default: ''
    },
    isOnline: Boolean,
    statusClasses: {
      type: String,
      default: 'w-4 h-4 border-2 mt-1'
    }
  },
}
</script>
