<template>
  <div v-if="job" class="w-full bg-primary text-white border-t border-auxiliary-blue overflow-hidden">
    <div class="container mx-auto px-6 lg:px-20 xl:px-32 py-5">
      <div class="flex justify-between items-center flex-wrap">
        <div class="flex mb-5 sm:mb-0">
          <AvatarImage v-if="userIsPro" :image="job.church.logo_url" width="w-16" height="h-16" class="mr-6" />
          <div v-else-if="bids.length" class="grid w-16 gap-y-2 justify-items-center mr-6 items-center" :class="bids.length == 1 ? 'grid-cols-1' : 'grid-cols-2'">
            <AvatarImage v-for="bid in bids.slice(0,3)" :key="bid.id" :image="bid.pro.image_url" :width="bids.length == 1 ? 'w-10' : 'w-6'" :height="bids.length == 1 ? 'h-10' : 'h-6'"/>
            <div v-if="rest > 0" class="w-6 h-6 rounded-full bg-auxiliary-blueLight opacity-80 text-xs flex items-center justify-center">+{{rest}}</div>
          </div>
          <div>
            <h4 class="text-white body-l-700">{{job.title}}</h4>
            <span class="text-skyBlue body-s-400">{{userIsPro ? job.church.name : applicantCount}}</span>
          </div>
        </div>
        <div v-if="!userIsPro" class="flex items-center">
          <RemoveJobAlertDialog :job="job">
            <template #trigger>
              <InfoIcon class="w-5 h-5 mr-3 body-xxs-400"/>Remove job
            </template>
          </RemoveJobAlertDialog>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import pluralize from 'pluralize';
import AvatarImage from "@/components/shared/AvatarImage"
import InfoIcon from "@/components/shared/svg/InfoIcon"
import RemoveJobAlertDialog from '@/components/shared/modals/RemoveJobAlertDialog';

export default {
  name: 'SubHeader',
  components: {AvatarImage, InfoIcon, RemoveJobAlertDialog },

  computed: {
    ...mapState({
      bids: state => state.bids.items,
      job: state => state.jobs.current,
      user: state => state.auth.user,
    }),

    applicantCount() {
      return `${this.bids.length} ${pluralize('Applicant', this.bids.length)}`
    },

    rest() {
      return this.bids.length - 3;
    },

    userIsPro() {
      return this.user.userable_type == 'pro';
    },
  },
  
}
</script>
