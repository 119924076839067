<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 3.16683H12"
      stroke="#26355C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 13.8333H12"
      stroke="#26355C"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.33334 3.16699V5.83366C5.33334 6.25366 5.53067 6.64833 5.86667 6.90033L8 8.50033L10.1333 6.90033C10.4693 6.64833 10.6667 6.25366 10.6667 5.83366V3.16699"
      stroke="#26355C"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6667 13.8333V11.1667C10.6667 10.7467 10.4693 10.352 10.1333 10.1L8 8.5L5.86667 10.1C5.53067 10.352 5.33334 10.7467 5.33334 11.1667V13.8333"
      stroke="#26355C"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "HourglassIcon",
};
</script>
