<template>
  <div class="bg-primary-10 border border-primary-40 rounded-xl py-8 px-10 body-s-400 flex items-center">
    <GuaranteeIcon class="mr-7" />
    <p>
      When you hire a Pro we guarantee your job will get done the way you want it or we will make it right.
      <!-- <a href="http://" class="underline ml-1">Learn More</a> -->
    </p>
  </div>
</template>
<script>

import GuaranteeIcon from '@/components/shared/svg/GuaranteeIcon';

export default {
  name: 'GuaranteeMessage',
  components: { GuaranteeIcon },
}
</script>
