<template>
  <div>
    <!--Filters header-->
    <div @click="toggle" :class="[border]" class="w-full flex justify-between items-center pb-2.5 cursor-pointer">
      <span class="label-s-500">{{ label }}</span>
      <ChevronLeftIcon v-if="isOpen" class="transform -rotate-90"/>
      <ChevronLeftIcon v-else class="transform rotate-90"/>
    </div>
    <!--Filters content-->
    <transition @enter="enter">
      <div v-if="isOpen" class="pt-5">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import ChevronLeftIcon from "@/components/shared/svg/ChevronLeftIcon";
import anime from "animejs";

export default {
  name: "Accordion",
  components: {ChevronLeftIcon},
  props: {
    label: String,
    border: {
      type: String,
      default: 'border-b'
    }
  },
  data() {
    return {
      isOpen: true
    }
  },
  methods: {
    enter(el) {
      anime({
        targets: el,
        translateY: [-10, 0],
        duration: 800
      })
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
  }

}
</script>

<style scoped>

</style>
