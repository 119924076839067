import { extend } from 'vee-validate';
import { required, email, confirmed } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: '{_field_} is required'
});
extend('email', email);
extend('confirmed', {
  ...confirmed,
  message: 'Password and Password confirmation fields do not match'
});
extend('min', {
  validate(value, { length }) {
    return value.length >= length;
  },
  params: ['length'],
  message: '{_field_} field must have at least {length} characters'
});
extend('max', {
  validate(value, { length }) {
    return value.length <= length;
  },
  params: ['length'],
  message: '{_field_} field must have no more than {length} characters'
});

