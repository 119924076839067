import API from './index';

const login = (data) => {
  return API.post(`/login`, data);
};

const oauthLogin = (provider, params, device_name) => {
  return API.post(`/${provider}/callback`, { device_name }, {
    params
  });
};

const confirmPassword = (password) => {
  return API.post(`/confirm-password`, { password });
};

const logout = () => {
  return API.post(`/logout`);
};

const register = (data) => {
  return API.post(`/register`, data);
};

const checkEmail = (data) => {
  return API.post(`/check-email`, data);
};

const setCSRFCookie = () => {
  return API.get(`/sanctum/csrf-cookie`);
};

const getUser = () => {
  return API.get(`/users/me`)
};

const requestResetPassword = (data) => {
  return API.post('/password/email', data)
};

const resetPassword = (data) => {
  return API.post('/password/reset', data)
};

const impersonate = (data) => {
  return API.post(`/validate-token`, data);
};

// Only for pros
const loginFromInvitation = (data) => {
  return API.post(`/bid-invitation`, data);
};

// Only for churches
const loginFromBid = (data) => {
  return API.post(`/bid-review`, data);
};

export {
  login,
  oauthLogin,
  confirmPassword,
  logout,
  setCSRFCookie,
  getUser,
  register,
  checkEmail,
  requestResetPassword,
  resetPassword,
  impersonate,
  loginFromInvitation,
  loginFromBid,
};
