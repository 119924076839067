<template>
  <svg width="113" height="113" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="113" height="113" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M55.9524 19.131C64.4316 20.1156 73.6826 17.6497 80.7074 22.4891C87.7923 27.37 89.5567 36.5845 92.8242 44.5324C96.4071 53.2475 104.09 62.206 100.64 70.9743C97.1774 79.7747 84.6592 79.8327 76.8354 85.166C69.3599 90.2618 65.0015 101.16 55.9524 101.437C46.9055 101.714 40.5189 92.8585 34.1161 86.4751C28.66 81.0354 25.4076 74.3857 21.9294 67.5167C17.7013 59.1664 10.8075 51.4434 11.6601 42.1266C12.5915 31.9485 17.538 20.9266 26.6246 16.2091C35.5585 11.5708 45.9489 17.9694 55.9524 19.131Z" fill="#EAECFB"/>
  <g clip-path="url(#clip0)">
  <path d="M77.9998 32H26.0098V78H77.9998V32Z" fill="#E6E9ED"/>
  <path d="M78 32H74V70H78V32Z" fill="#CCD1D9"/>
  <path d="M78 30V32H26.01H26V30C26 28.89 26.89 28 28 28H76C77.1 28 78 28.89 78 30Z" fill="#CCD1D9"/>
  <path d="M76 28H72C73.1 28 74 28.89 74 30V32H78V30C78 28.89 77.1 28 76 28Z" fill="#AAB2BD"/>
  <path d="M74 36H70V40H74V36Z" fill="#48CFAD"/>
  <path d="M70 36H30V40H70V36Z" fill="white"/>
  <path d="M74 44V57H54V44H74ZM63 50.5C63 49.12 61.88 48 60.5 48C59.12 48 58 49.12 58 50.5C58 51.88 59.12 53 60.5 53C61.88 53 63 51.88 63 50.5Z" fill="white"/>
  <path d="M60.5 53C61.8807 53 63 51.8807 63 50.5C63 49.1193 61.8807 48 60.5 48C59.1193 48 58 49.1193 58 50.5C58 51.8807 59.1193 53 60.5 53Z" fill="#48CFAD"/>
  <path d="M50 44V57H30V44H50ZM39 50.5C39 49.12 37.88 48 36.5 48C35.12 48 34 49.12 34 50.5C34 51.88 35.12 53 36.5 53C37.88 53 39 51.88 39 50.5Z" fill="white"/>
  <path d="M36.5 53C37.8807 53 39 51.8807 39 50.5C39 49.1193 37.8807 48 36.5 48C35.1193 48 34 49.1193 34 50.5C34 51.8807 35.1193 53 36.5 53Z" fill="#48CFAD"/>
  <path d="M36.5 70C37.8807 70 39 68.8807 39 67.5C39 66.1193 37.8807 65 36.5 65C35.1193 65 34 66.1193 34 67.5C34 68.8807 35.1193 70 36.5 70Z" fill="#48CFAD"/>
  <path d="M50 61V74H30V61H50ZM39 67.5C39 66.12 37.88 65 36.5 65C35.12 65 34 66.12 34 67.5C34 68.88 35.12 70 36.5 70C37.88 70 39 68.88 39 67.5Z" fill="white"/>
  <path d="M29.0265 31C28.4745 31 28.0215 30.552 28.0215 30C28.0215 29.448 28.4635 29 29.0155 29H29.0265C29.5785 29 30.0265 29.448 30.0265 30C30.0265 30.552 29.5785 31 29.0265 31Z" fill="#26355C"/>
  <path d="M32.0265 31C31.4745 31 31.0215 30.552 31.0215 30C31.0215 29.448 31.4635 29 32.0155 29H32.0265C32.5785 29 33.0265 29.448 33.0265 30C33.0265 30.552 32.5785 31 32.0265 31Z" fill="#26355C"/>
  <path d="M35.0265 31C34.4745 31 34.0215 30.552 34.0215 30C34.0215 29.448 34.4635 29 35.0155 29H35.0265C35.5785 29 36.0265 29.448 36.0265 30C36.0265 30.552 35.5785 31 35.0265 31Z" fill="#26355C"/>
  <path d="M68.0037 79H26.0137C25.4607 79 25.0137 78.553 25.0137 78V32C25.0137 31.448 25.4607 31 26.0137 31H78.0137C78.5667 31 79.0137 31.448 79.0137 32V68C79.0137 68.553 78.5667 69 78.0137 69C77.4607 69 77.0137 68.553 77.0137 68V33H27.0137V77H68.0037C68.5567 77 69.0037 77.447 69.0037 78C69.0037 78.553 68.5567 79 68.0037 79Z" fill="#26355C"/>
  <path d="M25.9961 31C25.4431 31 24.9961 30.552 24.9961 30C24.9961 28.346 26.3421 27 27.9961 27C28.5491 27 28.9961 27.448 28.9961 28C28.9961 28.552 28.5491 29 27.9961 29C27.4441 29 26.9961 29.449 26.9961 30C26.9961 30.552 26.5491 31 25.9961 31Z" fill="#26355C"/>
  <path d="M25.9961 33C25.4431 33 24.9961 32.552 24.9961 32V30C24.9961 29.448 25.4431 29 25.9961 29C26.5491 29 26.9961 29.448 26.9961 30V32C26.9961 32.552 26.5491 33 25.9961 33Z" fill="#26355C"/>
  <path d="M77.9961 31C77.4431 31 76.9961 30.552 76.9961 30C76.9961 29.449 76.5481 29 75.9961 29C75.4431 29 74.9961 28.552 74.9961 28C74.9961 27.448 75.4431 27 75.9961 27C77.6501 27 78.9961 28.346 78.9961 30C78.9961 30.552 78.5491 31 77.9961 31Z" fill="#26355C"/>
  <path d="M77.9961 33C77.4431 33 76.9961 32.552 76.9961 32V30C76.9961 29.448 77.4431 29 77.9961 29C78.5491 29 78.9961 29.448 78.9961 30V32C78.9961 32.552 78.5491 33 77.9961 33Z" fill="#26355C"/>
  <path d="M75.9961 29H27.9961C27.4431 29 26.9961 28.552 26.9961 28C26.9961 27.448 27.4431 27 27.9961 27H75.9961C76.5491 27 76.9961 27.448 76.9961 28C76.9961 28.552 76.5491 29 75.9961 29Z" fill="#26355C"/>
  <path d="M68.8379 75H54.0039C53.4509 75 53.0039 74.553 53.0039 74V61C53.0039 60.447 53.4509 60 54.0039 60H74.0039C74.5569 60 75.0039 60.447 75.0039 61V68.833C75.0039 69.386 74.5569 69.833 74.0039 69.833C73.4509 69.833 73.0039 69.386 73.0039 68.833V62H55.0039V73H68.8379C69.3909 73 69.8379 73.447 69.8379 74C69.8379 74.553 69.3909 75 68.8379 75Z" fill="#26355C"/>
  <path d="M70.0039 67H66.0039C65.4509 67 65.0039 66.553 65.0039 66C65.0039 65.447 65.4509 65 66.0039 65H70.0039C70.5569 65 71.0039 65.447 71.0039 66C71.0039 66.553 70.5569 67 70.0039 67Z" fill="#26355C"/>
  <path d="M68.0039 70H66.0039C65.4509 70 65.0039 69.553 65.0039 69C65.0039 68.447 65.4509 68 66.0039 68H68.0039C68.5569 68 69.0039 68.447 69.0039 69C69.0039 69.553 68.5569 70 68.0039 70Z" fill="#26355C"/>
  <path d="M74.0039 58H54.0039C53.4509 58 53.0039 57.552 53.0039 57V44C53.0039 43.448 53.4509 43 54.0039 43H74.0039C74.5569 43 75.0039 43.448 75.0039 44V57C75.0039 57.552 74.5569 58 74.0039 58ZM55.0039 56H73.0039V45H55.0039V56Z" fill="#26355C"/>
  <path d="M60.5039 54C58.5739 54 57.0039 52.43 57.0039 50.5C57.0039 48.57 58.5739 47 60.5039 47C62.4339 47 64.0039 48.57 64.0039 50.5C64.0039 52.43 62.4339 54 60.5039 54ZM60.5039 49C59.6769 49 59.0039 49.673 59.0039 50.5C59.0039 51.327 59.6769 52 60.5039 52C61.3309 52 62.0039 51.327 62.0039 50.5C62.0039 49.673 61.3309 49 60.5039 49Z" fill="#26355C"/>
  <path d="M70.0039 50H66.0039C65.4509 50 65.0039 49.552 65.0039 49C65.0039 48.448 65.4509 48 66.0039 48H70.0039C70.5569 48 71.0039 48.448 71.0039 49C71.0039 49.552 70.5569 50 70.0039 50Z" fill="#26355C"/>
  <path d="M68.0039 53H66.0039C65.4509 53 65.0039 52.552 65.0039 52C65.0039 51.448 65.4509 51 66.0039 51H68.0039C68.5569 51 69.0039 51.448 69.0039 52C69.0039 52.552 68.5569 53 68.0039 53Z" fill="#26355C"/>
  <path d="M50.0039 75H30.0039C29.4509 75 29.0039 74.553 29.0039 74V61C29.0039 60.447 29.4509 60 30.0039 60H50.0039C50.5569 60 51.0039 60.447 51.0039 61V74C51.0039 74.553 50.5569 75 50.0039 75ZM31.0039 73H49.0039V62H31.0039V73Z" fill="#26355C"/>
  <path d="M36.5039 71C34.5739 71 33.0039 69.43 33.0039 67.5C33.0039 65.57 34.5739 64 36.5039 64C38.4339 64 40.0039 65.57 40.0039 67.5C40.0039 69.43 38.4339 71 36.5039 71ZM36.5039 66C35.6769 66 35.0039 66.673 35.0039 67.5C35.0039 68.327 35.6769 69 36.5039 69C37.3309 69 38.0039 68.327 38.0039 67.5C38.0039 66.673 37.3309 66 36.5039 66Z" fill="#26355C"/>
  <path d="M46.0039 67H42.0039C41.4509 67 41.0039 66.553 41.0039 66C41.0039 65.447 41.4509 65 42.0039 65H46.0039C46.5569 65 47.0039 65.447 47.0039 66C47.0039 66.553 46.5569 67 46.0039 67Z" fill="#26355C"/>
  <path d="M44.0039 70H42.0039C41.4509 70 41.0039 69.553 41.0039 69C41.0039 68.447 41.4509 68 42.0039 68H44.0039C44.5569 68 45.0039 68.447 45.0039 69C45.0039 69.553 44.5569 70 44.0039 70Z" fill="#26355C"/>
  <path d="M50.0039 58H30.0039C29.4509 58 29.0039 57.552 29.0039 57V44C29.0039 43.448 29.4509 43 30.0039 43H50.0039C50.5569 43 51.0039 43.448 51.0039 44V57C51.0039 57.552 50.5569 58 50.0039 58ZM31.0039 56H49.0039V45H31.0039V56Z" fill="#26355C"/>
  <path d="M36.5039 54C34.5739 54 33.0039 52.43 33.0039 50.5C33.0039 48.57 34.5739 47 36.5039 47C38.4339 47 40.0039 48.57 40.0039 50.5C40.0039 52.43 38.4339 54 36.5039 54ZM36.5039 49C35.6769 49 35.0039 49.673 35.0039 50.5C35.0039 51.327 35.6769 52 36.5039 52C37.3309 52 38.0039 51.327 38.0039 50.5C38.0039 49.673 37.3309 49 36.5039 49Z" fill="#26355C"/>
  <path d="M46.0039 50H42.0039C41.4509 50 41.0039 49.552 41.0039 49C41.0039 48.448 41.4509 48 42.0039 48H46.0039C46.5569 48 47.0039 48.448 47.0039 49C47.0039 49.552 46.5569 50 46.0039 50Z" fill="#26355C"/>
  <path d="M44.0039 53H42.0039C41.4509 53 41.0039 52.552 41.0039 52C41.0039 51.448 41.4509 51 42.0039 51H44.0039C44.5569 51 45.0039 51.448 45.0039 52C45.0039 52.552 44.5569 53 44.0039 53Z" fill="#26355C"/>
  <path d="M74.0039 41H30.0039C29.4509 41 29.0039 40.552 29.0039 40V36C29.0039 35.448 29.4509 35 30.0039 35H74.0039C74.5569 35 75.0039 35.448 75.0039 36V40C75.0039 40.552 74.5569 41 74.0039 41ZM31.0039 39H73.0039V37H31.0039V39Z" fill="#26355C"/>
  <path d="M70.0039 41C69.4509 41 69.0039 40.552 69.0039 40V36C69.0039 35.448 69.4509 35 70.0039 35C70.5569 35 71.0039 35.448 71.0039 36V40C71.0039 40.552 70.5569 41 70.0039 41Z" fill="#26355C"/>
  <path d="M72.34 76.5679L73.073 78.7667H69.7234L69.4082 76.5679H72.34Z" fill="#434A54"/>
  <path d="M69.7242 78.7677L70.8749 86.8303H65.7442L64.2783 76.5688H69.409L69.7242 78.7677Z" fill="#656D78"/>
  <path d="M57.6816 69.9721C57.6816 66.3293 60.6355 63.3755 64.2783 63.3755H76.0056V76.5687H72.3408H69.409H64.6008H64.2783C60.6355 76.5687 57.6816 73.6142 57.6816 69.9721Z" fill="#ED5565"/>
  <path d="M86.2671 63.3752V76.5685C86.2671 78.5915 84.6253 80.2333 82.6023 80.2333C80.572 80.2333 78.9375 78.5915 78.9375 76.5685V71.4378H82.6023C83.4093 71.4378 84.0682 70.7781 84.0682 69.9719C84.0682 69.1656 83.4093 68.5059 82.6023 68.5059H78.9375V63.3752C78.9375 61.3523 80.572 59.7104 82.6023 59.7104C84.6253 59.7104 86.2671 61.3523 86.2671 63.3752Z" fill="#E6E9ED"/>
  <path d="M82.6023 80.2333C82.9886 80.2333 83.3536 80.1571 83.7032 80.0471C82.2204 79.5773 81.1364 78.2059 81.1364 76.5685V63.3752C81.1364 61.7378 82.2204 60.3664 83.7032 59.8966C83.3536 59.7867 82.9886 59.7104 82.6023 59.7104C80.5793 59.7104 78.9375 61.3523 78.9375 63.3752V76.5685C78.9375 78.5915 80.5793 80.2333 82.6023 80.2333Z" fill="#CCD1D9"/>
  <path d="M81.8685 59.7104H82.6015C80.5712 59.7104 78.9367 61.3523 78.9367 63.3752V68.5059V71.4378V76.5685C78.9367 78.5915 80.5712 80.2333 82.6015 80.2333H79.6697C77.6394 80.2333 76.0049 78.5915 76.0049 76.5685V63.3752C76.0049 61.3523 77.6394 59.7104 79.6697 59.7104H81.8685Z" fill="#F5F7FA"/>
  <path d="M82.6023 68.5059C83.4093 68.5059 84.0682 69.1655 84.0682 69.9718C84.0682 70.778 83.4093 71.4377 82.6023 71.4377H78.9375V68.5059H82.6023Z" fill="#ED5565"/>
  <path d="M81.1364 68.5059H78.9375V71.4377H81.1364V68.5059Z" fill="#DA4453"/>
  <path d="M78.9371 64.1307C78.5317 64.1307 78.2041 63.8031 78.2041 63.3977C78.2041 60.9724 80.1765 59 82.6019 59C83.0072 59 83.3348 59.3276 83.3348 59.733C83.3348 60.1383 83.0072 60.4659 82.6019 60.4659C80.9849 60.4659 79.67 61.7808 79.67 63.3977C79.67 63.8031 79.3424 64.1307 78.9371 64.1307Z" fill="#26355C"/>
  <path d="M86.2669 64.1307C85.8616 64.1307 85.5339 63.8031 85.5339 63.3977C85.5339 61.7808 84.219 60.4659 82.6021 60.4659C82.1968 60.4659 81.8691 60.1383 81.8691 59.733C81.8691 59.3276 82.1968 59 82.6021 59C85.0275 59 86.9998 60.9724 86.9998 63.3977C86.9998 63.8031 86.6722 64.1307 86.2669 64.1307Z" fill="#26355C"/>
  <path d="M82.6021 80.9891C82.1968 80.9891 81.8691 80.6615 81.8691 80.2561C81.8691 79.8508 82.1968 79.5232 82.6021 79.5232C84.219 79.5232 85.5339 78.2083 85.5339 76.5914C85.5339 76.186 85.8616 75.8584 86.2669 75.8584C86.6722 75.8584 86.9998 76.186 86.9998 76.5914C86.9998 79.0167 85.0275 80.9891 82.6021 80.9891Z" fill="#26355C"/>
  <path d="M82.6019 80.9891C80.1765 80.9891 78.2041 79.0167 78.2041 76.5914C78.2041 76.186 78.5317 75.8584 78.9371 75.8584C79.3424 75.8584 79.67 76.186 79.67 76.5914C79.67 78.2083 80.9849 79.5232 82.6019 79.5232C83.0072 79.5232 83.3348 79.8508 83.3348 80.2561C83.3348 80.6615 83.0072 80.9891 82.6019 80.9891Z" fill="#26355C"/>
  <path d="M78.9371 77.3242C78.5317 77.3242 78.2041 76.9966 78.2041 76.5912V63.398C78.2041 62.9927 78.5317 62.665 78.9371 62.665C79.3424 62.665 79.67 62.9927 79.67 63.398V76.5912C79.67 76.9966 79.3424 77.3242 78.9371 77.3242Z" fill="#26355C"/>
  <path d="M76.0044 64.1307C75.5991 64.1307 75.2715 63.8031 75.2715 63.3977C75.2715 60.9724 77.2439 59 79.6692 59C80.0746 59 80.4022 59.3276 80.4022 59.733C80.4022 60.1383 80.0746 60.4659 79.6692 60.4659C78.0523 60.4659 76.7374 61.7808 76.7374 63.3977C76.7374 63.8031 76.4098 64.1307 76.0044 64.1307Z" fill="#26355C"/>
  <path d="M79.6692 80.9891C77.2439 80.9891 75.2715 79.0167 75.2715 76.5914C75.2715 76.186 75.5991 75.8584 76.0044 75.8584C76.4098 75.8584 76.7374 76.186 76.7374 76.5914C76.7374 78.2083 78.0523 79.5232 79.6692 79.5232C80.0746 79.5232 80.4022 79.8508 80.4022 80.2561C80.4022 80.6615 80.0746 80.9891 79.6692 80.9891Z" fill="#26355C"/>
  <path d="M76.0044 77.3242C75.5991 77.3242 75.2715 76.9966 75.2715 76.5912V63.398C75.2715 62.9927 75.5991 62.665 76.0044 62.665C76.4098 62.665 76.7374 62.9927 76.7374 63.398V76.5912C76.7374 76.9966 76.4098 77.3242 76.0044 77.3242Z" fill="#26355C"/>
  <path d="M86.2662 77.3242C85.8608 77.3242 85.5332 76.9966 85.5332 76.5912V63.398C85.5332 62.9927 85.8608 62.665 86.2662 62.665C86.6715 62.665 86.9991 62.9927 86.9991 63.398V76.5912C86.9991 76.9966 86.6715 77.3242 86.2662 77.3242Z" fill="#26355C"/>
  <path d="M82.6021 72.1932C82.1968 72.1932 81.8691 71.8655 81.8691 71.4602C81.8691 71.0549 82.1968 70.7272 82.6021 70.7272C83.0067 70.7272 83.3351 70.3989 83.3351 69.9943C83.3351 69.5897 83.0067 69.2613 82.6021 69.2613C82.1968 69.2613 81.8691 68.9337 81.8691 68.5284C81.8691 68.123 82.1968 67.7954 82.6021 67.7954C83.8144 67.7954 84.801 68.782 84.801 69.9943C84.801 71.2066 83.8144 72.1932 82.6021 72.1932Z" fill="#26355C"/>
  <path d="M82.6019 69.2613H78.9371C78.5317 69.2613 78.2041 68.9337 78.2041 68.5284C78.2041 68.123 78.5317 67.7954 78.9371 67.7954H82.6019C83.0072 67.7954 83.3348 68.123 83.3348 68.5284C83.3348 68.9337 83.0072 69.2613 82.6019 69.2613Z" fill="#26355C"/>
  <path d="M82.6019 72.193H78.9371C78.5317 72.193 78.2041 71.8653 78.2041 71.46C78.2041 71.0547 78.5317 70.7271 78.9371 70.7271H82.6019C83.0072 70.7271 83.3348 71.0547 83.3348 71.46C83.3348 71.8653 83.0072 72.193 82.6019 72.193Z" fill="#26355C"/>
  <path d="M76.0125 64.131H64.2779C63.8726 64.131 63.5449 63.8033 63.5449 63.398C63.5449 62.9927 63.8726 62.665 64.2779 62.665H76.0125C76.4179 62.665 76.7455 62.9927 76.7455 63.398C76.7455 63.8033 76.4179 64.131 76.0125 64.131Z" fill="#26355C"/>
  <path d="M76.0052 77.3243H64.2779C63.8726 77.3243 63.5449 76.9967 63.5449 76.5914C63.5449 76.186 63.8726 75.8584 64.2779 75.8584H76.0052C76.4105 75.8584 76.7382 76.186 76.7382 76.5914C76.7382 76.9967 76.4105 77.3243 76.0052 77.3243Z" fill="#26355C"/>
  <path d="M64.2778 77.3242C60.2363 77.3242 56.9482 74.0362 56.9482 69.9946C56.9482 65.9531 60.2363 62.665 64.2778 62.665C64.6832 62.665 65.0108 62.9927 65.0108 63.398C65.0108 63.8033 64.6832 64.131 64.2778 64.131C61.0447 64.131 58.4142 66.7615 58.4142 69.9946C58.4142 73.2277 61.0447 75.8583 64.2778 75.8583C64.6832 75.8583 65.0108 76.1859 65.0108 76.5912C65.0108 76.9966 64.6832 77.3242 64.2778 77.3242Z" fill="#26355C"/>
  <path d="M82.6013 60.4659H79.6695C79.2642 60.4659 78.9365 60.1383 78.9365 59.733C78.9365 59.3276 79.2642 59 79.6695 59H82.6013C83.0066 59 83.3343 59.3276 83.3343 59.733C83.3343 60.1383 83.0066 60.4659 82.6013 60.4659Z" fill="#26355C"/>
  <path d="M82.6013 80.9884H79.6695C79.2642 80.9884 78.9365 80.6607 78.9365 80.2554C78.9365 79.8501 79.2642 79.5225 79.6695 79.5225H82.6013C83.0066 79.5225 83.3343 79.8501 83.3343 80.2554C83.3343 80.6607 83.0066 80.9884 82.6013 80.9884Z" fill="#26355C"/>
  <path d="M65.7432 87.585C65.384 87.585 65.0703 87.3219 65.0183 86.9561L63.5524 76.6947C63.4952 76.2938 63.7737 75.9222 64.1746 75.865C64.5778 75.8071 64.9472 76.0864 65.0044 76.4873L66.4703 86.7487C66.5274 87.1497 66.2489 87.5213 65.848 87.5784C65.8128 87.5828 65.7776 87.585 65.7432 87.585Z" fill="#26355C"/>
  <path d="M70.874 87.585C70.5149 87.585 70.2012 87.3219 70.1491 86.9561L68.6832 76.6947C68.6261 76.2938 68.9046 75.9222 69.3055 75.865C69.7042 75.8071 70.0773 76.0864 70.1352 76.4873L71.6011 86.7487C71.6583 87.1497 71.3798 87.5213 70.9788 87.5784C70.9437 87.5828 70.9085 87.585 70.874 87.585Z" fill="#26355C"/>
  <path d="M71.6083 87.5851H65.7447C65.3394 87.5851 65.0117 87.2574 65.0117 86.8521C65.0117 86.4468 65.3394 86.1191 65.7447 86.1191H71.6083C72.0137 86.1191 72.3413 86.4468 72.3413 86.8521C72.3413 87.2574 72.0137 87.5851 71.6083 87.5851Z" fill="#26355C"/>
  <path d="M73.0732 79.5228C72.7661 79.5228 72.4802 79.3286 72.3783 79.0214L71.6454 76.8226C71.5171 76.4385 71.7245 76.0229 72.1086 75.8954C72.4949 75.7671 72.9083 75.9745 73.0358 76.3586L73.7688 78.5575C73.897 78.9415 73.6896 79.3571 73.3055 79.4847C73.2278 79.5103 73.1494 79.5228 73.0732 79.5228Z" fill="#26355C"/>
  <path d="M73.0726 79.5226H69.7222C69.3169 79.5226 68.9893 79.1949 68.9893 78.7896C68.9893 78.3843 69.3169 78.0566 69.7222 78.0566H73.0726C73.4779 78.0566 73.8055 78.3843 73.8055 78.7896C73.8055 79.1949 73.4779 79.5226 73.0726 79.5226Z" fill="#26355C"/>
  <path d="M61.346 70.7271H57.6812C57.2759 70.7271 56.9482 70.3995 56.9482 69.9942C56.9482 69.5889 57.2759 69.2612 57.6812 69.2612H61.346C61.7513 69.2612 62.0789 69.5889 62.0789 69.9942C62.0789 70.3995 61.7513 70.7271 61.346 70.7271Z" fill="#26355C"/>
  <path d="M61.3457 67.7955H58.4087C58.0034 67.7955 57.6758 67.4679 57.6758 67.0625C57.6758 66.6572 58.0034 66.3296 58.4087 66.3296H61.3457C61.751 66.3296 62.0787 66.6572 62.0787 67.0625C62.0787 67.4679 61.751 67.7955 61.3457 67.7955Z" fill="#26355C"/>
  <path d="M61.3457 73.6593H58.4087C58.0034 73.6593 57.6758 73.3316 57.6758 72.9263C57.6758 72.521 58.0034 72.1934 58.4087 72.1934H61.3457C61.751 72.1934 62.0787 72.521 62.0787 72.9263C62.0787 73.3316 61.751 73.6593 61.3457 73.6593Z" fill="#26355C"/>
  </g>
  <defs>
  <clipPath id="clip0">
  <rect width="64" height="64" fill="white" transform="translate(25 26)"/>
  </clipPath>
  </defs>
  </svg>
</template>

<script>
export default {
  name: "OnlineAdsManagerIcon"
}
</script>

<style scoped>

</style>
