<template>
  <li class="flex items-center space-x-3 mb-3">
    <Checkbox
      v-if="canEdit"
      fontWeight="font-normal"
      hover="hover:text-auxiliary-blue"
      borderWidth="border"
      :value="this.editedTask.status == 'completed' ? 'active' : 'completed'"
      :title="task.name"
      name="price"
      :checked="isTaskCompleted"
      @on-toggle="changeTaskStatus"
    />
    <template v-else>
      <CircleIcon class="text-fonts-tertiary"/>
      <span class="body-m-400" :class="isTaskCompleted ? 'text-green-600' : 'text-fonts-tertiary'">{{task.name}}</span>
      <CheckIcon v-if="isTaskCompleted"/>
    </template>

  </li>
</template>
<script>
import {mapActions} from "vuex";
import CheckIcon from "@/components/shared/svg/CheckIcon"
import CircleIcon from "@/components/shared/svg/CircleIcon";
import Checkbox from '@/components/shared/Checkbox';

export default {
  name: 'TaskItem',
  components: {CircleIcon, Checkbox, CheckIcon},

  props: {
    task: Object,
    canEdit: Boolean,
  },

  data() {
    return {
      editedTask: {}
    }
  },

  mounted() {
    this.editedTask = this.task
  },

  methods: {
    ...mapActions({
      updateTask: 'jobs/updateTask',
    }),

    changeTaskStatus(status) {
      this.editedTask.status = status
      const data = {id: this.editedTask.id, status: this.editedTask.status};
      this.updateTask(data)
    },
  },

  computed: {
    isTaskCompleted() {
      return this.task.status == 'completed'
    },
  },
}
</script>
