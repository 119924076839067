<template>
  <div class="w-full">
    <TextInput :placeholder="placeholder" @input="debouncedQuery" v-model="query[property]">
      <template slot="leftIcon">
        <SearchIcon class="left-icon"/>
      </template>
      <template slot="rightIcon">
        <button @click.prevent="clearSearch()" type="button">
          <XIcon class="right-button"/>
        </button>
      </template>
    </TextInput>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapMutations, mapState } from 'vuex';
import TextInput from "@/components/shared/inputs/TextInput";
import SearchIcon from "@/components/shared/svg/SearchIcon";
import XIcon from "@/components/shared/svg/XIcon";

export default {
  name: 'Search',
  components: {XIcon, SearchIcon, TextInput},

  props: {
    property: { type: String, default: 'name'},
    placeholder: { type: String, default: 'Search pros...'},
  },
  methods: {
    ...mapMutations({
      setName: 'filters/setName',
      setTitle: 'filters/setTitle',
    }),

    fetch() {
      if (this.property == 'name') {
        this.setName(this.query[this.property]);    
      } else {
        this.setTitle(this.query[this.property]);
      }
      this.$emit('fetch')
    },

    debouncedQuery: _.debounce(function () { this.fetch(); }, 600),

    clearSearch() {
      switch (this.property) {
        case 'name':
          this.setName('');    
          break;
        case 'title':
          this.setTitle('');
          break;
        default:
          break;
      }
      this.fetch();
    },
  },

  computed: {
    ...mapState({
      query: state => state.filters.query,
    }),
  },
}
</script>