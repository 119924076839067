<template>
  <div>
    <SubHeader/>
    <div class="container mx-auto px-6 lg:px-20 xl:px-32 py-9">
      <div class="grid grid-cols-3 gap-x-5 mb-9">
        <div class="col-span-2">
          <h4 class="mb-4 heading-s-600">Requirements</h4>
          <JobRequirements :job="job" :loading="loadingJob" />
        </div>
        <div>
          <h4 class="mb-4 heading-s-600">Details</h4>
          <JobDetails
            :job="job"
            :showStatus="false"
            :loading="loadingJob"
            :threadId="bidsForUser.length == 1 ? bidsForUser[0].thread_id : null"
          />
        </div>
      </div>
      
      <div class="mb-10">
        <h4 class="mb-4 heading-s-600">Our Guarantee</h4>
        <GuaranteeMessage />
      </div>

      <h4 v-if="bidsForUser.length" class="mb-4 heading-s-600">Bids</h4>
      <div v-if="loadingBids" class="flex justify-center items-center">
        <loading-icon class="h-2" />
      </div>
      <div v-else class="space-y-11">
        <ReviewBid v-for="(bid, index) in bidsForUser" :key="index" :bid="bid" @declined="fetchBids"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import ReviewBid from "@/components/hire/ReviewBid";
import SubHeader from "@/components/hire/SubHeader";
import JobRequirements from '@/components/jobs/JobRequirements';
import JobDetails from '@/components/jobs/JobDetails';
import GuaranteeMessage from '@/components/shared/GuaranteeMessage';

export default {
  name: "JobBids",
  components: { ReviewBid, SubHeader, JobRequirements, JobDetails, GuaranteeMessage },

  data() {
    return {
      items: [],
      show: 'all',
      loadingJob: false,
      loadingBids: false,
    }
  },

  async mounted() {
    this.loadingJob = true;
    await this.fetchJob({ jobId: this.$route.params.jobId, includes: 'church,hiring_bid' })
    this.fetchBids();
    this.loadingJob = false;
  },

  methods: {
    ...mapActions({
      fetch: 'bids/fetch',
      fetchJob: 'jobs/fetchJob',
    }),

    async fetchBids() {
      this.loadingBids = true;
      await this.fetch({ jobId: this.$route.params.jobId })
      this.loadingBids = false;
    },
  },

  computed: {
    ...mapState({
      job: state => state.jobs.current,
      bids: state => state.bids.items,
      user: state => state.auth.user,
    }),

    userIsPro() {
      return this.user.userable_type == 'pro';
    },

    bidsForUser() {
      if (this.userIsPro) {
        return this.bids.filter(bid => bid.pro.id == this.user.id);
      }
      return this.bids;
    }
  },
}
</script>

