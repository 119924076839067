<template>
  <div class="bg-cream">
    <SubHeader v-if="bidStep == 1"/>
    <main class="py-10 container mx-auto px-6 lg:px-20 xl:px-72">
      <component :is="`Step${bidStep}`"/>
    </main>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";
import SubHeader from "@/components/bid/SubHeader";
import Step1 from "@/components/bid/Step1";
import Step2 from "@/components/bid/Step2";

export default {
  name: "Bid",
  components: {SubHeader, Step1, Step2},

  mounted() {
    this.resetBidSteps()

    if (this.$route.params.jobId) {
      this.fetchJob({jobId: this.$route.params.jobId, includes: 'church,bids'}).then(() => {
        this.setJob(this.job)
      });
    } else if (!this.$route.params.job) {
      this.$router.push({name:'Jobs'});
      this.setJob(this.$route.params.job)
    }
  },

  methods: {
    ...mapActions({
      fetchJob: 'jobs/fetchJob'
    }),

    ...mapMutations({
      resetBidSteps: 'bids/resetBidSteps',
      setJob: 'jobs/setJob',
    }),
  },
  computed:{
    ...mapState({
      bidStep: state => state.bids.bidStep,
      job: state => state.jobs.current,
    }),
  }
}
</script>

