<template>
  <div class="w-full lg:w-8/12 xl:w-6/12 mx-auto">
    <div class="px-5">
      <BackButton @onClick="$router.back()" text="Back to settings" font-weight="font-normal" class="mb-6"/>
      <PageTitle title="Account Details" class="mb-8"/>
    </div>
    <ValidationObserver v-slot="{ invalid, handleSubmit }" rules="required" ref="userForm">
      <form @submit.prevent="handleSubmit(save)" v-if="userType">
        <div class="px-5">
          <div class="grid grid-cols-1 gap-x-10">
            <span class="divider label-s-500">Personal Details</span>
            <div class="grid grid-cols-2 gap-6 mb-6">
              <ValidationProvider name="First name" rules="required" v-slot="{ errors }">
                <TextInput v-model="form.user.first_name" label="First Name" placeholder="John" x-padding="px-5" :errors="errors"/>
              </ValidationProvider>

              <ValidationProvider name="Last name" rules="required" v-slot="{ errors }">
                <TextInput v-model="form.user.last_name" label="Last Name" placeholder="Doe" x-padding="px-5" :errors="errors"/>
              </ValidationProvider>

              <ValidationProvider name="Email" rules="required|email" vid="email" v-slot="{ errors }">
                <TextInput v-model="form.user.email" label="Email" placeholder="john.smith@youremail.com" x-padding="px-5" class="col-span-2" :errors="errors" @onBlur="emailCheck(errors)">
                  <template slot="rightIcon" v-if="emailCheckLoading">
                    <loading-icon class="h-2 my-1"/>
                  </template>
                  <template slot="rightIcon" v-else-if="check != null">
                    <XIcon class="text-red-600" strokeWidth="2" v-if="!check || errors.length"/>
                    <CheckIcon v-else/>
                  </template>
                </TextInput>
              </ValidationProvider>

              <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
                <TextInput
                  v-model="form.user.title"
                  label="What is your title?"
                  :placeholder="form.userable_type == 'pro' ? 'Graphic designer' : 'Assistant pastor'"
                  x-padding="px-5"
                  class="col-span-2"
                  :errors="errors"
                />
              </ValidationProvider>

              <!-- Change password-->
              <TextInput v-if="changePassword !== true" :disabled="true" label="Password" x-padding="px-5"
                        class="col-span-2" value="12121212"
                        type="password">
                <template slot="rightIcon">
                  <div @click="changePassword = true" class="text-xs block flex-shrink-0 text-secondary-main cursor-pointer">
                    Change password
                  </div>
                </template>
              </TextInput>

              <div v-if="changePassword" class="col-span-2 -mx-6">
                <ChangePassword @changed="changePassword = false"/>
              </div>

              <ValidationProvider name="Phone number" :rules="userIsPro ? 'required' : ''" v-slot="{ errors }" class="col-span-2">
                <TextInput
                  v-model="userPhone"
                  label="Phone"
                  x-padding="px-5"
                  :phoneInput="true"
                  :errors="errors"
                  @phone="setPhone($event, form.user)"
                />
              </ValidationProvider>

              <div>
                <InputLabel :label="userIsPro ? 'Profile image' : 'Business logo'"/>
                <ImageUploadSingle
                  entity="pro"
                  :working="profileLoading"
                  :modelId="modelId"
                  :imageUrl="profileImageUrl"
                  :imageUuid="profileImageUuid"
                  :error="uploadingProfileError"
                  @uploaded="profileImageUpdated"
                />
              </div>

              <div>
                <InputLabel label="Cover image"/>
                <ImageUploadSingle
                  entity="pro"
                  :working="coverLoading"
                  :modelId="modelId"
                  :imageUrl="coverImageUrl"
                  :imageUuid="coverImageUuid"
                  :error="uploadingCoverError"
                  @uploaded="coverImageUpdated"
                  cropStencilComponent="rectangle-stencil"
                  :cropAspectRatio="12/6"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="px-5" v-if="user.userable_type == 'pro'">
          <!--Pro details-->
          <div class="mb-6">
            <span class="divider label-s-500">Pro Details</span>
            <div class="grid grid-cols-1 gap-y-6">
              <BaseMultiselect :options="categories" label="Categories" type="category" :initialValues="userType.categories" @selected="setProCategories" />
              <TagMultiselect :options="skills" label="Assign skills" type="skill" @selected="setProSkills" :initialValues="userType.skills" :optionsLimit="8"/>

              <ValidationProvider name="Bio" rules="required" v-slot="{ errors }">
                <TextAreaInput v-model="form.pro.bio" label="Your Bio" resizable="resize-y" :errors="errors"/>
              </ValidationProvider>

              <TextAreaInput v-model="form.pro.education" label="Your Education and Experience" resizable="resize-y"/>

              <BaseMultiselect :options="$constants.PROS.COUNTRIES" trackBy="code" label="Country" :multiple="false" type="country" :initialValues="initialCountry" @selected="setUserCountry" />

              <ValidationProvider name="State" rules="required" v-slot="{ errors }">
                <TextInput
                  v-model="form.user.state"
                  label="State/province"
                  x-padding="px-5"
                  placeholder="Florida"
                  :errors="errors"
                />
              </ValidationProvider>

              <ValidationProvider name="City" rules="required" v-slot="{ errors }">
                <TextInput
                  v-model="form.user.city"
                  label="City"
                  x-padding="px-5"
                  placeholder="Miami"
                  :errors="errors"
                />
              </ValidationProvider>
            </div>
          </div>
          <!--Portfolio-->
          <div>
            <span class="divider">Portfolio</span>
            <div>
              <InputLabel label="Add portfolio images"/>
              <ImageUploadMultiple
                :images="portfolio"
                @uploaded="portfolioUpdated"
                :working="portfolioLoading"
              />
            </div>
          </div>
        </div>

        <div class="px-5" v-else>
          <div class="mb-6">
            <span class="divider label-s-500">Business Details</span>
            <div class="grid grid-cols-1 gap-y-6">
              <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                <TextInput v-model="form.church.name" label="Business name" x-padding="px-5" :errors="errors"/>
              </ValidationProvider>

              <ValidationProvider name="Phone" rules="required" v-slot="{ errors }">
                <TextInput
                  v-model="churchPhone"
                  label="Phone"
                  :errors="errors"
                  :phoneInput="true"
                  @phone="setPhone($event, form.church)"/>
              </ValidationProvider>

              <BaseMultiselect :options="$constants.CHURCHES.COUNTRIES" trackBy="code" label="Country" :multiple="false" type="country" @selected="setChurchCountry" :initialValues="initialCountry" />

              <ValidationProvider name="State" rules="required" v-slot="{ errors }">
                <TextInput
                  v-model="form.church.state"
                  label="State/province"
                  x-padding="px-5"
                  placeholder="Florida"
                  :errors="errors"
                />
              </ValidationProvider>

              <ValidationProvider name="City" rules="required" v-slot="{ errors }">
                <TextInput
                  v-model="form.church.city"
                  label="City"
                  x-padding="px-5"
                  placeholder="Miami"
                  :errors="errors"
                />
              </ValidationProvider>
            </div>
          </div>
        </div>

        <div class="px-5">
          <span class="divider"></span>
          <div class="flex space-x-4">
            <button type="submit" :disabled="invalid">
              <ButtonIcon :loading="loading" :disabled="invalid" text="Update Details" font-weight="font-normal"/>
            </button>
            <ButtonIcon @onClick="$router.back()" text="Cancel" font-weight="font-normal" background="bg-transparent" hover-color=""
                        hover-background="" color="text-secondary-main"/>
          </div>
        </div>
      </form>
    </ValidationObserver>

  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { pick, isEmpty } from 'lodash';
import BackButton from "@/components/shared/buttons/BackButton";
import PageTitle from "@/components/shared/labels/PageTitle";
import TextInput from "@/components/shared/inputs/TextInput";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import BaseMultiselect from "@/components/shared/inputs/BaseMultiselect";
import TagMultiselect from "@/components/shared/inputs/TagMultiselect";
import TextAreaInput from "@/components/shared/inputs/TextAreaInput";
import InputLabel from "@/components/shared/labels/InputLabel";
import ImageUploadMultiple from "@/components/shared/ImageUploadMultiple";
import ChangePassword from '@/components/settings/ChangePassword.vue';
import ImageUploadSingle from '@/components/shared/ImageUploadSingle';
import CheckIcon from "@/components/shared/svg/CheckIcon";
import XIcon from "@/components/shared/svg/XIcon";

export default {
  name: "AccountDetails",
  components: {
    ImageUploadMultiple,
    InputLabel, TextAreaInput, TagMultiselect, BaseMultiselect, ButtonIcon, TextInput, PageTitle, BackButton,
    ChangePassword, ImageUploadSingle, CheckIcon, XIcon
  },
  data() {
    return {
      changePassword: false,
      loading: false,
      form: {
        user: {},
        pro: {},
        church: {},
      },
      profileLoading: false,
      coverLoading: false,
      uploadingProfileError: false,
      uploadingCoverError: false,
      portfolioLoading: false,
      portfolio: [],
      emailCheckLoading: false,
      userType: null,
      profileImageUrl: null,
      coverImageUrl: null,
      userPhone: null,
      churchPhone: null,
    }
  },

  async created() {
    await this.fetchCategories()
    await this.getUser();
    await this.fetchSkills();
    this.initializeForm();
    this.setCheckEmail(null)
    this.setInitialImages()
  },

  methods: {
    ...mapActions({
      getUser: 'auth/getUser',
      fetchCategories: 'categories/fetch',
      fetchSkills: 'skills/fetch',
      updateUser: 'users/update',
      addPortfolioImage: 'pros/addPortfolioImage',
      deleteMedia: 'users/deleteMedia',
      checkEmail: 'auth/checkEmail',
    }),

    ...mapMutations({
      setCheckEmail: 'auth/checkEmail',
    }),

    initializeForm() {
      this.userType = this.user.type
      const userSubset = pick(this.user, ['first_name', 'last_name', 'email', 'title', 'state', 'city', 'country']);
      this.form = Object.assign({}, {user: userSubset})
      this.userPhone = this.user.phone;
      if (this.user.userable_type == 'pro') {
        const proSubset = pick(this.userType, ['bio', 'education', 'categories', 'skills']);
        this.form.pro = proSubset;
        delete this.form['type']
        delete this.form.user['type']
        this.setInitialCategories();
        this.setInitialSkills();
        this.setInitialPortfolio();
      } else {
        const churchSubset = pick(this.userType.church, ['name', 'state', 'city', 'country']);
        this.churchPhone = this.userType.church.phone
        this.form.church = churchSubset;
        delete this.form['type']
        delete this.form.user['type']
        delete this.form.user['state']
        delete this.form.user['city']
        delete this.form.user['country']
      }
    },

    setInitialImages() {
      this.coverImageUrl = this.userIsPro ? this.user.type.cover_image_url : this.user.type.church.cover_image_url
      this.profileImageUrl = this.userIsPro ? this.user.type.profile_image_url : this.user.type.church.logo_url
    },

    setInitialCategories() {
      this.form.pro.categories = this.form.pro.categories.map(cat => cat.id)
    },

    setInitialSkills() {
      this.form.pro.skills = this.form.pro.skills.map(skill => skill.name)
    },

    setInitialPortfolio() {
      this.portfolio = this.userType.portfolio.map(p => p.url)
    },

    setProCategories(categories) {
      this.form.pro.categories = categories.map(cat => cat.id)
    },

    setProSkills(skills) {
      this.form.pro.skills = skills.map(skill => skill.name)
    },

    setChurchCountry(country) {
      if (isEmpty(country)) {
        delete this.form.church.country;
        this.userType.church.country = ''
      } else {
        this.form.church.country = country.code;
        this.userType.church.country = country.code
      }
      this.setUserCountry(country)
    },

    setUserCountry(country) {
      if (isEmpty(country)) {
        delete this.form.user.country;
      } else {
        this.form.user.country = country.code;
      }
    },

    setPhone(phoneObject, form) {
      form.phone_country_code = phoneObject.countryCode;
      form.phone = phoneObject.number;
    },

    save(){
      this.loading = true;

      this.updateUser(this.form)
        .then(response => {
          if (response) {
            this.$toast.success('Saved successfully')
          }
        })
        .catch(errors => {
          console.log(errors)
          this.$refs.userForm.setErrors(errors.errors)
        })
        .finally(() => this.loading = false)
    },

    profileImageUpdated(url) {
      this.profileImageUrl = url
      if (url) {
        this.updateImage()
      }
    },

    coverImageUpdated(url) {
      this.coverImageUrl = url
      if (url) {
        this.updateImage('cover')
      }
    },

    updateImage(image = 'profile') {
      if (image == 'profile') {
        this.profileLoading = true
        this.uploadingProfileError = false
        } else {
        this.coverLoading = true
        this.uploadingCoverError = false
      }
      let data;
      if (this.userIsPro) {
        data = image == 'profile' ? {pro:{profile_image_url: this.profileImageUrl}} : {pro:{cover_image_url: this.coverImageUrl}}
      } else {
        data = image == 'profile' ? {church:{logo_url: this.profileImageUrl}} : {church:{cover_image_url: this.coverImageUrl}}
      }

      this.updateUser(data)
        .catch(() => {
          this.uploadingProfileError = true
          this.uploadingCoverError = true
        })
        .finally(() => {
          this.profileLoading = false;
          this.coverLoading = false;
        })

    },

    async portfolioUpdated(url) {
      this.portfolioLoading = true;
      const index = this.portfolio.findIndex(p => p == url);
      if (index >= 0) {
        const portfolioImage = this.userType.portfolio.find(p => p.url == url);
        await this.deleteMedia({uuid:portfolioImage.uuid})
        this.portfolio.splice(index, 1)
      } else {
        await this.addPortfolioImage({id: this.userType.id, url})
        this.portfolio.push(url)
      }
      this.portfolioLoading = false;
    },

    emailCheck(errors) {
      if (!errors.length) {
        this.emailCheckLoading = true;
        this.checkEmail({email: this.form.user.email})
          .then(response => {
            if(!response){
              this.$refs.userForm.setErrors({email: 'Email already taken'})
            }
          })
          .finally(() => this.emailCheckLoading = false)
      }
      if (this.form.user.email == '') {
        this.setCheckEmail(null)
      }
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      categories: state => state.categories.items,
      skills: state => state.skills.items,
      check: state => state.auth.checkEmail,
    }),

    userIsPro() {
      return this.user.userable_type == 'pro'
    },

    initialCountry() {
      const country = this.userIsPro ? this.user.country : this.userType.church.country;
      if (country) {
        return [this.$constants.CHURCHES.COUNTRIES.find(c => country == c.code)];
      }
      return []
    },

    modelId() {
      return this.userIsPro ? this.user.type.id : this.user.type.user_id
    },

    profileImageUuid() {
      return this.userIsPro ? this.user.type.profile_image_uuid : this.user.type.church.logo_uuid
    },

    coverImageUuid() {
      return this.userIsPro ? this.user.type.cover_image_uuid : this.user.type.church.cover_image_uuid
    },
  },
}
</script>
