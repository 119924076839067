<template>
  <div>
    <nav class="flex">
      <button type="button"
              @click='goToPage(pagination.current_page - 1)'
              :class="pagination.links.previous ? 'hover:text-primary-70' : 'opacity-50 cursor-not-allowed pointer-events-none'"
              class="btn pr-2"
              aria-label="Previous">
        <ArrowIcon class="transform rotate-180"/>
      </button>
      <span v-for="(item, index) in pagination.total_pages" :key="index">
        <button v-if="pagination.total_pages < 10 || index < 4 || index > pagination.total_pages - 5 || pagination.current_page == index + 1"
                type="button"
                @click='goToPage(index + 1)'
                :class="{'text-white rounded-full bg-secondary-main': pagination.current_page == index + 1}"
                class="btn justify-center w-7 h-7 body-xs-400">
          {{ index + 1 }}
        </button>
        <span v-else-if="index == 4" class="px-2">...</span>
      </span>
      <button type="button"
              @click='goToPage(pagination.current_page + 1)'
              class="btn pl-2"
              :class="pagination.links.next ? 'hover:text-primary-70' : 'opacity-50 cursor-not-allowed pointer-events-none'"
              aria-label="Next">
        <ArrowIcon/>
      </button>
    </nav>
  </div>
</template>

<script>
  import ArrowIcon from "@/components/shared/svg/ArrowIcon";

  export default {
    name: "Pagination",
    components: {ArrowIcon},

    props: {
      pagination: Object
    },
    methods: {
      goToPage(page) {
        this.$emit('redirectTo', page)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .btn {
    @apply flex items-center transition ease-in-out duration-150;
    &:focus {
      @apply outline-none;
    }


  }
  
</style>
a