const filters = {
  namespaced: true,

  state: {
    query: {},
    filters: {},
    categories: '',
    skills: [],
    name: '',
    title: '',
    is_favorited: false,
    page: 1,

    sort: '',
    currentSort: '',
    proSortingOptions: [
      {
        id: '-created_at',
        name: 'Newest',
      },
    ],
    jobSortingOptions: [
      {
        id: '-created_at',
        name: 'Newest',
      },
    ]
  },

  mutations: {
    clearFilters(state) {
      state.query = {};
      state.filters = {};
      state.categories = '',
      state.skills =  [];
      state.name =  '';
      state.title =  '';
      state.is_favorited =  false;
      state.page =  1;

      state.sort = '';
      state.currentSort = '';
    },

    toggleCategory(state, catId) {
      const categories = state.categories == '' ? [] : state.categories.split(',')
      const index = categories.findIndex(cat => cat == catId);
      if (index >= 0) {
        categories.splice(index, 1);
      } else {
        categories.push(catId);
      }
      state.query.categories = categories.join(',')
      state.filters.categories = categories.join(',')
      state.categories = categories.join(',')
    },

    setCategories(state, ids) {
      state.categories = ids
      state.query.categories = ids
      state.filters.categories = ids
    },

    setSkills(state, skills) {
      state.skills = !skills ? [] : skills.split(',').map((skill, index) => {
        return {id: index + 1, name: skill}
      });
      state.filters.skills = skills
      state.query.skills = skills
    },

    setSkills2(state, skills) {
      state.skills = skills
      const skillsString = skills.map(skill => skill.name).join(',');
      state.filters.skills = skillsString
      state.query.skills = skillsString
    },

    setFavorited(state, favorited) {
      state.query.is_favorited = (favorited == 'true')
      state.filters.is_favorited = (favorited == 'true')
      state.is_favorited = (favorited == 'true')
    },

    toggleFavorited(state) {
      state.query.is_favorited = !state.query.is_favorited
      state.filters.is_favorited = !state.filters.is_favorited
      state.is_favorited = !state.is_favorited
    },

    setName(state, name) {
      state.filters.name = name
      state.name = name
      state.query.name = name
    },

    setTitle(state, title) {
      state.filters.title = title
      state.title = title
      state.query.title = title
    },

    setSort(state, sort) {
      state.query.sort = sort
      state.sort = sort
      const currentSort = state.proSortingOptions.find(s => s.id == sort)
      state.currentSort = currentSort.name
    },

    setPage(state, page) {
      state.query.page = page
      state.page = page
    },
  },
}

export default filters
