<template>
  <div class="h-24 bg-primary w-full flex justify-center items-center">
    <TalentPondLogo/>
  </div>
</template>
<script>
import TalentPondLogo from "@/components/shared/svg/TalentPondLogo"

export default {
  name: 'SubHeader',
  components: {TalentPondLogo}
}
</script>
