import Pros from "@/views/pro/Pros";
import Pro from "@/views/pro/Pro";
import BankAccountConfiguration from "@/views/pro/BankAccountConfiguration";

const routes = [
  {
    path: '/pros',
    name: 'Pros',
    component: Pros,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pros/:proId',
    name: 'Pro',
    component: Pro,
    props:true,
    meta: {
      rootClasses: true,
      requiresAuth: true,
    },
  },
  {
    path: '/bank-account-details',
    name: 'BankAccountConfiguration',
    component: BankAccountConfiguration,
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes;
