var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full lg:w-8/12 xl:w-6/12 mx-auto"},[_c('div',{staticClass:"px-5"},[_c('BackButton',{staticClass:"mb-6",attrs:{"text":"Back to settings","font-weight":"font-normal"},on:{"onClick":function($event){return _vm.$router.back()}}}),_c('PageTitle',{staticClass:"mb-8",attrs:{"title":"Account Details"}})],1),_c('ValidationObserver',{ref:"userForm",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [(_vm.userType)?_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('div',{staticClass:"px-5"},[_c('div',{staticClass:"grid grid-cols-1 gap-x-10"},[_c('span',{staticClass:"divider label-s-500"},[_vm._v("Personal Details")]),_c('div',{staticClass:"grid grid-cols-2 gap-6 mb-6"},[_c('ValidationProvider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"First Name","placeholder":"John","x-padding":"px-5","errors":errors},model:{value:(_vm.form.user.first_name),callback:function ($$v) {_vm.$set(_vm.form.user, "first_name", $$v)},expression:"form.user.first_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"Last Name","placeholder":"Doe","x-padding":"px-5","errors":errors},model:{value:(_vm.form.user.last_name),callback:function ($$v) {_vm.$set(_vm.form.user, "last_name", $$v)},expression:"form.user.last_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{staticClass:"col-span-2",attrs:{"label":"Email","placeholder":"john.smith@youremail.com","x-padding":"px-5","errors":errors},on:{"onBlur":function($event){return _vm.emailCheck(errors)}},model:{value:(_vm.form.user.email),callback:function ($$v) {_vm.$set(_vm.form.user, "email", $$v)},expression:"form.user.email"}},[(_vm.emailCheckLoading)?_c('template',{slot:"rightIcon"},[_c('loading-icon',{staticClass:"h-2 my-1"})],1):(_vm.check != null)?_c('template',{slot:"rightIcon"},[(!_vm.check || errors.length)?_c('XIcon',{staticClass:"text-red-600",attrs:{"strokeWidth":"2"}}):_c('CheckIcon')],1):_vm._e()],2)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{staticClass:"col-span-2",attrs:{"label":"What is your title?","placeholder":_vm.form.userable_type == 'pro' ? 'Graphic designer' : 'Assistant pastor',"x-padding":"px-5","errors":errors},model:{value:(_vm.form.user.title),callback:function ($$v) {_vm.$set(_vm.form.user, "title", $$v)},expression:"form.user.title"}})]}}],null,true)}),(_vm.changePassword !== true)?_c('TextInput',{staticClass:"col-span-2",attrs:{"disabled":true,"label":"Password","x-padding":"px-5","value":"12121212","type":"password"}},[_c('template',{slot:"rightIcon"},[_c('div',{staticClass:"text-xs block flex-shrink-0 text-secondary-main cursor-pointer",on:{"click":function($event){_vm.changePassword = true}}},[_vm._v(" Change password ")])])],2):_vm._e(),(_vm.changePassword)?_c('div',{staticClass:"col-span-2 -mx-6"},[_c('ChangePassword',{on:{"changed":function($event){_vm.changePassword = false}}})],1):_vm._e(),_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"name":"Phone number","rules":_vm.userIsPro ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"Phone","x-padding":"px-5","phoneInput":true,"errors":errors},on:{"phone":function($event){return _vm.setPhone($event, _vm.form.user)}},model:{value:(_vm.userPhone),callback:function ($$v) {_vm.userPhone=$$v},expression:"userPhone"}})]}}],null,true)}),_c('div',[_c('InputLabel',{attrs:{"label":_vm.userIsPro ? 'Profile image' : 'Business logo'}}),_c('ImageUploadSingle',{attrs:{"entity":"pro","working":_vm.profileLoading,"modelId":_vm.modelId,"imageUrl":_vm.profileImageUrl,"imageUuid":_vm.profileImageUuid,"error":_vm.uploadingProfileError},on:{"uploaded":_vm.profileImageUpdated}})],1),_c('div',[_c('InputLabel',{attrs:{"label":"Cover image"}}),_c('ImageUploadSingle',{attrs:{"entity":"pro","working":_vm.coverLoading,"modelId":_vm.modelId,"imageUrl":_vm.coverImageUrl,"imageUuid":_vm.coverImageUuid,"error":_vm.uploadingCoverError,"cropStencilComponent":"rectangle-stencil","cropAspectRatio":12/6},on:{"uploaded":_vm.coverImageUpdated}})],1)],1)])]),(_vm.user.userable_type == 'pro')?_c('div',{staticClass:"px-5"},[_c('div',{staticClass:"mb-6"},[_c('span',{staticClass:"divider label-s-500"},[_vm._v("Pro Details")]),_c('div',{staticClass:"grid grid-cols-1 gap-y-6"},[_c('BaseMultiselect',{attrs:{"options":_vm.categories,"label":"Categories","type":"category","initialValues":_vm.userType.categories},on:{"selected":_vm.setProCategories}}),_c('TagMultiselect',{attrs:{"options":_vm.skills,"label":"Assign skills","type":"skill","initialValues":_vm.userType.skills,"optionsLimit":8},on:{"selected":_vm.setProSkills}}),_c('ValidationProvider',{attrs:{"name":"Bio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextAreaInput',{attrs:{"label":"Your Bio","resizable":"resize-y","errors":errors},model:{value:(_vm.form.pro.bio),callback:function ($$v) {_vm.$set(_vm.form.pro, "bio", $$v)},expression:"form.pro.bio"}})]}}],null,true)}),_c('TextAreaInput',{attrs:{"label":"Your Education and Experience","resizable":"resize-y"},model:{value:(_vm.form.pro.education),callback:function ($$v) {_vm.$set(_vm.form.pro, "education", $$v)},expression:"form.pro.education"}}),_c('BaseMultiselect',{attrs:{"options":_vm.$constants.PROS.COUNTRIES,"trackBy":"code","label":"Country","multiple":false,"type":"country","initialValues":_vm.initialCountry},on:{"selected":_vm.setUserCountry}}),_c('ValidationProvider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"State/province","x-padding":"px-5","placeholder":"Florida","errors":errors},model:{value:(_vm.form.user.state),callback:function ($$v) {_vm.$set(_vm.form.user, "state", $$v)},expression:"form.user.state"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"City","x-padding":"px-5","placeholder":"Miami","errors":errors},model:{value:(_vm.form.user.city),callback:function ($$v) {_vm.$set(_vm.form.user, "city", $$v)},expression:"form.user.city"}})]}}],null,true)})],1)]),_c('div',[_c('span',{staticClass:"divider"},[_vm._v("Portfolio")]),_c('div',[_c('InputLabel',{attrs:{"label":"Add portfolio images"}}),_c('ImageUploadMultiple',{attrs:{"images":_vm.portfolio,"working":_vm.portfolioLoading},on:{"uploaded":_vm.portfolioUpdated}})],1)])]):_c('div',{staticClass:"px-5"},[_c('div',{staticClass:"mb-6"},[_c('span',{staticClass:"divider label-s-500"},[_vm._v("Business Details")]),_c('div',{staticClass:"grid grid-cols-1 gap-y-6"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"Business name","x-padding":"px-5","errors":errors},model:{value:(_vm.form.church.name),callback:function ($$v) {_vm.$set(_vm.form.church, "name", $$v)},expression:"form.church.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"Phone","errors":errors,"phoneInput":true},on:{"phone":function($event){return _vm.setPhone($event, _vm.form.church)}},model:{value:(_vm.churchPhone),callback:function ($$v) {_vm.churchPhone=$$v},expression:"churchPhone"}})]}}],null,true)}),_c('BaseMultiselect',{attrs:{"options":_vm.$constants.CHURCHES.COUNTRIES,"trackBy":"code","label":"Country","multiple":false,"type":"country","initialValues":_vm.initialCountry},on:{"selected":_vm.setChurchCountry}}),_c('ValidationProvider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"State/province","x-padding":"px-5","placeholder":"Florida","errors":errors},model:{value:(_vm.form.church.state),callback:function ($$v) {_vm.$set(_vm.form.church, "state", $$v)},expression:"form.church.state"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"City","x-padding":"px-5","placeholder":"Miami","errors":errors},model:{value:(_vm.form.church.city),callback:function ($$v) {_vm.$set(_vm.form.church, "city", $$v)},expression:"form.church.city"}})]}}],null,true)})],1)])]),_c('div',{staticClass:"px-5"},[_c('span',{staticClass:"divider"}),_c('div',{staticClass:"flex space-x-4"},[_c('button',{attrs:{"type":"submit","disabled":invalid}},[_c('ButtonIcon',{attrs:{"loading":_vm.loading,"disabled":invalid,"text":"Update Details","font-weight":"font-normal"}})],1),_c('ButtonIcon',{attrs:{"text":"Cancel","font-weight":"font-normal","background":"bg-transparent","hover-color":"","hover-background":"","color":"text-secondary-main"},on:{"onClick":function($event){return _vm.$router.back()}}})],1)])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }