<template>
  <div>
    <BackButton @onClick="$router.back()" text="Back to settings" font-weight="font-normal" class="mb-6"/>
    <PageTitle title="Payment details" class="mb-5 md:mb-10"/>

    <template v-if="!showCardForm && hasPaymentMethod">
      <div class="bg-white rounded-md p-5 flex mb-5">
        <component :is="`${brandIcon}Icon`" class="mr-3"></component>
        <span class="font-bold">{{`**** **** **** ${paymentMethod.last_four}`}}</span>
      </div>

      <ButtonIcon
        @onClick="showCardForm = true"
        text="Change card"
        font-weight="font-normal"
        background="bg-transparent"
        color="text-secondary-main"
        border="border border-secondary-main"
        hover-background="hover:bg-secondary-main"
        hover-color="hover:text-white"
        fontSize="text-sm"
        x-padding="px-12"
      />
    </template>

    <template v-else>
      <span class="divider label-s-500">{{ hasPaymentMethod ? "Change" : "Add" }} card</span>
      
      <AddCard @cancel="showCardForm = false" :saveBtnText="`${hasPaymentMethod ? 'Update' : 'Save'} card`" />
    </template>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BackButton from "@/components/shared/buttons/BackButton";
import PageTitle from '@/components/shared/labels/PageTitle';
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import MasterCardIcon from '@/components/shared/svg/creditCards/MasterCardIcon';
import VisaIcon from '@/components/shared/svg/creditCards/VisaIcon';
import AmexIcon from '@/components/shared/svg/creditCards/AmexIcon';
import OtherCardIcon from '@/components/shared/svg/creditCards/OtherCardIcon';
import AddCard from '@/components/shared/AddCard';

export default {
  name: 'ChurchPaymentDetails',
  components: {
    BackButton,
    PageTitle,
    ButtonIcon,
    MasterCardIcon,
    VisaIcon,
    AmexIcon,
    OtherCardIcon,
    AddCard,
  },

  data() {
    return {
      showCardForm: false,
    }
  },

  async mounted() {
    await this.fetchPaymentMethod();
  },

  methods: {
    ...mapActions({
      fetchPaymentMethod: "churches/fetchPaymentMethod",
    }),

    async saved() {
      await this.fetchPaymentMethod()
      this.showCardForm = false
    },
  },

  computed: {
    ...mapGetters({
      paymentMethod: 'churches/paymentMethod',
    }),

    hasPaymentMethod() {
      return this.paymentMethod && this.paymentMethod.last_four;
    },

    brandIcon() {
      switch (this.paymentMethod.brand) {
        case 'visa':
          return 'Visa'
        case 'amex':
          return 'Amex'
        case 'mastercard':
          return 'MasterCard'
        default:
          return 'OtherCard'
      }
    },
  },
}
</script>