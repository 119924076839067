<template>
  <div @click="goToJob()" class="flex flex-col bg-white rounded-xxl px-10 py-8 w-full" :class="{'cursor-pointer': clickable}">
    <h2 class="heading-s-700">{{ job.title }}</h2>
    <p class="mb-6">
      <Categories :categories="job.categories"/>
    </p>
    <p class="mb-8 body-m-400">{{ job.description }}</p>
    <div class="flex justify-between items-center">
      <div class="flex flex-wrap">
        <Badge v-for="skill in job.skills" :key="skill.id" :text="skill.name" background="bg-primary-10"
               color="text-auxiliary-blue" class="mr-3 mb-3"/>
      </div>
      <div v-if="showCreatedDate">
          <span class="text-auxiliary-blue label-m-700 mr-4" v-if="showNewLabel">New</span>
          <span class="text-fonts-tertiary label-m-700">{{createdAt}}</span>
        </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import Badge from "@/components/shared/Badge"
import Categories from "@/components/shared/Categories";

export default {
  name: 'JobCard',
  components: { Badge, Categories },
  props: {
    job: Object,
    showCreatedDate: { type: Boolean, default: true },
    clickable: { type: Boolean, default: true },
  },

  methods: {
    goToJob() {
      if (this.clickable) {
        this.$router.push({name: 'Job', params: {jobId: this.job.id}})
      }
    }
  },

  computed: {
    createdAt() {
      moment.updateLocale('en', {
        relativeTime : {
          s  : 'a few seconds',
          d  : '1d',
          mm: "%dmin",
          hh: "%dh",
          dd: "%dd",
          ww: "%dw",
          MM: "%dm",
          yy: "%dy"
        }
      });
      return moment(this.job.created_at).fromNow(true)
    },

    showNewLabel() {
      const today = moment()
      const createdDate = moment(this.job.created_at)
      return today.diff(createdDate, 'weeks') == 0;
    }
  },
}
</script>
