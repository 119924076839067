<template>
  <div class="bg-white rounded-xl py-4 px-6 divide-y">
    <div v-if="loading || !job" class="flex justify-center items-center">
      <loading-icon class="h-2" />
    </div>
    <template v-else>
      <div class="flex justify-between py-4" :class="{ 'flex-col': displayItemsVertically }">
        <p class="body-s-400">Name</p>
        <p class="body-s-700 text-right">{{ job.title }}</p>
      </div>
      <div class="flex justify-between py-4" :class="{ 'flex-col': displayItemsVertically }">
        <p class="body-s-400">{{ job.state == 'bidding' ? 'Business budget' : 'Amount' }}</p>
        <p class="body-s-700 text-right">{{ job.state == 'bidding' ? job.budget : job.job_amount | currency }}</p>
      </div>
      <div class="flex justify-between py-4" :class="{ 'flex-col': displayItemsVertically }">
        <p class="body-s-400">Frequency</p>
        <p class="body-s-700 text-right">{{ job.type == 'one_time' ? 'One-Time Job' : 'Monthly Recurring' }}</p>
      </div>
      <div class="flex justify-between py-4" :class="{ 'flex-col': displayItemsVertically }">
        <p class="body-s-400">Due date</p>
        <p class="body-s-700 text-right">{{ jobDate }}</p>
      </div>
      <div v-if="showStatus" class="flex justify-between py-4" :class="{ 'flex-col': displayItemsVertically }">
        <p class="body-s-400">Status</p>
        <div class="flex">
          <JobStatus v-if="status" :status="status"/>
        </div>
      </div>
      
      <div v-if="canMarkJobAsComplete" class="flex py-4">
        <ButtonIcon @onClick="markJobAsComplete" text="Mark job as complete" class="w-full" fontWeight="font-normal" xPadding="px-4">
          <template #leftIcon>
            <CheckJobIcon class="mr-2"/>
          </template>
        </ButtonIcon>
      </div>

      <div v-if="canApproveJobForPayment" class="flex flex-col py-4">
        <ButtonIcon
          text="Approve job for payment"
          font-weight="font-normal"
          x-padding="px-4"
          :loading="approveJobLoading"
          :disabled="approveJobLoading"
          class="w-full"
          @onClick="approveJobForPayment"
        >
          <template #leftIcon>
            <CheckJobIcon class="mr-2"/>
          </template>
        </ButtonIcon>
        <div @click="requestChanges" class="request-changes">Request additional changes</div>
      </div>

      <div v-if="threadId && userIsPro" class="flex py-4">
        <ButtonIcon
          text="Message Business"
          font-weight="font-medium"
          background="bg-secondary-main"
          color="text-white"
          hover-background="hover:bg-secondary-dark"
          class="w-full"
          @onClick="goToConversation"
        >
          <template #leftIcon>
            <ConversationIcon class="mr-2"/>
          </template>
        </ButtonIcon>
      </div>
    </template>
  </div>
</template>

<script>
import api from '@/api';
import moment from 'moment';
import { mapMutations, mapState } from 'vuex';
import JobStatus from "@/components/shared/JobStatus";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import ConversationIcon from "@/components/shared/svg/ConversationIcon";
import CheckJobIcon from "@/components/shared/svg/CheckJobIcon"

export default {
  name: "JobDetails",
  components: { JobStatus, ButtonIcon, ConversationIcon, CheckJobIcon },
  props: {
    job: { type: Object, default: null },
    loading: { type: Boolean, default: false },
    displayItemsVertically: { type: Boolean, default: false },
    showStatus: { type: Boolean, default: true },
    threadId: { type: Number, required: false },
  },

  data() {
    return {
      complete: false,
      status: null,
      approveJobLoading: false,
    }
  },

  watch: {
    job(job) {
      this.complete = job.complete;
      this.status = job.status;
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),

    userIsPro() {
      return this.user.userable_type == 'pro'
    },

    jobDate() {
      if (this.job.state == 'bidding') {
        return moment.utc(this.job.type == 'one_time' ? this.job.due_date : this.job.hiring_created_at).format("MMM DD, YYYY");
      }
      return moment.utc(this.job.due_date).format("MMM DD, YYYY");
    },

    canMarkJobAsComplete() {
      return !this.complete && this.status == 'active' && 
        this.userIsPro &&
        this.job.type == 'one_time' &&
        this.job.hiring_bid &&
        this.job.hiring_bid.pro.id == this.user.id;
    },

    canApproveJobForPayment() {
      return this.complete && this.status == 'active' && 
        !this.userIsPro &&
        this.job.type == 'one_time';
    },
  },

  mounted() {
    if (this.job) {
      this.complete = this.job.complete;
      this.status = this.job.status;
    }
  },

  methods: {
    ...mapMutations({
      setJob: "jobs/setJob",
      updateItem: "home/updateItem",
      hideModal: 'general/hideModal',
    }),

    goToConversation() {
      this.$router.push({ name: 'Inbox', params: { threadId: this.threadId } })
    },

    async markJobAsComplete() {
      try {
        const { data } = await api.post(`jobs/${this.job.id}/complete`);
        if (data.success) {
          this.complete = true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async requestChanges() {
      try {
        const { data } = await api.post(`jobs/${this.job.id}/incomplete`);
        if (data.success) {
          this.complete = false;
          const threadId = this.job.hiring_bid.thread_id;
          if (threadId) {
            this.$router.push({ name: 'Inbox', params: { threadId, prewrittenMessage: 'Please make the following changes:' } });
            this.hideModal();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async approveJobForPayment() {
      this.approveJobLoading = true;
      try {
        const { data } = await api.post(`jobs/${this.job.id}/approve`);
        if (data.success) {
          this.setJob({ ...this.job, status: 'closed' });
          this.updateItem({ id: this.job.id, data: { status: 'closed' } });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.approveJobLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.request-changes {
  @apply text-secondary-dark label-s-400 cursor-pointer flex items-center justify-center my-4;
}
</style>