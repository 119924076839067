<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M10.6666 2.79993C7.13203 2.79993 4.26665 5.6653 4.26665 9.19993V13.0248L3.5124 13.779C3.20733 14.0841 3.11608 14.5429 3.28118 14.9415C3.44628 15.3401 3.83523 15.5999 4.26665 15.5999H17.0666C17.4981 15.5999 17.887 15.3401 18.0521 14.9415C18.2172 14.5429 18.1259 14.0841 17.8209 13.779L17.0666 13.0248V9.19993C17.0666 5.6653 14.2012 2.79993 10.6666 2.79993Z"
        fill="currentColor"/>
    <path
        d="M10.6667 19.8666C8.89943 19.8666 7.46674 18.434 7.46674 16.6666H13.8667C13.8667 18.434 12.4341 19.8666 10.6667 19.8666Z"
        fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'BellIcon'
}
</script>
