<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="absolute right-7 top-6">
        <button @click.prevent="close" type="button" class="text-white focus:outline-none flex flex-col items-center" >
          <XIcon />
          <span>Esc</span>
        </button>
      </div>
      <div
        class="modal"
        :class="maxWH"
        :style="minWH"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <component :is="component" :props="props" id="modalDescription"></component>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';
import XIcon from "@/components/shared/svg/XIcon";

export default {
  name: "Modal2",
  components: { XIcon },

  data() {
    return {
      component: null,
    };
  },

  watch: {
    modalComponent(componentName) {
      if (!componentName) return;
      this.component = () => import(`@/components/modals/${componentName}`);
    },
    modalVisible(visible) {
      document.body.classList.toggle("overflow-hidden", visible);
    }
  },

  computed: {
    ...mapState({
      modalVisible: state => state.general.modalVisible,
      modalComponent: state => state.general.modalComponent,
      props: state => state.general.modalProps,
      minWH: state => state.general.modalMinWH,
      maxWH: state => state.general.modalMaxWH,
    }),
  },

  created() {
    document.addEventListener('keydown', this.escapeHandler);
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', this.escapeHandler);
    });
  },
  methods: {
    close() {
      this.$emit("close");
    },

    escapeHandler(e) {
      if (e.key === 'Escape' && this.modalVisible) {
        this.close();
      }
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  @apply bg-primary bg-opacity-90 z-50 fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center;
}

.modal {
  @apply bg-white z-50 rounded-xl overflow-y-scroll h-full w-full;
  box-shadow: 40px 72px 96px 0px rgba(157, 149, 140, 0.2);
  box-shadow: 24px 24px 40px 0px rgba(154, 151, 142, 0.15);
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
