<template>
  <div>
    <div class="rounded-lg flex items-center w-full py-1.5 px-3" :class="backgroundColor">
      <component :is="icon"></component>
      <span class="ml-2 capitalize label-xs-500">{{ text }}</span>
    </div>
  </div>
</template>

<script>
import BriefcaseIcon from "@/components/shared/svg/BriefcaseIcon";
import PauseIcon from "@/components/shared/svg/PauseIcon";
import HourglassIcon from "@/components/shared/svg/HourglassIcon";
import JobEndedIcon from "@/components/shared/svg/JobEndedIcon";

export default {
  name: 'JobStatus',
  components: { BriefcaseIcon, PauseIcon, HourglassIcon, JobEndedIcon },
  props: {
    status: String
  },
  computed: {
    text() {
      switch (this.status) {
        case 'active':
          return 'active';
        case 'inactive':
          return 'paused';
        case 'closed':
          return 'ended';
        default:
          return 'bidding';
      }
    },
    icon() {
      switch (this.status) {
        case 'active':
          return 'BriefcaseIcon';
        case 'inactive':
          return 'PauseIcon';
        case 'closed':
          return 'JobEndedIcon';
        default:
          return 'HourglassIcon';
      }
    },
    backgroundColor() {
      switch (this.status) {
        case 'active':
          return 'bg-statuses-green';
        case 'inactive':
          return 'bg-statuses-orange';
        case 'closed':
          return 'bg-statuses-red';
        default:
          return 'bg-statuses-yellow';
      }
    },
  },
}
</script>