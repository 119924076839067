var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"passwordForm",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updatePassword)}}},[_c('div',{staticClass:"password-box-background p-5 rounded-lg my-6 grid grid-cols-1 gap-y-6"},[_c('ValidationProvider',{attrs:{"name":"Current password","rules":"required|min:8","vid":"current_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"Current Password","x-padding":"px-5","type":_vm.showCurrentPassword ? 'text' : 'password',"errors":errors},model:{value:(_vm.form.current_password),callback:function ($$v) {_vm.$set(_vm.form, "current_password", $$v)},expression:"form.current_password"}},[_c('template',{slot:"rightIcon"},[_c('div',{staticClass:"eye-icon",class:_vm.showCurrentPassword ? 'active':'inactive',on:{"click":function($event){$event.preventDefault();_vm.showCurrentPassword = !_vm.showCurrentPassword}}},[_c('EyeIcon',{staticClass:"eye-icon-color"})],1)])],2)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"New password","rules":"required|min:8","vid":"new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"New Password","x-padding":"px-5","type":_vm.showNewPassword ? 'text' : 'password',"errors":errors},model:{value:(_vm.form.new_password),callback:function ($$v) {_vm.$set(_vm.form, "new_password", $$v)},expression:"form.new_password"}},[_c('template',{slot:"rightIcon"},[_c('div',{staticClass:"eye-icon",class:_vm.showNewPassword ? 'active':'inactive',on:{"click":function($event){$event.preventDefault();_vm.showNewPassword = !_vm.showNewPassword}}},[_c('EyeIcon',{staticClass:"eye-icon"})],1)])],2)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Confirm new password","rules":"required|min:8|confirmed:new_password","vid":"new_password_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"Confirm new Password","x-padding":"px-5","type":_vm.showConfirmPassword ? 'text' : 'password',"errors":errors},model:{value:(_vm.form.new_password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "new_password_confirmation", $$v)},expression:"form.new_password_confirmation"}},[_c('template',{slot:"rightIcon"},[_c('div',{staticClass:"eye-icon",class:_vm.showConfirmPassword ? 'active':'inactive',on:{"click":function($event){$event.preventDefault();_vm.showConfirmPassword = !_vm.showConfirmPassword}}},[_c('EyeIcon',{staticClass:"eye-icon-color"})],1)])],2)]}}],null,true)}),(_vm.error)?_c('h4',{staticClass:"text-functional-error text-xs mt-1"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('div',{staticClass:"flex space-x-4"},[_c('button',{attrs:{"type":"submit","disabled":invalid}},[_c('ButtonIcon',{attrs:{"disabled":invalid,"loading":_vm.loading,"text":"Confirm new password","font-weight":"font-normal"}})],1),_c('ButtonIcon',{attrs:{"text":"Cancel","font-weight":"font-normal","background":"bg-transparent","hover-color":"","hover-background":"","color":"text-secondary-main"},on:{"onClick":_vm.cancel}})],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }