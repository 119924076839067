<template>
  <header
    class="w-full bg-primary justify-between items-center flex flex-wrap md:flex-no-wrap py-6 px-10 overflow-hidden relative z-20"
  >
    <div class="flex items-center space-x-4">
      <TalentPondLogo class="hidden md:block"/>
      <button @click="toggleMobileMenu" class="block md:hidden focus:outline-none">
        <MenuIcon/>
      </button>
    </div>
    <!--Links-->
    <div v-if="user.email" class="space-x-10 hidden md:flex text-skyBlue">
      <router-link :to="{name:'Home'}" class="link-item label-s-400">
        <component :is="userIsPro ? 'HomeIcon' : 'TeamIcon'" class="mr-2"/>
        <div class="flex-col relative">
          <span class="mb-6 mt-6">{{userIsPro ? 'Home' : 'Team'}}</span>
          <div v-if="currentRoute == 'Home'" class="bg-secondary-dark rounded-full w-4 h-1 absolute top-9"></div>
        </div>
      </router-link>
      <router-link :to="{name:'Inbox'}" class="link-item transition-ease duration-300 label-s-400">
        <InboxIcon class="mr-2"/>
        <div class="flex-col relative">
          <span class="mb-6 mt-6">Inbox</span>
          <div v-if="currentRoute == 'Inbox'" class="bg-secondary-dark rounded-full w-4 h-1 absolute top-9"></div>
        </div>
      </router-link>

      <router-link v-if="user.userable_type == 'hire_manager'" :to="{name:'Pros'}"
                   class="link-item transition-ease duration-300 label-s-400">
        <ProsIcon class="mr-2"/>
        <div class="flex-col relative">
          <span class="mb-6 mt-6">Pros</span>
          <div v-if="currentRoute == 'Pros'" class="bg-secondary-dark rounded-full w-4 h-1 absolute top-9"></div>
        </div>
      </router-link>
      <router-link v-else :to="{name:'Jobs'}" class="link-item transition-ease duration-300 label-s-400">
        <ProsIcon class="mr-2"/>
        <div class="flex-col relative">
          <span class="mb-6 mt-6">Jobs</span>
          <div v-if="currentRoute == 'Jobs'" class="bg-secondary-dark rounded-full w-4 h-1 absolute top-9"></div>
        </div>
      </router-link>

      <router-link :to="{name:'Reports'}" class="link-item transition-ease duration-300 label-s-400">
        <ReportsIcon class="mr-2"/>
        <div class="flex-col relative">
          <span class="mb-6 mt-6">Reports</span>
          <div v-if="currentRoute == 'Reports'" class="bg-secondary-dark rounded-full w-4 h-1 absolute top-9"></div>
        </div>
      </router-link>
    </div>
    <!--Notifications-->
    <div v-if="user.email" class="flex items-center space-x-3">
      <div class="relative" v-if="!envIsProduction">
        <div class="bg-secondary-main w-1 h-1 right-0 rounded-full absolute"></div>
        <button @click="openNotificationsPopUp">
          <BellIcon class="text-pink-500"/>
        </button>
      </div>
      <!--Settings-->
      <router-link :to="{name:'Settings'}">
        <AvatarImage width="w-14" height="h-14" :image="user.image_url"/>
      </router-link>
    </div>
    <div v-else class="flex items-center space-x-3">
      <div 
        class="label-m-500 text-white cursor-pointer py-3 px-4 mr-4 hover:text-secondary-main"
        @click="$router.push({ name: 'Login'})"
      >Login</div>
      <ButtonIcon text="Sign Up" @onClick="$router.push({ name: 'UserType'})">
        <template #leftIcon> <ArrowIcon class="mr-2" /> </template>
      </ButtonIcon>
    </div>
    <!-- Notification pop up -->
    <NotificationsPopUp v-if="!envIsProduction"/>
  </header>
</template>
<script>
import {mapMutations, mapState} from "vuex";
import MenuIcon from '@/components/shared/svg/MenuIcon';
import TeamIcon from '@/components/shared/svg/TeamIcon';
import HomeIcon from '@/components/shared/svg/HomeIcon';
import InboxIcon from '@/components/shared/svg/InboxIcon';
import ProsIcon from '@/components/shared/svg/ProsIcon';
import ReportsIcon from '@/components/shared/svg/ReportsIcon';
import BellIcon from '@/components/shared/svg/BellIcon';
import AvatarImage from "@/components/shared/AvatarImage";
import TalentPondLogo from "@/components/shared/svg/TalentPondLogo";
import NotificationsPopUp from "@/components/shared/NotificationsPopUp";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import ArrowIcon from "@/components/shared/svg/ArrowIcon";

export default {
  name: 'Header',
  components: {
    NotificationsPopUp,
    TalentPondLogo,
    AvatarImage,
    MenuIcon,
    TeamIcon,
    HomeIcon,
    InboxIcon,
    ProsIcon,
    ReportsIcon,
    BellIcon,
    ButtonIcon,
    ArrowIcon,
  },
  methods: {
    ...mapMutations({
      setShowNotificationsPopUp: 'general/setShowNotificationsPopUp',
      setShowMobileMenu: 'general/setShowMobileMenu'
    }),
    openNotificationsPopUp() {
      this.setShowNotificationsPopUp(!this.showNotificationsPopUp);
    },
    toggleMobileMenu() {
      this.setShowMobileMenu(!this.showMobileMenu)
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      showNotificationsPopUp: state => state.general.showNotificationsPopUp,
      showMobileMenu: state => state.general.showMobileMenu
    }),

    currentRoute() {
      return this.$route.name
    },

    userIsPro() {
      return this.user.userable_type == 'pro'
    },

    envIsProduction() {
      return process.env.NODE_ENV == 'production';
    }
  },
}
</script>

<style scoped>
.link-item {
  @apply flex items-center outline-none hover:text-white;
}

.router-link-exact-active {
  @apply text-white;
}
</style>
