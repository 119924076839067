<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.76283 10.2366C10.1715 10.2366 10.5027 9.90535 10.5027 9.49672C10.5027 9.08809 10.1715 8.75684 9.76283 8.75684C9.35421 8.75684 9.02295 9.08809 9.02295 9.49672C9.02295 9.90535 9.35421 10.2366 9.76283 10.2366Z"
      stroke="currentColor" stroke-width="1.47977" stroke-linecap="round" stroke-linejoin="round"/>
    <path
      d="M9.76283 5.05765C10.1715 5.05765 10.5027 4.72639 10.5027 4.31777C10.5027 3.90914 10.1715 3.57788 9.76283 3.57788C9.35421 3.57788 9.02295 3.90914 9.02295 4.31777C9.02295 4.72639 9.35421 5.05765 9.76283 5.05765Z"
      stroke="currentColor" stroke-width="1.47977" stroke-linecap="round" stroke-linejoin="round"/>
    <path
      d="M9.76283 15.4156C10.1715 15.4156 10.5027 15.0843 10.5027 14.6757C10.5027 14.267 10.1715 13.9358 9.76283 13.9358C9.35421 13.9358 9.02295 14.267 9.02295 14.6757C9.02295 15.0843 9.35421 15.4156 9.76283 15.4156Z"
      stroke="currentColor" stroke-width="1.47977" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "DotsVerticalIcon"
}
</script>

<style scoped>

</style>
