<template>
  <transition @enter="enter">
    <div style="width: 429px" v-if="showNotificationsPopUp" v-on-clickaway="close"
         class="rounded-lg bg-white bg-pink-500 w-full absolute md:right-24 overflow-hidden shadow-xl z-50">
      <!--header-->
      <div class="px-6 py-5 border-b border-cream">
        <h4 class="font-bold">Activity</h4>
      </div>
      <div style="max-height: 500px;" id="notifications" class="overflow-y-auto p-3 h-full">
        <!--Notification item-->
        <div @click="close" v-for="notification in 30" :key="notification" class="notification-item">
          <AvatarImage width="w-16" height="h-16"/>
          <div class="text-sm">
            <p class="mb-1">You were not selected for the Book Keeper Job of the Vineyard Baptist Church</p>
            <span class="text-fonts-tertiary">8 hours ago</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import {mapMutations, mapState} from "vuex";
import {mixin as clickaway} from 'vue-clickaway';
import AvatarImage from "@/components/shared/AvatarImage"
import anime from "animejs";

export default {
  name: 'NotificationsPopUp',
  components: {AvatarImage},
  mixins: [clickaway],
  methods: {
    ...mapMutations({
      setShowNotificationsPopUp: 'general/setShowNotificationsPopUp'
    }),
    close() {
      this.setShowNotificationsPopUp(false);
    },
    enter(el) {
      anime({
        targets: el,
        translateY: [270, 300],
        opacity: [0, 1],
        duration: 800
      })
    }
  },
  computed: {
    ...mapState({
      showNotificationsPopUp: state => state.general.showNotificationsPopUp
    })
  }
}
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
#notifications::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#notifications {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


.notification-item {
  @apply p-3 w-full cursor-pointer hover:bg-primary-10 flex items-center space-x-3 transition-all duration-300 rounded-lg;
}

</style>
