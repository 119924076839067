<template>
  <div :class="active ? 'border-secondary-main' : 'border-transparent'"
       class="card">
    <div class="mb-5">
      <component :is="icon(iconName)" />
    </div>
    <div class="text-center">
      <h4 class="heading-s-700 mb-2">{{ title }}</h4>
      <p class="body-xs-400 text-fonts-tertiary">{{ summary }}</p>
    </div>
  </div>
</template>
<script>
import GraphicDesignerIcon from "@/components/shared/svg/jobTemplates/GraphicDesignerIcon";
import VirtualAssistantIcon from "@/components/shared/svg/jobTemplates/VirtualAssistantIcon";
import SocialMediaManagerIcon from "@/components/shared/svg/jobTemplates/SocialMediaManagerIcon";
import VideoEditorIcon from "@/components/shared/svg/jobTemplates/VideoEditorIcon";
import WebsiteDeveloperIcon from "@/components/shared/svg/jobTemplates/WebsiteDeveloperIcon";
import BookkeeperIcon from "@/components/shared/svg/jobTemplates/BookkeeperIcon";
import OnlineAdsManagerIcon from "@/components/shared/svg/jobTemplates/OnlineAdsManagerIcon";
import ExecutivePastorIcon from "@/components/shared/svg/jobTemplates/ExecutivePastorIcon";
import OtherIcon from "@/components/shared/svg/jobTemplates/OtherIcon";

export default {
  name: 'TemplateCard',
  components: {
    GraphicDesignerIcon,
    VirtualAssistantIcon,
    SocialMediaManagerIcon,
    VideoEditorIcon,
    WebsiteDeveloperIcon,
    BookkeeperIcon,
    OnlineAdsManagerIcon,
    ExecutivePastorIcon,
    OtherIcon,
  },
  props: {
    title: String,
    summary: String,
    iconName: String,
    active: Boolean
  },

  methods: {
    icon(icon) {
      return `${icon}Icon`
    },    
  },
}
</script>

<style scoped>
.card {
  min-height: 242px;
  @apply rounded-xl border-2 bg-white p-5 flex flex-col items-center justify-center cursor-pointer transition-all duration-300 hover:border-secondary-main;
}
</style>
