<template>
  <label class="flex items-center cursor-pointer my-1">
    <input
      type="checkbox"
      class="form-checkbox w-4 h-4 border-gray-400"
      :class="[backgroundColor, borderWidth]"
      name="name"
      :value="value"
      @input="$emit('on-toggle', $event.target.value)"
      :checked="checked ? 'checked' : ''"
    />
    <span class="ml-3" :class="[textColor, fontWeight, hover, fontSize]">{{ title }}</span>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    value: [String, Number, Array],
    title: String,
    name: String,
    checked: Boolean,
    textColor: { type: String, required: false, default: 'text-fonts-primary'},
    fontWeight: { type: String, required: false, default: 'font-light'},
    fontSize: { type: String, required: false, default: 'text-base'},
    hover: { type: String, required: false, default: ''},
    backgroundColor: { type: String, required: false, default: ''},
    borderWidth: { type: String, required: false, default: 'border-2'},
  }
}
</script>