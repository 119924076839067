
import Church from "@/views/church/Church";
import ChurchPaymentDetails from "@/views/church/ChurchPaymentDetails";

const routes = [
  {
    path: '/churches/:churchId',
    name: 'Church',
    component: Church,
    meta: {
      rootClasses: true,
      requiresAuth: true,
    },
  },
  {
    path: '/credit-card-details',
    name: 'ChurchPaymentDetails',
    component: ChurchPaymentDetails,
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes;
