<template>
  <div class="grid grid-cols-1 gap-x-6 gap-y-10">
    <div v-for="a in amount" :key="a">
      <div class="flex flex-col bg-white px-6 pb-10 pt-8 rounded-cards w-full">
        <div class="flex items-center mb-8">
          <VueSkeletonLoader
            type="circle"
            :color="baseColor"
            :wave-color="waveColor"
            :width="80"
            :height="80"
            animation="fade"
          />
          <div class="flex flex-col ml-8">
            <VueSkeletonLoader
              :color="baseColor"
              :wave-color="waveColor"
              :rounded="true"
              radius="5"
              :width="185"
              :height="17"
              animation="fade"
              class="mb-3"
            />
            <VueSkeletonLoader
              :color="baseColor"
              :wave-color="waveColor"
              :rounded="true"
              radius="5"
              :width="104"
              :height="17"
              animation="fade"
            />
          </div>
        </div>
        <VueSkeletonLoader
          :color="baseColor"
          :wave-color="waveColor"
          :rounded="true"
          radius="5"
          :width="350"
          :height="17"
          animation="fade"
          class="mb-3"
        />
        <VueSkeletonLoader
          :color="baseColor"
          :wave-color="waveColor"
          :rounded="true"
          radius="5"
          :width="450"
          :height="17"
          animation="fade"
          class="mb-8"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  name: 'ExploreSkeleton',
  components: { VueSkeletonLoader },
  data() {
    return {
      amount: 4,
      baseColor: "#E9DBCE",
      waveColor: "#413D51",
    };
  },
};
</script>
