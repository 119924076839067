<template>
  <div class="grid grid-cols-1 gap-y-8">
    <div class="flex justify-between mb-3 items-center" v-if="currentRoute == 'Pros'">
      <span class="label-s-500">Favorites</span>
      <Toggle label-classes="text-sm" color="bg-green-500" :active="showFavorited" @update="filterByFavorited"/>
    </div>

    <!--Categories-->
    <Accordion label="Categories">
      <template slot="content">
        <Category v-for="(category, index) in categories" :key="index" :name="category.name" :id="category.id" amount="141" @onToggle="toggleDebounced"/>
      </template>
    </Accordion>

    <!--Skills-->
    <Accordion label="Skills">
      <template slot="content">
        <BaseMultiselect :options="skills" type="skill" @selected="filterBySkills" :initialValues="initialSkills" :optionsLimit="8"/>
      </template>
    </Accordion>

    <!-- Button-->
    <ButtonIcon text="Reset filters" @onClick="resetFilters">
      <template slot="rightIcon">
        <SyncIcon class="ml-6"/>
      </template>
    </ButtonIcon>
  </div>
</template>
<script>
import _ from 'lodash';
import {mapActions, mapMutations, mapState} from "vuex";
import Accordion from "@/components/shared/Accordion"
import Category from "@/components/pros/filters/partials/Category";
import BaseMultiselect from "@/components/shared/inputs/BaseMultiselect";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import SyncIcon from "@/components/shared/svg/SyncIcon";
import Toggle from "@/components/shared/Toggle";

export default {
  name: 'Filters',
  components: { SyncIcon, ButtonIcon, Category, Accordion, Toggle, BaseMultiselect },

  mounted() {
    this.fetchCategories()
    this.fetchSkills()
  },
  
  methods: {
    ...mapActions({
      fetchCategories: 'categories/fetch',
      fetchSkills: 'skills/fetch',
    }),

    ...mapMutations({
      toggleFavorited: 'filters/toggleFavorited',
      clearFilters: 'filters/clearFilters',
      setSkills2: 'filters/setSkills2',
    }),

    toggleDebounced: _.debounce(function () { this.toggleFilter(); }, 1000),

    toggleFilter() {
      this.$emit('fetch')
    },

    filterByFavorited() {
      this.toggleFavorited()
      this.toggleDebounced();
    },

    filterBySkills(selectedSkills) {
      this.setSkills2(selectedSkills)
      this.toggleDebounced();
    },

    resetFilters() {
      this.clearFilters();
      this.toggleDebounced();
    }
  },

  computed: {
    ...mapState({
      categories: state => state.categories.items,
      skills: state => state.skills.items,
      showFavorited: state => state.filters.is_favorited,
      initialSkills: state => state.filters.skills,
    }),

    currentRoute() {
      return this.$route.name
    },
  },
}
</script>
