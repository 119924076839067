import api from '@/api'

const bids = {
  namespaced: true,

  state: {
    current: null,
    bidStep: 1,
    form: {},
    items: []
  },

  actions: {
    create({ rootState }, payload) {
      return api.post(`/jobs/${rootState.jobs.current.id}/bids`, payload)
        .then(({ data }) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error.response)
        })
    },

    update({ state }, payload) {
      return api.put(`/jobs/${state.current.job_id}/bids/${state.current.id}/update`, payload)
        .then(({ data }) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error.response)
        })
    },

    fetch({ rootState, commit }, payload) {
      const jobId = payload.jobId ?  payload.jobId : rootState.jobs.current.id;
      let url = `/jobs/${jobId}/bids`;
      if (payload.includes) {
        url += `?include=${payload.includes}`
      }
      return api.get(url)
        .then(({ data }) => {
          commit('setBids', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchBid({ commit }, payload) {
      let url = `/bids/${payload.bidId}`;

      if (payload.includes) {
        url += `?include=${payload.includes}`
      }
      return api.get(url)
        .then(({ data }) => {
          commit('setBid', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    accept({ rootState }, payload) {
      return api.put(`/jobs/${rootState.jobs.current.id}/bids/${payload.bidId}/accept`)
        .then(({ data }) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    decline({ rootState }, payload) {
      return api.put(`/jobs/${rootState.jobs.current.id}/bids/${payload.bidId}/decline`)
        .then(({ data }) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
  },

  mutations: {
    increaseBidStep(state) {
      state.bidStep++;
    },

    resetBidSteps(state) {
      state.bidStep = 1;
      state.form = {};
    },

    setBids(state, data) {
      state.items = data
    },

    setBid(state, data) {
      state.current = data;
    },

    setForm(state, form) {
      state.form = form;
    }
  },

}

export default bids
