<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="16"
      height="17"
      rx="3"
      stroke="#2C5DE5"
      stroke-width="2"
    />
  </svg>
</template>
<script>
export default {
  name: "TasksCheckBoxIcon",
};
</script>
