<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6252_73719)">
      <path
        opacity="0.2"
        d="M7.5 21.5062V10.5C7.5 10.1022 7.65804 9.72064 7.93934 9.43934C8.22064 9.15804 8.60218 9 9 9H39C39.3978 9 39.7794 9.15804 40.0607 9.43934C40.342 9.72064 40.5 10.1022 40.5 10.5V21.5062C40.5 37.2562 27.1313 42.4688 24.4688 43.35C24.1663 43.462 23.8337 43.462 23.5312 43.35C20.8687 42.4688 7.5 37.2562 7.5 21.5062Z"
        fill="#3144D3"
      />
      <path
        d="M7.5 21.5062V10.5C7.5 10.1022 7.65804 9.72064 7.93934 9.43934C8.22064 9.15804 8.60218 9 9 9H39C39.3978 9 39.7794 9.15804 40.0607 9.43934C40.342 9.72064 40.5 10.1022 40.5 10.5V21.5062C40.5 37.2562 27.1312 42.4687 24.4688 43.35C24.1663 43.462 23.8337 43.462 23.5312 43.35C20.8688 42.4687 7.5 37.2562 7.5 21.5062Z"
        stroke="#3144D3"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.25 19.5L21.2438 30L15.75 24.75"
        stroke="#3144D3"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6252_73719">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "GuaranteeIcon",
};
</script>

<style scoped></style>
