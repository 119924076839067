<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1707 5.05762L9.50371 10.7246L6.67096 7.89112"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.9462 10.1295V13.8C15.9462 14.67 15.2412 15.375 14.3712 15.375H4.77123C3.90123 15.375 3.19623 14.67 3.19623 13.8V4.2C3.19623 3.33 3.90123 2.625 4.77123 2.625H9.57123"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "CheckJobIcon",
};
</script>

<style scoped></style>
