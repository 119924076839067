<template>
  <div>
    <div class="w-full rounded-xxl p-5 bg-white" v-if="images.length < 6">
      <EmptyImageUpload @change="uploadImage($event)" :uploading="uploading" :uploadingError="uploadingError"/>
    </div>
    <span v-else class="w-full">You can upload up to 6 images</span>
    <div class="grid grid-cols-3 gap-x-3 mt-4">
      <div v-if="working" class="flex items-center justify-center col-span-3">
        <loading-icon class="h-10 w-10"/>
      </div>
      <div v-else v-for="(image, index) in images" :key="`${name}-${index}`" class="relative group">
        <Modal class="w-full" :closeButtonOutside="true">
          <img :src="image" :alt="nameStartCase" class="w-full object-contain rounded-xl">
          <template #content>
            <div class="bg-white p-1">
              <img :src="image" style="max-height: 80vh; max-width: 80vw" class="object-cover">
            </div>
          </template>
        </Modal>
        <div class="opacity-0 group-hover:opacity-100 absolute z-10 top-0 left-0 flex items-center justify-center transition duration-150 ease-in-out">
          <AlertDialog @confirm="deleteMedia(image, $event)" confirm="Delete" type="button" class="p-1 bg-red-400 bg-opacity-75 rounded-lg mt-2 ml-2 text-white hover:bg-opacity-100">
            <TrashIcon/>

            <template #title>
              Delete {{name}}
            </template>

            <template #content>
              Are you sure you want to delete this {{name}}?
            </template>
          </AlertDialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { startCase } from 'lodash'
  import api from '@/api'
  import AlertDialog from '@/components/shared/AlertDialog'
  import EmptyImageUpload from '@/components/shared/EmptyImageUpload'
  import Modal from '@/components/shared/Modal'
  import TrashIcon from '@/components/shared/svg/TrashIcon'

  export default {
    name: 'ImageUploadMultiple',
    props: {
      images: [Object, Array],
      modelId: Number,
      working: Boolean,
      name: { type:String, default: 'image' },
    },

    components: {
      AlertDialog,
      Modal,
      EmptyImageUpload,
      TrashIcon
    },

    data() {
      return {
        uploading: false,
        uploadingError: false,
      }
    },

    methods: {
      uploadImage(e) {
        if (e.target.files.length == 0 ) {
          return
        }

        this.uploading = true
        this.uploadingError = false

        let uploadForm = new FormData()

        uploadForm.append('image', e.target.files[0])

        api.post('/uploads', uploadForm)
          .then(response => {
            this.$emit('uploaded', response.data.uploads[0])
          })
          .catch(() => {
              this.uploadingError = true
          })
          .finally(() => {
              this.uploading = false
              e.target.value = null
          })
      },

      deleteMedia(image, callback) {
        callback.call();
        document.body.classList.toggle('overflow-hidden', false)
        this.$emit('uploaded', image)
      }
    },

    computed: {
      nameStartCase() {
        return startCase(this.name)
      }
    },
  }
</script>