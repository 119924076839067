import api from '@/api'

const users = {
  namespaced: true,

  state: {
    jobs: [],
    bids: [],
  },

  actions: {
    update({ commit, rootState }, payload) {
      return api.put(`/users/${rootState.auth.user.id}`, payload)
        .then(({ data }) => {
          commit('auth/setUser', data.data, { root: true })
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    changePassword({ rootState }, payload) {
      return api.put(`/users/${rootState.auth.user.id}/change-password`, payload)
        .then(({ data }) => {
            return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error.response)
        })
    },

    fetchJobs({ commit, rootState }, status = null) {
      const params = new URLSearchParams();
      if (status) {
        params.append('status', status);
      }

      return api.get(`/users/${rootState.auth.user.id}/jobs`, {params})
        .then(({ data }) => {
          commit('setUserJobs', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchBids({ commit, rootState }, status = null) {
      const params = new URLSearchParams();
      if(status) {
        params.append('status',status);
      }
      return api.get(`/users/${rootState.auth.user.id}/bids`, {params})
      .then(({ data }) => {
        commit('setUserBids', data.data)
        return Promise.resolve(data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
    },

    deleteMedia({ rootState }, payload) {
      return api.delete(`/users/${rootState.auth.user.id}/media/${payload.uuid}`)
        .then(() => {
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
  },

  mutations: {
    setUserJobs(state, data) {
      state.jobs = data;
    },
    setUserBids(state, data) {
      state.bids = data;
    }
  },

}

export default users
