<template>
  <div>
    <BackButton @onClick="$router.back()" text="Back to settings" font-weight="font-normal"
                class="mb-6"/>
    <PageTitle title="Privacy Policy" class="mb-8"/>
    <div class="rounded-cards bg-white p-6 lg:p-10 grid grid-cols-1 gap-y-10">
      <div>
        <h4 class="font-semibold mb-4">Lorem ipsum dolor sit amet</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam enim nec et dictumst mi orci sodales morbi
          in.
          Tincidunt platea ultrices libero mattis nisl nam quisque. Felis pretium eu vel, vitae posuere volutpat. Auctor
          egestas in integer sit id in imperdiet quis non. Dignissim quis leo mi ac odio malesuada tristique neque,
          morbi.
          Vestibulum eleifend in in metus, mauris orci mi scelerisque tortor. Risus mauris malesuada morbi adipiscing.
          Nunc id feugiat ut adipiscing phasellus nunc, ullamcorper. Vestibulum elementum etiam netus aliquam lobortis
          magna integer vitae. Vivamus viverra est facilisis diam. Purus tellus et tellus, faucibus felis enim, eget
          auctor. Venenatis, ornare consectetur ullamcorper dignissim augue lacus diam proin venenatis. Urna, ut
          venenatis
          nulla mauris ut turpis.
          Quam auctor adipiscing vitae nec. Odio tellus nullam vel auctor non in tincidunt. Eget ultrices dolor vel elit
          fermentum nulla arcu, nunc turpis. Lobortis viverra egestas in at tellus, senectus mauris, varius. Sed proin
          pulvinar leo egestas sed feugiat. Tempor mauris justo tincidunt gravida venenatis adipiscing nisl vulputate.
          Dis
          integer aenean libero, nec. In mi quam sit aliquam adipiscing egestas. Tincidunt cursus erat blandit eget
          cursus
          amet imperdiet.
          Pellentesque scelerisque lectus neque porttitor nullam odio diam porttitor rhoncus. At odio euismod ornare sit
          risus. Netus in tempor eleifend urna, at tincidunt mauris. Ut vulputate nullam potenti dolor massa. Habitasse
          sit eu molestie amet eu, vitae sodales. Dolor sed venenatis vitae at. Sapien scelerisque sagittis vehicula
          tristique id. Erat viverra enim, in aliquet nunc et amet, risus rutrum. Egestas augue velit at morbi mi sit.
          Cursus vel mattis pellentesque tincidunt consectetur nisi lorem. Turpis tincidunt pellentesque in ornare
          suspendisse integer eu. Pellentesque commodo maecenas porttitor volutpat consequat mattis tempor. Lorem
          suscipit
          a mauris elementum duis nisl, eget sed amet.
          Elit ultrices ac sed vehicula ullamcorper. Enim augue suspendisse malesuada sed donec sed aenean dignissim.
          Ornare nunc augue in integer. Arcu lectus ut id ipsum vel. Viverra libero fermentum dignissim arcu in varius.
          Ipsum ultricies et enim, accumsan, sollicitudin. Consequat scelerisque in vel viverra ultricies lectus
          consequat
          libero mauris. In facilisis cras turpis aliquam amet mi, ornare neque. Cras felis, faucibus commodo arcu dui.
          A
          dolor nisl mauris id lacus, in a nibh dictum. Feugiat ultrices sollicitudin magna turpis curabitur. Erat
          convallis volutpat sagittis volutpat. Amet ut laoreet est erat elit leo.
          Massa aliquam non mauris, in. Sed ipsum volutpat dignissim dictum amet amet. Nec in ultricies nunc eu. At vel
          id
          vulputate penatibus. Sit in parturient nam elit gravida. Nunc aliquam eget massa mauris, nisl. Cum blandit
          congue scelerisque venenatis, arcu donec lacus.
          Consectetur blandit sed in ut turpis ultricies interdum turpis est. Quisque euismod aliquam habitant
          elementum.
          Ridiculus sed varius tellus facilisis eget diam phasellus mauris. Quis ipsum nunc in cursus. Tortor, eleifend
          volutpat fermentum enim aliquet pellentesque.</p>
      </div>
      <div>
        <h4 class="font-semibold mb-4">Lorem ipsum dolor sit amet</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam enim nec et dictumst mi orci sodales morbi
          in. Tincidunt platea ultrices libero mattis nisl nam quisque. Felis pretium eu vel, vitae posuere volutpat.
          Auctor egestas in integer sit id in imperdiet quis non. Dignissim quis leo mi ac odio malesuada tristique
          neque, morbi. Vestibulum eleifend in in metus, mauris orci mi scelerisque tortor. Risus mauris malesuada morbi
          adipiscing. Nunc id feugiat ut adipiscing phasellus nunc, ullamcorper. Vestibulum elementum etiam netus
          aliquam lobortis magna integer vitae. Vivamus viverra est facilisis diam. Purus tellus et tellus, faucibus
          felis enim, eget auctor. Venenatis, ornare consectetur ullamcorper dignissim augue lacus diam proin venenatis.
          Urna, ut venenatis nulla mauris ut turpis.
          Quam auctor adipiscing vitae nec. Odio tellus nullam vel auctor non in tincidunt. Eget ultrices dolor vel elit
          fermentum nulla arcu, nunc turpis. Lobortis viverra egestas in at tellus, senectus mauris, varius. Sed proin
          pulvinar leo egestas sed feugiat. Tempor mauris justo tincidunt gravida venenatis adipiscing nisl vulputate.
          Dis integer aenean libero, nec. In mi quam sit aliquam adipiscing egestas. Tincidunt cursus erat blandit eget
          cursus amet imperdiet.</p>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/shared/buttons/BackButton";
import PageTitle from "@/components/shared/labels/PageTitle";

export default {
  name: "PrivacyPolicy",
  components: {PageTitle, BackButton}
}
</script>

<style scoped>

</style>
