var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{staticClass:"flex justify-center w-full",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"grid grid-cols-1 gap-y-10 w-full lg:w-6/12",on:{"submit":function($event){$event.preventDefault();return _vm.createJob($event)}}},[_c('ValidationProvider',{attrs:{"name":"Job title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"Give your job a title","x-padding":"px-6","errors":errors},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,true)}),_c('div',[_c('InputLabel',{attrs:{"label":"Select your job type"}}),_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-y-0 md:gap-x-4"},_vm._l((_vm.jobTypes),function(type,index){return _c('div',{key:index,staticClass:"card",class:[type.id == _vm.form.type ? 'active-option' : 'inactive-option'],on:{"click":function($event){return _vm.setType(type.id)}}},[_c(type.icon,{tag:"component",staticClass:"mr-5"}),_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"block text-auxiliary-blue body-m-700"},[_vm._v(_vm._s(type.title))]),_c('p',{staticClass:"body-xs-400"},[_vm._v(_vm._s(type.subtitle))])])],1)}),0)],1),_c('ValidationProvider',{attrs:{"name":"Budget","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"What is your budget for the job?","x-padding":"px-6","placeholder":"100","currencyInput":true,"errors":errors},scopedSlots:_vm._u([{key:"leftIcon",fn:function(){return [_c('DollarIcon',{staticClass:"text-primary"})]},proxy:true}],null,true),model:{value:(_vm.form.budget),callback:function ($$v) {_vm.$set(_vm.form, "budget", $$v)},expression:"form.budget"}})]}}],null,true)}),(_vm.form.type == 'one_time')?_c('ValidationProvider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"type":"date","label":"When does this one-time job need to be completed?","x-padding":"px-6","placeholder":"05/30/2022","errors":errors},scopedSlots:_vm._u([{key:"leftIcon",fn:function(){return [_c('CalendarIcon',{staticClass:"text-primary mr-1.5"})]},proxy:true}],null,true),model:{value:(_vm.form.due_date),callback:function ($$v) {_vm.$set(_vm.form, "due_date", $$v)},expression:"form.due_date"}})]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextAreaInput',{attrs:{"label":"What do you need help with? Include as much detail as possible","placeholder":"Add your details here...","rows":"5","errors":errors,"deleteOnEnter":false},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})]}}],null,true)}),_c('ValidationProvider',{ref:"listInput",attrs:{"name":"Task","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tasks),expression:"form.tasks"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.form.tasks)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "tasks", $event.target.value)}}}),_c('ListInput',{ref:"listInput",attrs:{"label":"Add tasks one at a time and press enter","placeholder":"Type and press enter to add tasks","errors":errors,"initialValues":_vm.initialTasks},on:{"itemsUpdated":_vm.setTasks}})]}}],null,true)}),_c('ButtonIcon',{attrs:{"text":"Confirm and Continue","disabled":invalid,"loading":_vm.loading},on:{"onClick":_vm.createJob}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }