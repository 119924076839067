<template>
  <div v-if="!loading">
    <CardDetails
      v-if="stripe"
      class="mb-8"
      :stripe="stripe"
      :error="error"
      @card="setCard"
      @zipCode="setZipCode"
      @nameOnCard="setNameOnCard"
      @canCharge="setCanCharge"
    />

    <div class="flex space-x-3">
      <ButtonIcon
        @onClick="cardSubmitted"
        :loading="saving"
        :disabled="!canSubmit || saving"
        :text="saveBtnText"
        fontWeight="font-normal"
        fontSize="text-sm"
        x-padding="px-12"
        :class="saveBtnClasses"
      />

      <ButtonIcon
        v-if="withCancelButton && hasPaymentMethod"
        @onClick="$emit('cancel')"
        text="Cancel"
        font-weight="font-normal"
        background="bg-transparent"
        color="text-secondary-main"
        hover-background="hover:bg-secondary-main"
        hover-color="hover:text-white"
        fontSize="text-sm"
        x-padding="px-12"
      />
    </div>
  </div>
  <div v-else class="flex justify-center items-center h-32">
    <loading-icon class="h-3" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import CardDetails from '@/components/church/CardDetails';

export default {
  name: 'AddCard',
  components: {
    ButtonIcon,
    CardDetails,
  },

  props: {
    saveBtnClasses: { type: String, default: ''},
    saveBtnText: { type: String, default: 'Save'},
    withCancelButton: { type: Boolean, default: true },
  },

  data() {
    return {
      loading: true,
      saving: false,
      canSubmit: false,
      error: null,
      stripe: null,
      card: null,
      zipCode: null,
    }
  },

  async mounted() {
    await this.getClientSecret();
    // eslint-disable-next-line no-undef
    this.stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getClientSecret: "churches/getClientSecret",
      savePaymentMethodId: "churches/savePaymentMethodId",
    }),

     ...mapMutations({
      updateChurchCapabilityToCreateJobs: "auth/updateChurchCapabilityToCreateJobs",
    }),

    setCard(card) {
      this.card = card;
    },

    setZipCode(zipCode) {
      this.zipCode = zipCode;
    },

    setNameOnCard(nameOnCard) {
      this.nameOnCard = nameOnCard;
    },

    setCanCharge(canCharge) {
      this.canSubmit = canCharge;
    },

    async cardSubmitted() {
      this.saving = true;
      const result = await this.stripe.createToken(
        this.card,
        this.extraDetails
      );
      this.token = result.token;
      if (result.error) {
        console.error(result.error);
        this.error = result.error.message;
        this.saving = true;
        return;
      }

      const { setupIntent, error } = await this.stripe.confirmCardSetup(
        this.clientSecret,
        {
          payment_method: {
            card: this.card,
            billing_details: {
              name: this.nameOnCard,
              address: {
                postal_code: this.zipCode,
              },
            },
          },
        }
      );

      if (error) {
        console.error(error.message);
        this.error = error.message;
      } else if (setupIntent.status === "succeeded") {
        await this.handleCreditCardSaveSuccess(setupIntent);
      }

      this.saving = false;
    },

    async handleCreditCardSaveSuccess(setupIntent) {
      try {
        await this.savePaymentMethodId({ payment_method_id: setupIntent.payment_method });
        this.updateChurchCapabilityToCreateJobs();
        this.$emit('saved');
      } catch (error) {
        this.error = error.message;
      }
    },
  },

  computed: {
    ...mapGetters({
      paymentMethod: 'churches/paymentMethod',
    }),

    ...mapState({
      clientSecret: (state) => state.churches.clientSecret,
    }),

    hasPaymentMethod() {
      return this.paymentMethod && this.paymentMethod.last_four;
    },

    extraDetails() {
      return {
        name: this.nameOnCard,
        address_zip: this.zipCode,
      };
    },
  },
}
</script>