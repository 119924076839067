<template>
  <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" stroke="#D9D9D9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7503 15.8582H8.69056L7.146 9.79235C7.07269 9.51332 6.91703 9.26664 6.68806 9.15038C6.11664 8.85821 5.48696 8.62568 4.80005 8.50841V8.27487H8.11813C8.57607 8.27487 8.91953 8.62568 8.97677 9.0331L9.77817 13.4086L11.8369 8.27487H13.8394L10.7503 15.8582ZM14.9843 15.8582H13.039L14.6408 8.27487H16.5861L14.9843 15.8582ZM19.1028 10.3757C19.16 9.96725 19.5035 9.73372 19.9042 9.73372C20.5338 9.67508 21.2197 9.79235 21.7922 10.0835L22.1356 8.45079C21.5632 8.21725 20.9335 8.09998 20.3621 8.09998C18.4741 8.09998 17.1003 9.15038 17.1003 10.6082C17.1003 11.7173 18.0734 12.2996 18.7603 12.6504C19.5035 13.0002 19.7897 13.2337 19.7324 13.5835C19.7324 14.1082 19.16 14.3418 18.5886 14.3418C17.9017 14.3418 17.2147 14.1669 16.5861 13.8747L16.2426 15.5084C16.9295 15.7996 17.6727 15.9169 18.3596 15.9169C20.4766 15.9745 21.7922 14.9251 21.7922 13.35C21.7922 11.3664 19.1028 11.2502 19.1028 10.3757ZM28.6 15.8582L27.0555 8.27487H25.3965C25.053 8.27487 24.7095 8.50841 24.5951 8.85821L21.7349 15.8582H23.7374L24.1371 14.7502H26.5976L26.8265 15.8582H28.6ZM25.6827 10.3171L26.2541 13.1751H24.6523L25.6827 10.3171Z" fill="#172B85"/>
  </svg>
</template>

<script>
export default {
  name: "VisaIcon"
}
</script>
