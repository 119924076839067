<template>
  <Modal ref="modal" :class="modalClasses">
    <slot />

    <template v-slot:content="{ close, classes }">
      <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full" :class="classes" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="bg-white px-4 pb-5 sm:pb-6 sm:px-6" style="min-width: 500px">
          <div class="mt-3 text-center sm:mt-0 sm:text-left">
            <h3 class="text-xl leading-6" id="modal-headline">
              <slot name="title">Title</slot>
            </h3>
          </div>
        </div>
        <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3">
            <template v-if="! loading">
              <ButtonIcon
                @onClick="confirmAction(close)"
                :text="confirm"
                class="w-full"
                :background="`bg-${confirmColor}`"
                :border="`border border-${confirmColor}`"
                hover-background="hover:bg-white"
                :hover-color="`hover:text-${confirmColor}`"
                y-padding="py-2.5"
                font-weight="font-normal"
                font-size="text-sm"
                :disabled="disabled"
              />
            </template>
            <template v-else>
              <template v-if="confirmDelay > 0">
                <span class="flex items-center justify-between w-full">
                  <button v-if="confirmCounter > 0" @click="cancelAction()" type="button" :class="`bg-${confirmColor}`" class="inline-flex justify-center w-10 rounded-l-md border border-transparent px-2 py-2 opacity-80 text-base leading-6 font-medium text-white shadow-sm hover:opacity-100 focus:outline-none focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    <XIcon/>
                  </button>
                  <span v-if="confirmCounter > 0" :class="`bg-${confirmColor}`" class="flex-1 inline-flex justify-center rounded-r-md border border-transparent px-2 py-2 bg-opacity-50 text-base leading-6 font-medium text-white shadow-sm  transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    {{ confirming }} in ... {{ confirmCounter }}
                  </span>
                  <span v-else :class="`bg-${confirmColor}`" class="flex-1 inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-opacity-50 text-base leading-6 font-medium text-white shadow-sm  transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    {{ confirming }}...
                  </span>
                </span>
              </template>
              <template v-else>
                <button type="button" :class="`bg-${confirmColor}`" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 opacity-50 text-base leading-6 font-medium text-white shadow-sm hover:opacity-100 focus:outline-none focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5" disabled>
                {{ confirm }}
              </button>
              </template>
            </template>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0">
            <ButtonIcon
              @onClick="closeAlert(close)"
              :text="cancel"
              class="w-full"
              background="bg-transparent"
              color="text-secondary-main"
              border="border border-secondary-main"
              hover-background="hover:bg-secondary-dark hover:bg-opacity-10"
              hover-color="hover:text-secondary-main"
              y-padding="py-2.5"
              font-weight="font-normal"
              font-size="text-sm"
            />
          </span>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/shared/Modal'
  import ButtonIcon from "@/components/shared/buttons/ButtonIcon"
  import XIcon from "@/components/shared/svg/XIcon";

  export default {
    props: {
      danger: {
        type: Boolean,
        default: false,
      },

      confirm: {
        type: String,
        default: 'Confirm'
      },

      confirming: {
        type: String,
        default: 'Confirming',
      },

      confirmColor: {
        type: String,
        default: 'functional-error',
      },

      cancel: {
        type: String,
        default: 'Cancel'
      },

      confirmDelay: {
        type: Number,
        default: 0, // seconds
      },

      modalClasses: {
        type: String,
        default: '',
      },

      disabled: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        loading: false,
        confirmCounter: this.confirmDelay,
        interval: null,
      }
    },

    components: { Modal, ButtonIcon, XIcon },

    methods: {
      confirmAction(callback) {
        this.loading = true

        if (this.confirmDelay == 0) {
          this.$emit('confirm', callback)
          return
        }

        this.interval = setInterval(() => {
          this.confirmCounter--

          if (this.confirmCounter <= 0) {
            this.$emit('confirm', callback)
            clearInterval(this.interval)
          }
        }, 1000)
      },

      cancelAction() {
        clearInterval(this.interval)
        this.confirmCounter = this.confirmDelay
        this.loading = false
      },

      closeAlert(callback) {
        this.cancelAction()
        this.$emit('cancel')
        callback.call()
      }
    },
  }
</script>
