<template>
  <div class="relative">
    <InputLabel v-if="label" :label="label" :color="labelColor"/>
    
    <div v-if="phoneInput" :class="[borderRadius, 'p-2', background, width, {'border-functional-error border-1.5': phoneNumberError}]">
      <vue-tel-input
        v-model="displayValue"
        mode="international"
        :validCharactersOnly="true"
        defaultCountry="US"
        :styleClasses="[background, fontWeight, 'tel-input']"
        @input="validatePhoneNumber"
      ></vue-tel-input>
    </div>

    <div v-else :class="[background, borderRadius, border, xPadding, yPadding, fontSize, fontWeight, {'border-functional-error border-1.5': errors.length}]" class="flex justify-center items-center">
      <slot name="leftIcon"></slot>
      <input
        :style="placeholderColor ? {'--placeholder-color': placeholderColor} : {}"
        v-model="displayValue"
        class="bg-transparent outline-none text-input"
        :class="[width, background, color, fontWeight]"
        :placeholder="placeholder"
        :type="type"
        :disabled="disabled"
        ref="textInput"
        @blur="handleBlur"
        @focus="handleFocus"
        @input="handleChange($event.target.value)"
        @keydown.enter.prevent="enterPressed($event.target.value)"
        :min="min"
        :max="max"
      />
      <slot name="rightIcon"></slot>
    </div>
    <h4 class="text-functional-error text-xs mt-1" v-if="errors.length">{{ errors[0] }}</h4>
    <h4 class="text-functional-error text-xs mt-1" v-else-if="phoneNumberError">Invalid phone number</h4>
  </div>
</template>
<script>
import InputLabel from "@/components/shared/labels/InputLabel"

export default {
  name: 'TextInput',

  components: {InputLabel},

  props: {
    clearAfterSubmit : { type: Boolean , required: false, default: false},
    value : { type: [String, Number] , required: false, default: ''},
    type : { type: String , required: false, default: 'text'},
    placeholder: { type: String, default: '' },
    placeholderColor: { type: String, default: '#C0B9B3' },
    width: { type: String, default: 'w-full' },
    background: { type: String, default: 'bg-white' },
    borderRadius: { type: String, default: 'rounded-xl' },
    xPadding: { type: String, default: 'px-12' },
    yPadding: { type: String, default: 'py-3' },
    border: { type: String, default: 'border-transparent' },
    color: { type: String, default: '' },
    label: { type: String, default: '' },
    labelColor: {type: String, default: 'text-black'},
    errors : { type: Array , required: false, default: () => []},
    currencyInput : { type: Boolean , required: false, default: false},
    phoneInput : { type: Boolean , required: false, default: false},
    disabled: { type: Boolean, required: false, default: false },
    fontSize: { type: String, required: false, default: 'text-base'},
    fontWeight: { type: String, required: false, default: 'font-normal'},
    min: { type: Number, required: false, default: 0},
    max: { type: Number, required: false, default: 999999},
  },

  data() {
    return {
      isInputActive: false,
      phoneNumberError: false,
    }
  },

  methods: {
    enterPressed(newValue) {
      this.$emit('enterPressed', newValue)
      if (this.clearAfterSubmit) {
        this.$refs.textInput.value = ''
      }
    },

    handleFocus() {
      this.isInputActive = true;
      this.$emit('onFocus');
    },

    handleBlur() {
      this.isInputActive = false;
      this.$emit('onBlur')
    },

    handleChange(text) {
      this.$emit('onChange', text);
    },

    validatePhoneNumber(number, phoneObject) {
      if (phoneObject.valid || typeof phoneObject.valid == "undefined") {
        this.phoneNumberError = false;
      } else {
        this.phoneNumberError = true;
      }
      this.$emit('phone', phoneObject)
    },
  },

  computed: {
    displayValue: {
      get() {
        if (this.isInputActive || !this.currencyInput) {
          // Cursor is inside the input field. unformat display value for user
          return this.value ? this.value.toString() : '';
        } else {
          if (this.value == '') {
            return this.value.toString()
          }
          // User is not modifying now. Format display value for user interface
          return parseFloat(this.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
        }
      },
      set(modifiedValue) {
        let newValue = modifiedValue
        if (this.currencyInput) {
          // Recalculate value after ignoring "$" and "," in user input
          newValue = parseFloat(modifiedValue.replace(/[^\d\\.]/g, ""))
          if (isNaN(newValue)) {
            newValue = ''
          }
        }
        this.$emit('input', newValue)
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .text-input::placeholder {
    color: var(--placeholder-color);
  }

  .tel-input {
    border-color: transparent;
    &:focus-within {
      border-color: transparent;
      outline: 2px solid transparent;
      outline-offset: 2px;
      box-shadow: unset;
    }
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
  }
</style>