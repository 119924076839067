<template>
  <div v-if="church">
    <div v-if="showInfoLabel" class="bg-secondary-light py-5 px-10 flex justify-between">
      <span class="body-m-400">You are currently viewing your profile as a pro</span>
      <router-link :to="{name: 'Settings'}" class="body-s-400 flex items-center"><AccountIcon class="w-4 h-4 mr-2"/>Back to my account</router-link>
    </div>
    <div
      :style="`background-image: url('${coverImage}')`"
      class="h-96 w-full bg-cover bg-no-repeat relative">
      <div class="inset-0  absolute flex items-center justify-center" :class="{'custom-overlay': church.cover_image_url}">
        <div class="flex flex-col items-center">
          <AvatarImage :image="church.logo_url" class="mb-3" width="w-28" height="h-28" right="right-3"
                       top="top-1"/>
          <h1 class="text-3xl font-bold text-white mb-2 tracking-wide">{{ church.name }}</h1>
        </div>
      </div>
    </div>

    <div class="bg-cream pt-6">
      <div class="container mx-auto px-6 lg:px-20 xl:px-32 py-10 grid grid-cols-1 gap-y-8">
        <template v-if="church.jobs.length !== 0">
          <JobCard v-for="job in church.jobs" :key="job.id" :job="job"/>
        </template>
        <template v-else>
          <h4 class="text-xl font-bold"> Not jobs posted yet!</h4>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import AvatarImage from "@/components/shared/AvatarImage";
import AccountIcon from "@/components/shared/svg/AccountIcon";
import JobCard from "@/components/church/JobCard";

export default {
  name: 'Church',
  components: {
    AvatarImage,
    JobCard,
    AccountIcon,
  },
  async mounted() {
    await this.fetchChurch({id: this.$route.params.churchId, includes: 'jobs,hire_managers'});
  },
  methods: {
    ...mapActions({
      fetchChurch: 'churches/fetchChurch'
    })
  },
  computed: {
    ...mapState({
      church: state => state.churches.current,
      loggedUser: state => state.auth.user,
    }),

    showInfoLabel() {
      return this.loggedUser && this.loggedUser.userable_type == 'hire_manager' && this.loggedUser.type.id == this.church.hire_managers[0].id
    },

    coverImage() {
      const defaultImage = require(`@/assets/images/default_cover_image.png`);
      return this.church.cover_image_url ? this.church.cover_image_url : defaultImage;
    },
  }
}
</script>

<style scoped>
.custom-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.carousel-cell {
  @apply w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mr-4;
}
</style>
