<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" class=" stroke-current">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.251 10.4596L16.5091 8.71767C16.2785 8.48593 15.9647 8.3562 15.6387 8.3562H11.2202C10.5394 8.3562 9.98828 8.90727 9.98828 9.58807V18.2123C9.98828 18.8931 10.5394 19.4442 11.2202 19.4442H17.3806C18.0614 19.4442 18.6125 18.8931 18.6125 18.2123V11.33C18.6125 11.004 18.4828 10.6902 18.251 10.4596Z"  stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.6124 11.9598H15.7295C15.3314 11.9598 15.0088 11.6371 15.0088 11.2391V8.3562"  stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.8444 23.8792H8.75647C6.30714 23.8792 4.32129 21.8934 4.32129 19.444V8.35608C4.32129 5.90675 6.30714 3.9209 8.75647 3.9209H19.8444C22.2937 3.9209 24.2796 5.90675 24.2796 8.35608V19.444C24.2796 21.8934 22.2937 23.8792 19.8444 23.8792Z"  stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
name:'FileIcon'
}
</script>
