import api from '@/api'

const skills = {
  namespaced: true,

  state: {
    items: [],
  },

  actions: {
    fetch({ commit }) {
      return api.get('/tags')
        .then(({ data }) => {
          commit('setSkills', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

  },

  mutations: {
    setSkills(state, data) {
      state.items = data;
    },
  },

}

export default skills
