<template>
  <button @click.stop="onClick"
          :type="type"
          :disabled="disabled"
          :class="[rounded,color,xPadding,yPadding, fontWeight, transition, border, disabled ? 'cursor-not-allowed bg-secondary-light' : [hoverBackground, hoverColor, background]]"
          class="flex justify-center items-center outline-none focus:outline-none">
    <slot name="leftIcon"></slot>
    <loading-icon v-if="loading" class="h-2 my-1"/>
    <span v-else :class="[fontWeight, fontSize]">{{ text }}</span>
    <slot name="rightIcon"></slot>
  </button>
</template>
<script>

export default {
  name: 'ButtonIcon',
  props: {
    text: {type: String, default: ""},
    type: {type: String, default: "button"},
    background: { type: String, default: 'bg-secondary-main' },
    rounded: { type: String, default: 'rounded-lg' },
    color: { type: String, default: 'text-white' },
    xPadding: { type: String, default: 'px-5' },
    yPadding: { type: String, default: 'py-3' },
    fontWeight: { type: String, default: 'font-semibold' },
    fontSize: { type: String, default: 'text-base' },
    transition: { type: String, default: 'transition-all duration-300' },
    hoverBackground: { type: String, default: 'hover:bg-secondary-dark' },
    hoverColor: { type: String, default: 'hover:text-white' },
    border: { type: String, default: 'border border-transparent' },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  methods: {
    onClick() {
      this.$emit('onClick')
    }
  }
}
</script>
