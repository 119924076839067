import api from '@/api'

const churches = {
  namespaced: true,

  state: {
    current: null,
    clientSecret: null,
    paymentMethod: null,
  },

  getters: {
    paymentMethod: state => state.paymentMethod,
  },

  actions: {
    fetchChurch({commit}, payload) {
      let url = `/churches/${payload.id}`;
      if (payload.includes) {
        url += `?include=${payload.includes}`
      }
      return api.get(url)
        .then(({data}) => {
          commit('setChurch', data.data)
          // commit('setPagination', data.meta)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    async getClientSecret({ commit }) {
      const response = await api.post('/payment-methods/setup-intent');

      const { success, data, message } = response.data;
      if (success) {
        commit('setClientSecret', data);
      }

      return { message, success };
    },

    async fetchPaymentMethod({ commit }) {
      const response = await api.get('/payment-methods');
      const { success, data } = response.data;
      if (success) {
        commit('setPaymentMethod', data);
      }
      return { success };
    },

    async savePaymentMethodId(_, id) {
      const response = await api.post(`/payment-methods`, id);
      const { success, data, message } = response.data;
      return { message, success, data };
    },
  },

  mutations: {
    setChurch(state, data) {
      state.current = data;
    },

    setClientSecret(state, data) {
      state.clientSecret = data;
    },

    setPaymentMethod(state, data) {
      state.paymentMethod = data;
    },
  },
}

export default churches
