<template>
  <div class="flex flex-col rounded-3xl py-6 px-8 bg-white overflow-hidden">
    <div class="flex flex-col justify-center overflow-hidden">
      <cropper
        ref="cropper"
        class="cropper overflow-hidden"
        :src="imageUrl"
        :touchResize="false"
        :wheelResize="false"
        :resizeImage="false"
        image-restriction="stencil"
        :min-height="400"
        :min-width="400"
        :stencil-component="stencilComponent"
        :stencil-props="{
          aspectRatio
        }"
        :default-size="defaultSize"
        :size-restrictions-algorithm="pixelsRestriction"
      ></cropper>
      <span class="mt-3 text-sm" style="max-width: 625px;">If image doesn't appear after 30 seconds try changing the image and then try cropping again.</span>
    </div>
    <div class="flex items-center justify-end pt-5">
      <ButtonIcon @onClick="cancel" text="Cancel" class="mr-2" y-padding="py-2" font-weight="font-normal" background="bg-transparent" color="text-secondary-main"/>
      <ButtonIcon @onClick="save" text="Save" y-padding="py-2" font-weight="font-normal" :loading="loading"/>
    </div>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import 'vue-advanced-cropper/dist/style.css';

export default {
  name: "CropImage",
  components: { Cropper, ButtonIcon },
  props: {
    imageUrl: String,
    loading: Boolean,
    aspectRatio: { type: Number, required: false, default: 1 },
    stencilComponent: { type: String, required: false, default: 'circle-stencil'}
  },
  methods: {
    save() {
      const { canvas } = this.$refs.cropper.getResult();
      this.$emit("cropped", canvas);
    },

    cancel() {
      this.$emit("cancel");
    },

    pixelsRestriction({ minWidth, minHeight, imageWidth, imageHeight }) {
      return {
        minWidth: minWidth,
        minHeight: minHeight,
        maxWidth: imageWidth,
        maxHeight: imageHeight
      };
    },

    defaultSize({ maxWidth, maxHeight }) {
      return {
        width: maxWidth,
        height: maxHeight
      };
    }
  }
};
</script>

<style scoped>
.cropper {
  border: solid 1px #eee;
  max-height: 500px;
  max-width: 700px;
  width: 100%;
}
</style>
