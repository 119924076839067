<template>
  <svg
    width="51"
    height="50"
    viewBox="0 0 51 50"
    fill="none"
    class="stroke-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.1651 0.999969H6.83207C3.88658 0.999969 1.49878 3.38778 1.49878 6.3333V43.6666C1.49878 46.6121 3.88658 48.9999 6.83207 48.9999H44.1651C47.1106 48.9999 49.4984 46.6121 49.4984 43.6666V6.3333C49.4984 3.38778 47.1106 0.999969 44.1651 0.999969Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.1667 19.6666C18.3758 19.6666 20.1667 17.8757 20.1667 15.6666C20.1667 13.4575 18.3758 11.6666 16.1667 11.6666C13.9576 11.6666 12.1667 13.4575 12.1667 15.6666C12.1667 17.8757 13.9576 19.6666 16.1667 19.6666Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M49.501 33L36.1678 19.6667L6.83472 49"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "AddNewImageIcon",
};
</script>
