import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/app.css"
import LoadingIcon from '@/components/shared/LoadingIcon'

import PortalVue from 'portal-vue'
import VueToast from 'vue-toast-notification';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Constants from "@/plugins/constants";
import './plugins/validate.js'
import 'vue-toast-notification/dist/theme-sugar.css';
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

Vue.config.productionTip = false

/** VUE GLOBAL COMPONENTS */
Vue.component('loading-icon', LoadingIcon);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.use(VueTelInput)
Vue.use(PortalVue)
Vue.use(Constants);
Vue.use(VueToast, {
  position: 'bottom-left',
  duration: 10000
});


Vue.filter('truncate', function (text, length, clamp) {
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
});

Vue.filter('currency', function (value) {
  if (isNaN(value) || !value) return '';
  return `$${parseFloat(value).toFixed(2)}`;
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
