var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-screen grid grid-cols-1 lg:grid-cols-2"},[_c('div',{staticClass:"flex flex-col items-center justify-center gradient text-white relative overflow-hidden"},[_c('Blur',{staticClass:"absolute left-0 top-0 z-0"}),_c('div',{staticClass:"relative z-10 w-full md:w-6/12 px-6 md:px-0"},[_c('h2',{staticClass:"text-white -mt-32 mb-24 heading-xl-600"},[_vm._v("Login")]),_c('ValidationObserver',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"flex flex-col items-center justify-center space-y-8",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.login)}}},[_c('div',{staticClass:"w-full"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"Email","background":"bg-primary","placeholder":"Email","x-padding":"px-5","label-color":"text-white","type":"email","errors":errors},on:{"enterPressed":function($event){return handleSubmit(_vm.login)}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1),_c('div',{staticClass:"w-full"},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"Password","background":"bg-primary","placeholder":"Password","x-padding":"px-5","label-color":"text-white","type":"password","errors":errors},on:{"enterPressed":function($event){return handleSubmit(_vm.login)}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('router-link',{staticClass:"mt-2 button-label-m-500 flex justify-end",attrs:{"to":{name:'ForgotPassword'}}},[_vm._v("Forgot your password?")])],1),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"text-sm text-left w-full"},[_vm._v(_vm._s(_vm.error))]),_c('div',{staticClass:"flex items-center w-full"},[_c('span',{staticClass:"w-full border border-auxiliary-blue"}),_c('span',{staticClass:"flex-shrink-0 mx-10"},[_vm._v("or")]),_c('span',{staticClass:"w-full border border-auxiliary-blue"})]),_c('div',{staticClass:"grid grid-cols-2 gap-x-4 w-full"},[_c('button',{staticClass:"bg-white flex justify-center items-center rounded-xl py-3",on:{"click":function($event){$event.preventDefault();return _vm.oauthLoginRedirect(_vm.providers.Google)}}},[_c('GoogleIcon')],1),_c('button',{staticClass:"bg-white flex justify-center items-center rounded-xl py-3",on:{"click":function($event){$event.preventDefault();return _vm.oauthLoginRedirect(_vm.providers.Facebook)}}},[_c('FacebookIcon')],1)]),_c('button',{staticClass:"w-full",attrs:{"type":"submit","disabled":invalid}},[_c('ButtonIcon',{staticClass:"w-full",attrs:{"text":"Log in","disabled":invalid,"loading":_vm.loading}})],1),_c('router-link',{staticClass:"body-m-400",attrs:{"to":{ name:'UserType' }}},[_vm._v(" Don't have an account? Create one here. ")])],1)]}}])})],1)],1),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-cream relative overflow-hidden hidden lg:block"},[_c('img',{staticClass:"absolute right-0 top-0 mt-32",attrs:{"src":require("@/assets/images/talentpond_preview.png")}})])}]

export { render, staticRenderFns }