<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.00009 12.4163C9.99163 12.4163 12.4168 9.99122 12.4168 6.99967C12.4168 4.00813 9.99163 1.58301 7.00009 1.58301C4.00854 1.58301 1.58342 4.00813 1.58342 6.99967C1.58342 9.99122 4.00854 12.4163 7.00009 12.4163Z"
      stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7 9.16667V7" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7 4.83301H7.007" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "InfoIcon"
}
</script>

<style scoped>

</style>
