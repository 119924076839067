<template>
  <div v-if="user && bid">
    <p class="heading-s-700 px-4 py-9">Hey, {{user.first_name}}  we have a new pro bidding on your project...</p>
    <div class="flex flex-col items-center justify-center bg-white mt-8">
      <AvatarImage
        :image="bid.pro.image_url"
        border-radius="rounded-md"
        height="h-16"
        width="w-16"
        class="-mt-8"
      />
      <span class="heading-s-700 text-fonts-primary mt-3.5">{{bid.pro.full_name}}</span>
      <router-link
        class="text-fonts-secondary hover:text-primary label-xs-400 mb-5"
        :to="{ name: 'Pro', params: { proId: bid.pro.type.id } }"
        >View profile</router-link
      >
    </div>

    <div class="p-6 bg-white mt-4">
      <p v-if="bid.personal_message" class="text-fonts-secondary italic mb-10">
        “{{ bid.personal_message }}”
      </p>
      <div class="flex flex-wrap">
        <Badge
            class="mb-3 mr-3"
            y-padding="py-2"
            background="bg-primary-10"
            color="text-auxiliary-blue"
            styles="body-s-500"
            :text="`$${bid.amount} ${job.type == 'one_time' ? '' : 'per month'}`"
            border="border-2 border-auxiliary-blue"
          />
      </div>
    </div>

    <div class="mt-5 p-6 bg-white flex flex-col w-full">
      <template v-if="bid.status == 'pending'">
        <!-- Hire -->
        <div class="flex justify-center items-center mb-5">
          <AlertDialog
            v-if="bid.job.church.can_create_jobs"
            :confirm="`Yes, hire ${bid.pro.first_name}`"
            @confirm="hirePro($event)"
            modalClasses="text-white bg-secondary-main hover:bg-secondary-dark py-3 px-10 w-full flex justify-center items-center rounded-lg outline-none focus:outline-none border border-transparent transition-all duration-300"
          >
            <span>Hire Pro to work on your project</span>
            <template #title>
              Are you sure you want to hire {{ bid.pro.full_name }}? We will
              notify the other pros that this job is no longer open for bids
            </template>
          </AlertDialog>
          <button
            v-else
            class="text-white bg-secondary-main hover:bg-secondary-dark py-3.5 body-m-400 px-6 flex justify-center items-center rounded-lg outline-none focus:outline-none border border-transparent transition-all duration-300"
            @click="openCreditCardIncompleteModal"
          >
            Hire
          </button>
        </div>
      </template>
      <template v-else>
        Bid was already {{bid.status}}
      </template>
    </div>
  </div>
  <div v-else class="flex flex-col items-center justify-center h-screen">
    <loading-icon class="h-5 text-auxiliary-blueLight" />
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from "vuex";
import AvatarImage from "@/components/shared/AvatarImage";
import Badge from "@/components/shared/Badge";
import AlertDialog from "@/components/shared/AlertDialog";

export default {
  name: "JobBid",
  components: { AvatarImage, Badge, AlertDialog },
  props: {
    bidId: [String, Number],
  },

  async created() {
    const query = this.$route.query;
    const { bid } = await this.loginFromBid({ email: query.email, token: query.token, bid_id: this.$route.params.bidId})
    this.setBid(bid);
    this.setJob(bid.job);
  },

  methods: {
    ...mapActions({
      loginFromBid: 'auth/loginFromBid',
      acceptBid: "bids/accept",
    }),

    ...mapMutations({
      setBid: "bids/setBid",
      setJob: "jobs/setJob",
      showModal: 'general/showModal',
    }),

    hirePro(callback) {
      this.acceptBid({ bidId: this.bidId }).then((response) => {
        if (response) {
          callback.call();
          this.$router.push("/");
        }
      });
    },

    openCreditCardIncompleteModal(){
      this.showModal({
        componentName:
        'CreditCardIncompleteModal',
        maxWH: 'max-w-2xl max-h-200'
      });
    },
  },

  computed: {
    ...mapState({
      bid: state => state.bids.current,
      user: (state) => state.auth.user,
    }),
  }
}
</script>

