<template>
  <div v-if="user !== null">
    <div
      v-if="showInfoLabel"
      class="bg-secondary-light py-5 px-10 flex justify-between"
    >
      <span class="body-m-400"
        >You are currently viewing your profile as a business</span
      >
      <router-link :to="{ name: 'Settings' }" class="text-sm flex items-center"
        ><AccountIcon class="w-4 h-4 mr-2" />Back to my account</router-link
      >
    </div>
    <div
      :style="`background-image: url(${coverImage})`"
      class="h-96 w-full bg-cover bg-no-repeat relative"
    >
      <div
        class="inset-0 absolute flex items-center justify-center"
        :class="{ 'custom-overlay': user.type.cover_image_url }"
      >
        <div class="flex flex-col items-center">
          <AvatarImage
            class="mb-3"
            width="w-28"
            height="h-28"
            right="right-3"
            top="top-1"
            :isOnline="user.is_online"
            :image="user.image_url"
          />
          <h1 class="text-3xl font-bold text-white mb-8 tracking-wide">
            {{ user.full_name }}
          </h1>
          <ButtonIcon
            @onClick="goToCreateJob"
            text="Hire me"
            fontWeight="font-normal"
          />
        </div>
      </div>
    </div>
    <!--Content-->
    <div class="bg-cream">
      <div class="container mx-auto px-6 lg:px-20 xl:px-40 py-10">
        <!--Pero description-->
        <p class="whitespace-pre-wrap">{{ user.type.bio }}</p>
        <p v-if="user.type.education" class="mt-5 whitespace-pre-wrap">
          {{ user.type.education }}
        </p>
        <!--Skills-->
        <h2 v-if="user.type.skills.length" class="heading-m-700 mb-6 mt-6">
          Skills
        </h2>
        <div class="mb-10">
          <Badge
            background="bg-primary-10"
            color="text-auxiliary-blue"
            class="mr-3 mb-3"
            v-for="skill in user.type.skills"
            :key="skill.id"
            :text="skill.name"
          />
        </div>
        <!--Categories-->
        <h2 class="heading-m-700 mb-6 mt-6">Categories</h2>
        <div v-if="user.type.categories.length > 0" class="mb-10">
          <Badge
            background="bg-primary-10"
            color="text-auxiliary-blue"
            class="mr-3 mb-3"
            v-for="category in user.type.categories"
            :key="category.id"
            :text="category.name"
          />
        </div>
        <div v-else class="mb-10">
          <span>No categories yet</span>
        </div>
        <!-- Portfolio-->
        <div
          class="flex space-x-4 items-center mb-6"
          v-if="user.type.portfolio && user.type.portfolio.length"
        >
          <h2 class="heading-m-700">Portfolio</h2>
          <LongArrowIcon class="mt-1 w-11 text-secondary-main transform rotate-180" />
        </div>
        <!--Carousel (ONLY ON MOBILE)-->
        <flickity
          v-if="mobile"
          class="mb-14"
          ref="flickity"
          :options="flickityOptions"
        >
          <Modal
            v-for="(img, index) in user.type.portfolio"
            :key="index"
            class="carousel-cell"
            :closeButtonOutside="true"
          >
            <div
              :style="`background-image: url('${img.thumb_url}')`"
              class="w-full h-64 bg-cover bg-no-repeat rounded-xl"
            ></div>
            <template #content>
              <div class="bg-white p-1">
                <img
                  :src="img.url"
                  style="max-height: 80vh; max-width: 80vw"
                  class="object-cover"
                />
              </div>
            </template>
          </Modal>
        </flickity>
        <!--Portfolio grid ONLY ON DESKTOP -->
        <div
          v-else
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
        >
          <Modal
            v-for="(img, index) in user.type.portfolio"
            :key="index"
            class="w-full"
            :closeButtonOutside="true"
          >
            <div
              :style="`background-image: url('${img.url}')`"
              class="
                w-full
                h-64
                bg-cover bg-no-repeat
                rounded-xl
                cursor-pointer
              "
            ></div>
            <template #content>
              <div class="bg-white p-1">
                <img
                  :src="img.url"
                  style="max-height: 80vh; max-width: 80vw"
                  class="object-cover"
                />
              </div>
            </template>
          </Modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import isMobile from "is-mobile";
import AvatarImage from "@/components/shared/AvatarImage";
import LongArrowIcon from "@/components/shared/svg/LongArrowIcon";
import AccountIcon from "@/components/shared/svg/AccountIcon";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import Badge from "@/components/shared/Badge";
import Flickity from "vue-flickity";
import { mapActions, mapMutations, mapState } from "vuex";
import Modal from "@/components/shared/Modal";

export default {
  name: "Pro",
  props: {
    proId: [String, Number],
  },
  mounted() {
    this.fetchPro({ id: this.proId, includes: 'skills,categories,portfolio' });
  },
  beforeDestroy() {
    this.setPro(null);
  },
  components: {
    AvatarImage,
    Badge,
    LongArrowIcon,
    Flickity,
    AccountIcon,
    ButtonIcon,
    Modal,
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: false,
        imagesLoaded: true,
        cellAlign: "left",
      },
      mobile: isMobile(),
    };
  },
  methods: {
    ...mapMutations({ setPro: "pros/setPro" }),
    ...mapActions({
      fetchPro: "pros/fetchPro",
    }),

    goToCreateJob() {
      const pro = {
        id: this.user.type.id,
        email: this.user.email,
      };
      this.$router.push({ name: "PostJob", params: { pro } });
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.pros.current,
      loggedUser: (state) => state.auth.user,
    }),

    coverImage() {
      const defaultImage = require(`@/assets/images/default_cover_image.png`);
      return this.user.type.cover_image_url
        ? this.user.type.cover_image_url
        : defaultImage;
    },

    showInfoLabel() {
      return (
        this.loggedUser &&
        this.loggedUser.id == this.user.id &&
        this.loggedUser.userable_type == "pro"
      );
    },
  },
};
</script>

<style scoped>
.custom-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.carousel-cell {
  @apply w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mr-4;
}
</style>
