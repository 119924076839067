<template>
  <ValidationProvider ref="multiselect" :name="typeStartCase" v-slot="{ errors }">
    <InputLabel :label="label"/>
    <div class="w-full rounded-lg bg-white flex items-center" :class="errors.length ? 'border-functional-error border-1.5' : 'mb-4'">
      <multiselect
        class="w-full relative"
        :multiple="multiple"
        :hide-selected="true"
        :options-limit="optionsLimit"
        v-model="selected"
        track-by="id"
        label="name"
        :placeholder="placeholder"
        open-direction="bottom"
        :searchable="true"
        :options="options"
        tag-placeholder="Add this as a new tag"
        :taggable="true"
        @tag="addTag"
      >
        <span class="arrow" slot="caret">
          <SearchIcon class="flex-shrink-0 text-auxiliary-blue mr-3"/>
        </span>
        <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} options selected</span></template>
      </multiselect>
    </div>
    <div>
      <SelectedOption @removeOption="removeOption" v-for="(tag, index) in selected" :key="index" :tag="tag"/>
    </div>
    <p class="text-functional-error text-xs mt-1" v-if="errors.length">{{ errors[0] }}</p>
  </ValidationProvider>
</template>

<script>
import {isArray, startCase} from 'lodash';
import pluralize from 'pluralize'
import SelectedOption from "@/components/shared/inputs/TagMultiselect/partials/SelectedOption";
import SearchIcon from "@/components/shared/svg/SearchIcon";
import Multiselect from 'vue-multiselect';
import InputLabel from "@/components/shared/labels/InputLabel"

export default {
  name: "TagMultiselect",
  components: {SearchIcon, SelectedOption, Multiselect, InputLabel},

  props: {
    label: String,
    options: Array,
    type: String,
    multiple: {type: Boolean, default: true},
    optionsLimit: {type: Number, default: 99999},
    placeholder: {type: String, default: 'Search or add a tag...'},
    errors: {type: Array, required: false, default: () => []},
    initialValues: {type: Array, required: false, default: () => []},
  },
  data() {
    return {
      selected: [],
    }
  },
  mounted() {
    let vm = this;
    this.$nextTick().then(() => {
      if (vm.initialValues.length) {
        vm.selected = vm.initialValues
      }
    });
  },
  methods: {
    async addTag(newTag) {
      const tag = {
        name: newTag,
        id: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.selected.push(tag)
      await this.$refs.multiselect.validate();
    },

    async removeOption(id, e) {
      const tagIndex = this.selected.findIndex(tag => tag.id == id)
      this.selected.splice(tagIndex, 1)
      await this.$refs.multiselect.validate(e);
    },

    limitText(count) {
      return `and ${count} other ${pluralize(this.type, count)}`
    },
  },

  computed: {
    typeStartCase() {
      return startCase(this.type)
    }
  },

  watch: {
    selected() {
      this.selected = isArray(this.selected) ? this.selected : [this.selected]
      this.$emit('selected', this.selected)
    }
  },
}
</script>

<style scoped>
.arrow {
    @apply absolute left-0 pl-4 h-full flex items-center;
}
</style>

