<template>
  <div class="flex justify-center pb-10">
    <div class="w-full lg:w-10/12">
      <SelectJobTemplate @select="selectTemplate" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import SelectJobTemplate from "@/components/shared/SelectJobTemplate";

export default {
  name: "Step1",
  components: {
    SelectJobTemplate,
  },

  methods: {
    ...mapMutations({
      increaseStep: "jobs/increaseJobPostingStep",
      setCurrentTemplate: "jobs/setCurrentTemplate",
    }),

    selectTemplate(template) {
      this.setCurrentTemplate(template);
      this.increaseStep();
    },
  },
};
</script>

