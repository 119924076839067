<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5041 4.78C12.4417 4.70894 12.3521 4.66774 12.2575 4.66667H9.74414V3.72667C9.74414 3.54 9.78414 3.32667 10.0841 3.32667H12.0841C12.1731 3.33046 12.2589 3.29367 12.3175 3.22667C12.3762 3.16571 12.4096 3.08467 12.4108 3V0.333333C12.4108 0.149238 12.2616 0 12.0775 0H9.19081C5.99081 0 5.74414 2.73333 5.74414 3.56667V4.66667H4.07747C3.89338 4.66667 3.74414 4.81591 3.74414 5V7.66667C3.74414 7.85076 3.89338 8 4.07747 8H5.74414V15.6667C5.74414 15.8508 5.89338 16 6.07747 16H9.41081C9.5949 16 9.74414 15.8508 9.74414 15.6667V8H11.9775C12.1493 8.00086 12.2936 7.87097 12.3108 7.7L12.5908 5.03333C12.5986 4.94049 12.5672 4.84862 12.5041 4.78Z"
      fill="#1877F2"/>
  </svg>
</template>

<script>
export default {
  name: "FacebookIcon"
}
</script>

<style scoped>

</style>
