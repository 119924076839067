<template>
  <ValidationObserver v-slot="{ invalid, handleSubmit }" rules="required" ref="passwordForm">
    <form @submit.prevent="handleSubmit(updatePassword)">
      <div class="password-box-background p-5 rounded-lg my-6 grid grid-cols-1 gap-y-6">
        <ValidationProvider name="Current password" rules="required|min:8" vid="current_password" v-slot="{ errors }">
          <TextInput
            v-model="form.current_password"
            label="Current Password"
            x-padding="px-5"
            :type="showCurrentPassword ? 'text' : 'password'"
            :errors="errors"
          >
            <template slot="rightIcon">
              <div @click.prevent="showCurrentPassword = !showCurrentPassword" class="eye-icon" :class="showCurrentPassword ? 'active':'inactive'">
                <EyeIcon class="eye-icon-color"/>
              </div>
            </template>
          </TextInput>
        </ValidationProvider>

        <ValidationProvider name="New password" rules="required|min:8" vid="new_password" v-slot="{ errors }">
          <TextInput
            v-model="form.new_password"
            label="New Password"
            x-padding="px-5"
            :type="showNewPassword ? 'text' : 'password'"
            :errors="errors"
          >
            <template slot="rightIcon">
              <div @click.prevent="showNewPassword = !showNewPassword" class="eye-icon" :class="showNewPassword ? 'active':'inactive'">
              <EyeIcon class="eye-icon"/>
              </div>
            </template>
          </TextInput>
        </ValidationProvider>

        <ValidationProvider name="Confirm new password" rules="required|min:8|confirmed:new_password" vid="new_password_confirmation" v-slot="{ errors }">
          <TextInput
            v-model="form.new_password_confirmation"
            label="Confirm new Password"
            x-padding="px-5"
            :type="showConfirmPassword ? 'text' : 'password'"
            :errors="errors"
          >
            <template slot="rightIcon">
              <div @click.prevent="showConfirmPassword = !showConfirmPassword" class="eye-icon" :class="showConfirmPassword ? 'active':'inactive'">
                <EyeIcon class="eye-icon-color"/>
              </div>
            </template>
          </TextInput>
        </ValidationProvider>

        <h4 class="text-functional-error text-xs mt-1" v-if="error">{{ error }}</h4>

        <div class="flex space-x-4">
          <button type="submit" :disabled="invalid">
            <ButtonIcon :disabled="invalid" :loading="loading" text="Confirm new password" font-weight="font-normal"/>
          </button>
          <ButtonIcon @onClick="cancel" text="Cancel" font-weight="font-normal"
                      background="bg-transparent" hover-color=""
                      hover-background="" color="text-secondary-main"/>
        </div>
      </div>
      </form>
  </ValidationObserver>
</template>

<script>
import { mapActions } from 'vuex';
import EyeIcon from "@/components/shared/svg/EyeIcon";
import TextInput from "@/components/shared/inputs/TextInput";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";

export default {
  name: 'ChangePassword',
  components: { EyeIcon, TextInput, ButtonIcon },

  data() {
    return {
      loading: false,
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      form: {
        current_password: '',
        new_password: '',
        new_password_confirmation: '',
      },
      error: ''
    }
  },

  methods: {
    ...mapActions({
      changePassword: 'users/changePassword',
    }),

    updatePassword() {
      this.loading = true;
      this.error = '';
      this.changePassword(this.form)
        .then(response => {
          if (response.success) {
            this.$emit('changed')
          } else { 
            this.error = response.message;
          }
        })
        .catch(errors => {
          if (errors.data) {
            this.$refs.passwordForm.setErrors(errors.data.errors)
          }
        })
        .finally(() => this.loading = false)
    },

    cancel() {
      this.$emit('changed')
    }
  },
  
}
</script>

<style lang="scss" scoped>
  .password-box-background {
    background: rgba(235, 223, 217, .3);
  }

  .eye-icon {
    @apply cursor-pointer;
    &.active {
      @apply text-fonts-primary;
      &:hover {
        @apply text-fonts-secondary;
      }
    }

    &.inactive {
      @apply text-darkSand;
      &:hover {
        @apply text-fonts-secondary;
      }
    }
  }
</style>