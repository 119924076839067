<template>
  <div class="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 gap-x-20">
    <!--Left col-->
    <div class="md:col-span-4">
      <h2 class="text-4xl font-medium mb-10">Pros</h2>
      <Filters @fetch="fetchPros(true)" />
    </div>
    <!--Right col-->
    <div class="md:col-span-8">
      <div class="flex items-center justify-between mb-10 flex-col md:flex-row">
        <Search @fetch="fetchPros(true)" class="md:w-1/2 mb-2 md:mb-0" />
        <Sort
          @fetch="fetchPros(true)"
          class="w-full md:w-auto"
          :options="proSortingOptions"
        />
      </div>
      <ExploreSkeleton v-if="loading"/>
      <div class="grid grid-cols-1 gap-y-6" v-else-if="pros.length">
        <ProCard v-for="(pro, index) in pros" :key="index" :pro="pro" />
      </div>
      <div
        v-else
        class="flex flex-col items-center justify-center pt-20 p-40 text-center"
      >
        <span class="body-l-500 mb-5">No results found</span>
        <span class="body-s-400 text-fonts-secondary"
          >Try adjusting the filters or the search criteria to find what you are
          looking for</span
        >
      </div>
      <Pagination
        class="flex justify-start mt-8 sm:justify-end"
        :pagination="pagination"
        v-on:redirectTo="redirectTo"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Filters from "@/components/pros/filters/Filters";
import Sort from "@/components/pros/Sort";
import Search from "@/components/pros/filters/Search";
import ProCard from "@/components/pros/ProCard";
import Pagination from "@/components/shared/Pagination";
import ExploreSkeleton from "@/components/skeleton/ExploreSkeleton";

export default {
  name: "Pros",
  components: { ProCard, Filters, Search, Sort, Pagination, ExploreSkeleton },

  data() {
    return {
      loading: false,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.clearFilters();
      if (vm.$route.query.categories) {
        vm.setCategories(vm.$route.query.categories);
      }

      if (vm.$route.query.is_favorited) {
        vm.setFavorited(vm.$route.query.is_favorited);
      }

      if (vm.$route.query.name) {
        vm.setName(vm.$route.query.name);
      }

      if (vm.$route.query.skills) {
        vm.setSkills(vm.$route.query.skills);
      }

      if (vm.$route.query.sort) {
        vm.setSort(vm.$route.query.sort);
      }

      if (vm.$route.query.page) {
        vm.setPage(vm.$route.query.page);
      }
    });
  },

  methods: {
    ...mapActions({
      getPros: "pros/fetch",
    }),

    ...mapMutations({
      clearFilters: "filters/clearFilters",
      setCategories: "filters/setCategories",
      setFavorited: "filters/setFavorited",
      setName: "filters/setName",
      setSkills: "filters/setSkills",
      setSort: "filters/setSort",
      setPage: "filters/setPage",
    }),

    async fetchPros(resetPagination = false) {
      if (resetPagination) {
        this.setPage(1)
      }

      this.addParams()

      this.loading = true;
      await this.getPros({
        filters: this.filters,
        sort: this.sort,
        page: this.page,
      });
      this.loading = false;
      window.scrollTo(0, 0);
    },

    redirectTo(page) {
      this.setPage(page);
      this.fetchPros();
    },

    addParams() {
      history.pushState(
        {},
        null,
        this.$route.path + '?' +
        Object.keys(this.query)
          .map(key => {
            return (
              encodeURIComponent(key) + '=' + encodeURIComponent(this.query[key])
            )
          })
          .join('&')
        )
    }
  },

  mounted() {
    this.fetchPros();
  },

  computed: {
    ...mapState({
      pros: (state) => state.pros.items,
      pagination: (state) => state.pros.pagination,
      query: (state) => state.filters.query,
      filters: (state) => state.filters.filters,
      sort: (state) => state.filters.sort,
      page: (state) => state.filters.page,
      proSortingOptions: (state) => state.filters.proSortingOptions,
    }),
  },
};
</script>

