<template>
  <svg width="113" height="113" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="113" height="113" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6168 59.3127C14.4274 68.994 22.6332 76.6266 29.5584 83.4051C35.7298 89.4458 43.0889 94.8798 51.7008 95.6113C59.6276 96.2846 66.5145 91.144 73.3783 87.1272C79.0199 83.8257 83.4393 79.4199 87.7531 74.5131C92.7852 68.7893 100.953 63.8428 100.534 56.2388C100.115 48.6247 90.5116 45.4573 85.8202 39.4402C81.1095 33.3985 79.7234 24.7998 73.0629 21.0048C65.5975 16.7511 56.1993 15.086 48.0938 17.9445C39.957 20.814 35.9087 29.3184 30.4853 36.0201C24.4264 43.5073 14.805 49.6895 14.6168 59.3127Z" fill="#EAECFB"/>
    <path d="M62.2969 73.0625V80.5156C62.2969 80.9719 61.9242 81.3438 61.4688 81.3438H49.0469L54.0156 74.7188L52.3594 73.0625L54.8438 69.75L53.1875 68.0938H57.3281C60.0775 68.0938 62.2969 70.3214 62.2969 73.0625Z" fill="#4A89DC"/>
    <path d="M57.3281 68.0938H54.0156C56.765 68.0938 58.9844 70.3214 58.9844 73.0625V80.5156C58.9844 80.9719 58.6117 81.3438 58.1562 81.3438H61.4688C61.9242 81.3438 62.2969 80.9719 62.2969 80.5156V73.0625C62.2969 70.3214 60.0775 68.0938 57.3281 68.0938Z" fill="#3981D1"/>
    <path d="M49.0469 81.3438H47.3906L50.5872 69.8494L51.5312 66.4375L53.1875 68.0938L54.8438 69.75L52.3594 73.0625L54.0156 74.7188L49.0469 81.3438Z" fill="#5D9CEC"/>
    <path d="M39.1094 74.7188L44.0781 81.3438H31.6562C31.2008 81.3438 30.8281 80.9719 30.8281 80.5156V73.0625C30.8281 70.3214 33.0475 68.0938 35.7969 68.0938H39.9375L38.2812 69.75L40.7656 73.0625L39.1094 74.7188Z" fill="#4A89DC"/>
    <path d="M42.5378 69.8494L45.7344 81.3438H44.0781L39.1094 74.7188L40.7656 73.0625L38.2812 69.75L39.9375 68.0938L41.5938 66.4375L42.5378 69.8494Z" fill="#5D9CEC"/>
    <path d="M50.5869 69.8494L47.3903 81.3438H45.734L42.5375 69.8494L42.8853 69.75L46.5622 68.0938L50.239 69.75L50.5869 69.8494Z" fill="#E6E9ED"/>
    <path d="M46.5625 65.6094C48.3264 65.6094 50.0075 65.2375 51.5312 64.5742V66.4375L50.5872 69.8494L50.2394 69.75L46.5625 68.0938L42.8856 69.75L42.5378 69.8494L41.5938 66.4375V64.5742C43.1175 65.2375 44.7986 65.6094 46.5625 65.6094Z" fill="#F5F7FA"/>
    <path d="M38.2812 34.1655V42.4219C38.2812 44.7083 36.4262 46.5625 34.1406 46.5625V38.2812C34.1406 35.9956 35.9956 34.1406 38.2812 34.1406L38.2895 34.158C38.2895 34.1655 38.2812 34.1655 38.2812 34.1655Z" fill="#DA4453"/>
    <path d="M61.4688 46.5625V59.8125C61.4688 56.5505 60.533 54.0736 59.0341 52.2029C55.1916 47.4155 47.6308 46.5625 42.4219 46.5625C40.1363 46.5625 38.2812 44.7083 38.2812 42.4219V34.1655C38.2812 34.1655 38.2895 34.1655 38.2895 34.1572C40.658 32.5838 43.4984 31.6562 46.5625 31.6562C54.7941 31.6562 61.4688 38.3318 61.4688 46.5625Z" fill="#ED5565"/>
    <path d="M46.5629 31.6562C46.0081 31.6562 45.4632 31.6968 44.9241 31.7556C52.3673 32.5887 58.1567 38.8974 58.1567 46.5625V51.2455C58.4647 51.547 58.7612 51.8625 59.0345 52.202C60.5334 54.0736 61.4692 56.5505 61.4692 59.8125V46.5625C61.4692 38.3318 54.7945 31.6562 46.5629 31.6562Z" fill="#DA4453"/>
    <path d="M58.9844 52.1474C55.1278 47.4113 47.6101 46.5625 42.4219 46.5625C40.1363 46.5625 38.2812 44.7083 38.2812 42.4219C38.2812 44.7083 36.4262 46.5625 34.1406 46.5625V53.1875C34.1406 58.2805 37.2047 62.6612 41.5938 64.5742C43.1175 65.2375 44.7986 65.6094 46.5625 65.6094C48.3264 65.6094 50.0075 65.2375 51.5312 64.5742C55.9203 62.6612 58.9844 58.2805 58.9844 53.1875V52.6666V52.2186V52.1474Z" fill="#EAC6BB"/>
    <path d="M58.9844 52.1473C58.0627 51.0152 56.9315 50.1068 55.6719 49.3772V53.1874C55.6719 58.2804 52.6078 62.6611 48.2188 64.5741C47.1811 65.0254 46.0656 65.3294 44.9062 65.4859C45.4495 65.5579 45.9994 65.6093 46.5625 65.6093C48.3264 65.6093 50.0075 65.2374 51.5312 64.5741C55.9203 62.6611 58.9844 58.2804 58.9844 53.1874V52.6665V52.2185V52.1473Z" fill="#D3B1A9"/>
    <path d="M30.8281 73.8931C30.3702 73.8931 30 73.523 30 73.065C30 69.8676 32.6011 67.2665 35.7985 67.2665C36.2565 67.2665 36.6267 67.6367 36.6267 68.0946C36.6267 68.5526 36.2565 68.9227 35.7985 68.9227C33.5146 68.9227 31.6562 70.781 31.6562 73.065C31.6562 73.523 31.2861 73.8931 30.8281 73.8931Z" fill="#26355C"/>
    <path d="M62.2969 73.8922C61.8389 73.8922 61.4688 73.522 61.4688 73.0641C61.4688 70.7809 59.6113 68.9235 57.3281 68.9235C56.8702 68.9235 56.5 68.5533 56.5 68.0953C56.5 67.6374 56.8702 67.2672 57.3281 67.2672C60.5239 67.2672 63.125 69.8684 63.125 73.0641C63.125 73.522 62.7548 73.8922 62.2969 73.8922Z" fill="#26355C"/>
    <path d="M39.9366 68.9236L35.7985 68.9227C35.3405 68.9227 34.9703 68.5517 34.9703 68.0946C34.9703 67.6367 35.3413 67.2665 35.7985 67.2665L39.9366 67.2673C40.3946 67.2673 40.7647 67.6383 40.7647 68.0954C40.7647 68.5534 40.3937 68.9236 39.9366 68.9236Z" fill="#26355C"/>
    <path d="M57.3282 68.9236L53.1884 68.9227C52.7304 68.9227 52.3602 68.5517 52.3602 68.0946C52.3602 67.6367 52.7312 67.2665 53.1884 67.2665L57.3282 67.2673C57.7861 67.2673 58.1563 67.6383 58.1563 68.0954C58.1563 68.5534 57.7853 68.9236 57.3282 68.9236Z" fill="#26355C"/>
    <path d="M30.829 81.3437C30.3718 81.3437 30.0008 80.9735 30.0008 80.5156L30 73.0649C30 72.6078 30.3702 72.2368 30.8281 72.2368C31.2852 72.2368 31.6562 72.607 31.6562 73.0649L31.6571 80.5156C31.6571 80.9727 31.2869 81.3437 30.829 81.3437Z" fill="#26355C"/>
    <path d="M31.6571 82.1719C30.7437 82.1719 30.0009 81.429 30.0009 80.5156C30.0009 80.0577 30.371 79.6875 30.829 79.6875C31.2869 79.6875 31.6571 80.0577 31.6571 80.5156V80.5173C32.1151 80.5173 32.4852 80.8866 32.4852 81.3446C32.4852 81.8017 32.1151 82.1719 31.6571 82.1719Z" fill="#26355C"/>
    <path d="M61.4688 82.1719C61.0108 82.1719 60.6406 81.8017 60.6406 81.3438C60.6406 80.8858 61.0108 80.5156 61.4688 80.5156H61.4704C61.4704 80.0577 61.8397 79.6875 62.2977 79.6875C62.7548 79.6875 63.125 80.0577 63.125 80.5156C63.125 81.429 62.3822 82.1719 61.4688 82.1719Z" fill="#26355C"/>
    <path d="M62.2969 81.3437C61.8389 81.3437 61.4688 80.9735 61.4688 80.5156V73.0641C61.4688 72.6061 61.8389 72.236 62.2969 72.236C62.7548 72.236 63.125 72.6061 63.125 73.0641V80.5156C63.125 80.9735 62.7548 81.3437 62.2969 81.3437Z" fill="#26355C"/>
    <path d="M61.4688 82.1719H31.6571C31.1992 82.1719 30.829 81.8017 30.829 81.3438C30.829 80.8858 31.1992 80.5156 31.6571 80.5156H61.4688C61.9267 80.5156 62.2969 80.8858 62.2969 81.3438C62.2969 81.8017 61.9267 82.1719 61.4688 82.1719Z" fill="#26355C"/>
    <path d="M41.5938 67.2656C41.1358 67.2656 40.7656 66.8954 40.7656 66.4374V64.5758C40.7656 64.1179 41.1358 63.7477 41.5938 63.7477C42.0517 63.7477 42.4219 64.1179 42.4219 64.5758V66.4374C42.4219 66.8954 42.0517 67.2656 41.5938 67.2656Z" fill="#26355C"/>
    <path d="M51.5312 67.2656C51.0733 67.2656 50.7031 66.8954 50.7031 66.4374V64.5758C50.7031 64.1179 51.0733 63.7477 51.5312 63.7477C51.9892 63.7477 52.3594 64.1179 52.3594 64.5758V66.4374C52.3594 66.8954 51.9892 67.2656 51.5312 67.2656Z" fill="#26355C"/>
    <path d="M46.5625 66.4384C39.2568 66.4384 33.3125 60.4941 33.3125 53.1884C33.3125 52.7304 33.6827 52.3602 34.1406 52.3602C34.5986 52.3602 34.9688 52.7304 34.9688 53.1884C34.9688 59.5815 40.1694 64.7821 46.5625 64.7821C52.9556 64.7821 58.1562 59.5815 58.1562 53.1884C58.1562 52.7304 58.5264 52.3602 58.9844 52.3602C59.4423 52.3602 59.8125 52.7304 59.8125 53.1884C59.8125 60.4941 53.8682 66.4384 46.5625 66.4384Z" fill="#26355C"/>
    <path d="M34.1406 54.0165C33.6827 54.0165 33.3125 53.6463 33.3125 53.1883V38.2812C33.3125 37.8233 33.6827 37.4531 34.1406 37.4531C34.5986 37.4531 34.9688 37.8233 34.9688 38.2812V53.1883C34.9688 53.6463 34.5986 54.0165 34.1406 54.0165Z" fill="#26355C"/>
    <path d="M46.5876 72.2344C46.1297 72.2344 45.7554 71.8642 45.7554 71.4062C45.7554 70.9483 46.1214 70.5781 46.5785 70.5781H46.5876C47.0448 70.5781 47.4158 70.9483 47.4158 71.4062C47.4158 71.8642 47.0448 72.2344 46.5876 72.2344Z" fill="#26355C"/>
    <path d="M46.5876 75.5469C46.1297 75.5469 45.7554 75.1767 45.7554 74.7188C45.7554 74.2608 46.1214 73.8906 46.5785 73.8906H46.5876C47.0448 73.8906 47.4158 74.2608 47.4158 74.7188C47.4158 75.1767 47.0448 75.5469 46.5876 75.5469Z" fill="#26355C"/>
    <path d="M61.4688 47.3906C61.0108 47.3906 60.6406 47.0205 60.6406 46.5625C60.6406 38.7997 54.3253 32.4844 46.5625 32.4844C46.1045 32.4844 45.7344 32.1142 45.7344 31.6562C45.7344 31.1983 46.1045 30.8281 46.5625 30.8281C55.2388 30.8281 62.2969 37.8862 62.2969 46.5625C62.2969 47.0205 61.9267 47.3906 61.4688 47.3906Z" fill="#26355C"/>
    <path d="M38.2818 34.9944C38.0151 34.9944 37.7518 34.8661 37.5928 34.6267C37.3377 34.2466 37.4395 33.7324 37.8205 33.4781C40.4109 31.744 43.4343 30.8281 46.5622 30.8281C47.0201 30.8281 47.3903 31.1983 47.3903 31.6562C47.3903 32.1142 47.0201 32.4844 46.5622 32.4844C43.7631 32.4844 41.0585 33.3042 38.7414 34.8545C38.6006 34.9497 38.4399 34.9944 38.2818 34.9944Z" fill="#26355C"/>
    <path d="M61.4688 60.6406C61.0108 60.6406 60.6406 60.2705 60.6406 59.8125V46.5625C60.6406 46.1045 61.0108 45.7344 61.4688 45.7344C61.9267 45.7344 62.2969 46.1045 62.2969 46.5625V59.8125C62.2969 60.2705 61.9267 60.6406 61.4688 60.6406Z" fill="#26355C"/>
    <path d="M61.4688 60.6406C61.0108 60.6406 60.6406 60.2705 60.6406 59.8125C60.6406 49.5462 50.7329 47.3906 42.4219 47.3906C41.9639 47.3906 41.5938 47.0205 41.5938 46.5625C41.5938 46.1045 41.9639 45.7344 42.4219 45.7344C55.2388 45.7344 62.2969 50.7338 62.2969 59.8125C62.2969 60.2705 61.9267 60.6406 61.4688 60.6406Z" fill="#26355C"/>
    <path d="M58.9844 54.0166C58.5264 54.0166 58.1562 53.6464 58.1562 53.1885V52.145C58.1562 51.6871 58.5264 51.3169 58.9844 51.3169C59.4423 51.3169 59.8125 51.6871 59.8125 52.145V53.1885C59.8125 53.6464 59.4423 54.0166 58.9844 54.0166Z" fill="#26355C"/>
    <path d="M38.2812 43.2498C37.8233 43.2498 37.4531 42.8797 37.4531 42.4217V34.1661C37.4531 33.7082 37.8233 33.338 38.2812 33.338C38.7392 33.338 39.1094 33.7082 39.1094 34.1661V42.4217C39.1094 42.8797 38.7392 43.2498 38.2812 43.2498Z" fill="#26355C"/>
    <path d="M34.1406 47.3906C33.6827 47.3906 33.3125 47.0205 33.3125 46.5625C33.3125 46.1045 33.6827 45.7344 34.1406 45.7344C35.9675 45.7344 37.4531 44.2487 37.4531 42.4219C37.4531 41.9639 37.8233 41.5938 38.2812 41.5938C38.7392 41.5938 39.1094 41.9639 39.1094 42.4219C39.1094 45.1621 36.8809 47.3906 34.1406 47.3906Z" fill="#26355C"/>
    <path d="M42.4219 47.3906C39.6816 47.3906 37.4531 45.1621 37.4531 42.4219C37.4531 41.9639 37.8233 41.5938 38.2812 41.5938C38.7392 41.5938 39.1094 41.9639 39.1094 42.4219C39.1094 44.2487 40.595 45.7344 42.4219 45.7344C42.8798 45.7344 43.25 46.1045 43.25 46.5625C43.25 47.0205 42.8798 47.3906 42.4219 47.3906Z" fill="#26355C"/>
    <path d="M34.1406 39.1094C33.6827 39.1094 33.3125 38.7392 33.3125 38.2812C33.3125 35.541 35.541 33.3125 38.2812 33.3125C38.7392 33.3125 39.1094 33.6827 39.1094 34.1406C39.1094 34.5986 38.7392 34.9688 38.2812 34.9688C36.4544 34.9688 34.9688 36.4544 34.9688 38.2812C34.9688 38.7392 34.5986 39.1094 34.1406 39.1094Z" fill="#26355C"/>
    <path d="M42.884 70.5798C42.5677 70.5798 42.2663 70.3984 42.128 70.092C41.94 69.6747 42.1263 69.1844 42.5428 68.9964L46.2222 67.3385C46.6412 67.1505 47.1307 67.3369 47.3178 67.7534C47.5058 68.1708 47.3195 68.661 46.9029 68.849L43.2236 70.5069C43.1126 70.5566 42.9967 70.5798 42.884 70.5798Z" fill="#26355C"/>
    <path d="M50.2422 70.5782C50.1288 70.5782 50.0137 70.555 49.9035 70.5053L46.2233 68.849C45.806 68.661 45.6196 68.1716 45.8076 67.7543C45.9964 67.3369 46.4867 67.1522 46.9024 67.3385L50.5826 68.9948C51 69.1828 51.1863 69.6722 50.9983 70.0896C50.86 70.3968 50.5586 70.5782 50.2422 70.5782Z" fill="#26355C"/>
    <path d="M38.2815 70.5796C38.0695 70.5796 37.8575 70.4985 37.696 70.337C37.3722 70.0132 37.3722 69.4898 37.696 69.166L41.0085 65.8519C41.3323 65.5281 41.8556 65.5281 42.1794 65.8519C42.5032 66.1757 42.5032 66.699 42.1794 67.0228L38.8669 70.337C38.7055 70.4985 38.4935 70.5796 38.2815 70.5796Z" fill="#26355C"/>
    <path d="M40.7665 73.8904C40.5147 73.8904 40.2663 73.7761 40.1031 73.56L37.6188 70.2491C37.3447 69.8831 37.4184 69.3638 37.7836 69.0897C38.1512 68.8173 38.6697 68.8893 38.9429 69.2545L41.4273 72.5654C41.7014 72.9314 41.6277 73.4506 41.2625 73.7247C41.1135 73.8365 40.9395 73.8904 40.7665 73.8904Z" fill="#26355C"/>
    <path d="M39.1096 75.5468C38.8976 75.5468 38.6856 75.4657 38.5241 75.3042C38.2003 74.9804 38.2003 74.457 38.5241 74.1332L40.1803 72.477C40.5041 72.1532 41.0275 72.1532 41.3513 72.477C41.6751 72.8008 41.6751 73.3242 41.3513 73.6479L39.6951 75.3042C39.5336 75.4657 39.3216 75.5468 39.1096 75.5468Z" fill="#26355C"/>
    <path d="M44.0793 82.1717C43.8267 82.1717 43.5791 82.0574 43.4159 81.8405L38.4472 75.2155C38.1723 74.8503 38.2468 74.331 38.6128 74.0569C38.978 73.782 39.4964 73.8557 39.7714 74.2226L44.7401 80.8476C45.015 81.2128 44.9405 81.732 44.5745 82.0061C44.4263 82.1179 44.2523 82.1717 44.0793 82.1717Z" fill="#26355C"/>
    <path d="M45.7339 82.1716C45.3712 82.1716 45.0383 81.9314 44.9364 81.5654L40.7958 66.6592C40.6741 66.2186 40.9316 65.7623 41.3722 65.6397C41.8119 65.5188 42.2699 65.7756 42.3916 66.2161L46.5322 81.1224C46.654 81.5629 46.3964 82.0192 45.9558 82.1418C45.8821 82.1617 45.8076 82.1716 45.7339 82.1716Z" fill="#26355C"/>
    <path d="M54.8441 70.5797C54.6321 70.5797 54.4201 70.4986 54.2586 70.3371L50.9461 67.0229C50.6223 66.6992 50.6223 66.1758 50.9461 65.852C51.2699 65.5282 51.7933 65.5282 52.1171 65.852L55.4296 69.1661C55.7534 69.4899 55.7534 70.0133 55.4296 70.3371C55.2681 70.4986 55.0561 70.5797 54.8441 70.5797Z" fill="#26355C"/>
    <path d="M52.3587 73.8906C52.1856 73.8906 52.0117 73.8368 51.8618 73.725C51.4966 73.4509 51.4229 72.9316 51.697 72.5656L54.1814 69.2548C54.4555 68.8887 54.9739 68.8167 55.3408 69.09C55.706 69.3641 55.7797 69.8833 55.5056 70.2493L53.0212 73.5602C52.8597 73.7763 52.6104 73.8906 52.3587 73.8906Z" fill="#26355C"/>
    <path d="M54.016 75.5468C53.804 75.5468 53.592 75.4657 53.4305 75.3042L51.7742 73.6479C51.4504 73.3242 51.4504 72.8008 51.7742 72.477C52.098 72.1532 52.6214 72.1532 52.9452 72.477L54.6014 74.1332C54.9252 74.457 54.9252 74.9804 54.6014 75.3042C54.44 75.4657 54.228 75.5468 54.016 75.5468Z" fill="#26355C"/>
    <path d="M49.0456 82.172C48.8726 82.172 48.6986 82.1182 48.5496 82.0064C48.1836 81.7322 48.109 81.213 48.384 80.8478L53.3527 74.2228C53.6276 73.8568 54.1461 73.7831 54.5113 74.0572C54.8773 74.3313 54.9518 74.8505 54.6769 75.2157L49.7081 81.8407C49.5466 82.0577 49.2982 82.172 49.0456 82.172Z" fill="#26355C"/>
    <path d="M47.3912 82.172C47.3175 82.172 47.243 82.162 47.1685 82.1422C46.7279 82.0196 46.4695 81.5633 46.5921 81.1227L50.7327 66.2165C50.8553 65.7759 51.3124 65.5192 51.7522 65.6401C52.1927 65.7627 52.4511 66.219 52.3285 66.6595L48.1879 81.5658C48.0869 81.9318 47.7531 82.172 47.3912 82.172Z" fill="#26355C"/>
    <path d="M69.9513 43.0023C69.6913 43.0023 69.4313 42.9013 69.2353 42.7003L67.2843 40.7003C66.8983 40.3043 66.9063 39.6723 67.3023 39.2863C67.6973 38.9013 68.3293 38.9083 68.7163 39.3043L70.6673 41.3043C71.0533 41.7003 71.0453 42.3323 70.6493 42.7183C70.4553 42.9073 70.2033 43.0023 69.9513 43.0023Z" fill="#26355C"/>
    <path d="M69.9513 43.0023C69.6953 43.0023 69.4393 42.9042 69.2443 42.7092C68.8533 42.3182 68.8533 41.6862 69.2443 41.2952L73.2463 37.2932C73.6373 36.9022 74.2693 36.9022 74.6603 37.2932C75.0513 37.6842 75.0513 38.3162 74.6603 38.7072L70.6583 42.7092C70.4633 42.9042 70.2073 43.0023 69.9513 43.0023Z" fill="#26355C"/>
    <path d="M90.9534 39.0022H78.9534C78.4004 39.0022 77.9534 38.5552 77.9534 38.0022C77.9534 37.4492 78.4004 37.0022 78.9534 37.0022H90.9534C91.5064 37.0022 91.9534 37.4492 91.9534 38.0022C91.9534 38.5552 91.5064 39.0022 90.9534 39.0022Z" fill="#26355C"/>
    <path d="M84.9534 43.0022H78.9534C78.4004 43.0022 77.9534 42.5552 77.9534 42.0022C77.9534 41.4492 78.4004 41.0022 78.9534 41.0022H84.9534C85.5064 41.0022 85.9534 41.4492 85.9534 42.0022C85.9534 42.5552 85.5064 43.0022 84.9534 43.0022Z" fill="#26355C"/>
    <path d="M69.9513 54.0023C69.6913 54.0023 69.4313 53.9013 69.2353 53.7003L67.2843 51.7003C66.8983 51.3043 66.9063 50.6723 67.3023 50.2863C67.6973 49.9013 68.3293 49.9083 68.7163 50.3043L70.6673 52.3043C71.0533 52.7003 71.0453 53.3323 70.6493 53.7183C70.4553 53.9073 70.2033 54.0023 69.9513 54.0023Z" fill="#26355C"/>
    <path d="M69.9513 54.0023C69.6953 54.0023 69.4393 53.9042 69.2443 53.7092C68.8533 53.3182 68.8533 52.6862 69.2443 52.2952L73.2463 48.2932C73.6373 47.9022 74.2693 47.9022 74.6603 48.2932C75.0513 48.6842 75.0513 49.3162 74.6603 49.7072L70.6583 53.7092C70.4633 53.9042 70.2073 54.0023 69.9513 54.0023Z" fill="#26355C"/>
    <path d="M90.9534 50.0022H78.9534C78.4004 50.0022 77.9534 49.5552 77.9534 49.0022C77.9534 48.4492 78.4004 48.0022 78.9534 48.0022H90.9534C91.5064 48.0022 91.9534 48.4492 91.9534 49.0022C91.9534 49.5552 91.5064 50.0022 90.9534 50.0022Z" fill="#26355C"/>
    <path d="M84.9534 54.0022H78.9534C78.4004 54.0022 77.9534 53.5552 77.9534 53.0022C77.9534 52.4492 78.4004 52.0022 78.9534 52.0022H84.9534C85.5064 52.0022 85.9534 52.4492 85.9534 53.0022C85.9534 53.5552 85.5064 54.0022 84.9534 54.0022Z" fill="#26355C"/>
    <path d="M69.9513 65.0023C69.6913 65.0023 69.4313 64.9013 69.2353 64.7003L67.2843 62.7003C66.8983 62.3043 66.9063 61.6723 67.3023 61.2863C67.6973 60.9013 68.3293 60.9083 68.7163 61.3043L70.6673 63.3043C71.0533 63.7003 71.0453 64.3323 70.6493 64.7183C70.4553 64.9073 70.2033 65.0023 69.9513 65.0023Z" fill="#26355C"/>
    <path d="M69.9513 65.0023C69.6953 65.0023 69.4393 64.9042 69.2443 64.7092C68.8533 64.3182 68.8533 63.6862 69.2443 63.2952L73.2463 59.2932C73.6373 58.9022 74.2693 58.9022 74.6603 59.2932C75.0513 59.6842 75.0513 60.3162 74.6603 60.7072L70.6583 64.7092C70.4633 64.9042 70.2073 65.0023 69.9513 65.0023Z" fill="#26355C"/>
    <path d="M90.9534 61.0022H78.9534C78.4004 61.0022 77.9534 60.5552 77.9534 60.0022C77.9534 59.4492 78.4004 59.0022 78.9534 59.0022H90.9534C91.5064 59.0022 91.9534 59.4492 91.9534 60.0022C91.9534 60.5552 91.5064 61.0022 90.9534 61.0022Z" fill="#26355C"/>
    <path d="M84.9534 65.0022H78.9534C78.4004 65.0022 77.9534 64.5552 77.9534 64.0022C77.9534 63.4492 78.4004 63.0022 78.9534 63.0022H84.9534C85.5064 63.0022 85.9534 63.4492 85.9534 64.0022C85.9534 64.5552 85.5064 65.0022 84.9534 65.0022Z" fill="#26355C"/>
  </svg>
</template>

<script>
export default {
  name: "VirtualAssistantIcon"
}
</script>

<style scoped>

</style>
