<template>
  <button
    type="button"
    @click.stop="toggle"
    class="focus:outline-none"
    :disabled="disabled"
  >
    <slot />
    <portal to="modal">
      <div class="modal" @click="close">
        <transition
          enter-active-class="ease-out duration-300"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="ease-in duration-100"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-if="modalOpened"
            class="fixed inset-0 transition-opacity pointer-events-auto"
          >
            <div class="absolute inset-0" :class="overlay"></div>
          </div>
        </transition>

        <transition
          enter-active-class="ease-out duration-300"
          enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to-class="opacity-100 translate-y-0 sm:scale-100"
          leave-active-class="ease-in duration-100"
          leave-class="opacity-100 translate-y-0 sm:scale-100"
          leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            v-if="modalOpened"
            @click.stop
            class="
              transition-all
              transform
              flex
              items-center
              justify-center
              w-full
              h-full
              pointer-events-auto
              overflow-y-auto
            "
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div class="relative" :class="width">
              <div
                v-if="withCloseButton"
                class="absolute right-0 z-50"
                :class="closeButtonOutside ? '-top-6' : 'top-0 mt-4 mr-4'"
              >
                <button
                  @click.prevent="close"
                  type="button"
                  class="
                    rounded-full
                    text-fonts-tertiary
                    opacity-75
                    hover:opacity-100
                    focus:outline-none
                  "
                >
                  <XIcon />
                </button>
              </div>
              <slot
                name="content"
                v-bind:close="close"
                v-bind:classes="'pt-8'"
              />
            </div>
          </div>
        </transition>
      </div>
    </portal>
  </button>
</template>

<script>
import XIcon from "@/components/shared/svg/XIcon";

export default {
  name: "Modal",
  components: { XIcon },
  props: {
    withCloseButton: {
      type: Boolean,
      default: true,
    },
    closeButtonOutside: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: String,
      default: "bg-gray-900 opacity-75",
    },
    width: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modalOpened: false,
    };
  },

  watch: {
    modalOpened(opened) {
      document.body.classList.toggle("overflow-hidden", opened);

      this.$emit(opened ? "opened" : "closed");
    },
  },

  methods: {
    toggle() {
      this.modalOpened = !this.modalOpened;
    },

    close() {
      this.modalOpened = false;
      this.$emit("close");
    },

    handleEscape(e) {
      if (e.keyCode === 27) {
        this.close();
      }
    },
  },

  mounted() {
    document.addEventListener("keyup", this.handleEscape);
  },

  destroyed() {
    document.removeEventListener("keyup", this.handleEscape);
    document.body.classList.remove("overflow-hidden");
  },
};
</script>

<style scoped>
.modal {
  @apply fixed bottom-0 inset-x-0 px-4 inset-0 pb-6 xl:p-0 sm:flex sm:items-center sm:justify-center pointer-events-none z-50;
}
</style>