<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.64 8.6496H3.36002"
      stroke="#2C5DE5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.16002 2.7744V4.9344"
      stroke="#2C5DE5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.84 2.7744V4.9344"
      stroke="#2C5DE5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.76 20.1734H6.24002C4.6493 20.1734 3.36002 18.8842 3.36002 17.2934V6.73344C3.36002 5.14272 4.6493 3.85344 6.24002 3.85344H17.76C19.3507 3.85344 20.64 5.14272 20.64 6.73344V17.2934C20.64 18.8842 19.3507 20.1734 17.76 20.1734Z"
      stroke="#2C5DE5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.392 13.2806L14.7936 12.0134"
      stroke="#2C5DE5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.5072 16.8134H16.08"
      stroke="#2C5DE5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.7936 12.0134V16.8134"
      stroke="#2C5DE5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.16 13.2806L9.5616 12.0134"
      stroke="#2C5DE5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.27521 16.8134H10.848"
      stroke="#2C5DE5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.5616 12.0134V16.8134"
      stroke="#2C5DE5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "CalendarIcon",
};
</script>

<style scoped></style>
