<template>
  <div v-if="loading" class="flex items-center justify-center">
    <loading-icon class="h-2"/>
  </div>
  <div v-else class="w-full grid grid-cols-3 gap-5">
    <div @click.stop="selectTemplate(template)" v-for="(template, index) in templates" :key="index">
      <TemplateCard
        :title="template.title"
        :iconName="template.icon"
        :summary="template.summary"
        :active="currentTemplate && currentTemplate.id == template.id"
      />
    </div>
  </div>
</template>

<script>
import TemplateCard from "@/components/jobs/postJob/partials/TemplateCard";
import { mapActions, mapState } from 'vuex';

export default {
  name: "SelectJobTemplate",
  components: { TemplateCard },

  data() {
    return {
      loading: false
    }
  },

  mounted() {
    this.loading = true;
    this.fetchTemplates().finally(()=> this.loading = false)
  },

  methods: {
    ...mapActions({
      fetchTemplates: 'jobs/fetchTemplates',
    }),

    selectTemplate(template){
      this.$emit('select', template)
    }
  },

  computed: {
    ...mapState({
      templates: state => state.jobs.templates,
      currentTemplate: state => state.jobs.currentTemplate,
    }),
  },
}
</script>