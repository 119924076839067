<template>
  <svg width="113" height="113" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="113" height="113" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8122 57.2029C17.7968 48.7236 15.3308 39.4727 20.1703 32.4479C25.0512 25.363 34.2656 23.5985 42.2135 20.3311C50.9286 16.7481 59.8872 9.06478 68.6554 12.515C77.4559 15.9779 77.5138 28.4961 82.8471 36.3199C87.9429 43.7953 98.8409 48.1537 99.1182 57.2029C99.3954 66.2498 90.5397 72.6364 84.1562 79.0391C78.7166 84.4953 72.0669 87.7477 65.1978 91.2258C56.8476 95.454 49.1245 102.348 39.8078 101.495C29.6297 100.564 18.6078 95.6173 13.8902 86.5307C9.2519 77.5967 15.6506 67.2064 16.8122 57.2029Z" fill="#EAECFB"/>
  <path d="M81.0088 45L76.0088 48V42L81.0088 45Z" fill="white"/>
  <path d="M87.999 35H26.019H25.999V33C25.999 31.89 26.898 31 27.999 31H85.999C87.108 31 87.999 31.89 87.999 33V35Z" fill="#CCD1D9"/>
  <path d="M76.0088 48L81.0088 45L76.0088 42V48ZM84.0088 40V50H72.0088V40H84.0088Z" fill="#ED5565"/>
  <path d="M81.0088 59L76.0088 62V56L81.0088 59Z" fill="white"/>
  <path d="M76.0088 62L81.0088 59L76.0088 56V62ZM84.0088 54V64H72.0088V54H84.0088Z" fill="#ED5565"/>
  <path d="M76.0088 76L81.0088 73L76.0088 70V76ZM84.0088 68V78H72.0088V68H84.0088Z" fill="#ED5565"/>
  <path d="M81.0088 73L76.0088 76V70L81.0088 73Z" fill="white"/>
  <path d="M41.0088 53.5V53.99C41.3488 54.04 41.6888 54.09 42.0088 54.13V57.99V58C42.0088 60.79 43.6488 63.2 46.0088 64.31V64.321V67.991V68.001H43.0088V67.991C41.3488 67.991 40.0088 69.341 40.0088 70.991V74.001H34.0088H30.0088V40.001H68.0088V74.001H64.0088H58.0088V70.991C58.0088 69.341 56.6688 67.991 55.0088 67.991V68.001H52.0088V67.991V64.321V64.31C54.3678 63.201 56.0088 60.79 56.0088 58V57.99V54.87V53.5C56.0088 51.54 55.2488 49.74 53.9988 48.41C52.6478 46.93 50.6878 46 48.5088 46C44.3678 46 41.0088 49.359 41.0088 53.5Z" fill="#ED5565"/>
  <path d="M68.0088 74H64.0088V78H68.0088V74Z" fill="#434A54"/>
  <path d="M51.0088 76C51.0088 74.89 50.1178 74 49.0088 74H58.0088H64.0088V78H49.0088C50.1178 78 51.0088 77.1 51.0088 76Z" fill="#656D78"/>
  <path d="M34.0088 74H30.0088V78H34.0088V74Z" fill="#434A54"/>
  <path d="M88.0186 35V82H26.0186V35H87.9986H88.0186ZM84.0086 78V68H72.0086V78H84.0086ZM84.0086 64V54H72.0086V64H84.0086ZM84.0086 50V40H72.0086V50H84.0086ZM68.0086 78V74V40H30.0086V74V78H34.0086H49.0086H64.0086H68.0086Z" fill="#E6E9ED"/>
  <path d="M49.0088 78H34.0088V74H40.0088H49.0088C47.9078 74 47.0088 74.89 47.0088 76C47.0088 77.1 47.9078 78 49.0088 78Z" fill="#656D78"/>
  <path d="M49.0088 74C50.1178 74 51.0088 74.89 51.0088 76C51.0088 77.1 50.1178 78 49.0088 78C47.9078 78 47.0088 77.1 47.0088 76C47.0088 74.89 47.9078 74 49.0088 74Z" fill="#AAB2BD"/>
  <path d="M52.0088 64.3201V67.9901V68.0001L51.0088 68.9901L49.0088 67.9901L47.0088 68.9901L46.0088 68.0001V67.9901V64.3201V64.3101C46.9188 64.7501 47.9388 64.9911 49.0088 64.9911C50.0778 64.9911 51.0988 64.7511 52.0088 64.3101V64.3201Z" fill="#26355C"/>
  <path d="M51.0088 68.9902L52.0088 68.0002H55.0088V67.9902C56.6688 67.9902 58.0088 69.3402 58.0088 70.9902V74.0002H49.0088H40.0088V70.9902C40.0088 69.3402 41.3488 67.9902 43.0088 67.9902V68.0002H46.0088L47.0088 68.9902L49.0088 67.9902L51.0088 68.9902Z" fill="#26355C"/>
  <path d="M56.0088 54.8702C54.2878 52.6302 53.2488 52.2202 52.0088 52.4792V52.4692L53.9988 48.4092C55.2488 49.7402 56.0088 51.5402 56.0088 53.5002V54.8702Z" fill="#26355C"/>
  <path d="M56.0088 57.9899V57.9999C56.0088 60.7899 54.3678 63.1999 52.0088 64.3099C51.0988 64.7499 50.0778 64.9909 49.0088 64.9909C47.9388 64.9909 46.9188 64.7509 46.0088 64.3099C43.6488 63.2009 42.0088 60.7899 42.0088 57.9999V57.9899V54.1299C47.8188 54.8099 50.1388 52.8589 52.0088 52.4799C53.2488 52.2199 54.2878 52.6299 56.0088 54.8709V57.9899Z" fill="#26355C"/>
  <path d="M53.9988 48.41L52.0088 52.47V52.48C50.1388 52.86 47.8188 54.81 42.0088 54.13C41.6888 54.09 41.3488 54.04 41.0088 53.99V53.5C41.0088 49.359 44.3678 46 48.5088 46C50.6888 46 52.6488 46.93 53.9988 48.41Z" fill="#26355C"/>
  <path d="M58.0088 71V74H40.0088V71C40.0088 69.34 41.3488 68 43.0088 68V68.01H46.0088L47.0088 69L49.0088 68L51.0088 69L52.0088 68.01H55.0088V68C56.6688 68 58.0088 69.34 58.0088 71Z" fill="#E6E9ED"/>
  <path d="M55.009 68V68.01H52.009L51.876 68.142C53.109 68.515 54.009 69.644 54.009 71V74H58.009V71C58.009 69.34 56.669 68 55.009 68Z" fill="#CCD1D9"/>
  <path d="M52.0088 67.9998V68.0098L51.0088 68.9998L49.0088 67.9998L47.0088 68.9998L46.0088 68.0098V67.9998V64.3198C46.9188 64.7588 47.9288 64.9998 49.0088 64.9998C50.0888 64.9998 51.0988 64.7598 52.0088 64.3198V67.9998Z" fill="#F5F7FA"/>
  <path d="M56.0088 53.4999V54.8799C54.2888 52.6299 53.2488 52.2199 52.0088 52.4799L54.0088 48.4199C55.2488 49.7499 56.0088 51.5399 56.0088 53.4999Z" fill="#EDAB37"/>
  <path d="M54.0088 48.42L52.0088 52.48C50.1388 52.871 47.8188 54.821 42.0088 54.14C41.6888 54.1 41.3588 54.06 41.0088 54V53.5C41.0088 49.36 44.3688 46 48.5088 46C50.6888 46 52.6488 46.93 54.0088 48.42Z" fill="#FFCE54"/>
  <path d="M48.5089 46C48.1809 46 47.8599 46.026 47.5429 46.069C47.4959 46.075 47.4509 46.083 47.4049 46.09C47.0989 46.137 46.7989 46.197 46.5069 46.278C46.5069 46.278 46.5059 46.278 46.5049 46.278C47.8699 46.656 49.0809 47.402 50.0089 48.419C51.0329 49.519 51.7229 50.934 51.9339 52.498C51.9579 52.492 51.9849 52.483 52.0089 52.478L54.0089 48.418C52.6489 46.93 50.6889 46 48.5089 46Z" fill="#F6BB42"/>
  <path d="M56.0088 54.8799V57.9999C56.0088 60.7899 54.3788 63.1999 52.0088 64.3199C51.0988 64.7589 50.0888 64.9999 49.0088 64.9999C47.9288 64.9999 46.9188 64.7599 46.0088 64.3199C43.6388 63.1999 42.0088 60.7899 42.0088 57.9999V54.1399C47.8188 54.8209 50.1388 52.8699 52.0088 52.4799C53.2488 52.2199 54.2888 52.6299 56.0088 54.8799Z" fill="#EAC6BB"/>
  <path d="M52.0088 52.479V58C52.0088 60.79 50.3788 63.2 48.0088 64.32C47.6888 64.474 47.3528 64.594 47.0088 64.698C47.6418 64.889 48.3088 65 49.0088 65C50.0888 65 51.0988 64.76 52.0088 64.32C54.3788 63.2 56.0088 60.79 56.0088 58V54.88C54.2888 52.63 53.2488 52.22 52.0088 52.479Z" fill="#D3B1A9"/>
  <path d="M29.0304 34C28.4774 34 28.0254 33.553 28.0254 33C28.0254 32.447 28.4674 32 29.0194 32H29.0304C29.5824 32 30.0304 32.447 30.0304 33C30.0304 33.553 29.5824 34 29.0304 34Z" fill="#26355C"/>
  <path d="M32.0304 34C31.4774 34 31.0254 33.553 31.0254 33C31.0254 32.447 31.4674 32 32.0194 32H32.0304C32.5824 32 33.0304 32.447 33.0304 33C33.0304 33.553 32.5824 34 32.0304 34Z" fill="#26355C"/>
  <path d="M35.0304 34C34.4774 34 34.0254 33.553 34.0254 33C34.0254 32.447 34.4674 32 35.0194 32H35.0304C35.5824 32 36.0304 32.447 36.0304 33C36.0304 33.553 35.5824 34 35.0304 34Z" fill="#26355C"/>
  <path d="M88.0176 83H26.0176C25.4646 83 25.0176 82.553 25.0176 82V35C25.0176 34.447 25.4646 34 26.0176 34H88.0176C88.5706 34 89.0176 34.447 89.0176 35V82C89.0176 82.553 88.5706 83 88.0176 83ZM27.0176 81H87.0176V36H27.0176V81Z" fill="#26355C"/>
  <path d="M26 34C25.447 34 25 33.553 25 33C25 31.346 26.346 30 28 30C28.553 30 29 30.447 29 31C29 31.553 28.553 32 28 32C27.448 32 27 32.448 27 33C27 33.553 26.553 34 26 34Z" fill="#26355C"/>
  <path d="M26 36C25.447 36 25 35.553 25 35V33C25 32.447 25.447 32 26 32C26.553 32 27 32.447 27 33V35C27 35.553 26.553 36 26 36Z" fill="#26355C"/>
  <path d="M88 34C87.447 34 87 33.553 87 33C87 32.448 86.552 32 86 32C85.447 32 85 31.553 85 31C85 30.447 85.447 30 86 30C87.654 30 89 31.346 89 33C89 33.553 88.553 34 88 34Z" fill="#26355C"/>
  <path d="M88 36C87.447 36 87 35.553 87 35V33C87 32.447 87.447 32 88 32C88.553 32 89 32.447 89 33V35C89 35.553 88.553 36 88 36Z" fill="#26355C"/>
  <path d="M86 32H28C27.447 32 27 31.553 27 31C27 30.447 27.447 30 28 30H86C86.553 30 87 30.447 87 31C87 31.553 86.553 32 86 32Z" fill="#26355C"/>
  <path d="M68.0088 79H30.0088C29.4558 79 29.0088 78.553 29.0088 78V40C29.0088 39.447 29.4558 39 30.0088 39H68.0088C68.5618 39 69.0088 39.447 69.0088 40V78C69.0088 78.553 68.5618 79 68.0088 79ZM31.0088 77H67.0088V41H31.0088V77Z" fill="#26355C"/>
  <path d="M68.0088 75H30.0088C29.4558 75 29.0088 74.553 29.0088 74C29.0088 73.447 29.4558 73 30.0088 73H68.0088C68.5618 73 69.0088 73.447 69.0088 74C69.0088 74.553 68.5618 75 68.0088 75Z" fill="#26355C"/>
  <path d="M64.0088 79C63.4558 79 63.0088 78.553 63.0088 78V74C63.0088 73.447 63.4558 73 64.0088 73C64.5618 73 65.0088 73.447 65.0088 74V78C65.0088 78.553 64.5618 79 64.0088 79Z" fill="#26355C"/>
  <path d="M34.0088 79C33.4558 79 33.0088 78.553 33.0088 78V74C33.0088 73.447 33.4558 73 34.0088 73C34.5618 73 35.0088 73.447 35.0088 74V78C35.0088 78.553 34.5618 79 34.0088 79Z" fill="#26355C"/>
  <path d="M42.0088 58.9998C41.4558 58.9998 41.0088 58.5528 41.0088 57.9998V54.1328C41.0088 53.5798 41.4558 53.1328 42.0088 53.1328C42.5618 53.1328 43.0088 53.5798 43.0088 54.1328V57.9998C43.0088 58.5528 42.5618 58.9998 42.0088 58.9998Z" fill="#26355C"/>
  <path d="M56.0088 59C55.4558 59 55.0088 58.553 55.0088 58V53.5C55.0088 52.947 55.4558 52.5 56.0088 52.5C56.5618 52.5 57.0088 52.947 57.0088 53.5V58C57.0088 58.553 56.5618 59 56.0088 59Z" fill="#26355C"/>
  <path d="M56.0088 54.5C55.4558 54.5 55.0088 54.053 55.0088 53.5C55.0088 49.916 52.0928 47 48.5078 47C44.9238 47 42.0088 49.916 42.0088 53.5C42.0088 54.053 41.5618 54.5 41.0088 54.5C40.4558 54.5 40.0088 54.053 40.0088 53.5C40.0088 48.813 43.8218 45 48.5078 45C53.1958 45 57.0088 48.813 57.0088 53.5C57.0088 54.053 56.5618 54.5 56.0088 54.5Z" fill="#26355C"/>
  <path d="M56.0094 55.8711C55.7084 55.8711 55.4114 55.7361 55.2144 55.4791C53.2824 52.9561 52.7584 53.1661 50.8004 53.9411C48.9224 54.6841 46.0844 55.8111 40.8524 54.9831C40.3074 54.8971 39.9344 54.3851 40.0214 53.8391C40.1074 53.2941 40.6224 52.9181 41.1654 53.0081C45.8574 53.7491 48.2884 52.7851 50.0644 52.0811C52.4724 51.1261 54.0884 50.7191 56.8024 54.2621C57.1384 54.7001 57.0554 55.3281 56.6164 55.6641C56.4354 55.8041 56.2214 55.8711 56.0094 55.8711Z" fill="#26355C"/>
  <path d="M52.0082 53.4769C51.8612 53.4769 51.7102 53.4439 51.5692 53.3739C51.0732 53.1309 50.8692 52.5309 51.1122 52.0359L53.1122 47.9639C53.3552 47.4669 53.9532 47.2629 54.4502 47.5069C54.9462 47.7499 55.1502 48.3499 54.9072 48.8449L52.9072 52.9169C52.7332 53.2709 52.3772 53.4769 52.0082 53.4769Z" fill="#26355C"/>
  <path d="M40.0088 71.9961C39.4558 71.9961 39.0088 71.5491 39.0088 70.9961C39.0088 68.7901 40.8028 66.9961 43.0088 66.9961C43.5618 66.9961 44.0088 67.4431 44.0088 67.9961C44.0088 68.5491 43.5618 68.9961 43.0088 68.9961C41.9058 68.9961 41.0088 69.8931 41.0088 70.9961C41.0088 71.5491 40.5618 71.9961 40.0088 71.9961Z" fill="#26355C"/>
  <path d="M46.0088 69.002H43.0088C42.4558 69.002 42.0088 68.555 42.0088 68.002C42.0088 67.449 42.4558 67.002 43.0088 67.002H46.0088C46.5618 67.002 47.0088 67.449 47.0088 68.002C47.0088 68.555 46.5618 69.002 46.0088 69.002Z" fill="#26355C"/>
  <path d="M40.0088 75.0001C39.4558 75.0001 39.0088 74.5531 39.0088 74.0001V70.9961C39.0088 70.4431 39.4558 69.9961 40.0088 69.9961C40.5618 69.9961 41.0088 70.4431 41.0088 70.9961V74.0001C41.0088 74.5531 40.5618 75.0001 40.0088 75.0001Z" fill="#26355C"/>
  <path d="M58.0088 71.9961C57.4558 71.9961 57.0088 71.5491 57.0088 70.9961C57.0088 69.8931 56.1118 68.9961 55.0088 68.9961C54.4558 68.9961 54.0088 68.5491 54.0088 67.9961C54.0088 67.4431 54.4558 66.9961 55.0088 66.9961C57.2148 66.9961 59.0088 68.7901 59.0088 70.9961C59.0088 71.5491 58.5618 71.9961 58.0088 71.9961Z" fill="#26355C"/>
  <path d="M55.0088 69.002H52.0088C51.4558 69.002 51.0088 68.555 51.0088 68.002C51.0088 67.449 51.4558 67.002 52.0088 67.002H55.0088C55.5618 67.002 56.0088 67.449 56.0088 68.002C56.0088 68.555 55.5618 69.002 55.0088 69.002Z" fill="#26355C"/>
  <path d="M58.0088 75.0001C57.4558 75.0001 57.0088 74.5531 57.0088 74.0001V70.9961C57.0088 70.4431 57.4558 69.9961 58.0088 69.9961C58.5618 69.9961 59.0088 70.4431 59.0088 70.9961V74.0001C59.0088 74.5531 58.5618 75.0001 58.0088 75.0001Z" fill="#26355C"/>
  <path d="M46.0088 69.0019C45.4558 69.0019 45.0088 68.5549 45.0088 68.0019V64.3179C45.0088 63.7649 45.4558 63.3179 46.0088 63.3179C46.5618 63.3179 47.0088 63.7649 47.0088 64.3179V68.0019C47.0088 68.5549 46.5618 69.0019 46.0088 69.0019Z" fill="#26355C"/>
  <path d="M52.0088 68.9959C51.4558 68.9959 51.0088 68.5489 51.0088 67.9959V64.3179C51.0088 63.7649 51.4558 63.3179 52.0088 63.3179C52.5618 63.3179 53.0088 63.7649 53.0088 64.3179V67.9959C53.0088 68.5489 52.5618 68.9959 52.0088 68.9959Z" fill="#26355C"/>
  <path d="M49.0088 65.9921C44.5978 65.9921 41.0088 62.4051 41.0088 57.9961C41.0088 57.4431 41.4558 56.9961 42.0088 56.9961C42.5618 56.9961 43.0088 57.4431 43.0088 57.9961C43.0088 61.3031 45.6998 63.9921 49.0088 63.9921C52.3178 63.9921 55.0088 61.3031 55.0088 57.9961C55.0088 57.4431 55.4558 56.9961 56.0088 56.9961C56.5618 56.9961 57.0088 57.4431 57.0088 57.9961C57.0088 62.4051 53.4198 65.9921 49.0088 65.9921Z" fill="#26355C"/>
  <path d="M47.0097 69.9961C46.6427 69.9961 46.2897 69.7941 46.1137 69.4431C45.8667 68.9491 46.0667 68.3481 46.5607 68.1011L48.5607 67.1011C49.0547 66.8561 49.6547 67.0541 49.9027 67.5481C50.1497 68.0421 49.9497 68.6431 49.4557 68.8901L47.4557 69.8901C47.3127 69.9621 47.1597 69.9961 47.0097 69.9961Z" fill="#26355C"/>
  <path d="M51.009 69.9959C50.753 69.9959 50.497 69.8979 50.302 69.7029C49.911 69.3119 49.911 68.6799 50.302 68.2889L51.302 67.2889C51.693 66.8979 52.325 66.8979 52.716 67.2889C53.107 67.6799 53.107 68.3119 52.716 68.7029L51.716 69.7029C51.521 69.8979 51.265 69.9959 51.009 69.9959Z" fill="#26355C"/>
  <path d="M47.009 69.9959C46.753 69.9959 46.497 69.8979 46.302 69.7029L45.302 68.7029C44.911 68.3119 44.911 67.6799 45.302 67.2889C45.693 66.8979 46.325 66.8979 46.716 67.2889L47.716 68.2889C48.107 68.6799 48.107 69.3119 47.716 69.7029C47.521 69.8979 47.265 69.9959 47.009 69.9959Z" fill="#26355C"/>
  <path d="M51.0076 69.996C50.8576 69.996 50.7046 69.962 50.5616 69.891L48.5616 68.891C48.0676 68.644 47.8676 68.043 48.1146 67.549C48.3626 67.055 48.9626 66.857 49.4566 67.102L51.4566 68.102C51.9506 68.349 52.1506 68.95 51.9036 69.444C51.7276 69.794 51.3746 69.996 51.0076 69.996Z" fill="#26355C"/>
  <path d="M41.0088 54.996C40.4558 54.996 40.0088 54.549 40.0088 53.996V53.5C40.0088 52.947 40.4558 52.5 41.0088 52.5C41.5618 52.5 42.0088 52.947 42.0088 53.5V53.996C42.0088 54.549 41.5618 54.996 41.0088 54.996Z" fill="#26355C"/>
  <path d="M49.0088 79C47.3548 79 46.0088 77.654 46.0088 76C46.0088 74.346 47.3548 73 49.0088 73C50.6628 73 52.0088 74.346 52.0088 76C52.0088 77.654 50.6628 79 49.0088 79ZM49.0088 75C48.4568 75 48.0088 75.448 48.0088 76C48.0088 76.552 48.4568 77 49.0088 77C49.5608 77 50.0088 76.552 50.0088 76C50.0088 75.448 49.5608 75 49.0088 75Z" fill="#26355C"/>
  <path d="M84.0088 51H72.0088C71.4558 51 71.0088 50.553 71.0088 50V40C71.0088 39.447 71.4558 39 72.0088 39H84.0088C84.5618 39 85.0088 39.447 85.0088 40V50C85.0088 50.553 84.5618 51 84.0088 51ZM73.0088 49H83.0088V41H73.0088V49Z" fill="#26355C"/>
  <path d="M76.0088 49C75.4558 49 75.0088 48.553 75.0088 48V42C75.0088 41.447 75.4558 41 76.0088 41C76.5618 41 77.0088 41.447 77.0088 42V48C77.0088 48.553 76.5618 49 76.0088 49Z" fill="#26355C"/>
  <path d="M76.0105 48.9999C75.6705 48.9999 75.3395 48.8269 75.1525 48.5149C74.8685 48.0409 75.0215 47.4269 75.4955 47.1429L80.4955 44.1429C80.9705 43.8579 81.5835 44.0129 81.8675 44.4859C82.1515 44.9599 81.9985 45.5739 81.5245 45.8579L76.5245 48.8579C76.3635 48.9539 76.1855 48.9999 76.0105 48.9999Z" fill="#26355C"/>
  <path d="M81.0085 46.0002C80.8335 46.0002 80.6555 45.9542 80.4945 45.8572L75.4945 42.8572C75.0205 42.5732 74.8675 41.9592 75.1515 41.4852C75.4355 41.0112 76.0495 40.8572 76.5235 41.1422L81.5235 44.1422C81.9975 44.4262 82.1505 45.0402 81.8665 45.5142C81.6795 45.8272 81.3485 46.0002 81.0085 46.0002Z" fill="#26355C"/>
  <path d="M84.0088 65H72.0088C71.4558 65 71.0088 64.553 71.0088 64V54C71.0088 53.447 71.4558 53 72.0088 53H84.0088C84.5618 53 85.0088 53.447 85.0088 54V64C85.0088 64.553 84.5618 65 84.0088 65ZM73.0088 63H83.0088V55H73.0088V63Z" fill="#26355C"/>
  <path d="M76.0088 63C75.4558 63 75.0088 62.553 75.0088 62V56C75.0088 55.447 75.4558 55 76.0088 55C76.5618 55 77.0088 55.447 77.0088 56V62C77.0088 62.553 76.5618 63 76.0088 63Z" fill="#26355C"/>
  <path d="M76.0105 63.0002C75.6705 63.0002 75.3395 62.8272 75.1525 62.5152C74.8685 62.0412 75.0215 61.4272 75.4955 61.1432L80.4955 58.1432C80.9705 57.8592 81.5835 58.0122 81.8675 58.4862C82.1515 58.9602 81.9985 59.5742 81.5245 59.8582L76.5245 62.8582C76.3635 62.9542 76.1855 63.0002 76.0105 63.0002Z" fill="#26355C"/>
  <path d="M81.0085 60C80.8335 60 80.6555 59.954 80.4945 59.857L75.4945 56.857C75.0205 56.573 74.8675 55.959 75.1515 55.485C75.4355 55.012 76.0495 54.858 76.5235 55.142L81.5235 58.142C81.9975 58.426 82.1505 59.04 81.8665 59.514C81.6795 59.827 81.3485 60 81.0085 60Z" fill="#26355C"/>
  <path d="M84.0088 79H72.0088C71.4558 79 71.0088 78.553 71.0088 78V68C71.0088 67.447 71.4558 67 72.0088 67H84.0088C84.5618 67 85.0088 67.447 85.0088 68V78C85.0088 78.553 84.5618 79 84.0088 79ZM73.0088 77H83.0088V69H73.0088V77Z" fill="#26355C"/>
  <path d="M76.0088 77C75.4558 77 75.0088 76.553 75.0088 76V70C75.0088 69.447 75.4558 69 76.0088 69C76.5618 69 77.0088 69.447 77.0088 70V76C77.0088 76.553 76.5618 77 76.0088 77Z" fill="#26355C"/>
  <path d="M76.0105 77.0002C75.6705 77.0002 75.3395 76.8272 75.1525 76.5152C74.8685 76.0412 75.0215 75.4272 75.4955 75.1432L80.4955 72.1432C80.9705 71.8592 81.5835 72.0122 81.8675 72.4862C82.1515 72.9602 81.9985 73.5742 81.5245 73.8582L76.5245 76.8582C76.3635 76.9542 76.1855 77.0002 76.0105 77.0002Z" fill="#26355C"/>
  <path d="M81.0085 74.0002C80.8335 74.0002 80.6555 73.9542 80.4945 73.8572L75.4945 70.8572C75.0205 70.5732 74.8675 69.9592 75.1515 69.4852C75.4355 69.0112 76.0495 68.8582 76.5235 69.1422L81.5235 72.1422C81.9975 72.4262 82.1505 73.0402 81.8665 73.5142C81.6795 73.8272 81.3485 74.0002 81.0085 74.0002Z" fill="#26355C"/>
  </svg>
</template>

<script>
export default {
  name: "VideoEditorIcon"
}
</script>

<style scoped>

</style>
