<template>
  <div class="flex flex-col items-center">
    <WomanLaptop/>
    <h2 class="font-bold text-xl w-3/12 text-center mb-12">Congrats! Your job bidding is being processed</h2>
    <ButtonIcon @onClick="goToJobs" text="Look for more jobs" class="mb-4 w-4/12"/>
    <ButtonIcon
      @onClick="goToJob"
      text="View job posting"
      class="w-4/12"
      background="bg-primary"
      hoverBackground="hover:bg-primary-70"
      hoverColor="hover:text-white"
    />
  </div>
</template>

<script>
import WomanLaptop from "@/components/shared/svg/WomanLaptop";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";

export default {
  name: 'Step2',
  components: {WomanLaptop, ButtonIcon},

  methods: {
    goToJobs() {
      this.$router.push({name:'Jobs'});
    },

    goToJob() {
      this.$router.push({name:'Job', params: { jobId: this.$route.params.job.id}});
    }
  },
}
</script>