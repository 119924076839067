<template>
  <div v-if="job" class="w-full lg:w-9/12 mx-auto">
    <!--Top Card-->
    <div class="bg-white rounded-xxl p-6 lg:px-10 lg:py-6 flex flex-col mb-10">
      <div class="flex flex-wrap md:flex-nowrap mb-5">
        <AvatarImage width="w-16 lg:w-24 mb-2 lg:b-0" height="h-16 lg:h-24" :image="job.church.logo_url"
                     class="mr-6 my-auto"/>
        <div class="flex flex-col w-full">
          <h4 class="heading-m-700">{{ job.title }}</h4>
          <div class="flex flex-col lg:flex-row text-lg mb-1">
            <span class="font-bold mr-4 mb-1">{{ job.church.name }}</span>
            <span>{{ job.church.location }}</span>
          </div>
          <div class="flex flex-wrap">
            <Categories :categories="job.categories"/>
          </div>
        </div>
        <DeleteJobDropdown v-if="showDropdown"/>
        <div v-if="showApplyButton" class="w-full lg:w-4/12 mt-3 lg:mt-0">
          <ApplyToJob :job="job" class="w-full flex justify-end"/>
        </div>
      </div>
      <div class="flex justify-between">
        <div class="flex flex-col">
          <span class="font-semibold">{{ applicantCount }}</span>
          <router-link v-if="showSeeAll" :to="{name:'JobBids', params:{jobId: job.id}}"
                       class="font-medium text-secondary-dark">See all
          </router-link>
        </div>
        <div class="label-m-700">
          <span class="text-auxiliary-blue mr-4" v-if="showNewLabel">New</span>
          <span class="text-fonts-tertiary">{{ createdAt }}</span>
        </div>
      </div>
    </div>

    <div class="bg-white rounded-xxl p-5 lg:px-10 lg:py-6">
      <div v-if="job.description" class="mb-10">
        <h4 class="heading-s-700 mb-3">Job Description</h4>
        <p class="body-m-400">{{ job.description }}</p>
      </div>

      <div>
        <h4 class="heading-s-700 mb-3">Tasks</h4>
        <ul>
          <li v-for="(task, index) in job.tasks" :key="index" class="flex items-center mb-2">
            <CircleIcon class="text-auxiliary-blue mr-3"/>
            <span class="body-m-400">{{ task.name }}</span>
          </li>
        </ul>
      </div>

      <div v-if="job.skills.length" class="mt-10">
        <h4 class="heading-s-700 mb-3">Skills needed</h4>
        <div class="flex flex-wrap">
          <Badge v-for="skill in job.skills" :key="skill.id" :text="skill.name"
                 background="bg-primary-10"
                 color="text-auxiliary-blue" class="mr-3 mb-3"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pluralize from 'pluralize';
import moment from 'moment'
import {mapActions, mapState} from "vuex";
import AvatarImage from "@/components/shared/AvatarImage";
import CircleIcon from "@/components/shared/svg/CircleIcon"
import Badge from "@/components/shared/Badge";
import Categories from "@/components/shared/Categories";
import DeleteJobDropdown from "@/components/jobs/DeleteJobDropdown";
import ApplyToJob from "@/components/jobs/ApplyToJob";

export default {
  name: "Job",
  components: {Badge, AvatarImage, CircleIcon, DeleteJobDropdown, ApplyToJob, Categories},
  props: {
    jobId: [String, Number]
  },
  mounted() {
    this.fetchJob({jobId: this.jobId, includes: 'church,bids,hire_manager,skills'});
  },
  methods: {
    ...mapActions({
      fetchJob: 'jobs/fetchJob'
    }),
  },
  
  computed: {
    ...mapState({
      job: state => state.jobs.current,
      user: state => state.auth.user,
    }),

    createdAt() {
      moment.updateLocale('en', {
        relativeTime: {
          s: 'a few seconds',
          d: '1d',
          mm: "%dmin",
          hh: "%dh",
          dd: "%dd",
          ww: "%dw",
          MM: "%dm",
          yy: "%dy"
        }
      });
      return moment(this.job.created_at).fromNow(true)
    },

    showNewLabel() {
      const today = moment()
      const createdDate = moment(this.job.created_at)
      return today.diff(createdDate, 'weeks') == 0;
    },

    applicantCount() {
      return `${this.job.bid_count} ${pluralize('Applicant', this.job.bid_count)}`
    },

    showSeeAll() {
      return this.job.bid_count && this.user.userable_type == 'hire_manager' && this.job.hire_manager.id == this.user.type.id
    },

    showDropdown() {
      return this.user.userable_type == 'hire_manager' && this.job.hire_manager.id == this.user.type.id
    },

    showApplyButton() {
      return this.user.userable_type == 'pro'
    }
  }
}
</script>

<style scoped>

</style>
