<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4.23535V19.7646" stroke="currentColor" stroke-width="1.05882" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path
      d="M15.5298 7.05884H10.2357C9.58047 7.05884 8.95207 7.31913 8.48875 7.78245C8.02543 8.24577 7.76514 8.87417 7.76514 9.52941C7.76514 10.1846 8.02543 10.813 8.48875 11.2764C8.95207 11.7397 9.58047 12 10.2357 12H13.7651C14.4203 12 15.0487 12.2603 15.5121 12.7236C15.9754 13.1869 16.2357 13.8153 16.2357 14.4705C16.2357 15.1258 15.9754 15.7542 15.5121 16.2175C15.0487 16.6808 14.4203 16.9411 13.7651 16.9411H7.76514"
      stroke="currentColor" stroke-width="1.05882" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "DollarIcon"
}
</script>

<style scoped>

</style>
