<template>
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.62042 12.6867H19.3353" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.62042 4.82927H19.3353" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.62042 20.5441H9.66461" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
 name:'MenuIcon'
}
</script>
