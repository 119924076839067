import Settings from "@/views/settings/Settings";
import PrivacyPolicy from "@/views/settings/PrivacyPolicy";
import EditNotifications from "@/views/settings/EditNotifications";
import ReportProblem from "@/views/settings/ReportProblem";
import AccountDetails from "@/views/settings/AccountDetails";

const routes = [
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/edit-notifications',
    name: 'EditNotifications',
    component: EditNotifications,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/report-problem',
    name: 'ReportProblem',
    component: ReportProblem,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account-details',
    name: 'AccountDetails',
    component: AccountDetails,
    meta: {
      requiresAuth: true,
    },
  },
];

export default routes;
