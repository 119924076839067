<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6667 13.6667H3.33333C2.59667 13.6667 2 13.07 2 12.3333V6.33333C2 5.59667 2.59667 5 3.33333 5H12.6667C13.4033 5 14 5.59667 14 6.33333V12.3333C14 13.07 13.4033 13.6667 12.6667 13.6667Z"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.7826 4.99992V3.66659C10.7826 2.92992 10.186 2.33325 9.44928 2.33325H6.55062C5.81395 2.33325 5.21729 2.92992 5.21729 3.66659V4.99992"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 6.33325L6.234 9.12725C6.452 9.27125 6.70733 9.34792 6.96867 9.34792H9.03133C9.29267 9.34792 9.548 9.27125 9.766 9.12725L14 6.33325"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "BriefcaseIcon",
};
</script>
