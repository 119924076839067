<template>
  <button @click="action" class="flex items-center focus:outline-none" :class="[color, fontWeight, color == 'text-secondary-dark' ? 'body-s-500' : 'label-s-500']">
    <LongArrowIcon v-if="icon" class="mr-3"/>
    <span>{{ text }}</span>
  </button>
</template>
<script>
import LongArrowIcon from "@/components/shared/svg/LongArrowIcon"

export default {
  name: 'BackButton',
  components: {LongArrowIcon},
  props: {
    text: {type: String, default: 'Back'},
    icon: {type: Boolean, default: true},
    color: {type: String, default: 'text-secondary-dark'},
    fontWeight: {type: String, default: 'font-semibold'},
  },
  methods: {
    action() {
      this.$emit('onClick')
    }
  }
}
</script>
