import api from '@/api'

const jobs = {
  namespaced: true,

  state: {
    current: null,
    items: [],
    templates: [],
    currentTemplate: null,
    form: {
      visible: true,
      type: 'one_time',
    },
    skills: [],
    jobPostingStep: 1,
    recommendedPros: [],
    pagination: {
      total: 0,
      count: 0,
      per_page: 15,
      current_page: 1,
      total_pages: 1,
      links: {}
    },
  },

  actions: {
    fetch({ commit }, payload = {}) {
      const params = new URLSearchParams();

      if (payload.includes) {
        params.append('include', payload.includes);
      }

      if (payload.filters) {
        for (const filter in payload.filters) {
          params.append(`filter[${filter}]`, payload.filters[filter]);
        }
      }

      if (payload.sort) {
        params.append('sort', payload.sort);
      }

      if (payload.page) {
        params.append('page', payload.page);
      }

      let data = {params};
      return api.get('/jobs', data)
        .then(({ data }) => {
          commit('setJobs', data.data)
          commit('setPagination', data.meta)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchTemplates({ commit }) {
      return api.get('/job-templates')
        .then(({ data }) => {
          commit('setTemplates', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    create({ commit }, payload) {
      return api.post('/jobs', payload)
        .then(({ data }) => {
          commit('setJob', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    update({ commit, state }, payload) {
      return api.put(`/jobs/${state.current.id}`, payload)
        .then(({ data }) => {
          commit('setJob', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error.response)
        })
    },

    inviteProToJob({ state }, payload) {
      return api.post(`/jobs/${state.current.id}/invite`, payload)
        .then(({ data }) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchRecommendedProsForJob({ state, commit }, ids = '') {
      const params = new URLSearchParams();
      if (ids != '') {
        params.append('ids', ids);
      }
      let data = {params};
      let url = `/jobs/${state.current.id}/invite`;
      return api.get(url, data)
        .then(({ data }) => {
          commit('setRecommended', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchJob({ commit }, payload) {
      let url = `/jobs/${payload.jobId}`;

      if (payload.includes) {
        url += `?include=${payload.includes}`
      }
      return api.get(url)
        .then(({ data }) => {
          commit('setJob', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    remove({ state, commit }) {
      return api.delete(`/jobs/${state.current.id}`)
        .then(({ data }) => {
          commit('setJob', null)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    updateTask({ state }, payload) {
      return api.put(`/jobs/${state.current.id}/tasks/${payload.id}`, payload)
        .then(({ data }) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
  },

  mutations: {
    setJobPostingStep(state, step) {
      state.jobPostingStep = step;
    },

    increaseJobPostingStep(state) {
      state.jobPostingStep++;
    },

    resetJobPostingSteps(state) {
      state.jobPostingStep = 1;
    },
    
    setJobs(state, data) {
      state.items = data;
    },

    setPagination(state, data) {
      if (data.pagination) {
        state.pagination = data.pagination;
      }
    },

    setTemplates(state, data) {
      state.templates = data;
    },

    setCurrentTemplate(state, data) {
      state.currentTemplate = data;
    },

    setJob(state, data) {
      state.current = data;
    },

    setJobForm(state, data) {
      state.form = data;
    },

    setType(state, data) {
      state.form.type = data;
    },

    setJobTasks(state, tasks) {
      state.form.tasks = tasks.map(task => task.text);
    },

    setJobCategories(state, categories) {
      state.form.categories = categories.map(category => category.id);
    },

    setProSkills(state, skills) {
      state.form.skills = skills.map(skill => skill.name);
    },

    toggleVisible(state) {
      state.form.visible = !state.form.visible
    },

    setRecommended(state, data) {
      state.recommendedPros = data;
    },
  },

}

export default jobs
