<template>
  <div class="relative">
    <InputLabel :label="label"/>
    <div :class="[background, borderRadius, border, xPadding, yPadding, fontSize, fontWeight, {'border-functional-error border-1.5': errors.length}]" class="flex justify-center items-center">
      <textarea
        :value="value"
        @input="updateValue($event)"
        ref="input"
        :placeholder="placeholder"
        :class="[background, color, fontWeight]"
        :rows="rows"
        :disabled="disabled"
        @keydown.enter="enterPressed($event.target.value, $event)"
        class="bg-transparent outline-none w-full"
      ></textarea>
      <div class="flex gap-4 self-end">
        <slot name="rightIcon1"></slot>
        <slot name="rightIcon2"></slot>
      </div>
    </div>
    <p class="text-functional-error text-xs mt-1" v-if="errors.length">{{ errors[0] }}</p>
  </div>
</template>

<script>
import InputLabel from "@/components/shared/labels/InputLabel"

export default {
  name: 'TextAreaInput',

  components: {InputLabel},

  props: {
    value: { type: String, required: false, default: '' },
    placeholder: { type: String, default: '' },
    background: { type: String, default: 'bg-white rounded-x' },
    borderRadius: { type: String, default: 'rounded-xl' },
    xPadding: { type: String, default: 'px-5' },
    yPadding: { type: String, default: 'py-3' },
    border: { type: String, default: 'border-transparent' },
    color: { type: String, default: '' },
    label: { type: String, default: '' },
    resizable: { type: String, default: 'resize-none' },
    rows: { type: String, default: '' },
    errors : { type: Array, required: false, default: () => []},
    fontSize: { type: String, required: false, default: 'text-base'},
    fontWeight: { type: String, required: false, default: 'font-normal'},
    verticalAlign: {type: String, required: false, default: ''},
    disabled: { type: Boolean, required: false, default: false },
    resizableInput: { type: Boolean, required: false, default: false },
    deleteOnEnter: { type: Boolean, required: false, default: true },
  },

  updated() {
    this.$refs.input.style.height = 'auto';
    this.$refs.input.style.height = `${this.$refs.input.scrollHeight}px`;
  },

  methods: {
    updateValue(event) {
      if (this.resizableInput) {
        this.resize(event);
      }

      this.$refs.input.value = event.target.value;
      this.$emit("input", event.target.value);
    },

    enterPressed(newValue, event) {
      this.$emit('enterPressed', newValue);
      if(this.deleteOnEnter) {
        event.preventDefault();
        this.$refs.input.value = '';
        this.$refs.input.style.height = 'auto';
      }
    },

    resize(event) {
      if (this.resizableInput) {
        event.target.style.height = 'auto';
        event.target.style.height = `${event.target.scrollHeight}px`;
      }
    },
  },

  computed: {
    alignClass() {
      switch (this.verticalAlign) {
        case 'middle':
          return 'middle-align-textbox';
        default:
          return '';
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .middle-align-textbox {
    vertical-align: middle;
  }

  textarea {
    resize: none;
  }
</style>
