<template>
  <div class="w-full bg-white rounded-cards">
    <!--Header-->
    <div class="w-full px-8 py-4 grid grid-cols-12 border-b border-cream">
      <div class="col-span-6 flex items-center">
        <AvatarImage
          :image="bid.pro.image_url"
          border-radius="rounded-md"
          height="h-7"
          width="w-7"
          class="mr-5"
        />
        <div class="leading-4">
          <h4 class="heading-s-700">{{ bid.pro.full_name }}</h4>
          <p class="label-xs-400">{{ bid.pro.location }}</p>
        </div>
        <div v-if="!userIsPro && bid.pro.type.recommended" class="ml-3">
          <div class="rounded-lg flex items-center w-full py-1.5 px-3 bg-functional-activeLight">
            <CheckStarIcon />
            <span class="ml-2 label-xs-500">Recommended</span>
          </div>
        </div>
      </div>

      <div v-if="userIsPro" class="col-span-6 flex justify-end items-center">
        <RemoveBidAlertDialog :job="job" :bidId="bid.id">
          <div slot="trigger" class="flex items-center">
            <XCircleIcon class="mr-1"/>
            <span class="label-xs-500">Remove your bid</span>
          </div>
        </RemoveBidAlertDialog>
      </div>
      <div v-else class="col-span-6 flex justify-end items-center space-x-5">
        <router-link class="button-label-m-500" :to="{ name: 'Pro', params: { proId: bid.pro.type.id } }">
          View portfolio
        </router-link>
        <div v-if="bid.status == 'pending'">
          <router-link class="button-label-m-500" :to="{ name: 'Inbox', params: { threadId: bid.thread_id } }">
            Message
          </router-link>
        </div>
        <div v-if="bid.status == 'pending'">
          <div>
            <AlertDialog
              v-if="user.type && user.type.church.can_create_jobs"
              :confirm="`Yes, hire ${bid.pro.first_name}`"
              @confirm="hirePro($event)"
              modalClasses="text-white bg-secondary-main hover:bg-secondary-dark py-3.5 body-m-400 px-6 flex justify-center items-center rounded-lg outline-none focus:outline-none border border-transparent transition-all duration-300"
            >
              <span>Hire</span>
              <template #title>
                Are you sure you want to hire {{ bid.pro.full_name }}? We will
                notify the other pros that this job is no longer open for bids
              </template>
            </AlertDialog>
            <button
              v-else
              class="text-white bg-secondary-main hover:bg-secondary-dark py-3.5 body-m-400 px-6 flex justify-center items-center rounded-lg outline-none focus:outline-none border border-transparent transition-all duration-300"
              @click="openCreditCardIncompleteModal"
            >
              Hire
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--Content-->
    <div class="p-6">
      <div class="pr-16">
        <p
          v-if="bid.personal_message"
          class="mb-6 text-auxiliary-blueLighter italic"
        >
          “{{ bid.personal_message }}”
        </p>
        <div class="flex flex-wrap">
          <Badge
            class="mb-3 mr-3"
            y-padding="py-2"
            background="bg-primary-10"
            color="text-auxiliary-blue"
            styles="body-s-500"
            :text="`$${bid.amount} ${job.type == 'one_time' ? '' : 'per month'}`"
            border="border-2 border-auxiliary-blue"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import AvatarImage from "@/components/shared/AvatarImage";
import Badge from "@/components/shared/Badge";
import AlertDialog from "@/components/shared/AlertDialog";
import XCircleIcon from "@/components/shared/svg/XCircleIcon";
import RemoveBidAlertDialog from '@/components/shared/modals/RemoveBidAlertDialog';
import CheckStarIcon from "@/components/shared/svg/CheckStarIcon";

export default {
  name: "ReviewBid",
  components: {
    AvatarImage,
    Badge,
    AlertDialog,
    XCircleIcon,
    RemoveBidAlertDialog,
    CheckStarIcon,
  },

  props: {
    bid: Object,
  },

  methods: {
    ...mapActions({
      acceptBid: "bids/accept",
    }),

    ...mapMutations({
      showModal: 'general/showModal',
      setBid: "bids/setBid",
    }),

    hirePro(callback) {
      this.acceptBid({ bidId: this.bid.id }).then((response) => {
        if (response) {
          callback.call();
          this.$router.push("/");
        }
      });
    },

    openCreditCardIncompleteModal(){
      this.setBid(this.bid);
      this.showModal({
        componentName:
        'CreditCardIncompleteModal',
        maxWH: 'max-w-2xl max-h-200'
      });
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      job: state => state.jobs.current,
    }),

    userIsPro() {
      return this.user.userable_type == 'pro'
    },
  },
};
</script>
